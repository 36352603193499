import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import firebase from "firebase";
import './JoinRoom.css';
import {joinRoomHandler} from "../../services/room";
import HomeImg from '../../assets/BG@2x.png';
import queryString from "query-string/index";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import HomeLogo from '../../assets/Logo.svg';
import {auth} from "firebaseui";
import Tooltip from "react-bootstrap/Tooltip";
import {OverlayTrigger} from "react-bootstrap";
import GoogleAds from '../GoogleAds';


class JoinRoom extends Component {

    constructor(props) {
        super(props)

        this.state = {
            rooms: [],
            filteredRooms: [],
            name: '',
            searchRoomText: '',
            bool: false,
            linkRoomName: '',
            link: false,
            isSignedIn: true,
            linkRedirect: false,
            spectateRedirect: false,
            roomCode: "", 
            intialBuyIn: 2000,
            roomSelectedToJoin: "",
            roomCodeSelectedToJoin: "",
            joinRoomRedirect: false,
            spectateRoomRedirect: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.searchTextChangeHandler = this.searchTextChangeHandler.bind(this)
    }

    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            auth.AnonymousAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccess: () => false
        }
    }

    componentDidMount = () => {

        let response;

        const {name, code} = queryString.parse(this.props.location.search);
        console.log('Link Room', name);
        if(name) {
            this.setState({
                link: true,
                linkRoomName: name,
                roomCode: code
            })
        }

        firebase.auth().onAuthStateChanged( async function(user) {
            if (user) {
                response = await joinRoomHandler().catch((err) => alert(err));
                console.log(response);
                this.setState({
                    rooms: response.data,
                    filteredRooms:  response.data,

                    name: firebase.auth().currentUser.displayName || '',
                    isSignedIn: true
                });
                this.interval = setInterval(() => this.tick(), 5000);
            } else {
                console.log('Firebase Signed Out user');
                this.setState({
                    isSignedIn: false
                })
            }
        }.bind(this));
    }

    async tick() {
        let response = await joinRoomHandler().catch((err) => alert(err));
        console.log(response);
        this.setState({
            rooms: response.data
        });

        const filtered = this.state.rooms.filter(room => {
            return room.roomName.toLowerCase().includes(this.state.searchRoomText.toLowerCase()) || room.tablePositions.reduce((isPersonPresent,tablePosition) => {

                return isPersonPresent || tablePosition.toLowerCase().includes(this.state.searchRoomText.toLowerCase());
            }, false)
        })
        this.setState({filteredRooms: filtered})
      }

    componentWillUnmount() {
        clearInterval(this.interval);
      }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            bool: this.state.name.length > 0 ? true : false
        })
    }

    spectateLinkHandler = () => {this.setState({spectateRedirect: true})}

    joinLinkHandler = () => {
        let {linkRoomName, rooms} = this.state;
        // if(rooms.map(item => item.roomName).includes(linkRoomName)) {
        //     this.setState({
        //         linkRedirect: true
        //     })
        // }
        // else{
        //     alert(`Room ${linkRoomName} doesn't exist`);
        // }
        this.setState({
            linkRedirect: true
        })
    }

    handleChange(event) {
        const {name, value, type, checked} = event.target;
        this.setState({[name]: value})
    }

    searchTextChangeHandler(event) {
        const {name, value, type, checked} = event.target;
        this.setState({searchRoomText: value})

        const filtered = this.state.rooms.filter(room => {
            return room.roomName.toLowerCase().includes(value.toLowerCase()) || room.tablePositions.reduce((isPersonPresent,tablePosition) => {

                return isPersonPresent || tablePosition.toLowerCase().includes(value.toLowerCase());
            }, false)
        })

        this.setState({filteredRooms: filtered})



    }

    onJoinRoomClick = (room) => {
        let { name, intialBuyIn } = this.state;
        this.setState({roomSelectedToJoin: room.roomName, roomCodeSelectedToJoin: room.roomCode})
        console.log('ROOM JOIN CLICKED', room);
        if(name.length === 0) {alert('ENTER YOUR NAME')}
        else if(room.clients === 7 && !room.callingPlayerInfo.isUserPlayer) {
            alert('TABLE FULL, JOIN AS SPECTATOR')
        }
        else if(intialBuyIn > room.settings.MAX_INITIAL_BUY_IN || intialBuyIn < room.settings.MIN_INITIAL_BUY_IN) {alert('WRONG AMOUNT ENTERED')}
        else if(!room.callingPlayerInfo.isUserPlayer && !room.callingPlayerInfo.isUserSpectator) {
            if(room.roomStatus == 0) {
                if(room.tablePositions.includes(name)) {alert('THERE IS ALREADY A PLAYER WITH THE SAME NAME, ENTER WITH A DIFFERENT NAME')}
                else {this.setState({joinRoomRedirect: true})}
            }
            else if(room.roomStatus === 1) {this.setState({spectateRoomRedirect: true})}
        }
        else if(room.callingPlayerInfo.isUserPlayer) {
            if(room.callingPlayerInfo.userName === name) {this.setState({joinRoomRedirect: true})}
            else {alert(`Enter with your previous name: ${room.callingPlayerInfo.userName}`)}
        }
        else if(room.callingPlayerInfo.isUserSpectator) {this.setState({spectateRoomRedirect: true})}
    }

    onSpectateRoomClick = (room) => {
        let { name, intialBuyIn } = this.state;
        this.setState({roomSelectedToJoin: room.roomName, roomCodeSelectedToJoin: room.roomCode})
        console.log('ROOM SPECTATE CLICKED', room);
        if(name.length === 0) {alert('ENTER YOUR NAME')}
        else if(intialBuyIn > room.settings.MAX_INITIAL_BUY_IN || intialBuyIn < room.settings.MIN_INITIAL_BUY_IN) {alert('WRONG AMOUNT ENTERED')}
        else if(!room.callingPlayerInfo.isUserPlayer && !room.callingPlayerInfo.isUserSpectator) {this.setState({spectateRoomRedirect: true})}
        else if(room.callingPlayerInfo.isUserSpectator) {this.setState({spectateRoomRedirect: true})}
    }

    render() {
        const { rooms, filteredRooms, searchRoomText, name, linkRoomName, isSignedIn, joinRoomRedirect, roomCodeSelectedToJoin, roomSelectedToJoin, spectateRoomRedirect } = this.state;

        return (
            <div className="createroomoutercontainer">
                {!isSignedIn && <Redirect to={`/`}/>}
                <div className="joinroomsize">
                {/* <img className="homeImgjoin" src={HomeImg} alt="Home"/> */}
                <div className="homepagelink">
                    <Link to={'/'} className="homepageBtn">
                        <i class="fa fa-home" aria-hidden="true"></i> <span>HOME</span>
                    </Link>
                    <div className="ad-class">
                    {/* <GoogleAds 
                                            slot="9909118413" 
                                            style={{
                                                display: 'inline-block',
                                                width: '200px',
                                                height: '150px',
                                        }}/> */}
                    </div>
                </div>
                {/* {!isSignedIn && <div className="joinRoomContainer">
                    <img className="joinLogo" src={HomeLogo} alt="HomeLogo"/>
                    <div className="iconsfirebase">
                        <StyledFirebaseAuth
                                uiConfig={this.uiConfig}
                                firebaseAuth={firebase.auth()}
                            />
                    </div>
                </div>} */}
                {isSignedIn && <div>
                    <div className="joinRoomContainer">
                        {joinRoomRedirect && <Redirect to={`/game?name=${name}&room=${roomSelectedToJoin}&spectate=${false}&initBuy=${this.state.intialBuyIn}&code=${roomCodeSelectedToJoin}`}/>}
                        {spectateRoomRedirect && <Redirect to={`/game?name=${name}&room=${roomSelectedToJoin}&spectate=${true}&initBuy=${this.state.intialBuyIn}&code=${roomCodeSelectedToJoin}`}/>}
                        <div className="heading mb-2"><h1>Join Room</h1></div>
                        <br/><br/>
                        <h2>Enter your name</h2>
                        <div>
                            <input className="joinRoomInput" type="text" name="name" value={name} onChange={this.changeHandler}/>
                        </div>

                        <div className="mt-3">
                        {!this.state.link && <h3>Available Rooms to Join</h3>}
                        {this.state.link && <h3>{linkRoomName}</h3>}
                        {
                            rooms.length === 0 && !this.state.link &&
                            <div>
                                <span>No rooms to join at the moment !</span>
                            </div>
                        }
                        {rooms.length > 0 && !this.state.link && 
                        <div>
                        BUY-IN
                        <input name="intialBuyIn" className="buyinInput" type="number" value={Number(this.state.intialBuyIn).toString()}
                            onChange={this.handleChange}/>
                        </div>}
                        <br/>

                        {!this.state.link && <div id="id" className="joinRoomInput">

                            <i className="fa fa-search" aria-hidden="true"></i>
                            <input
                                name="searchRoomText" value={searchRoomText}
                                className="ml-3 w-75 search-input-text-box"
                                type="text"
                                placeholder={"Search a room or player..."}
                                onChange={this.searchTextChangeHandler}/>



                        </div>}
                        <br/>

                        {rooms.length>0 && filteredRooms.length===0 && <div>No search results found</div>}
                        {filteredRooms.length > 0 && !this.state.link && <div className="roomdata">{
                            filteredRooms.map(room =>
                                <div key={room.roomName} className="cardBorder p-3 mb-2">

                                    <OverlayTrigger placement="bottom" overlay={<Tooltip><span>Inside the room:</span><ul className='list-unstyled'>{room.tablePositions.map((player)=><li>{player}</li>)}</ul></Tooltip>}>
                                        <span>
                                            Players: {room.clients}
                                        </span>
                                    </OverlayTrigger>

                                    &nbsp; &nbsp; Room: {room.roomName}

                                    <br/>
                                    Min: {room.settings.MIN_INITIAL_BUY_IN} &nbsp; &nbsp; Max: {room.settings.MAX_INITIAL_BUY_IN}
                                    {/* <Link onClick={e => (!name || !room.roomName) ? e.preventDefault() : null}
                                        to={`/game?name=${name}&room=${room.roomName}&spectate=${false}&initBuy=${this.state.intialBuyIn}&code=${room.roomCode}`}> */}
                                    <div>
                                        <button style={{fontSize: "14px", width: "160px", padding: "4px"}} className='button'
                                                type="submit" onClick={() => this.onJoinRoomClick(room)}>
                                            Join
                                        </button>
                                    </div>
                                    {/* </Link> */}
                                    <br/>
                                    {/* <Link onClick={e => (!name || !room.roomName) ? e.preventDefault() : null}
                                        to={`/game?name=${name}&room=${room.roomName}&spectate=${true}&initBuy=${this.state.intialBuyIn}&code=${room.roomCode}`}> */}
                                        {!room.callingPlayerInfo.isUserPlayer && <div>
                                            <button style={{fontSize: "14px", width: "160px", padding: "4px"}} className='button'
                                                    type="submit" onClick={() => this.onSpectateRoomClick(room)}>
                                                Spectate
                                            </button>
                                        </div>}
                                    {/* </Link> */}
                                </div>
                            )
                        }</div>}
                        {this.state.link && <div>
                                    {/* <Link onClick={e => (!name || !linkRoomName) ? e.preventDefault() : null}
                                        to={`/game?name=${name}&room=${linkRoomName}`}> */}
                                        <div>
                                        BUY-IN
                                        <input name="intialBuyIn" className="buyinInput" type="number" value={Number(this.state.intialBuyIn).toString()}
                                            onChange={this.handleChange}/>
                                        </div>
                                        <div onClick={this.joinLinkHandler}>
                                            <button disabled={this.state.name.length === 0} style={{fontSize: "14px", width: "160px", padding: "4px"}} className='button'
                                                    type="submit">
                                                Join
                                            </button>
                                        </div>
                                        <br/>
                                        <div onClick={this.spectateLinkHandler}>
                                            <button disabled={this.state.name.length === 0} style={{fontSize: "14px", width: "160px", padding: "4px"}} className='button'
                                                    type="submit">
                                                Spectate
                                            </button>
                                        </div>
                                        {this.state.linkRedirect && <Redirect to={`/game?name=${name}&room=${linkRoomName}&spectate=${false}&initBuy=${this.state.intialBuyIn}&code=${this.state.roomCode}`}/>}
                                        {this.state.spectateRedirect && <Redirect to={`/game?name=${name}&room=${linkRoomName}&spectate=${true}&initBuy=${this.state.intialBuyIn}&code=${this.state.roomCode}`}/>}
                                    {/* </Link> */}
                            </div>}
                        </div>
                    </div>
                </div>}
                </div>
                <div className="joinroominfo">
                {/* <img className="homeImgjoin" src={HomeImg} alt="Home"/> */}
                <div className="createroominfo">
                        Currently, playing from mobile devices is not supported. We are working to support it. You can play with a larger sized screen.
                        <br/><br/>
                        <Link to={'/'} className="homepageBtn">
                            <i class="fa fa-home" aria-hidden="true"></i> <span>HOME</span>
                        </Link>
                </div>
                </div>
            </div>
         );
    }
}

export default JoinRoom;
