'use strict';

// UTILS method
import {suitSymbolsMappingForWild, cardValuesMappingForWild, reverseSuitSymbolsMappingForWild, reverseCardValuesMappingForWild, JOKER_SUIT, suitsSet} from '../CardsConstants' 
import {separateWildcards, isAllowCardsToEvaluate, sevenChooseFiveIndexCombinations, sixChooseFiveIndexCombinations, nineChooseFiveIndexCombinations, eightChooseFiveIndexCombination} from '../CardsUtils'

const convertInDeckCardsToWild = (cards, wildCardsSettings, loSettings) => {

  if(wildCardsSettings){
    var {wildCardsSet, jokersInDeckSet, handsMustHaveOneNaturalCard} = wildCardsSettings
  }
  else{ return cards }
 
  const {playerWildCards, playerNonWildCards} = separateWildcards(cards, wildCardsSet, jokersInDeckSet)
  
  let convertedWildCards = []
  let rankToReplace = (loSettings) ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER
  let wildCardToReplace
  for (let i = 0; i < playerWildCards.length; i++) {
      const card = playerWildCards[i];
      let cardToAdd = card
      if(wildCardsSet.has(card)){
          cardToAdd = cardValuesMappingForWild[card[0]] + suitSymbolsMappingForWild[card[1]]
          const cardRank = (loSettings) ?  ((loSettings.considerAceLow) ? loAceValues.indexOf(card[0]) : hiValues.indexOf(card[0])) : values.indexOf(card[0])
          const cond = (loSettings) ? cardRank < rankToReplace : cardRank > rankToReplace
          if(cond){ 
            wildCardToReplace = cardToAdd
            rankToReplace = cardRank
          }
      }
      convertedWildCards.push(cardToAdd)
  }
  
  let cardsToReturn = [...convertedWildCards, ...playerNonWildCards]
  if(! handsMustHaveOneNaturalCard){ return cardsToReturn }

  const noOfWildCards = playerWildCards.length
  const noOfCards = cards.length
  const noOfNonWildCards = playerNonWildCards.length

  const replaceWildCardWithHighestRank = (wildCardToReplace, convertedWildCards, playerNonWildCards) => {
    if(! wildCardToReplace){ return cardsToReturn}
    convertedWildCards.splice(convertedWildCards.indexOf(wildCardToReplace), 1)
    playerNonWildCards.push(reverseCardValuesMappingForWild[wildCardToReplace[0]] + reverseSuitSymbolsMappingForWild[wildCardToReplace[1]])
  }

  if(noOfCards <= 5){
    if(noOfWildCards < noOfCards){ return cardsToReturn}
    replaceWildCardWithHighestRank(wildCardToReplace, convertedWildCards, playerNonWildCards)
  }
  else{
    if(noOfWildCards < 5){ return cardsToReturn}
    if(noOfNonWildCards > 0){
      convertedWildCards = convertedWildCards.slice(0,4)
    }
    else{
      replaceWildCardWithHighestRank(wildCardToReplace, convertedWildCards, playerNonWildCards)
      convertedWildCards = convertedWildCards.slice(0,4)
    }
  }
  
  return [...convertedWildCards, ...playerNonWildCards]
}

const convertInDeckWildCardToNormalCard = (card) => {
  const cond = suitsSet.has(card.suit) || card.suit === JOKER_SUIT
  if(! cond){
    card.value = reverseCardValuesMappingForWild[card.value]
    card.suit = reverseSuitSymbolsMappingForWild[card.suit]
  }
  return card
}

// NOTE: The 'joker' will be denoted with a value of 'O' and any suit.
var values = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A'];
var hiValues = values.slice(1, values.length)
var loAceValues = ['A', '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K'];

/**
 * Base Card class that defines a single card.
 */
class Card {
  constructor(str, loSettings) {
    this.value = str.substr(0, 1);
    this.suit = str.substr(1, 1).toLowerCase();
    let valuesArr = values
    if(loSettings){
      const {considerAceLow} = loSettings
      valuesArr = (considerAceLow) ? loAceValues : hiValues
      this.loSettings = loSettings
    }
    this.rank = valuesArr.indexOf(this.value);
    this.wildValue = str.substr(0, 1);
  }

  toString() {
    return this.wildValue.replace('T', '10') + this.suit;
  }

  static sort(a, b) {
    if (a.rank > b.rank) {
      return -1;
    } else if (a.rank < b.rank) {
      return 1;
    } else {
      return 0;
    }
  }

  static sortLo(a, b) {
    if (a.rank > b.rank) {
      return 1;
    } else if (a.rank < b.rank) {
      return -1;
    } else {
      return 0;
    }
  }
}

/**
 * Base Hand class that handles comparisons of full hands.
 */
export class Hand {
  constructor(cards, name, game, settings, canDisqualify) {
    settings = settings || {}
    const {wildCardsSettings} = settings
    this.cardsProvided = [...cards]
    this.cardPool = [];
    this.cards = [];
    this.suits = {};
    this.values = [];
    this.wilds = [];
    this.nonWilds = [];
    this.discardedCards = []; // If rule is atleast one natural card needed, then we discard some cards (not use in hand evaluation) in few cases.
    this.name = name;
    this.game = game;
    this.sfLength = 0;
    this.alwaysQualifies = true;
    this.settings = settings
    if(wildCardsSettings){ this.wildCardsSettings = wildCardsSettings }
    
    cards = convertInDeckCardsToWild(cards, wildCardsSettings) // ['As', 'Rw', 'Ts', ] --> ['Rz', 'Rw', 'Lz']

    // Qualification rules apply for dealer's hand.
    // Also applies for single player games, like video poker.
    if (canDisqualify && this.game.lowestQualified) {
      this.alwaysQualifies = false;
    }

    // Ensure no duplicate cards in standard game.
    if (game.descr === 'standard' && new Set(cards).size !== cards.length) {
      throw new Error('Duplicate cards');
    }
    
    // Get rank based on game.
    var handRank = this.game.handValues.length;
    for (var i=0; i<this.game.handValues.length; i++) {
      if (this.game.handValues[i] === this.constructor) {
        break;
      }
    }
    this.rank = handRank - i;

    // Set up the pool of cards.
    this.cardPool = cards.map(function(c) {
      return (typeof c === 'string') ? new Card(c) : c;
    });

    // Fix the card ranks for wild cards, and sort.
    for (var i=0; i<this.cardPool.length; i++) {
      card = this.cardPool[i];
      if (card.value === this.game.wildValue) {
        card.rank = -1;
      }
      if (card.rank === -1) { this.wilds.push(card); }
      else  { this.nonWilds.push(card) }
    }

    this.cardPool = this.cardPool.sort(Card.sort);

    // Create the arrays of suits and values.
    var obj, obj1, key, key1, card;
    for (var i=0; i<this.cardPool.length; i++) {
      // Make sure this value already exists in the object.
      card = this.cardPool[i];

      if(card.rank !== -1 ){
        (obj = this.suits)[key = card.suit] || (obj[key] = []);
        (obj1 = this.values)[key1 = card.rank] || (obj1[key1] = []);

        // Add the value to the array for that type in the object.
        this.suits[card.suit].push(card);
        this.values[card.rank].push(card);
      }
    }

    this.noOfWildCards = this.wilds.length
    this.noOfNonJokersWildCards = this.wilds.filter(card => card.suit !== JOKER_SUIT).length
    this.noOfNonWildsCards = this.nonWilds.length

    this.values.reverse();
    this.isPossible = this.solve();
  }

  /**
   * Compare current hand with another to determine which is the winner.
   * @param  {Hand} a Hand to compare to.
   * @return {Number}
   */
  compare(a) {
    if (this.rank < a.rank) {
      return 1;
    } else if (this.rank > a.rank) {
      return -1;
    }

    var result = 0;
    for (var i=0; i<=4; i++) {
      if (this.cards[i] && a.cards[i] && this.cards[i].rank < a.cards[i].rank) {
        result = 1;
        break;
      } else if (this.cards[i] && a.cards[i] && this.cards[i].rank > a.cards[i].rank) {
        result = -1;
        break;
      }
    }

    if(result !== 0) { return result }

    return result;
  }

  /**
   * Determine whether a hand loses to another.
   * @param  {Hand} hand Hand to compare to.
   * @return {Boolean}
   */
  loseTo(hand) {
    return (this.compare(hand) > 0);
  }

  /**
   * Determine the number of cards in a hand of a rank.
   * @param  {Number} val Index of this.values.
   * @return {Number} Number of cards having the rank, including wild cards.
   */
  getNumCardsByRank(val) {
    var cards = this.values[val];
    var checkCardsLength = (cards) ? cards.length : 0;

    for (var i=0; i<this.wilds.length; i++) {
      if (this.wilds[i].rank > -1) {
        continue;
      } else if (cards) {
        if (this.game.wildStatus === 1 || cards[0].rank === values.length - 1) {
          checkCardsLength += 1;
        }
      } else if (this.game.wildStatus === 1 || val === values.length - 1) {
        checkCardsLength += 1;
      }
    }

    return checkCardsLength;
  }

  /**
   * Determine the cards in a suit for a flush.
   * @param  {String} suit Key for this.suits.
   * @param  {Boolean} setRanks Whether to set the ranks for the wild cards.
   * @return {Array} Cards having the suit, including wild cards.
   */
  getCardsForFlush(suit, setRanks) {
    var cards = (this.suits[suit] || []).sort(Card.sort);

    for (var i=0; i<this.wilds.length; i++) {
      var wild = this.wilds[i];

      if (setRanks) {
        var j=0;
        while (j<values.length && j<cards.length) {
          if (cards[j].rank === values.length-1-j) {
            j += 1;
          } else {
            break;
          }
        }
        wild.rank = values.length-1-j;
        wild.wildValue = values[wild.rank];
      }

      cards.push(wild);
      cards = cards.sort(Card.sort);
    }

    return cards;
  }

  /**
   * Resets the rank and wild values of the wild cards.
   */
  resetWildCards() {
    for (var i=0; i<this.wilds.length; i++) {
      this.wilds[i].rank = -1;
      this.wilds[i].wildValue = this.wilds[i].value;
    }
  }

  /**
   * Highest card comparison.
   * @return {Array} Highest cards
   */
  nextHighest() {
    var picks;
    var excluding = [];
    excluding = excluding.concat(this.cards);

    picks = this.cardPool.filter(function(card) {
      if (excluding.indexOf(card) < 0) {
        return true;
      }
    });

    // Account for remaining wild card when it must be ace.
    if (this.game.wildStatus === 0) {
      for (var i=0; i<picks.length; i++) {
        var card = picks[i];
        if (card.rank === -1) {
          card.wildValue = 'A';
          card.rank = values.length - 1;
        }
      }
      picks = picks.sort(Card.sort);
    }

    return picks;
  }

  /**
   * Return list of contained cards in human readable format.
   * @return {String}
   */
  toString() {
    var cards = this.cards.map(function(c) {
      return c.toString();
    });

    return cards.join(', ');
  }

  /**
   * Return array of contained cards.
   * @return {Array}
   */
  toArray() {
    var cards = this.cards.map(function(c) {
      return c.toString();
    });

    return cards;
  }

  /**
   * Determine if qualifying hand.
   * @return {Boolean}
   */
  qualifiesHigh() {
    if (!this.game.lowestQualified || this.alwaysQualifies) {
      return true;
    }

    return (this.compare(Hand.solve(this.game.lowestQualified, this.game)) <= 0);
  }

  /**
   * Find highest ranked hands and remove any that don't qualify or lose to another hand.
   * @param  {Array} hands Hands to evaluate.
   * @return {Array}       Winning hands.
   */
  static winners(hands) {
    hands = hands.filter(function(h) {
      return h.qualifiesHigh();
    });

    var highestRank = Math.max.apply(Math, hands.map(function(h) {
      return h.rank;
    }));

    hands = hands.filter(function(h) {
      return h.rank === highestRank;
    });

    hands = hands.filter(function(h) {
      var lose = false;
      for (var i=0; i<hands.length; i++) {
        lose = h.loseTo(hands[i]);
        if (lose) {
          break;
        }
      }

      return !lose;
    });

    return hands;
  }

  /**
   * Build and return the best hand.
   * @param  {Array} cards Array of cards (['Ad', '3c', 'Th', ...]).
   * @param  {String} game Game being played.
   * @param  {Boolean} canDisqualify Check for a qualified hand.
   * @return {Hand}       Best hand.
   */
  static solve(cards, game, settings, canDisqualify) {
    game = game || 'standard';
    game = (typeof game === 'string') ? new Game(game) : game;
    cards = cards || [''];
    settings = settings || {}
    
    var hands = game.handValues;
    var result = null;

    for (var i=0; i<hands.length; i++) {
      result = new hands[i](cards, game, settings, canDisqualify);
      if (result.isPossible) {
        break;
      }
    }

    // It might happen that when A assumes value of 1 in straight A,2,3,4,5, we get card.value '1'.
    // Convert it back to 'A'
    for(const card of result.cards){ if(card.value === '1'){ card.value = 'A'} }

    // convert cards back
    result.cards = result.cards.map(card => convertInDeckWildCardToNormalCard(card))
    result.cardPool = result.cardPool.map(card => convertInDeckWildCardToNormalCard(card))

    return result;
  }

  /**
   * Separate cards based on if they are wild cards.
   * @param  {Array} cards Array of cards (['Ad', '3c', 'Th', ...]).
   * @param  {Game} game Game being played.
   * @return {Array} [wilds, nonWilds] Wild and non-Wild Cards.
   */
  static stripWilds(cards, game) {
    var card, wilds, nonWilds;
    cards = cards || [''];
    wilds = [];
    nonWilds = [];

    for (var i=0; i<cards.length; i++) {
      card = cards[i];
      if (card.rank === -1) {
        wilds.push(cards[i]);  
      } else {
        nonWilds.push(cards[i]);  
      }
    }

    return [wilds, nonWilds];
  }

  handleAllWildsCaseForNofAkind(){
    let wildCardsToReplace = null;
    if(this.constructor === FiveOfAKind){
      if(this.wilds.length >= 5){ wildCardsToReplace = this.wilds.slice(0, 5) }
    }
    else if(this.constructor === FourOfAKind){
      if(this.wilds.length === 4){ wildCardsToReplace = this.wilds }
    }
    else if(this.constructor === ThreeOfAKind){
      if(this.wilds.length === 3){ wildCardsToReplace = this.wilds }
    }
    else if(this.constructor === OnePair){
      if(this.wilds.length === 2){ wildCardsToReplace = this.wilds }
    }
    else if(this.constructor === HighCard){
      if(this.wilds.length === 1){ wildCardsToReplace = this.wilds }
    }

    if(! wildCardsToReplace) { return }

    for(const wildCard of wildCardsToReplace){
      wildCard.rank = values.length-1
      wildCard.wildValue = values[wildCard.rank]
      this.cards.push(wildCard)
    }
  }
}
class StraightFlush extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Straight Flush', game, settings, canDisqualify);
  }

  solve() {
    var cards;
    this.resetWildCards();
    var possibleStraight = null;
    var nonCards = [];

    for (var suit in this.suits) {
      cards = this.getCardsForFlush(suit, false);
      if (cards && cards.length >= this.game.sfQualify) {
        possibleStraight = cards;
        break;
      } 
    }

    
    const res = this.handleAllWildsCase()
    possibleStraight = (res) ? res : possibleStraight
    
    if (possibleStraight) {
      if (this.game.descr !== 'standard') {
        for (let suit in this.suits) {
          if (possibleStraight[0].suit !== suit) {
            nonCards = nonCards.concat(this.suits[suit] || []);
            nonCards = Hand.stripWilds(nonCards, this.game)[1];
          }
        }
      }
      var straight = new Straight(possibleStraight, this.game, this.settings);
      if (straight.isPossible) {
        this.cards = straight.cards;
        // this.cards = this.cards.concat(nonCards); // Not needed
        this.sfLength = straight.sfLength;
      }
    }

    if (this.cards[0] && this.cards[0].rank === 13) {
      this.descr = 'Royal Flush';
    } else if (this.cards.length >= this.game.sfQualify) {
      this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + suit + ' High';
    }

    return this.cards.length >= this.game.sfQualify;
  }
     
  handleAllWildsCase(){
    if(this.noOfWildCards < 5){ return  }
    return this.wilds.slice(0,5) 
  }
}

class RoyalFlush extends StraightFlush {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, game, settings, canDisqualify);
  }

  solve() {
    this.resetWildCards();
    var result = super.solve();
    return result && this.descr === 'Royal Flush';
  }
}

class NaturalRoyalFlush extends RoyalFlush {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, game, settings, canDisqualify);
  }

  solve() {
    var i = 0;
    this.resetWildCards();
    var result = super.solve();
    if (result && this.cards) {
      for (i=0; i<this.game.sfQualify && i<this.cards.length; i++) {
        if (this.cards[i].value === this.game.wildValue) {
          result = false;
          this.descr = 'Wild Royal Flush';
          break;
        }
      }
      if (i === this.game.sfQualify) {
        this.descr = 'Royal Flush';
      }
    }
    return result;
  }
}

class WildRoyalFlush extends RoyalFlush {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, game, settings, canDisqualify);
  }

  solve() {
    var i = 0;
    this.resetWildCards();
    var result = super.solve();
    if (result && this.cards) {
      for (i=0; i<this.game.sfQualify && i<this.cards.length; i++) {
        if (this.cards[i].value === this.game.wildValue) {
          this.descr = 'Wild Royal Flush';
          break;
        }
      }
      if (i === this.game.sfQualify) {
        result = false;
        this.descr = 'Royal Flush';
      }
    }
    return result;
  }
}

class FiveOfAKind extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Five of a Kind', game, settings, canDisqualify);
  }

  solve() {
    this.resetWildCards();

    for (var i=0; i<this.values.length; i++) {
      if (this.getNumCardsByRank(i) >= 5) {
        this.cards = this.values[i] || [];
        for (var j=0; j<this.wilds.length && this.cards.length<5; j++) {
          var wild = this.wilds[j];
          if (this.cards) {
            wild.rank = this.cards[0].rank;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
        this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-5));
        break;
      }
    }

    this.handleAllWildsCaseForNofAkind()

    if (this.cards.length >= 5) {
      this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + '\'s';
    }

    return this.cards.length >= 5;
  }    
}

class FourOfAKindPairPlus extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Four of a Kind with Pair or Better', game, settings, canDisqualify);
  }

  solve() {
    var cards;
    this.resetWildCards();

    for (var i=0; i<this.values.length; i++) {
      if (this.getNumCardsByRank(i) === 4) {
        this.cards = this.values[i] || [];
        for (var j=0; j<this.wilds.length && this.cards.length<4; j++) {
          var wild = this.wilds[j];
          if (this.cards) {
            wild.rank = this.cards[0].rank;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
        break;
      }
    }

    if (this.cards.length === 4) {
      for (i=0; i<this.values.length; i++) {
        cards = this.values[i];
        if (cards && this.cards[0].wildValue === cards[0].wildValue) {
          continue;
        }
        if (this.getNumCardsByRank(i) >= 2) {
          this.cards = this.cards.concat(cards || []);
          for (var j=0; j<this.wilds.length; j++) {
            var wild = this.wilds[j];
            if (wild.rank !== -1) {
              continue;
            }
            if (cards) {
              wild.rank = cards[0].rank;
            } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
              wild.rank = values.length - 2;
            } else {
              wild.rank = values.length - 1;
            }
            wild.wildValue = values[wild.rank];
            this.cards.push(wild);
          }
          this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-6));
          break;
        }
      }
    }

    if (this.cards.length >= 6) {
      var type = this.cards[0].toString().slice(0, -1) + '\'s over ' + this.cards[4].toString().slice(0, -1) + '\'s';
      this.descr = this.name + ', ' + type;
    }

    return this.cards.length >= 6;
  }
}

class FourOfAKind extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Four of a Kind', game, settings, canDisqualify);
  }

  solve() {
    this.resetWildCards();

    for (var i=0; i<this.values.length; i++) {
      if (this.getNumCardsByRank(i) === 4) {
        this.cards = this.values[i] || [];
        for (var j=0; j<this.wilds.length && this.cards.length<4; j++) {
          var wild = this.wilds[j];
          if (this.cards) {
            wild.rank = this.cards[0].rank;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }

        this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-4));
        break;
      }
    }

    this.handleAllWildsCaseForNofAkind()

    if (this.cards.length >= 4) {
      if (this.game.noKickers) {
        this.cards.length = 4;
      }

      this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + '\'s';
    }

    return this.cards.length >= 4;
  }
}

class FourWilds extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Four Wild Cards', game, settings, canDisqualify);
  }

  solve() {
    if (this.wilds.length === 4) {
      this.cards = this.wilds;
      this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-4));
    }

    if (this.cards.length >= 4) {
      if (this.game.noKickers) {
        this.cards.length = 4;
      }

      this.descr = this.name;
    }

    return this.cards.length >= 4;
  }
}

class ThreeOfAKindTwoPair extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Three of a Kind with Two Pair', game, settings, canDisqualify);
  }

  solve() {
    var cards;
    this.resetWildCards();

    for (var i=0; i<this.values.length; i++) {
      if (this.getNumCardsByRank(i) === 3) {
        this.cards = this.values[i] || [];
        for (var j=0; j<this.wilds.length && this.cards.length<3; j++) {
          var wild = this.wilds[j];
          if (this.cards) {
            wild.rank = this.cards[0].rank;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
        break;
      }
    }

    if (this.cards.length === 3) {
      for (var i=0; i<this.values.length; i++) {
        var cards = this.values[i];
        if (cards && this.cards[0].wildValue === cards[0].wildValue) {
          continue;
        }
        if (this.cards.length > 5 && this.getNumCardsByRank(i) === 2) {
          this.cards = this.cards.concat(cards || []);
          for (var j=0; j<this.wilds.length; j++) {
            var wild = this.wilds[j];
            if (wild.rank !== -1) {
              continue;
            }
            if (cards) {
              wild.rank = cards[0].rank;
            } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
              wild.rank = values.length - 2;
            } else {
              wild.rank = values.length - 1;
            }
            wild.wildValue = values[wild.rank];
            this.cards.push(wild);
          }
          this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-4));
          break;
        } else if (this.getNumCardsByRank(i) === 2) {
          this.cards = this.cards.concat(cards);
          for (var j=0; j<this.wilds.length; j++) {
            var wild = this.wilds[j];
            if (wild.rank !== -1) {
              continue;
            }
            if (cards) {
              wild.rank = cards[0].rank;
            } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
              wild.rank = values.length - 2;
            } else {
              wild.rank = values.length - 1;
            }
            wild.wildValue = values[wild.rank];
            this.cards.push(wild);
          }
        }
      }
    }

    if (this.cards.length >= 7) {
      var type = this.cards[0].toString().slice(0, -1) + '\'s over ' + this.cards[3].toString().slice(0, -1) + '\'s & ' + this.cards[5].value + '\'s';
      this.descr = this.name + ', ' + type;
    }

    return this.cards.length >= 7;
  }
}

class FullHouse extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Full House', game, settings, canDisqualify);
  }

  solve() {
    var cards;
    this.resetWildCards();

    for (var i=0; i<this.values.length; i++) {
      if (this.getNumCardsByRank(i) === 3) {
        this.cards = this.values[i] || [];
        for (var j=0; j<this.wilds.length && this.cards.length<3; j++) {
          var wild = this.wilds[j];
          if (this.cards) {
            wild.rank = this.cards[0].rank;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
        break;
      }
    }

    if (this.cards.length === 3) {
      for (i=0; i<this.values.length; i++) {
        cards = this.values[i];
        if (cards && this.cards[0].wildValue === cards[0].wildValue) {
          continue;
        }
        if (this.getNumCardsByRank(i) >= 2) {
          this.cards = this.cards.concat(cards || []);
          for (var j=0; j<this.wilds.length; j++) {
            var wild = this.wilds[j];
            if (wild.rank !== -1) {
              continue;
            }
            if (cards) {
              wild.rank = cards[0].rank;
            } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
              wild.rank = values.length - 2;
            } else {
              wild.rank = values.length - 1;
            }
            wild.wildValue = values[wild.rank];
            this.cards.push(wild);
          }
          this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-5));
          break;
        }
      }
    }

    if (this.cards.length >= 5) {
      var type = this.cards[0].toString().slice(0, -1) + '\'s over ' + this.cards[3].toString().slice(0, -1) + '\'s';
      this.descr = this.name + ', ' + type;
    }

    return this.cards.length >= 5;
  }
}

class Flush extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Flush', game, settings, canDisqualify);
  }

  solve() {
    this.sfLength = 0;
    this.resetWildCards();

    for (var suit in this.suits) {
      var cards = this.getCardsForFlush(suit, true);
      if (cards.length >= this.game.sfQualify) {
        this.cards = cards;
        break;
      }
    }

    if (this.cards.length >= this.game.sfQualify) {
      this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + suit + ' High';
      this.sfLength = this.cards.length;
      if (this.cards.length < this.game.cardsInHand) {
        this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-this.cards.length));
      }
    }

    return this.cards.length >= this.game.sfQualify;
  }
}

class Straight extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Straight', game, settings, canDisqualify);
  }

  solve() {
    var card, checkCards;
    this.resetWildCards();

    // There are still some games that count the wheel as second highest.
    // These games do not have enough cards/wilds to make AKQJT and 5432A both possible.
    if (this.game.wheelStatus === 1) {
      this.cards = this.getWheel();
      if (this.cards.length) {
        var wildCount = 0;
        for (var i=0; i<this.cards.length; i++) {
          card = this.cards[i];
          if (card.value === this.game.wildValue) {
            wildCount += 1;
          }
          if (card.rank === 0) {
            card.rank = values.indexOf('A');
            card.wildValue = 'A';
            if (card.value === '1') {
              card.value = 'A';
            }
          }
        }
        this.cards = this.cards.sort(Card.sort);
        for (; wildCount<this.wilds.length && this.cards.length < this.game.cardsInHand; wildCount++) {
          card = this.wilds[wildCount];
          card.rank = values.indexOf('A');
          card.wildValue = 'A';
          this.cards.push(card);
        }
        this.descr = this.name + ', Wheel';
        this.sfLength = this.sfQualify;
        if (this.cards[0].value === 'A') {
          this.cards = this.cards.concat(this.nextHighest().slice(1, this.game.cardsInHand-this.cards.length+1));
        } else {
          this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-this.cards.length));
        }
        return true;
      }
      this.resetWildCards();
    }

    this.cards = this.getGaps();

    // Now add the wild cards, if any, and set the appropriate ranks
    if(this.cards.length > 0){
      for (var i=0; i<this.wilds.length; i++) {
        card = this.wilds[i];
        checkCards = this.getGaps(this.cards.length);
        const gaps = ((this.cards[0].rank - this.cards[this.cards.length-1].rank) ) - (this.cards.length-1)

        if (gaps === 0) {
          // This is an "open-ended" straight, the high rank is the highest possible rank.
          if (this.cards[0].rank < (values.length - 1)) {
            card.rank = this.cards[0].rank + 1;
            card.wildValue = values[card.rank];
            this.cards.push(card);
          } else {
            card.rank = this.cards[this.cards.length - 1].rank - 1;
            card.wildValue = values[card.rank];
            this.cards.push(card);
          }
        } else {
          // This is an "inside" straight, the high card doesn't change.
          for (var j=1; j<this.cards.length; j++) {
            if (this.cards[j-1].rank - this.cards[j].rank > 1) {
              card.rank = this.cards[j-1].rank - 1;
              card.wildValue = values[card.rank];
              this.cards.push(card);
              break;
            }
          }
        }
        this.cards = this.cards.sort(Card.sort);
      }
    }
    else{
      this.handleAllWildsCase()
    }

    if (this.cards.length >= this.game.sfQualify) {
      this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + ' High';
      this.cards = this.cards.slice(0, this.game.cardsInHand);
      this.sfLength = this.cards.length;
      if (this.cards.length < this.game.cardsInHand) {
        if (this.cards[this.sfLength-1].rank === 0) {
          this.cards = this.cards.concat(this.nextHighest().slice(1, this.game.cardsInHand-this.cards.length+1));
        } else {
          this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-this.cards.length));
        }
      }
    }

    return this.cards.length >= this.game.sfQualify;
  }

  handleAllWildsCase(){
    if(this.noOfWildCards >= 5){
      const highestRank = (values.length - 1)
      for (let i = 0; i < 5; i++) {
        const wildCard = this.wilds[i];
        wildCard.rank = highestRank - i
        wildCard.wildValue = values[ wildCard.rank]
        this.cards.push(wildCard)
      }
    }
  }

  /**
   * Get the number of gaps in the straight.
   * @return {Array} Highest potential straight with fewest number of gaps.
   */
  getGaps(checkHandLength) {
    var wildCards, cardsToCheck, i, card, gapCards, cardsList, gapCount, prevCard, diff;

    var stripReturn = Hand.stripWilds(this.cardPool, this.game);
    wildCards = stripReturn[0];
    cardsToCheck = stripReturn[1];

    for (i=0; i<cardsToCheck.length; i++) {
      card = cardsToCheck[i];
      if (card.wildValue === 'A') {
        cardsToCheck.push(new Card('1' + card.suit));
      }
    }
    cardsToCheck = cardsToCheck.sort(Card.sort);

    if (checkHandLength) {
      i = cardsToCheck[0].rank + 1;
    } else {
      checkHandLength = this.game.sfQualify;
      i = values.length;
    }

    gapCards = [];
    for (; i>0; i--) {
      cardsList = [];
      gapCount = 0;
      for (var j=0; j<cardsToCheck.length; j++) {
        card = cardsToCheck[j];
        if (card.rank > i) {
          continue;
        }
        prevCard = cardsList[cardsList.length - 1];
        diff = (prevCard) ? prevCard.rank - card.rank : i - card.rank;

        if (diff === null) {
          cardsList.push(card);
        } else if (checkHandLength < (gapCount + diff + cardsList.length)) {
          break;
        } else if (diff > 0) {
          cardsList.push(card);
          gapCount += (diff - 1);
        }
      }
      if (cardsList.length > gapCards.length) {
        gapCards = cardsList.slice();
      }
      if (this.game.sfQualify - gapCards.length <= wildCards.length) {
        break;
      }
    }

    return gapCards;
  }

  getWheel() {
    var wildCards, cardsToCheck, i, card, wheelCards, wildCount, cardFound;

    var stripReturn = Hand.stripWilds(this.cardPool, this.game);
    wildCards = stripReturn[0];
    cardsToCheck = stripReturn[1];

    for (i=0; i<cardsToCheck.length; i++) {
      card = cardsToCheck[i];
      if (card.wildValue === 'A') {
        cardsToCheck.push(new Card('1' + card.suit));
      }
    }
    cardsToCheck = cardsToCheck.sort(Card.sort);

    wheelCards = [];
    wildCount = 0;
    for (i = this.game.sfQualify-1; i>=0; i--) {
      cardFound = false;
      for (var j=0; j<cardsToCheck.length; j++) {
        card = cardsToCheck[j];
        if (card.rank > i) {
          continue;
        }
        if (card.rank < i) {
          break;
        }
        wheelCards.push(card);
        cardFound = true;
        break;
      }
      if (!cardFound) {
        if (wildCount < wildCards.length) {
          wildCards[wildCount].rank = i;
          wildCards[wildCount].wildValue = values[i];
          wheelCards.push(wildCards[wildCount]);
          wildCount += 1;
        } else {
          return [];
        }
      }
    }

    return wheelCards;
  }
}

class TwoThreeOfAKind extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Two Three Of a Kind', game, settings, canDisqualify);
  }

  solve() {
    this.resetWildCards();
    for (var i=0; i<this.values.length; i++) {
      var cards = this.values[i];
      if (this.cards.length > 0 && this.getNumCardsByRank(i) === 3) {
        this.cards = this.cards.concat(cards || []);
        for (var j=0; j<this.wilds.length; j++) {
          var wild = this.wilds[j];
          if (wild.rank !== -1) {
            continue;
          }
          if (cards) {
            wild.rank = cards[0].rank;
          } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
            wild.rank = values.length - 2;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
        this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-6));
        break;
      } else if (this.getNumCardsByRank(i) === 3) {
        this.cards = this.cards.concat(cards);
        for (var j=0; j<this.wilds.length; j++) {
          var wild = this.wilds[j];
          if (wild.rank !== -1) {
            continue;
          }
          if (cards) {
            wild.rank = cards[0].rank;
          } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
            wild.rank = values.length - 2;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
      }
    }

    if (this.cards.length >= 6) {
      var type = this.cards[0].toString().slice(0, -1) + '\'s & ' + this.cards[3].toString().slice(0, -1) + '\'s';
      this.descr = this.name + ', ' + type;
    }

    return this.cards.length >= 6;
  }
}

class ThreeOfAKind extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Three of a Kind', game, settings, canDisqualify);
  }

  solve() {
    this.resetWildCards();

    for (var i=0; i<this.values.length; i++) {
      if (this.getNumCardsByRank(i) === 3) {
        this.cards = this.values[i] || [];
        for (var j=0; j<this.wilds.length && this.cards.length<3; j++) {
          var wild = this.wilds[j];
          if (this.cards) {
            wild.rank = this.cards[0].rank;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
        this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-3));
        break;
      }
    }

    this.handleAllWildsCaseForNofAkind()

    if (this.cards.length >= 3) {
      if (this.game.noKickers) {
        this.cards.length = 3;
      }

      this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + '\'s';
    }

    return this.cards.length >= 3;
  }
}

class ThreePair extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Three Pair', game, settings, canDisqualify);
  }

  solve() {
    this.resetWildCards();

    for (var i=0; i<this.values.length; i++) {
      var cards = this.values[i];
      if (this.cards.length > 2 && this.getNumCardsByRank(i) === 2) {
        this.cards = this.cards.concat(cards || []);
        for (var j=0; j<this.wilds.length; j++) {
          var wild = this.wilds[j];
          if (wild.rank !== -1) {
            continue;
          }
          if (cards) {
            wild.rank = cards[0].rank;
          } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
            wild.rank = values.length - 2;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
        this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-6));
        break;
      } else if (this.cards.length > 0 && this.getNumCardsByRank(i) === 2) {
        this.cards = this.cards.concat(cards || []);
        for (var j=0; j<this.wilds.length; j++) {
          var wild = this.wilds[j];
          if (wild.rank !== -1) {
            continue;
          }
          if (cards) {
            wild.rank = cards[0].rank;
          } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
            wild.rank = values.length - 2;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
      } else if (this.getNumCardsByRank(i) === 2) {
        this.cards = this.cards.concat(cards);
        for (var j=0; j<this.wilds.length; j++) {
          var wild = this.wilds[j];
          if (wild.rank !== -1) {
            continue;
          }
          if (cards) {
            wild.rank = cards[0].rank;
          } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
            wild.rank = values.length - 2;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
      }
    }

    if (this.cards.length >= 6) {
      var type = this.cards[0].toString().slice(0, -1) + '\'s & ' + this.cards[2].toString().slice(0, -1) + '\'s & ' + this.cards[4].toString().slice(0, -1) + '\'s';
      this.descr = this.name + ', ' + type;
    }

    return this.cards.length >= 6;
  }
}

class TwoPair extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Two Pair', game, settings, canDisqualify);
  }

  solve() {
    this.resetWildCards();

    for (var i=0; i<this.values.length; i++) {
      var cards = this.values[i];
      if (this.cards.length > 0 && this.getNumCardsByRank(i) === 2) {
        this.cards = this.cards.concat(cards || []);
        for (var j=0; j<this.wilds.length; j++) {
          var wild = this.wilds[j];
          if (wild.rank !== -1) {
            continue;
          }
          if (cards) {
            wild.rank = cards[0].rank;
          } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
            wild.rank = values.length - 2;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
        this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-4));
        break;
      } else if (this.getNumCardsByRank(i) === 2) {
        this.cards = this.cards.concat(cards);
        for (var j=0; j<this.wilds.length; j++) {
          var wild = this.wilds[j];
          if (wild.rank !== -1) {
            continue;
          }
          if (cards) {
            wild.rank = cards[0].rank;
          } else if (this.cards[0].rank === values.length - 1 && this.game.wildStatus === 1) {
            wild.rank = values.length - 2;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
      }
    }

    if (this.cards.length >= 4) {
      if (this.game.noKickers) {
        this.cards.length = 4;
      }

      var type = this.cards[0].toString().slice(0, -1) + '\'s & ' + this.cards[2].toString().slice(0, -1) + '\'s';
      this.descr = this.name + ', ' + type;
    }

    return this.cards.length >= 4;
  }
}

class OnePair extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Pair', game, settings, canDisqualify);
  }

  solve() {
    this.resetWildCards();

    for (var i=0; i<this.values.length; i++) {
      if (this.getNumCardsByRank(i) === 2) {
        this.cards = this.cards.concat(this.values[i] || []);
        for (var j=0; j<this.wilds.length && this.cards.length<2; j++) {
          var wild = this.wilds[j];
          if (this.cards) {
            wild.rank = this.cards[0].rank;
          } else {
            wild.rank = values.length - 1;
          }
          wild.wildValue = values[wild.rank];
          this.cards.push(wild);
        }
        this.cards = this.cards.concat(this.nextHighest().slice(0, this.game.cardsInHand-2));
        break;
      }
    }

    this.handleAllWildsCaseForNofAkind()

    if (this.cards.length >= 2) {
      if (this.game.noKickers) {
        this.cards.length = 2;
      }

      this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + '\'s';
    }

    return this.cards.length >= 2;
  }
}

class HighCard extends Hand {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'High Card', game, settings, canDisqualify);
  }

  solve() {
    this.cards = this.cardPool.slice(0, this.game.cardsInHand);

    for (var i=0; i<this.cards.length; i++) {
      var card = this.cards[i];
      if (this.cards[i].value === this.game.wildValue) {
        this.cards[i].wildValue = 'A';
        this.cards[i].rank = values.indexOf('A');
      }
    }

    this.handleAllWildsCaseForNofAkind()

    if (this.game.noKickers) {
      this.cards.length = 1;
    }

    this.descr = this.cards[0].toString().slice(0, -1) + ' High';
    this.cards = this.cards.sort(Card.sort);

    return true;
  }
}

/*
 * Base class for handling Pai Gow Poker hands.
 * House Way is in accordance with the MGM Grand Casino, Las Vegas NV.
 * http://wizardofodds.com/games/pai-gow-poker/house-way/mgm/
 * EXCEPTION: With Four of a Kind and S/F, preserve the S/F, just like Three of a Kind.
 */
class PaiGowPokerHelper {
  /*
   * Constructor class.
   * @param {Hand} hand Solved hand against Game 'paigowpokerfull'.
   */
  constructor(hand) {
    this.baseHand = null;
    this.hiHand = null;
    this.loHand = null;
    this.game = null;
    this.loGame = new Game('paigowpokerlo');
    this.hiGame = new Game('paigowpokerhi');

    if (Array.isArray(hand)) {
      this.baseHand = Hand.solve(hand, new Game('paigowpokerfull'));
    } else {
      this.baseHand = hand;
    }

    this.game = this.baseHand.game;
  }

  /*
   * Set a full hand into high and low hands, according to House Way.
   */
  splitHouseWay() {
    var hiCards, loCards;
    var rank = this.game.handValues.length - this.baseHand.rank;
    var handValue = this.game.handValues[rank];

    if (handValue === FiveOfAKind) {
      if (this.baseHand.cards[5].value === 'K' && this.baseHand.cards[6].value === 'K') {
        loCards = this.baseHand.cards.slice(5, 7);
        hiCards = this.baseHand.cards.slice(0, 5);
      } else {
        loCards = this.baseHand.cards.slice(0, 2);
        hiCards = this.baseHand.cards.slice(2, 7);
      }
    } else if (handValue === FourOfAKindPairPlus) {
      if (this.baseHand.cards[0].wildValue === 'A' && this.baseHand.cards[4].value !== 'K') {
        hiCards = this.baseHand.cards.slice(0, 2);
        loCards = this.baseHand.cards.slice(2, 4);
        hiCards = hiCards.concat(this.baseHand.cards.slice(4, 7));
      } else {
        hiCards = this.baseHand.cards.slice(0, 4);
        loCards = this.baseHand.cards.slice(4, 6);
        hiCards.push(this.baseHand.cards[6]);
      }
    } else if (handValue === StraightFlush || handValue === Flush || handValue === Straight) {
      var sfReturn;
      var altGame = new Game('paigowpokeralt');
      var altHand = Hand.solve(this.baseHand.cards, altGame);
      var altRank = altGame.handValues.length - altHand.rank;
      if (altGame.handValues[altRank] === FourOfAKind) {
        sfReturn = this.getSFData(altHand.cards);
        hiCards = sfReturn[0];
        loCards = sfReturn[1];
      } else if (altGame.handValues[altRank] === FullHouse) {
        hiCards = altHand.cards.slice(0, 3);
        loCards = altHand.cards.slice(3, 5);
        hiCards = hiCards.concat(altHand.cards.slice(5, 7));
      } else if (altGame.handValues[altRank] === ThreeOfAKind) {
        sfReturn = this.getSFData(altHand.cards);
        hiCards = sfReturn[0];
        loCards = sfReturn[1];
      } else if (altGame.handValues[altRank] === ThreePair) {
        loCards = altHand.cards.slice(0, 2);
        hiCards = altHand.cards.slice(2, 7);
      } else if (altGame.handValues[altRank] === TwoPair) {
        if (altHand.cards[0].rank < 6) {
          if (altHand.cards[4].wildValue === 'A') {
            hiCards = altHand.cards.slice(0, 4);
            loCards = altHand.cards.slice(4, 6);
            hiCards.push(altHand.cards[6]);
          } else {
            sfReturn = this.getSFData(altHand.cards);
            hiCards = sfReturn[0];
            loCards = sfReturn[1];
          }
        } else if (altHand.cards[0].rank < 10) {
          if (altHand.cards[4].wildValue === 'A') {
            hiCards = altHand.cards.slice(0, 4);
            loCards = altHand.cards.slice(4, 6);
            hiCards.push(altHand.cards[6]);
          } else {
            hiCards = altHand.cards.slice(0, 2);
            loCards = altHand.cards.slice(2, 4);
            hiCards = hiCards.concat(altHand.cards.slice(4, 7));
          }
        } else if (altHand.cards[0].wildValue !== 'A' && altHand.cards[2].rank < 6 && altHand.cards[4].wildValue === 'A') {
          hiCards = altHand.cards.slice(0, 4);
          loCards = altHand.cards.slice(4, 6);
          hiCards.push(altHand.cards[6]);
        } else {
          hiCards = altHand.cards.slice(0, 2);
          loCards = altHand.cards.slice(2, 4);
          hiCards = hiCards.concat(altHand.cards.slice(4, 7));
        }
      } else if (altGame.handValues[altRank] === OnePair) {
        if (altHand.cards[0].rank >= values.indexOf('T') && altHand.cards[0].rank <= values.indexOf('K') && altHand.cards[2].wildValue === 'A') {
          var possibleSF = altHand.cards.slice(0, 2);
          possibleSF = possibleSF.concat(altHand.cards.slice(3, 7));
          sfReturn = this.getSFData(possibleSF);
          if (sfReturn[0]) {
            hiCards = sfReturn[0];
            loCards = sfReturn[1];
            loCards.push(altHand.cards[2]);
          } else {
            hiCards = altHand.cards.slice(0, 2);
            loCards = altHand.cards.slice(2, 4);
            hiCards = hiCards.concat(altHand.cards.slice(4, 7));
          }
        } else {
          sfReturn = this.getSFData(altHand.cards.slice(2, 7));
          if (sfReturn[0]) {
            hiCards = sfReturn[0];
            loCards = altHand.cards.slice(0, 2);
          } else {
            sfReturn = this.getSFData(altHand.cards);
            hiCards = sfReturn[0];
            loCards = sfReturn[1];
          }
        }
      } else {
        sfReturn = this.getSFData(altHand.cards);
        hiCards = sfReturn[0];
        loCards = sfReturn[1];
      }
    } else if (handValue === FourOfAKind) {
      if (this.baseHand.cards[0].rank < 6) {
        hiCards = this.baseHand.cards.slice(0, 4);
        loCards = this.baseHand.cards.slice(4, 6);
        hiCards.push(this.baseHand.cards[6]);
      } else if (this.baseHand.cards[0].rank < 10 && this.baseHand.cards[4].wildValue === 'A') {
        hiCards = this.baseHand.cards.slice(0, 4);
        loCards = this.baseHand.cards.slice(4, 6);
        hiCards.push(this.baseHand.cards[6]);
      } else {
        hiCards = this.baseHand.cards.slice(0, 2);
        loCards = this.baseHand.cards.slice(2, 4);
        hiCards = hiCards.concat(this.baseHand.cards.slice(4, 7));
      }
    } else if (handValue === TwoThreeOfAKind) {
      loCards = this.baseHand.cards.slice(0, 2);
      hiCards = this.baseHand.cards.slice(3, 6);
      hiCards.push(this.baseHand.cards[2]);
      hiCards.push(this.baseHand.cards[6]);
    } else if (handValue === ThreeOfAKindTwoPair) {
      hiCards = this.baseHand.cards.slice(0, 3);
      loCards = this.baseHand.cards.slice(3, 5);
      hiCards = hiCards.concat(this.baseHand.cards.slice(5, 7));
    } else if (handValue === FullHouse) {
      if (this.baseHand.cards[3].wildValue === '2' && this.baseHand.cards[5].wildValue === 'A' && this.baseHand.cards[6].wildValue === 'K') {
        hiCards = this.baseHand.cards.slice(0, 5);
        loCards = this.baseHand.cards.slice(5, 7);
      } else {
        hiCards = this.baseHand.cards.slice(0, 3);
        loCards = this.baseHand.cards.slice(3, 5);
        hiCards = hiCards.concat(this.baseHand.cards.slice(5, 7));
      }
    } else if (handValue === ThreeOfAKind) {
      if (this.baseHand.cards[0].wildValue === 'A') {
        hiCards = this.baseHand.cards.slice(0, 2);
        loCards = this.baseHand.cards.slice(2, 4);
        hiCards = hiCards.concat(this.baseHand.cards.slice(4, 7));
      } else {
        hiCards = this.baseHand.cards.slice(0, 3);
        loCards = this.baseHand.cards.slice(3, 5);
        hiCards = hiCards.concat(this.baseHand.cards.slice(5, 7));
      }
    } else if (handValue === ThreePair) {
      loCards = this.baseHand.cards.slice(0, 2);
      hiCards = this.baseHand.cards.slice(2, 7);
    } else if (handValue === TwoPair) {
      if (this.baseHand.cards[0].rank < 6) {
        hiCards = this.baseHand.cards.slice(0, 4);
        loCards = this.baseHand.cards.slice(4, 6);
        hiCards.push(this.baseHand.cards[6]);
      } else if (this.baseHand.cards[0].rank < 10) {
        if (this.baseHand.cards[4].wildValue === 'A') {
          hiCards = this.baseHand.cards.slice(0, 4);
          loCards = this.baseHand.cards.slice(4, 6);
          hiCards.push(this.baseHand.cards[6]);
        } else {
          hiCards = this.baseHand.cards.slice(0, 2);
          loCards = this.baseHand.cards.slice(2, 4);
          hiCards = hiCards.concat(this.baseHand.cards.slice(4, 7));
        }
      } else if (this.baseHand.cards[0].wildValue !== 'A' && this.baseHand.cards[2].rank < 6 && this.baseHand.cards[4].wildValue === 'A') {
        hiCards = this.baseHand.cards.slice(0, 4);
        loCards = this.baseHand.cards.slice(4, 6);
        hiCards.push(this.baseHand.cards[6]);
      } else {
        hiCards = this.baseHand.cards.slice(0, 2);
        loCards = this.baseHand.cards.slice(2, 4);
        hiCards = hiCards.concat(this.baseHand.cards.slice(4, 7));
      }
    } else if (handValue === OnePair) {
      hiCards = this.baseHand.cards.slice(0, 2);
      loCards = this.baseHand.cards.slice(2, 4);
      hiCards = hiCards.concat(this.baseHand.cards.slice(4, 7));
    } else {
      hiCards = [this.baseHand.cards[0]];
      loCards = this.baseHand.cards.slice(1, 3);
      hiCards = hiCards.concat(this.baseHand.cards.slice(3, 7));
    }

    this.hiHand = Hand.solve(hiCards, this.hiGame);
    this.loHand = Hand.solve(loCards, this.loGame);
  }

  /*
   * Determine the best possible Straight and/or Flush.
   * @param  {Array} cards 5-7 Card objects to check.
   * @return {Array} [hiCards, loCards] High and Low components, if any.
   */
  getSFData(cards) {
    var hiCards, possibleLoCards, bestLoCards, bestHand;
    var handsToCheck = [
      new StraightFlush(cards, new Game('paigowpokersf7')),
      new StraightFlush(cards, new Game('paigowpokersf6')),
      new StraightFlush(cards, this.game),
      new Flush(cards, new Game('paigowpokersf7')),
      new Flush(cards, new Game('paigowpokersf6')),
      new Flush(cards, this.game),
      new Straight(cards, new Game('paigowpokersf7')),
      new Straight(cards, new Game('paigowpokersf6')),
      new Straight(cards, this.game)
    ];

    for (var i=0; i<handsToCheck.length; i++) {
      var hand = handsToCheck[i];
      if (hand.isPossible) {
        if (hand.sfLength === 7) {
          possibleLoCards = [hand.cards[0], hand.cards[1]];
        } else if (hand.sfLength === 6) {
          possibleLoCards = [hand.cards[0]];
          if (cards.length > 6) {
            possibleLoCards.push(hand.cards[6]);
          }
        } else if (cards.length > 5) {
          possibleLoCards = [hand.cards[5]];
          if (cards.length > 6) {
            possibleLoCards.push(hand.cards[6]);
          }
        }
        if (possibleLoCards) {
          possibleLoCards = possibleLoCards.sort(Card.sort);
          if (!bestLoCards || bestLoCards[0].rank < possibleLoCards[0].rank || (bestLoCards.length > 1 && bestLoCards[0].rank === possibleLoCards[0].rank && bestLoCards[1].rank < possibleLoCards[1].rank)) {
            bestLoCards = possibleLoCards;
            bestHand = hand;
          }
        } else if (!bestHand) {
          bestHand = hand;
          break;
        }
      }
    }

    if (bestHand) {
      if (bestHand.sfLength === 7) {
        hiCards = bestHand.cards.slice(2, 7);
      } else if (bestHand.sfLength === 6) {
        hiCards = bestHand.cards.slice(1, 6);
      } else {
        hiCards = bestHand.cards.slice(0, 5);
      }
    }

    return [hiCards, bestLoCards];
  }

  /*
   * Determine if the setting of the hands is valid. Hi must be higher than lo.
   * @return {Boolean}
   */
  qualifiesValid() {
    var compareHands = Hand.winners([this.hiHand, this.loHand]);

    return !(compareHands.length === 1 && compareHands[0] === this.loHand);
  }

  /**
   * Find which of two split hands is best, according to rules.
   * @param  {PaiGowPokerHelper} player Player hand to evaluate. Must be set.
   * @param  {PaiGowPokerHelper} banker Banker hand to evaluate. Must be set.
   * @param  {int}               winner Winning party, if any.
   *                                    Player = 1, Banker = -1, Push = 0
   */
  static winners(player, banker) {
    if (!player.qualifiesValid()) {
      if (banker.qualifiesValid()) {
        return -1;
      }
      // Probably shouldn't get here because the dealer must set house way.
      // However, we'll still have it as a sanity check, just in case.
      return 0;
    }

    if (!banker.qualifiesValid()) {
      return 1;
    }

    var hiWinner = Hand.winners([player.hiHand, banker.hiHand]);
    var loWinner = Hand.winners([player.loHand, banker.loHand]);

    // In Pai Gow Poker, Banker takes any equal valued hands.
    if (hiWinner.length === 1 && hiWinner[0] === player.hiHand) {
      if (loWinner.length === 1 && loWinner[0] === player.loHand) {
        // Player wins both; player wins
        return 1;
      }
      // Player wins hi, Banker wins lo; push
      return 0;
    }

    if (loWinner.length === 1 && loWinner[0] === player.loHand) {
      // Banker wins hi, Player wins lo; push
      return 0;
    }

    // Banker wins both; banker wins
    return -1;
  }

  /*
   * Set a full hand into high and low hands, according to manual input.
   * @param  {Array} hiHand       High hand to specify.
   *                              Can also be {Hand} with game of 'paigowpokerhi'.
   * @param  {Array} loHand       Low hand to specify.
   *                              Can also be {Hand} with game of 'paigowpokerlo'.
   * @return {PaiGowPokerHelper}  Object with split hands.
   */
  static setHands(hiHand, loHand) {
    var fullHand = [];

    if (Array.isArray(hiHand)) {
      hiHand = Hand.solve(hiHand, new Game('paigowpokerhi'));
    }
    fullHand = fullHand.concat(hiHand.cardPool);
    if (Array.isArray(loHand)) {
      loHand = Hand.solve(loHand, new Game('paigowpokerlo'));
    }
    fullHand = fullHand.concat(loHand.cardPool);

    var result = new PaiGowPokerHelper(fullHand);
    result.hiHand = hiHand;
    result.loHand = loHand;

    return result;
  }

  /**
   * Build and return PaiGowPokerHelper object with hands split House Way.
   * @param  {Array} fullHand    Array of cards (['Ad', '3c', 'Th', ...]).
   *                             Can also be {Hand} with game of 'paigowpokerfull'.
   * @return {PaiGowPokerHelper} Object with split hands.
   */
  static solve(fullHand) {
    var result = new PaiGowPokerHelper(fullHand = fullHand || ['']);
    result.splitHouseWay();

    return result;
  }
}

export class HandLo{
  constructor(cards, name, game, settings, canDisqualify){
    const {wildCardsSettings, loSettings} = settings
    this.cardsProvided = [...cards]
    this.cardPool = [];
    this.cards = [];
    this.suits = {};
    this.values = [];
    this.wilds = [];
    this.nonWilds = [];
    this.name = name;
    this.game = game;
    this.sfLength = 0;
    this.alwaysQualifies = true;
    this.settings = settings
    this.loSettings = loSettings;
    if(wildCardsSettings){ this.wildCardsSettings = wildCardsSettings }
    this.distinctCards = []
    this.nonWildCardsRankSet = null;
    this.noOfDistinctCards = null      

    cards = convertInDeckCardsToWild(cards, wildCardsSettings, loSettings) // ['As', 'Rw', 'Ts', ] --> ['Rz', 'Rw', 'Lz']

    // Qualification rules apply for dealer's hand.
    // Also applies for single player games, like video poker.
    if (canDisqualify && this.game.lowestQualified) {
      this.alwaysQualifies = false;
    }
    
    // Get rank based on game.
    var handRank = this.game.handValues.length;
    for (var i=0; i<this.game.handValues.length; i++) {
      if (this.game.handValues[i] === this.constructor) {
        break;
      }
    }
    this.rank = handRank - i;

    // Set up the pool of cards.
    this.cardPool = cards.map((c) => {
      return (typeof c === 'string') ? new Card(c, loSettings) : c;
    });

    // Fix the card ranks for wild cards, and sort.
    for (var i=0; i<this.cardPool.length; i++) {
      card = this.cardPool[i];
      if (card.value === this.game.wildValue) {
        card.rank = -1;
      }
    }
    this.cardPool = this.cardPool.sort(Card.sortLo);
    
    // Create the arrays of suits and values.
    var obj, obj1, key, key1, card;
    for (var i=0; i<this.cardPool.length; i++) {
      // Make sure this value already exists in the object.
      card = this.cardPool[i];

      // We do something special if this is a wild card.
      if (card.rank === -1) {
        this.wilds.push(card);
      } else {
        (obj = this.suits)[key = card.suit] || (obj[key] = []);
        (obj1 = this.values)[key1 = card.rank] || (obj1[key1] = []);

        // Add the value to the array for that type in the object.
        this.suits[card.suit].push(card);
        this.values[card.rank].push(card);

        this.nonWilds.push(card)
      }
    }
    
    this.nonWildCardsRankSet = new Set(this.nonWilds.map(card => card.rank))
    this.noOfDistinctCardsPossible = this.wilds.length + this.nonWildCardsRankSet.size

    this.noOfWildCards = this.wilds.length
    this.noOfNonJokersWildCards = this.wilds.filter(card => card.suit !== JOKER_SUIT).length
    this.noOfNonWildsCards = this.nonWilds.length
    
    this.isPossible = this.solve();
  }

  static solve(cards, game, settings, canDisqualify) {
    game = 'jokerLo';
    game = (typeof game === 'string') ? new Game(game) : game;
    cards = cards || [''];

    var hands = game.handValues;
    var result = null;

    if(cards.length > 7){ HandLo.solveForMoreThan7Cards(cards, game, settings, canDisqualify) }
    
    for (var i=0; i<hands.length; i++) {
      result = new hands[i](cards, game, settings, canDisqualify);
      if (result.isPossible) {
        break;
      }
    }

    // convert cards back
    result.cards = result.cards.map(card => convertInDeckWildCardToNormalCard(card))
    result.cardPool = result.cardPool.map(card => convertInDeckWildCardToNormalCard(card))

    result.rank = 10 - result.rank
    return result;
  }

  static solveForMoreThan7Cards(cards, game, settings, canDisqualify){
    const competingHands = []
    const noOfCardsToCombinationsMapping = {
      9 : nineChooseFiveIndexCombinations,
      8 : eightChooseFiveIndexCombination
    }

    const combinations = noOfCardsToCombinationsMapping[cards.length]
    for(const indexCombination of combinations){
      const cardsCombination = indexCombination.map(index => cards[index])
      const allowCardsToEvaluate = isAllowCardsToEvaluate(cards, this.wildCardsSettings, this.noOfNonWildsCards)
      if(! allowCardsToEvaluate) { continue }  
      const evaluatedHand = HandLo.solve(cardsCombination, game, settings, canDisqualify)
      competingHands.push(evaluatedHand)
    }

    const winners = HandLo.winners(competingHands)
    return winners[0]
  }

   compare(a) {
    if (this.rank > a.rank) {
      return 1;
    } else if (this.rank < a.rank) {
      return -1;
    }

    var result = 0;
    for (var i=0; i<=4; i++) {
      if (this.cards[i] && a.cards[i] && this.cards[i].rank > a.cards[i].rank) {
        result = 1;
        break;
      } else if (this.cards[i] && a.cards[i] && this.cards[i].rank < a.cards[i].rank) {
        result = -1;
        break;
      }
    }

    if(result !== 0) { return result }

    return result;
  }

  loseTo(hand) {
    return (this.compare(hand) > 0);
  }

  static winners(hands) {
    var lowestRank = Math.min.apply(Math, hands.map(function(h) {
      return h.rank;
    }));

    hands = hands.filter(function(h) {
      return h.rank === lowestRank;
    });

    hands = hands.filter(function(h) {
      var lose = false;
      for (var i=0; i<hands.length; i++) {
        lose = h.loseTo(hands[i]);
        if (lose) {
          break;
        }
      }

      return !lose;
    });

    return hands;
  }

  getRanksWithMoreThanNCards(n){
    const ranks = []
    for(const [rank, values] of this.values.entries()){
      if(!values){continue}
      if(values.length >= n){ ranks.push(rank) }
    }
    return ranks
  }

  solve() {
    let result;
    result = this.solveForAllCardsNo()

    if(! result){return false }

    if (this.game.noKickers) {
      this.cards.length = 1;
    }

    this.setDescr()
    
    return true;
  }

  replaceWildCards(){
    // Used by N of Kind, 2 Pair, Full house
    // Only useful for hand with 5 cards.
    const {considerAceLow} = this.loSettings
    const cardValues = (considerAceLow) ? loAceValues : hiValues
    
    let wildCardsResolved = 0;
    
    for(const [rank, loValue] of cardValues.entries()){
      if(this.nonWildCardsRankSet.has(rank)){ continue }
      
      // Wild Card Replaced
      const wildCardToReplace = this.wilds[wildCardsResolved]
      wildCardToReplace.rank = rank
      wildCardToReplace.wildValue = loValue
      this.cards.push(wildCardToReplace)
      wildCardsResolved += 1

      if(wildCardsResolved === this.wilds.length){ break }
    }
  }

  sortCards(){
      this.cards = this.cards.sort((a, b) => {
      const aFrequency = this.values[a.rank].length 
      const bFrequency = this.values[b.rank].length
      if(aFrequency > bFrequency){ return -1 }
      else if(aFrequency < bFrequency){ return 1 }
      else { 
        if (a.rank > b.rank) { return 1 } 
        else if (a.rank < b.rank) { return -1 }
        else return 0
      }
    })
  }

  changeRankOfAce(){
    // Used only in StraightLo and StraightFlushLo
    const {considerAceLow} = this.loSettings
    const aRank = (considerAceLow) ? loAceValues.indexOf('A') : hiValues.indexOf('A')
    if(! this.nonWildCardsRankSet.has(aRank)){ return }

    const changeRankOfCards = (value, rank) => {
      for(const card of this.cards){
        if(card.value === value){
          card.rank = rank
        }
      }
    }

    //Change rank of Ace 
    if(considerAceLow && this.nonWildCardsRankSet.has(loAceValues.indexOf('K'))){
      changeRankOfCards('A', loAceValues.indexOf('K') + 1) // Change Ace rank to 13
    }
    if((!considerAceLow) && this.nonWildCardsRankSet.has(hiValues.indexOf('5'))){
      changeRankOfCards('A', hiValues.indexOf('2') - 1) // Change Ace rank to 1
    }
  }
}
class HighCardLo extends HandLo {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'High Card', game, settings, canDisqualify);
  }

  solve() {
    let result;
    if(this.cardPool.length <= 5){ result = this.solveLessThanSixCards() }
    else{ result = this.solveMoreThanFiveCards() }

    if(! result){return false }

    if (this.game.noKickers) {
      this.cards.length = 1;
    }

    this.descr = this.cards[0].wildValue + ' High';
    
    return true;
  }

  solveLessThanSixCards(){
    // No of cards : 1,2,3,4,5
    if(this.noOfDistinctCardsPossible < this.cardPool.length){ return false }

    // If there are no wild cards and straight or flush is possible then also
    const {countStraightAndFlushes} = this.loSettings
    if(this.wilds.length === 0 && (countStraightAndFlushes)){
      const hand = Hand.solve(this.cardsProvided, 'standard', this.settings, this.canDisqualify)
      if([5,6,9].includes(hand.rank)){ return false } // straight, flush, straight flush
    }

    this.cards = this.cardPool.filter(card => card.rank !== -1)

    // Replace wild cards
    if(this.wilds.length > 0){
      this.replaceWildCards()
    }

    this.handleEdgeCases()

    this.sortCards()

    return true
  }

  handleEdgeCases(){
    const {countStraightAndFlushes, considerAceLow} = this.loSettings
    if(this.wilds.length === 0 && this.cardPool.length >= 5 && (! countStraightAndFlushes) && (!considerAceLow)){
      // Case -1 : 
      // Handle edge cases : A2345. when countStraightAndFlushes : false and  considerAceLow : false
      // Here rank of A should be changed to 0.
      const hand = Hand.solve(this.cardsProvided, 'standard', this.settings, this.canDisqualify)
      const isStraight =[5,9].includes(hand.rank)
      const isA2345Straight = hand.cards[this.cards.length - 1].rank === 0
      if(isStraight && isA2345Straight){
        this.changeRankOfAce()
        return 
      }
    }


    if(this.wilds.length > 0 && this.cardPool.length >= 5 && (! countStraightAndFlushes) && (!considerAceLow)){
      // Case - 2 :
      // If we make 23456 . when countStraightAndFlushes : false and  considerAceLow : false --> Make 6 --> A
      this.sortCards()
      if(this.cards[0].rank === hiValues.indexOf('6')){
        const cardToUpdate = this.cards[0]
        cardToUpdate.rank = hiValues.indexOf('2') - 1
        cardToUpdate.wildValue = 'A'
        return
      }
    }
  }

  solveMoreThanFiveCards(){
    // No of cards > 5 : 6,7
    if(this.noOfDistinctCardsPossible < 5){ return false }

    // Generate all combinations and check for every combination
    const noOfCardsToCombinationsMapping = {
      6 : sixChooseFiveIndexCombinations,
      7 : sevenChooseFiveIndexCombinations,
      8 : eightChooseFiveIndexCombination,
      9 : nineChooseFiveIndexCombinations
    }

    const indexCombinations = noOfCardsToCombinationsMapping[this.cardPool.length]
    const competingHands = []
    for(const indexCombination of indexCombinations){
      const cards = indexCombination.map(index => this.cardsProvided[index])
      const allowCardsToEvaluate = isAllowCardsToEvaluate(cards, this.wildCardsSettings, this.noOfNonWildsCards)
      if(! allowCardsToEvaluate){ continue }
      const evaluatedHandLo = HandLo.solve(cards, 'jokerLo', this.settings)
      if(evaluatedHandLo.rank !== 1){ continue } // If this combination is not high card skip.
      competingHands.push(evaluatedHandLo)
    }

    // Get best hand
    const winners = HandLo.winners(competingHands)
    const bestHand = winners[0]

    this.cards = bestHand.cards

    this.sortCards()

    return true
  }

  sortCards(){
    this.cards = this.cards.sort((a, b) => {
      if (a.rank > b.rank) { return -1 } 
      else if (a.rank < b.rank) { return 1 }
      else return 0
    })
  }

  replaceWildCards(){
    // Only useful for hand with 5 cards.
    const {considerAceLow, countStraightAndFlushes} = this.loSettings
    const cardValues = (considerAceLow) ? loAceValues : hiValues
    
    const nonWildCardsRanks = [...this.nonWildCardsRankSet]
    let minRank = Math.min(...nonWildCardsRanks)
    let maxRank = Math.max(...nonWildCardsRanks)
    
    let wildCardsResolved = 0;
    
    for(const [rank, loValue] of cardValues.entries()){
      if(this.nonWildCardsRankSet.has(rank)){ continue }
      
      if(this.cards.length === 4 && (countStraightAndFlushes)){
        // Check if straight is not forming
        const noOfCards = 5

        const tempMinRank = (rank < minRank) ? rank : minRank
        const tempMaxRank = (rank > maxRank) ? rank : maxRank
        const cond = (tempMaxRank - tempMinRank) === noOfCards - 1

        if(cond){ continue } // straight possible
      }
      
      // Wild Card Replaced
      const wildCardToReplace = this.wilds[wildCardsResolved]
      wildCardToReplace.rank = rank
      wildCardToReplace.wildValue = loValue
      this.cards.push(wildCardToReplace)
      wildCardsResolved += 1
      if(rank < minRank) { minRank =  rank}
      if(rank > maxRank) { maxRank =  rank}

      if(wildCardsResolved === this.wilds.length){ break }
    }
    
  }

  isAllowCardsToEvaluate(cards){
    // Added to handle the case when we have rule : handsMustHaveOneNaturalCard true and noOfCards are > 5
    if(! this.wildCardsSettings){ return true }
    const {handsMustHaveOneNaturalCard, wildCardsSet, jokersInDeckSet} = this.wildCardsSettings
    
    if(! handsMustHaveOneNaturalCard){ return true }
    
    const {playerNonWildCards} = separateWildcards(cards, wildCardsSet, jokersInDeckSet)
    if(playerNonWildCards.length > 0){ return true }

    if(this.noOfNonWildsCards > 0){ return false }
    else { return true }
  }
}

function get_N_OF_A_KIND_LO_class(N){
  const descriptionStrings = {
    2 : 'Pair',
    3 : 'Three of a kind',
    4 : 'Four of a Kind'
  }
  return class N_OF_A_KIND_LO extends HandLo {
    constructor(cards, game, settings, canDisqualify) {
      super(cards, descriptionStrings[N], game, settings, canDisqualify);
    }

    solveForAllCardsNo(){
      // No of cards : 1,2,3,4,5,6,7
      const cond1 = (this.cardPool.length >= N && this.cardPool.length <= 5) && (this.noOfDistinctCardsPossible === this.cardPool.length - (N - 1))
      const cond2 = (this.cardPool.length > 5) && (this.noOfDistinctCardsPossible === (5 - (N - 1)) )
      const ranksHavingMoreThanNCard = this.getRanksWithMoreThanNCards(N)
      const cond3 = ranksHavingMoreThanNCard.length > 0
      const cond = (cond1 || cond2) && cond3 
      if(! cond){ return false }

      const rankCardsAddedSet = new Set()
      for(const card of this.cardPool){
        if(card.rank === -1){ continue }
        if(rankCardsAddedSet.has(card.rank)){ continue }
        else{
          rankCardsAddedSet.add(card.rank)
          this.cards.push(card)
        }
      }

      // Add remaining card that contributes to N of a kind rank
      let contributingRanks = new Set([ranksHavingMoreThanNCard[0]]);

      let noOfCardsToAdd = N-1
      const tempCardsSet = new Set(this.cards.map(card=>card.value + card.suit))
      for(const card of this.cardPool){
        if(contributingRanks.has(card.rank)){
          if(! tempCardsSet.has(card.value + card.suit)){
            this.cards.push(card)
            noOfCardsToAdd--
          }
        }
        if(noOfCardsToAdd === 0){ break }
      }
      
      this.sortCards()

      // Replace wild cards
      if(this.wilds.length > 0){
        this.replaceWildCards()
      }

      return true
    }

    setDescr(){
      if(N === 2){
        this.descr = this.cards[0].toString().slice(0, -1) + ' Pair';
      }
      else{
        this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + '\'s';
      }
    }
  }
}

class TwoPairLo extends HandLo {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Two Pair', game, settings, canDisqualify);
  }

  solveForAllCardsNo(){
    // No of cards : 4,5,6,7
    const cond1 = (this.cardPool.length >= 4 && this.cardPool.length <= 5) && (this.noOfDistinctCardsPossible === this.cardPool.length - 2)
    const cond2 = (this.cardPool.length > 5) && (this.noOfDistinctCardsPossible === 3)
    const ranksHavingMoreThan1Card = this.getRanksWithMoreThanNCards(2)
    const cond3 = ranksHavingMoreThan1Card.length > 1
    const finalCond = (cond1 || cond2) && cond3
    if(! finalCond){ return false }

    const rankCardsAddedSet = new Set()
    for(const card of this.cardPool){
      if(card.rank === -1){ continue }
      if(rankCardsAddedSet.has(card.rank)){ continue }
      else{
        rankCardsAddedSet.add(card.rank)
        this.cards.push(card)
      }
    }

    // Add remaining card that contributes to Two Pair
    let contributingRanks = ranksHavingMoreThan1Card.slice(0,2)
    const tempCardsSet = new Set(this.cards.map(card => card.value + card.suit))
    
    for(const contributingRank of contributingRanks){
      let noOfCardsToAdd = 1
      for(const card of this.cardPool){
        if(card.rank === contributingRank){
          if(! tempCardsSet.has(card.value + card.suit)){
            this.cards.push(card)
            noOfCardsToAdd--
          }
        }
        if(noOfCardsToAdd === 0){ break }
      }
    }
    
    this.sortCards();

    // Replace wild cards
    if(this.wilds.length > 0){
      this.replaceWildCards()
    }

    // Add other cards

    return true
  }

  setDescr(){
    var type = this.cards[0].toString().slice(0, -1) + '\'s & ' + this.cards[2].toString().slice(0, -1) + '\'s';
    this.descr = this.name + ', ' + type;
  }
}

class FullHouseLo extends HandLo {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Full House', game, settings, canDisqualify);
  }

  solveForAllCardsNo(){
    // No of cards : 5,6,7
    const cond1 = (this.cardPool.length >= 5) && (this.noOfDistinctCardsPossible === 2)
    const contributingRanks = this.getContributingRanks()
    const finalCond = cond1 && contributingRanks
    if(! finalCond){ return false }

    for(const key in contributingRanks){
      const contributingRank = contributingRanks[key]
      let noOfCardsToAdd = parseInt(key)
      for(const card of this.cardPool){
        if(card.rank === contributingRank){
            this.cards.push(card)
            noOfCardsToAdd--
        }
        if(noOfCardsToAdd === 0){ break }
      }
    }
    
    this.sortCards();
    return true
  }

  getContributingRanks(){
    const ranks = {}

    const ranksHavingMoreThan3Card = this.getRanksWithMoreThanNCards(3)
    if(ranksHavingMoreThan3Card.length > 0){
      ranks['3'] = ranksHavingMoreThan3Card[0]
    }
    else{ return null }

    const ranksHavingMoreThan2Card = this.getRanksWithMoreThanNCards(2)
    for(const rank of ranksHavingMoreThan2Card){
      if(rank === ranks[3]){ continue }
      ranks['2'] = rank
    }

    return (ranks['2']) ? ranks : null
  }

  setDescr(){
    var type = this.cards[4].toString().slice(0, -1) + '\'s & ' + this.cards[0].toString().slice(0, -1) + '\'s';
    this.descr = this.name + ', ' + type;
  }
}

class StraightLo extends HandLo {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Straight', game, settings, canDisqualify);
  }

  solveForAllCardsNo(){
    // No of cards : 5
    /** Observation - 1 : There is never going to be a wildcard. Because if there were a wildcard, we would have made hand a Highcard.
     *  1. So we don't need a wildcards replacement
     *  Observation - 2 : #noOfDistinctCardsPossible >= 5 then straight can never be form with #cardsInHand > 5.
     *  2. So we don't need to worry about case when #cards > 5
     */
    if(this.noOfDistinctCardsPossible !== 5){ return false }

    // Make sure it's not flush
    for(const suit in this.suits){
      if(this.suits[suit].length === 5){
        return false
      }
    }

    this.cards = this.cardPool
    this.changeRankOfAce()
    this.sortCards()

    return true
  }

  sortCards(){
    this.cards = this.cards.sort((a, b) => {
      if (a.rank > b.rank) { return -1 } 
      else if (a.rank < b.rank) { return 1 }
      else return 0
    })
  }

  setDescr(){
    this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + ' High';
  }
  
}
class FlushLo extends HandLo {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Flush', game, settings, canDisqualify);
  }

  solveForAllCardsNo(){
    // No of cards : 5,6,7
    /** Observation - 1 : There is never going to be a wildcard. Because if there were a wildcard, we would have made hand a Highcard.
     *  1. So we don't need a wildcards replacement
     *  Observation - 2 : #noOfDistinctCardsPossible >= 5 then straight can never be form with #cardsInHand > 5.
     *  2. So we don't need to worry about case when #cards > 5
     */
    const cond1 = this.noOfDistinctCardsPossible >= 5
    
    // we must have atleast 5 cards from any one suit. And there can never be 2 such suits.
    let fiveCardsFromSameSuit = false
    let contributingSuit;
    for(const suit in this.suits){
      if(this.suits[suit].length === 5){
        fiveCardsFromSameSuit = true
        contributingSuit = suit
        break
      }
    }
    const cond2 = fiveCardsFromSameSuit
    if(! (cond1 && cond2)){ return false }

    
    this.cards = this.cardPool.filter(card => card.suit === contributingSuit)
    this.cards = this.cards.slice(0, 5)
    this.changeRankOfAce()
    this.sortCards()

    const isStraight = (this.cards[0].rank - this.cards[4].rank) === 4 // Handle case of a,k,q,j,t

    if(isStraight) { return false }

    return true
  }

  sortCards(){
    this.cards = this.cards.sort((a, b) => {
      if (a.rank > b.rank) { return -1 } 
      else if (a.rank < b.rank) { return 1 }
      else return 0
    })
  }

  setDescr(){
    this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + ' High';
  }
}

class StraightFlushLo extends HandLo {
  constructor(cards, game, settings, canDisqualify) {
    super(cards, 'Straight Flush', game, settings, canDisqualify);
  }

  solveForAllCardsNo(){
    // No of cards : 5
    /** Observation - 1 : There is never going to be a wildcard. Because if there were a wildcard, we would have made hand a Highcard.
     *  1. So we don't need a wildcards replacement
     *  Observation - 2 : #noOfDistinctCardsPossible >= 5 then straight can never be form with #cardsInHand > 5.
     *  2. So we don't need to worry about case when #cards > 5
     */
    this.cards = this.cardPool
    this.changeRankOfAce()
    this.sortCards()
    return true
  }

  sortCards(){
    this.cards = this.cards.sort((a, b) => {
      if (a.rank > b.rank) { return -1 } 
      else if (a.rank < b.rank) { return 1 }
      else return 0
    })
  }

  setDescr(){
    this.descr = this.name + ', ' + this.cards[0].toString().slice(0, -1) + ' High';
  }
}

var gameRules = {
  'standard': {
    'cardsInHand': 5,
    'handValues': [StraightFlush, FourOfAKind, FullHouse, Flush, Straight, ThreeOfAKind, TwoPair, OnePair, HighCard],
    'wildValue': null,
    'wildStatus': 1,
    'wheelStatus': 0,
    'sfQualify': 5,
    'lowestQualified': null,
    "noKickers": false
  },
  'jacksbetter': {
    'cardsInHand': 5,
    'handValues': [StraightFlush, FourOfAKind, FullHouse, Flush, Straight, ThreeOfAKind, TwoPair, OnePair, HighCard],
    'wildValue': null,
    'wildStatus': 1,
    'wheelStatus': 0,
    'sfQualify': 5,
    'lowestQualified': ['Jc', 'Jd', '4h', '3s', '2c'],
    "noKickers": true
  },
  'joker': {
    'cardsInHand': 5,
    'handValues': [NaturalRoyalFlush, FiveOfAKind, WildRoyalFlush, StraightFlush, FourOfAKind, FullHouse, Flush, Straight, ThreeOfAKind, TwoPair, OnePair, HighCard],
    'wildValue': 'O',
    'wildStatus': 1,
    'wheelStatus': 0,
    'sfQualify': 5,
    'lowestQualified': null,
    "noKickers": false
  },
  'joker_FOK_beats_RF': {
    'cardsInHand': 5,
    'handValues': [FiveOfAKind, NaturalRoyalFlush, WildRoyalFlush, StraightFlush, FourOfAKind, FullHouse, Flush, Straight, ThreeOfAKind, TwoPair, OnePair, HighCard],
    'wildValue': 'O',
    'wildStatus': 1,
    'wheelStatus': 0,
    'sfQualify': 5,
    'lowestQualified': null,
    "noKickers": false
  },
  'jokerLo': {
    'cardsInHand': 5,
    'handValues': [HighCardLo, get_N_OF_A_KIND_LO_class(2), TwoPairLo, get_N_OF_A_KIND_LO_class(3), StraightLo, FlushLo, FullHouseLo, get_N_OF_A_KIND_LO_class(4), StraightFlushLo],
    'wildValue': 'O',
    'wildStatus': 1,
    'wheelStatus': 0,
    'sfQualify': 5,
    'lowestQualified': null,
    "noKickers": false
  },
  'deuceswild': {
    'cardsInHand': 5,
    'handValues': [NaturalRoyalFlush, FourWilds, WildRoyalFlush, FiveOfAKind, StraightFlush, FourOfAKind, FullHouse, Flush, Straight, ThreeOfAKind, HighCard],
    'wildValue': '2',
    'wildStatus': 1,
    'wheelStatus': 0,
    'sfQualify': 5,
    'lowestQualified': ['5c', '4d', '3h', '3s', '3c'],
    "noKickers": true
  },
  'threecard': {
    'cardsInHand': 3,
    'handValues': [StraightFlush, ThreeOfAKind, Straight, Flush, OnePair, HighCard],
    'wildValue': null,
    'wildStatus': 1,
    'wheelStatus': 0,
    'sfQualify': 3,
    'lowestQualified': ['Qh', '3s', '2c'],
    "noKickers": false
  },
  'fourcard': {
    'cardsInHand': 4,
    'handValues': [FourOfAKind, StraightFlush, ThreeOfAKind, Flush, Straight, TwoPair, OnePair, HighCard],
    'wildValue': null,
    'wildStatus': 1,
    'wheelStatus': 0,
    'sfQualify': 4,
    'lowestQualified': null,
    "noKickers": true
  },
  'fourcardbonus': {
    'cardsInHand': 4,
    'handValues': [FourOfAKind, StraightFlush, ThreeOfAKind, Flush, Straight, TwoPair, OnePair, HighCard],
    'wildValue': null,
    'wildStatus': 1,
    'wheelStatus': 0,
    'sfQualify': 4,
    'lowestQualified': ['Ac', 'Ad', '3h', '2s'],
    "noKickers": true
  },
  'paigowpokerfull': {
    'cardsInHand': 7,
    'handValues': [FiveOfAKind, FourOfAKindPairPlus, StraightFlush, Flush, Straight, FourOfAKind, TwoThreeOfAKind, ThreeOfAKindTwoPair, FullHouse, ThreeOfAKind, ThreePair, TwoPair, OnePair, HighCard],
    'wildValue': 'O',
    'wildStatus': 0,
    'wheelStatus': 1,
    'sfQualify': 5,
    'lowestQualified': null
  },
  'paigowpokeralt': {
    'cardsInHand': 7,
    'handValues': [FourOfAKind, FullHouse, ThreeOfAKind, ThreePair, TwoPair, OnePair, HighCard],
    'wildValue': 'O',
    'wildStatus': 0,
    'wheelStatus': 1,
    'sfQualify': 5,
    'lowestQualified': null
  },
  'paigowpokersf6': {
    'cardsInHand': 7,
    'handValues': [StraightFlush, Flush, Straight],
    'wildValue': 'O',
    'wildStatus': 0,
    'wheelStatus': 1,
    'sfQualify': 6,
    'lowestQualified': null
  },
  'paigowpokersf7': {
    'cardsInHand': 7,
    'handValues': [StraightFlush, Flush, Straight],
    'wildValue': 'O',
    'wildStatus': 0,
    'wheelStatus': 1,
    'sfQualify': 7,
    'lowestQualified': null
  },
  'paigowpokerhi': {
    'cardsInHand': 5,
    'handValues': [FiveOfAKind, StraightFlush, FourOfAKind, FullHouse, Flush, Straight, ThreeOfAKind, TwoPair, OnePair, HighCard],
    'wildValue': 'O',
    'wildStatus': 0,
    'wheelStatus': 1,
    'sfQualify': 5,
    'lowestQualified': null
  },
  'paigowpokerlo': {
    'cardsInHand': 2,
    'handValues': [OnePair, HighCard],
    'wildValue': 'O',
    'wildStatus': 0,
    'wheelStatus': 1,
    'sfQualify': 5,
    'lowestQualified': null
  }
};

/**
 * Base Game class that defines the rules of the game.
 */
class Game {
  constructor(descr) {
    this.descr = descr;
    this.cardsInHand = 0;
    this.handValues = [];
    this.wildValue = null;
    this.wildStatus = 0;
    this.wheelStatus = 0;
    this.sfQualify = 5;
    this.lowestQualified = null;
    this.noKickers = null;

    // Set values based on the game rules.
    if (!this.descr || !gameRules[this.descr]) {
      this.descr = 'standard';
    }
    this.cardsInHand = gameRules[this.descr]['cardsInHand'];
    this.handValues = gameRules[this.descr]['handValues'];
    this.wildValue = gameRules[this.descr]['wildValue'];
    this.wildStatus = gameRules[this.descr]['wildStatus'];
    this.wheelStatus = gameRules[this.descr]['wheelStatus'];
    this.sfQualify = gameRules[this.descr]['sfQualify'];
    this.lowestQualified = gameRules[this.descr]['lowestQualified'];
    this.noKickers = gameRules[this.descr]['noKickers'];
  }
}

function exportToGlobal(global) {
  global.Card = Card;
  global.Hand = Hand;
  global.HandLo = HandLo;
  global.Game = Game;
  global.RoyalFlush = RoyalFlush;
  global.NaturalRoyalFlush = NaturalRoyalFlush;
  global.WildRoyalFlush = WildRoyalFlush;
  global.FiveOfAKind = FiveOfAKind;
  global.StraightFlush = StraightFlush;
  global.FourOfAKindPairPlus = FourOfAKindPairPlus;
  global.FourOfAKind = FourOfAKind;
  global.FourWilds = FourWilds;
  global.TwoThreeOfAKind = TwoThreeOfAKind;
  global.ThreeOfAKindTwoPair = ThreeOfAKindTwoPair;
  global.FullHouse = FullHouse;
  global.Flush = Flush;
  global.Straight = Straight;
  global.ThreeOfAKind = ThreeOfAKind;
  global.ThreePair = ThreePair;
  global.TwoPair = TwoPair;
  global.OnePair = OnePair;
  global.HighCard = HighCard;
  global.PaiGowPokerHelper = PaiGowPokerHelper;
}

// Export the classes for node.js use.
if (typeof exports !== 'undefined') {
  exportToGlobal(exports);
}

// Add the classes to the window for browser use.
if (typeof window !== 'undefined') {
  exportToGlobal(window);
}
