import React, { useEffect } from 'react';

import CreateRoom from './components/Rooms/CreateRoom';
import Home from './components/Home';
import JoinRoom from './components/Rooms/JoinRoom';
import Login from './components/Login';
import PlayerAccount from './components/PlayerAccount';
import Privacypolicy from './components/Ppolicy/Privacypolicy';
import NewHome from './components/NewHome';
import Rules from './components/GameRules/Rules';
import Preferences from './components/Rooms/Preferences';

import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Game from "./components/Game/Game";

import './index.css';

import ReactGA from 'react-ga';
import ErrorPage from './components/ErrorPage';

function App () {

  // ReactGA.initialize('G-WEZEQHVP58');
  // ReactGA.pageview(window.location.pathname + window.location.search);

  return (
      <Router>
        <Switch>
          <Route exact path="/" component={NewHome}/>
          <Route path="/create" component={CreateRoom}/>
          <Route path="/game" component={Game}/>
          <Route path="/join" component={JoinRoom}/>
          <Route path="/join/joinroom?" component={JoinRoom}/> 
          <Route path="/account" component={PlayerAccount}/>
          <Route path="/privacypolicy" component={Privacypolicy}/>
          <Route path="/rules" component={Rules}/>
          <Route path="/preferences" component={Preferences}/>
          <Route component={ErrorPage} />
          </Switch>
      </Router>
  );
};

export default App;
