import {loadStripe} from '@stripe/stripe-js';
import firebase from 'firebase'

export const getPremiumPlan = async (priceId) => {
    const currentUser = firebase.auth().currentUser
    const db = firebase.firestore()
    const docRef = await db
    .collection('customers')
    .doc(currentUser.uid)
    .collection('checkout_sessions')
    .add({
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
    }).
    catch(err => {console.log("Error : ", err)});

    docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
        // Show an error to your customer and 
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
            // We have a session, let's redirect to Checkout
            // Init Stripe
            const stripe = await loadStripe('pk_test_51IIUz3BJFZ95ERMXP7Wy6dD7JIMEhMSEPZWFhdff3Ir6blZnEwYLUsSsp4KCWpoMQIQUPObktSlr27jNDhulCFZW00xvLJJ21D');
            stripe.redirectToCheckout({ sessionId });
        }
    });
}   


export const sendToCustomerPortal = async () => {
    const functionRef = firebase
      .app()
      .functions()
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
}

export const prices = {
    "perMonth" : "price_1IIWBHBJFZ95ERMXwSsvr6JJ",
    "perYear" : "price_1IIx4CBJFZ95ERMXZ73Idltp"
}