
export const getActionMsg = (data) => {
    let mapping = {
        "call": `bets ${data.action.maxBet}`,
        "raise": data.action.name === "raise" ? `raises ${data.action.data.amount} and bets ${data.action.maxBet}` : "",
        "fold": `folded`,
        "pass": `passed his turn`
    };
    return `${data.clientName} ${mapping[data.action.name]}`
};
