import {evaluateCards} from 'phe'; 
import {RANKS_PS} from '../CardsConstants' 

const isFiveOfAKind = (cards) => {
    // TODO : Use pokersolver library
    const frequencies = {}
    for(const card of cards){
        const value = card[0]
        const eleFrequency = frequencies[value]
        frequencies[value] = (eleFrequency === undefined) ? 1 : eleFrequency + 1
        if(frequencies[value] === 5){ return value }
    }
    return undefined
}

const isRoyalFlush = (cards) => {
    // TODO : Use pokersolver library
    if(cards.length < 5){ return false }

    // Phe library will evaluate royal flush to 1.
    const strength = evaluateCards(cards)
    if(strength === 1){ return true }
    else { return false }
}

const getTopPlayers = (hands, winners) => {
    /* players = [
        {handStrength : 130, ...},
        {handStrength : 130, ...},
        {handStrength : 140, ...},
        {handStrength : 150, ...}
    ]
    This would add first two entries of players into winners array.
    */
    if(hands.length > 0){
        const winningStrength = hands[0].handStrength
        for (let i = 0; i < hands.length; i++) {
            const hand = hands[i]
            const {player,handStrength} = hand;
            if(handStrength === winningStrength){
                winners.push(hand)
            }
            else{ break } 
        }
    }
}

const getPokerSolverHandSetting = (settings) => {
    const {wildCardsSettings} = settings
    if(! wildCardsSettings) { return 'joker' }

    const {royalFlushBeatsFiveOfKind} = wildCardsSettings

    return royalFlushBeatsFiveOfKind ? 'joker' : 'joker_FOK_beats_RF'
}

const getPokerSolverHandSettingLo = (settings) => {
    return 'jokerLo'
}

const transformRank = (rank, royalFlushBeatsFiveOfKind) => {
    if(rank <= RANKS_PS.STRAIGHT_FLUSH){ return rank }
    else{
        if(royalFlushBeatsFiveOfKind){ return (rank === 12) ? 10 : 11 }
        else{ return (rank === 12) ? 11 : 10 }
    }
}  

export {
    isFiveOfAKind,
    isRoyalFlush,
    getTopPlayers,
    getPokerSolverHandSetting,
    getPokerSolverHandSettingLo,
    transformRank
}