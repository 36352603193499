import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import firebase from "firebase";
import './CreateRoom.css';
import {createRoomHandler, setPrefernces, getPrefernces, getSpecificPrefernce} from "../../services/room";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Select from 'react-select';
import {ListManager} from "react-beautiful-dnd-grid";
import { getItems, sortCards } from "../../utils/modules/GameFunctions";
import Cards from "../Cards/Cards";
import HomeImg from '../../assets/BG@2x.png';
import HomeLogo from '../../assets/Logo.svg';
import GoogleAds from '../GoogleAds';

const cloneDeep = require('lodash.clonedeep')

class Preferences extends Component {

    constructor(props) {
        super(props);

        this.gamePreferenceState = {
            games: [
                {id: 1, value: "Anaconda", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5, loAce: true, loFlush: true},
                {id: 2, value: "357", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5},
                {id: 3, value: "3-Brent-7", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5, loAce: true, loFlush: true},
                {id: 4, value: "Texas-Holdem", isChecked: true, maxRaise: 0, minRaise: 10, timesRaise: 3, ante: 5},
                {id: 5, value: "7-Card-Stud", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5},
                {id: 6, value: "Lo Chicago", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5, loAce: true, loFlush: true},
                {id: 7, value: "High Chicago", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5},
                {id: 8, value: "5-Card-Draw", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5, restrict5CDOpening: true},
                {id: 9, value: "Diablo", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5, restrict5CDOpening: true},
                {id: 10, value: "Cincinnati", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5, loAce: true, loFlush: true},
                {id: 11, value: "5-Card-Draw Round", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5, restrict5CDOpening: true},
                {id: 12, value: "Texas-Holdem Round", isChecked: true, maxRaise: 0, minRaise: 10, timesRaise: 3, ante: 5},
                {id: 13, value: "HomeRun", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5},
                {id: 14, value: "HomeRun Round", isChecked: true, maxRaise: 25, minRaise: 5, timesRaise: 3, ante: 5},
                {id: 15, value: "Omaha", isChecked: true, maxRaise: 0, minRaise: 10, timesRaise: 3, ante: 5},
                {id: 16, value: "Omaha Round", isChecked: true, maxRaise: 0, minRaise: 10, timesRaise: 3, ante: 5},
                {id: 17, value: "Omaha8", isChecked: true, maxRaise: 0, minRaise: 10, timesRaise: 3, ante: 5, loAce: true, loFlush: true},
                {id: 18, value: "Omaha8 Round", isChecked: true, maxRaise: 0, minRaise: 10, timesRaise: 3, ante: 5, loAce: true, loFlush: true}
            ],




            allChecked: true,
            allCheckedMaxBet: false,
            allCheckedMaxNoOfRaise: false,
            allCheckedAnte: false,
            allCheckedMinBet: false,
            privateRoom: false,
            intialBuyIn: 2000,
            maxReBuy: 2,
            allAceBool: true,
            allFlushBool: true,
            allJBool: true,
            maxStartAmount: 2000,
            minStartAmount: 1000,
            savedPreferencesList: [],
            preferenceName: '',
            selectedPreference: {},
            noOfJokers: 0,
            selectedWildCardChoice: {},
            wildCardOptions: [{value: "By Rank", label: "By Rank"}, {value: "One-Eye Jacks", label: "One-Eye Jacks"}, {value: "King with Axe", label: "King with Axe"}, {value: "Suicide King", label: "Suicide King"}],
            wildCardSelectedOption: "",
            wildCardOptionsByRank: [{value: "A", label: "A"}, {value: "K", label: "K"}, {value: "Q", label: "Q"}, {value: "J", label: "J"}, {value: "10", label: "10"},
                                    {value: "9", label: "9"}, {value: "8", label: "8"}, {value: "7", label: "7"}, {value: "6", label: "6"}, {value: "5", label: "5"},
                                    {value: "4", label: "4"}, {value: "3", label: "3"}, {value: "2", label: "2"}],
            wildCardOptionsBySuit: [{value: "♠︎", label: "♠︎"}, {value: "♥︎", label: "♥︎"}, {value: "♦︎", label: "♦︎"}, {value: "♣︎", label: "♣︎"}],
            selectedWildCardsList: [], addWildCardsBool: true, selectedWildCardsByRank: [], selectedWildCardsBySuit: [],
            localhandsMustHaveOneNaturalCard: false, localRoyalFlushBeatsFiveOfKind: true, localPayForFaceUpWildCard: 10,

        };
        this.uiState = {
            displayCreatePreference: false,
            displayUpdatePreference: false
        }
        this.state = {...cloneDeep(this.gamePreferenceState), ...this.uiState};

        this.handleChange = this.handleChange.bind(this);
        this.handleCreateNewPreferences = this.handleCreateNewPreferences.bind(this)
        this.handleUpdateCurrentPreferences = this.handleUpdateCurrentPreferences.bind(this)
        this.handleBackPress = this.handleBackPress.bind(this)
    }

    componentDidMount = async () => {

        firebase.auth().onAuthStateChanged( async function(user) {
            if (user) {
                this.setState({
                    clientName: firebase.auth().currentUser.displayName || '',
                    redirectSuccess: false
                });
                if(!firebase.auth().currentUser.isAnonymous) {
                    let response = await getPrefernces();
                    console.log('APICall getPreferences', response);
                    let temp = [];
                    for (const i in response.data) {
                        if(response.data[i]) {
                            let body = {
                                value: response.data[i],
                                label: response.data[i]
                            }
                            temp.push(body);
                        }
                    }
                    this.setState({savedPreferencesList: temp})
                    this.gamePreferenceState.savedPreferencesList= temp
                    console.log('PREFERENCES GET', this.state.savedPreferencesList);
                }
            } else {
                console.log('Firebase Signed Out user');
            }
        }.bind(this));
    }

    handleJokerChange = (operation) => {
        if(operation === '+') {
            if(this.state.noOfJokers < 4) {this.setState({noOfJokers: this.state.noOfJokers + 1})}
        }
        if(operation === '-') {
            if(this.state.noOfJokers > 0) {this.setState({noOfJokers: this.state.noOfJokers - 1})}
        }
    }

    handleWildCardsChange = selectedWildCardChoice => {
        this.setState(
            { selectedWildCardChoice },
            () => console.log(`Option selected:`, this.state.selectedWildCardChoice)
        );
        if(selectedWildCardChoice.value === "By Rank") {
            this.setState({wildCardSelectedOption: "By Rank"})
        }
        if(selectedWildCardChoice.value === "By Suit") {
            this.setState({wildCardSelectedOption: "By Suit"})
        }
        if(selectedWildCardChoice.value === "One-Eye Jacks") {
            this.setState({wildCardSelectedOption: "One-Eye Jacks"})
        }
        if(selectedWildCardChoice.value === "King with Axe") {
            this.setState({wildCardSelectedOption: "King with Axe"})
        }
        if(selectedWildCardChoice.value === "Suicide King") {
            this.setState({wildCardSelectedOption: "Suicide King"})
        }
    }

    listWildCardElement = (item) => {
        if (item) {
            let id = item.id;
            return <div className="wildCard">
                    <Cards suit={id[id.length - 1]} value={id.slice(0, id.length - 1)}/>
                    <div className="wildCardClose" onClick={() => this.removeWildCardsItem(item)}><i class="fa fa-window-close" aria-hidden="true"/></div>
                </div>;
        }
        else
            return ""
    };

    removeWildCardsItem = (data) => {
        let { selectedWildCardsList } = this.state;
        let temp = selectedWildCardsList.filter(function(value){ 
            return value !== data.id;
        });
        this.setState({selectedWildCardsList: temp})
    }

    addWildCards = () => {
        let { selectedWildCardChoice, selectedWildCardBySuit, selectedWildCardByRank, selectedWildCardsByRank, selectedWildCardsBySuit, selectedWildCardsList, addWildCardsBool } = this.state;
        if(selectedWildCardChoice.value === "By Rank" && selectedWildCardsByRank.length > 0 && selectedWildCardsBySuit.length > 0) {
            // let rankList = selectedWildCardByRank.map(({ value }) => value);
            let rankList = selectedWildCardsByRank;
            let suitTemp = selectedWildCardsBySuit;
            let suitList = [];
            if(suitTemp.includes("♠︎")) {suitList.push('s')}
            if(suitTemp.includes("♥︎")) {suitList.push('h')}
            if(suitTemp.includes("♦︎")) {suitList.push('d')}
            if(suitTemp.includes("♣︎")) {suitList.push('c')}
            let temp = [];
            for (const i in rankList) {
                for(const j in suitList) {
                    if(rankList[i] === "10") {temp.push('T'.concat(suitList[j]))}
                    else {temp.push(rankList[i].concat(suitList[j]))}
                }
            }
            console.log('TEMP', temp);
            this.removeDuplicates(selectedWildCardsList.concat(temp));
        }
        if(selectedWildCardChoice.value === "One-Eye Jacks") {
            this.removeDuplicates(selectedWildCardsList.concat(["Js", "Jh"]));
        }
        if(selectedWildCardChoice.value === "King with Axe") {
            this.removeDuplicates(selectedWildCardsList.concat(["Kd"]));
        }
        if(selectedWildCardChoice.value === "Suicide King") {
            this.removeDuplicates(selectedWildCardsList.concat(["Kh"]));
        }
    }

    removeDuplicates = (data) => {
        let temp = data.filter((value, index) => data.indexOf(value) == index);
        this.setState({selectedWildCardsList: temp})
    }

    handleWildCardsByRankChangeTable = card => {
        if(this.state.selectedWildCardsByRank.includes(card)) {
            let x = this.state.selectedWildCardsByRank;
            const index = x.indexOf(card);
            if (index > -1) {
                x.splice(index, 1);
            }
            this.setState({selectedWildCardsByRank: x})
        }
        else {
            this.state.selectedWildCardsByRank.push(card);
            let y = [...new Set(this.state.selectedWildCardsByRank)]
            this.setState({selectedWildCardsByRank: y})
        }
    }

    handleWildCardsBySuitChangeTable = suit => {
        if(this.state.selectedWildCardsBySuit.includes(suit)) {
            let x = this.state.selectedWildCardsBySuit;
            const index = x.indexOf(suit);
            if (index > -1) {
                x.splice(index, 1);
            }
            this.setState({selectedWildCardsBySuit: x})
        }
        else {
            this.state.selectedWildCardsBySuit.push(suit);
            let y = [...new Set(this.state.selectedWildCardsBySuit)]
            this.setState({selectedWildCardsBySuit: y})
        }
    }

    handleRoyalFlush = (event) => {
        this.setState({
            localRoyalFlushBeatsFiveOfKind: event.target.checked
        })
    }

    handleNaturalCard = (event) => {
        this.setState({localhandsMustHaveOneNaturalCard: event.target.checked})
    }

    handlePreferenceChange = async (selectedPreference) => {
        this.setState(
            { selectedPreference },
            () => console.log(`Preference selected:`, this.state.selectedPreference)
        );
        let response = await getSpecificPrefernce(selectedPreference.value);
        console.log('APICall get preference', response);
        if(response) {
            this.setState({
                    games: response.data[0].preference.games,
                    allCheckedMaxBet: response.data[0].preference.allCheckedMaxBet,
                    allCheckedMaxNoOfRaise: response.data[0].preference.allCheckedMaxNoOfRaise,
                    allCheckedAnte: response.data[0].preference.allCheckedAnte,
                    allCheckedMinBet: response.data[0].preference.allCheckedMinBet,
                    privateRoom: response.data[0].preference.privateRoom,
                    intialBuyIn: response.data[0].preference.intialBuyIn,
                    maxReBuy: response.data[0].preference.maxReBuy,
                    allAceBool: response.data[0].preference.allAceBool,
                    allFlushBool: response.data[0].preference.allFlushBool,
                    allJBool: response.data[0].preference.allJBool,
                    maxStartAmount: response.data[0].preference.maxStartAmount,
                    minStartAmount: response.data[0].preference.minStartAmount,
                    noOfJokers: response.data[0].preference.noOfJokers,
                    localhandsMustHaveOneNaturalCard: response.data[0].preference.localhandsMustHaveOneNaturalCard,
                    localRoyalFlushBeatsFiveOfKind: response.data[0].preference.localRoyalFlushBeatsFiveOfKind,
                    localPayForFaceUpWildCard: response.data[0].preference.localPayForFaceUpWildCard,
                    selectedWildCardsList: response.data[0].preference.selectedWildCardsList
            })
        }
        else {
            alert('No saved room settings');
        }
    }

    handleChange(event) {
        const {name, value, type, checked} = event.target;
        type === "checkbox" ? this.setState({[name]: checked}) : this.setState({[name]: value})
    }
    handleCreateNewPreferences(event){
        this.setState({displayCreatePreference:true, displayUpdatePreference: false ,...cloneDeep(this.gamePreferenceState)})

    }
    handleUpdateCurrentPreferences(event){
        this.setState({displayCreatePreference:false, displayUpdatePreference: true })
    }

    handleBackPress(event){
        this.setState({displayCreatePreference: false, displayUpdatePreference:false})
    }

    handleAmountChange = (event) => {
        let value = parseInt(event.target.value, 10) || 0;
        this.setState({
            [event.target.name] : value
        })
    }

    handleAllChecked = (event) => {
        let games = this.state.games;
        games.forEach(game => game.isChecked = event.target.checked) 
        this.setState({games: games,
        allChecked: !this.state.allChecked})
    }

    handleMaxBetChecked = (event) => {
        this.setState({
            allCheckedMaxBet: event.target.checked
        })
    }

    handleMinBetChecked = (event) => {
        this.setState({
            allCheckedMinBet: event.target.checked
        })
    }

    handleMaxNoOfRaiseChecked = (event) => {
        this.setState({
            allCheckedMaxNoOfRaise: event.target.checked
        })
    }

    handleAnteChecked = (event) => {
        this.setState({
            allCheckedAnte: event.target.checked
        })
    }

    handleRoomType = (event) => {
        this.setState({
            privateRoom: event.target.checked
        })
    }
    
    handleCheckChieldElement = (event) => {
        let games = this.state.games
        games.forEach(game => {
            if (game.value === event.target.value)
                game.isChecked =  event.target.checked
        })
        this.setState({games: games})
    }

    handleMaxRaiseChange = (event, id) => {
        let value = parseInt(event.target.value, 10) || 0;
        let games = this.state.games;
        games.forEach(game => {
            if(this.state.allCheckedMaxBet) {
                game.maxRaise = value
            }
            else {
                if(game.id === id) {
                    game.maxRaise = value
                }
            }
        })
        this.setState({games: games})
    }

    handleMinRaiseChange = (event, id) => {
        let value = parseInt(event.target.value, 10) || 0;
        let games = this.state.games;
        games.forEach(game => {
            if(this.state.allCheckedMinBet) {
                game.minRaise = value
            }
            else {
                if(game.id === id) {
                    game.minRaise = value
                }
            }
        })
        this.setState({games: games})
    }

    handleTimesRaiseChange = (event, id) => {
        let value = parseInt(event.target.value, 10) || 0;
        let games = this.state.games;
        games.forEach(game => {
            if(this.state.allCheckedMaxNoOfRaise) {
                game.timesRaise = value
            }
            else {
                if(game.id === id) {
                    game.timesRaise = value
                }
            }
        })
        this.setState({games: games})
    }

    handleAnteChange = (event, id) => {
        let value = parseInt(event.target.value, 10) || 0;
        let games = this.state.games;
        games.forEach(game => {
            if(this.state.allCheckedAnte) {
                if(game.value === "Texas-Holdem" || game.value =="Texas-Holdem Round" || game.value === "Omaha" || game.value =="Omaha Round" || game.value === "Omaha8" || game.value =="Omaha8 Round")
                {
                    game.minRaise = value*2
                }
                else {
                    game.minRaise = value
                }
                game.ante = value
            }
            else {
                if(game.id === id) {
                    if(game.value === "Texas-Holdem" || game.value =="Texas-Holdem Round" || game.value === "Omaha" || game.value =="Omaha Round" || game.value === "Omaha8" || game.value =="Omaha8 Round")
                    {
                        game.minRaise = value*2
                    }
                    else {
                        game.minRaise = value
                    }
                    game.ante = value
                }
            }
        })
        this.setState({games: games})
    }

    handleNoLimitPress = (event, id) => {
        let games = this.state.games;
        games.forEach(game => {
            if(game.id === id)
                {
                    game.ante = 5;
                    game.timesRaise = 0;
                    game.maxRaise = 0;
                    if(game.value === "Texas-Holdem" || game.value === "Texas-Holdem Round" || game.value === "Omaha" || game.value =="Omaha Round" || game.value === "Omaha8" || game.value =="Omaha8 Round")
                    {
                        game.minRaise = 10;
                    }
                    else {
                        game.minRaise = 5;
                    }
                }
        })
        this.setState({games: games})
    }

    handleLoDecisions = (event, id) => {
        const {name, checked} = event.target;
        let games = this.state.games;
        games.forEach(game => {
            if(game.id === id)
                {
                    if(name === "ace") {
                        game.loAce = checked;
                    }
                    if(name === "flush") {
                        game.loFlush = checked;
                    }
                }
        })
        this.setState({games: games})
    }

    handleBetRestriction = (event, id) => {
        const {checked} = event.target;
        let games = this.state.games;
        games.forEach(game => {
            if(game.id === id)
                {
                    game.restrict5CDOpening = checked;
                }
        })
        this.setState({games: games})
    }

    handleAllAce = (event) => {
        const {checked} = event.target;
        let games = this.state.games;
        games.forEach(game => {
            if("loAce" in game)
                {
                    game.loAce = checked;
                }
        })
        this.setState({
            games: games,
            allAceBool: checked
        })
    }

    handleAllFlush = (event) => {
        const {checked} = event.target;
        let games = this.state.games;
        games.forEach(game => {
            if("loFlush" in game)
                {
                    game.loFlush = checked;
                }
        })
        this.setState({
            games: games,
            allFlushBool: checked
        })
    }

    handleallMinJ = (event) => {
        const {checked} = event.target;
        let games = this.state.games;
        games.forEach(game => {
            if("restrict5CDOpening" in game)
                {
                    game.restrict5CDOpening = checked;
                }
        })
        this.setState({
            games: games,
            allJBool: checked
        })
    }

    savePreferences = async () => {
        let { preferenceName, savedPreferencesList } = this.state;
        if(preferenceName.length === 0) {
            alert('Enter a preference name');
        }
        else {
            let bool = false;
            for(const i in savedPreferencesList) {
                if(preferenceName === savedPreferencesList[i].value) {bool = true}
            }
            if(bool) {
                alert(`You already have a saved preference with this name. Enter a different Name`)
            }
            else {
                let body = {
                    name: preferenceName,
                    preference: {
                        games: this.state.games,
                        allCheckedMaxBet: this.state.allCheckedMaxBet,
                        allCheckedMaxNoOfRaise: this.state.allCheckedMaxNoOfRaise,
                        allCheckedAnte: this.state.allCheckedAnte,
                        allCheckedMinBet: this.state.allCheckedMinBet,
                        privateRoom: this.state.privateRoom,
                        intialBuyIn: this.state.intialBuyIn,
                        maxReBuy: this.state.maxReBuy,
                        allAceBool: this.state.allAceBool,
                        allFlushBool: this.state.allFlushBool,
                        allJBool: this.state.allJBool,
                        maxStartAmount: this.state.maxStartAmount,
                        minStartAmount: this.state.minStartAmount
                    }
                };
                let response = await setPrefernces(body, 'POST');
                console.log('APICALL setPreferences', response);
                if(response) {
                    let response_new = await getPrefernces();
                    console.log('APICall getPreferences', response_new);
                    let temp = [];
                    for (const i in response_new.data) {
                        if(response_new.data[i]) {
                            let body = {
                                value: response_new.data[i],
                                label: response_new.data[i]
                            }
                            temp.push(body);
                        }
                    }

                    // this.setState({selectedPreference: {value: preferenceName, label: preferenceName}})

                    this.setState({savedPreferencesList: temp, preferenceName: "",
                        displayCreatePreference: false,
                        ...cloneDeep(this.gamePreferenceState)})
                    console.log('PREFERENCES GET', this.state.savedPreferencesList);
                }
            }
        }
    }

    updatePreferences = async () => {
        let { selectedPreference } = this.state;

        if(!selectedPreference.value) {
            alert('Select a preference to update');
        }
        else {
            console.log('UPDATE', selectedPreference.value);
            let body = {
                name: selectedPreference.value,
                preference: {
                    games: this.state.games,
                    allCheckedMaxBet: this.state.allCheckedMaxBet,
                    allCheckedMaxNoOfRaise: this.state.allCheckedMaxNoOfRaise,
                    allCheckedAnte: this.state.allCheckedAnte,
                    allCheckedMinBet: this.state.allCheckedMinBet,
                    privateRoom: this.state.privateRoom,
                    intialBuyIn: this.state.intialBuyIn,
                    maxReBuy: this.state.maxReBuy,
                    allAceBool: this.state.allAceBool,
                    allFlushBool: this.state.allFlushBool,
                    allJBool: this.state.allJBool,
                    maxStartAmount: this.state.maxStartAmount,
                    minStartAmount: this.state.minStartAmount,
                    noOfJokers: this.state.noOfJokers,
                    localhandsMustHaveOneNaturalCard: this.state.localhandsMustHaveOneNaturalCard,
                    localRoyalFlushBeatsFiveOfKind: this.state.localRoyalFlushBeatsFiveOfKind,
                    localPayForFaceUpWildCard: this.state.localPayForFaceUpWildCard,
                    selectedWildCardsList: this.state.selectedWildCardsList
                }
            };
            let response = await setPrefernces(body, 'PUT');
            console.log('APICALL setPreferences', response);
            this.setState({displayUpdatePreference:false})
        }
    }



    render() {

        let  { selectedWildCardsByRank, selectedWildCardsBySuit } = this.state

        return (
            <div className="createroomoutercontainer">
                <div className="createroomsize">
                <div className="homepagelink">
                    <Link to={'/'} className="homepageBtn">
                        <i class="fa fa-home" aria-hidden="true"></i> <span>HOME</span>
                    </Link>
                    <div className="ad-class">
                    {/* <GoogleAds 
                                            slot="9909118413" 
                                            style={{
                                                display: 'inline-block',
                                                width: '200px',
                                                height: '150px',
                                        }}/> */}
                    </div>
                </div>
                <div className="createroominnercontainer">
                    {/*<div className="heading mb-2"><h1>ROOM PREFERENCES</h1></div>*/}

                    {!this.state.displayCreatePreference && <div className="selecttag">
                        <span className="private">SELECT PREFERENCE</span>

                        <div className="mx-auto preferenceselection">
                            <Select placeholder="Preference"
                                    value={this.state.selectedPreference.name}
                                    onChange={this.handlePreferenceChange}
                                    options={this.state.savedPreferencesList}
                            />
                        </div>



                        <br/>
                    </div>}

                    <div>
                        {!this.state.displayCreatePreference && !this.state.displayUpdatePreference &&
                        <button className={'preference-button mb-10'} type="submit" onClick={this.handleUpdateCurrentPreferences}>
                            UPDATE CURRENT PREFERENCE: {this.state.selectedPreference.value}
                        </button>}

                        { this.state.displayUpdatePreference &&
                        <div>
                            <button className={'preference-button mb-10 p-1 mr-1'} type="submit" onClick={this.handleBackPress}>
                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <button className={'preference-button mb-10'} type="submit" onClick={this.updatePreferences}>
                                SAVE CHANGES
                            </button>
                        </div>}
                        <br/>

                        {!this.state.displayCreatePreference &&!this.state.displayUpdatePreference &&
                        <button className={'preference-button mb-10'} type="submit" onClick={this.handleCreateNewPreferences}>
                            CREATE A NEW PREFERENCE
                        </button>}


                        {this.state.displayCreatePreference && <div>

                            <button className={'preference-button mb-10 p-1 mr-1'} type="submit" onClick={this.handleBackPress}>
                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>

                            <input name="preferenceName" className="createRoomnewInput" type="text" value={this.state.preferenceName}
                                   onChange={this.handleChange} placeholder="Preference Name"/>
                            <span>&nbsp;</span>
                            <button className={'preference-button mb-10'} type="submit" onClick={this.savePreferences}>
                                SAVE
                            </button>
                        </div>}
                    </div>

                    <br/>


                    {(Object.keys(this.state.selectedPreference).length !== 0 || this.state.displayCreatePreference || this.state.displayUpdatePreference) && <div>



                        <br/>
                        <div className="private">
                            <span className="privateborder"><input type="checkbox" onClick={this.handleRoomType} checked={this.state.privateRoom}/>&nbsp; PRIVATE</span> &nbsp; &nbsp; &nbsp; BUY-IN
                            <input name="intialBuyIn" className="buyinInput" type="number" value={Number(this.state.intialBuyIn).toString()}
                                onChange={this.handleAmountChange}/> &nbsp; &nbsp; &nbsp; MAX RE-BUY
                                <input name="maxReBuy" className="buyinInput" type="number" value={Number(this.state.maxReBuy).toString()}
                                    onChange={this.handleAmountChange}/>
                        </div><br/>
                        <div className="private">
                            MINIMUM START AMOUNT <input name="minStartAmount" className="buyinInput" type="number" value={Number(this.state.minStartAmount).toString()}
                                onChange={this.handleAmountChange}/> &nbsp; &nbsp; &nbsp; &nbsp; MAXIMUM START AMOUNT
                                <input name="maxStartAmount" className="buyinInput" type="number" value={Number(this.state.maxStartAmount).toString()}
                                    onChange={this.handleAmountChange}/>
                        </div><br/>
                        <div className="private">
                        <span className="privateborder"><input type="checkbox" onClick={this.handleAllAce} checked={this.state.allAceBool}/>&nbsp; CONSIDER ACE LOW</span> &nbsp; &nbsp; &nbsp;
                        <span className="privateborder"><input type="checkbox" onClick={this.handleAllFlush} checked={this.state.allFlushBool}/>&nbsp; COUNT STRAIGHT AND FLUSH AS LOW</span>
                         &nbsp; &nbsp; &nbsp;
                         <span className="privateborder"><input type="checkbox" onClick={this.handleallMinJ} checked={this.state.allJBool}/>&nbsp; MIN PAIR J's TO BET</span>
                        </div>
                        <br/><br/>
                        <div className="private">
                            <span className="privateborder"><input type="checkbox" onClick={this.handleRoyalFlush} checked={this.state.localRoyalFlushBeatsFiveOfKind}/>&nbsp; ROYAL-FLUSH BEATS 5-OF-A-KIND</span> &nbsp; &nbsp; &nbsp;
                            <span className="privateborder"><input type="checkbox" onClick={this.handleNaturalCard} checked={this.state.localhandsMustHaveOneNaturalCard}/>&nbsp; HAND MUST HAVE 1 NATURAL CARD</span> &nbsp; &nbsp; &nbsp;
                        </div>
                        <br/>
                        <br/>
                        <div className="startgametext">
                            No of Jokers
                            &nbsp; &nbsp;
                            <button className={'jokersmodifyBtn mb-10 p-1 mr-1'} type="submit" onClick={() => this.handleJokerChange('-')}>
                                -
                            </button>
                            &nbsp;
                            <span className="jokertext">{this.state.noOfJokers}</span>
                            &nbsp; &nbsp;
                            <button className={'jokersmodifyBtn mb-10 p-1 mr-1'} type="submit" onClick={() => this.handleJokerChange('+')}>
                                +
                            </button>
                        </div>
                        <br/>
                        <div className="wildCardSelection">
                            <Row>
                                <div>
                                    <Select placeholder="Wild Cards"
                                        value={this.state.selectedWildCardChoice.name}
                                        onChange={this.handleWildCardsChange}
                                        options={this.state.wildCardOptions}
                                    />
                                </div>
                                {this.state.wildCardSelectedOption === "By Rank" && <div>&nbsp; &nbsp;</div>}
                                {this.state.wildCardSelectedOption === "By Rank" && <div>
                                    {/* <Select placeholder="Choose Rank"
                                        isMulti={true}
                                        onChange={this.handleWildCardsByRankChange}
                                        options={this.state.wildCardOptionsByRank}
                                        closeMenuOnSelect={false}
                                    /> */}
                                    <table className="wildcardtable">
                                        <tr>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('2') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('2')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('2') ? "rankbuttonselected" : ""}`}>2</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('3') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('3')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('3') ? "rankbuttonselected" : ""}`}>3</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('4') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('4')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('4') ? "rankbuttonselected" : ""}`}>4</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('5') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('5')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('5') ? "rankbuttonselected" : ""}`}>5</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('6') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('6')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('6') ? "rankbuttonselected" : ""}`}>6</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('7') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('7')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('7') ? "rankbuttonselected" : ""}`}>7</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('8') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('8')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('8') ? "rankbuttonselected" : ""}`}>8</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('9') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('9')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('9') ? "rankbuttonselected" : ""}`}>9</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('10') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('10')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('10') ? "rankbuttonselected" : ""}`}>10</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('J') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('J')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('J') ? "rankbuttonselected" : ""}`}>J</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('Q') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('Q')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('Q') ? "rankbuttonselected" : ""}`}>Q</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('K') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('K')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('K') ? "rankbuttonselected" : ""}`}>K</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsByRank.includes('A') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('A')}>
                                                <button className={`byrankbutton ${selectedWildCardsByRank.includes('A') ? "rankbuttonselected" : ""}`}>A</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>}
                                {this.state.wildCardSelectedOption === "By Rank" && <div>&nbsp; &nbsp; &nbsp;</div>}
                                {this.state.wildCardSelectedOption === "By Rank" && <div>
                                    {/* <Select placeholder="Choose Suit"
                                        isMulti={true}
                                        onChange={this.handleWildCardsBySuitChange}
                                        options={this.state.wildCardOptionsBySuit}
                                        closeMenuOnSelect={false}
                                    /> */}
                                    <table className="wildcardtable">
                                        <tr>
                                            <td className={`byrankelement ${selectedWildCardsBySuit.includes('♦︎') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsBySuitChangeTable('♦︎')}>
                                                <button className={`byrankbutton ${selectedWildCardsBySuit.includes('♦︎') ? "rankbuttonselected" : ""}`}>Diamond ♦︎</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsBySuit.includes('♣︎') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsBySuitChangeTable('♣︎')}>
                                                <button className={`byrankbutton ${selectedWildCardsBySuit.includes('♣︎') ? "rankbuttonselected" : ""}`}>Club ♣︎</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={`byrankelement ${selectedWildCardsBySuit.includes('♥︎') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsBySuitChangeTable('♥︎')}>
                                                <button className={`byrankbutton ${selectedWildCardsBySuit.includes('♥︎') ? "rankbuttonselected" : ""}`}>Heart ♥︎</button>
                                            </td>
                                            <td className={`byrankelement ${selectedWildCardsBySuit.includes('♠︎') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsBySuitChangeTable('♠︎')}>
                                                <button className={`byrankbutton ${selectedWildCardsBySuit.includes('♠︎') ? "rankbuttonselected" : ""}`}>Spade ♠︎</button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>}
                            </Row>
                        </div>
                        <br/>
                        <div>
                            <Button className="StartGameBtn" onClick={this.addWildCards}>
                            ADD CARDS
                            </Button>
                        </div>
                        {this.state.selectedWildCardsList.length === 0 && <span className="startgametext">NO WILD CARDS SELECTED</span>}
                        {this.state.selectedWildCardsList.length > 0 && <span className="startgametext">WILD CARDS</span>}
                        <br/>
                        <div className="createroomwildcards">
                            <ListManager
                                items={sortCards(getItems(this.state.selectedWildCardsList))}
                                direction="horizontal"
                                maxItems={17}
                                render={(item) => this.listWildCardElement(item)}
                            />
                        </div>


                        {this.state.redirectSuccess && <Redirect to={`/game?name=${this.state.clientName}&room=${this.state.roomName}&spectate=${false}&initBuy=${this.state.intialBuyIn}&code=${this.state.roomCode}`}/>}

                        <div className="tablecontainer">
                            <table id="tablecreate" className="font-weight-bold">
                                <tr>
                                    <td>Game</td>
                                    <td>Max Bet Allowed <input type="checkbox" onClick={this.handleMaxBetChecked}  value="checkedall" checked={this.state.allCheckedMaxBet}/></td>
                                    <td>Min Bet Allowed <input type="checkbox" onClick={this.handleMinBetChecked}  value="checkedall" checked={this.state.allCheckedMinBet}/></td>
                                    <td>No of Raises <input type="checkbox" onClick={this.handleMaxNoOfRaiseChecked}  value="checkedall" checked={this.state.allCheckedMaxNoOfRaise}/></td>
                                    <td>Ante <input type="checkbox" onClick={this.handleAnteChecked}  value="checkedall" checked={this.state.allCheckedAnte}/></td>
                                    <td className="otherrules">Other-Rules</td>
                                </tr>
                            </table>
                                {
                                this.state.games.map((game) => {
                                    return( <table id="tablecreate" className="font-weight-bold">
                                    <tr>
                                        <td>
                                            <input key={game.id} onClick={this.handleCheckChieldElement} type="checkbox" checked={game.isChecked} value={game.value} /> {game.value}
                                        </td>
                                        <td>{game.value !== "357" && game.value !== "3-Brent-7" && game.value !== "HomeRun" && game.value !== "HomeRun Round" && <input className="inputTextArea" key={game.id} onChange={(e) => this.handleMaxRaiseChange(e, game.id)} type="number" gameName={game.value} value={Number(game.maxRaise).toString()}/>}</td>
                                        <td>{game.value !== "357" && game.value !== "3-Brent-7" && game.value !== "HomeRun" && game.value !== "HomeRun Round" && <input className="inputTextArea" key={game.id} onChange={(e) => this.handleMinRaiseChange(e, game.id)} type="number" gameName={game.value} value={Number(game.minRaise).toString()}/>}</td>
                                        <td>{game.value !== "357" && game.value !== "3-Brent-7" && game.value !== "HomeRun" && game.value !== "HomeRun Round" && <input className="inputTextArea" key={game.id} onChange={(e) => this.handleTimesRaiseChange(e, game.id)} type="number" placeholder={game.timesRaise} gameName={game.value} value={Number(game.timesRaise).toString()}/>}</td>
                                        <td>{true && <input className="inputTextArea" key={game.id} onChange={(e) => this.handleAnteChange(e, game.id)} type="number" placeholder={game.ante} gameName={game.value} value={Number(game.ante).toString()}/>}</td>
                                        <td  className="otherrules">
                                        {game.maxRaise === 0 && game.minRaise === 0 && game.ante === 0 && game.timesRaise === 0 && game.value !== "357" && game.value !== "3-Brent-7" && game.value !== "HomeRun" && game.value !== "HomeRun Round" && <button className="no-limit-true" key={game.id} onClick={(e) => this.handleNoLimitPress(e, game.id)}>Limit-less</button>}
                                        {(game.maxRaise !== 0 || game.ante !== 0 || game.timesRaise !== 0 || game.minRaise !== 0) && game.value !== "357" && game.value !== "3-Brent-7" && game.value !== "HomeRun" && game.value !== "HomeRun Round" && <button className="no-limit-false" key={game.id} onClick={(e) => this.handleNoLimitPress(e, game.id)}>No-Limit</button>}
                                        </td>
                                    </tr>
                                </table>
                                            )
                                })
                            }
                        </div>
                    </div>}
                </div>
                </div>
                <div className="createroominfo">
                Currently, playing from mobile devices is not supported. We are working to support it. You can play with a larger sized screen.
                        <br/><br/>
                        <Link to={'/'} className="homepageBtn">
                            <i class="fa fa-home" aria-hidden="true"></i> <span>HOME</span>
                        </Link>
                </div>
            </div>
        );
    }
}

export default Preferences;
