import React, {Component} from "react";
import socket from "../../services/socket";
import queryString from "query-string/index";
import {Redirect, withRouter} from "react-router-dom";
import {auth, getCustomClaimRole} from '../../services/firebase-service';
import firebase from "firebase";
import GoogleAds from "../GoogleAds";
import ReactGA from 'react-ga';
import {
    dealCards,
    getMembersInfo,
    bettingAction,
    postStartGame,
    getAvailableGames,
    getAccountHistory,
    getPlayerRejoinTableDetails,
    playerVoteOutRequest,
    playerVoteOutAction,
    getAllPlayerInfo,
    dealThreeFiveSevenCards,
    getCurrentState,
    fetchInPlayersCards,
    deal5CDCards,
    leaveRoom,
    dealTexasCards,
    deal7CSCards,
    deal5CSCards,
    dealCincinnatiCards,
    deal727Cards,
    dealBaseballCards,
    getAllowedGames,
    dealHomeRunCards, reSubmitSettings, 
    buyIn,
    roomReady,
    selectAutoReady,
    pauseSelectionRound,
    feedbackAPI,
    sortCardsAPI,
    removeDisconnectedPlayers,
    orderHomeRunCards,
    playerToSpectator, spectatorToPlayer,
    kickOutSpectator, spectatorDecision,
    destroyRoom, getFriends, inviteAllFriends, createBots
} from "../../services/room";
import './Game.css';
import './GameTable.css';
import {Button, Col, Row} from "react-bootstrap";
import Cards from "../Cards/Cards";
import GameTable from "./GameTable";
import Select from "react-select";
import 'react-rangeslider/lib/index.css';
import {ListManager} from "react-beautiful-dnd-grid";
import Messages from "./Messages";
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import Settings from "../../assets/settingsIcon.svg";
import MyTurn from "../../assets/MyTurnNew.mp3";
import LoadingGIF from '../../assets/loading.gif';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Avatar from "react-avatar";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import {getAllArrangements, CARDS_ORDER_TYPE, SETTINGS} from "../../utils/Games/Homerun/Homerun";
import { getAllSpectators, updatePlayersPositions, anacondaPassingCardStatus, rollOnPlayerCardsFunction, getItems, sortCards, descriptionWildCards, getAllDoYaWildCards } from "../../utils/modules/GameFunctions";
import {sortHiHand, getHiHandStringDescription, getBestCardsOmaha} from '../../utils/modules/HandEvaluation/HiHandEvaluation';
import {sortLoHand, getLoHandStringDescription} from '../../utils/modules/HandEvaluation/LoHandEvaluation';
import {ironCrossHiHandDescription, ironCrossLoHandDescription} from '../../utils/modules/HandEvaluation/Ironcross';
import calculateCardsSum from "../../utils/modules/HandEvaluation/727";
import {HouseRulesModal, 
        WinnerModal,
        PotDetailsModal,
        AccountDetailsModal,
        GameDetails357,
        AfterRoundDetails357,
        HomeRunModal,
        TexasHoldemModal,
        CounterApp,
        RulesModal357,
        RulesModal5CD,
        RulesModal7CS,
        RulesModal5CS,
        RulesModal3B7,
        RulesModalAnaconda,
        RulesModalCincinnati,
        RulesModalHomerun,
        RulesModalTexas,
        RulesModal727,
        RulesModalBaseball
        } from "./Modal";


const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
`;

const StyledThumb = styled.div`
    height: 15px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    color: white;
    border-radius: 50%;
    cursor: grab;
    top: -17px;
`;
const StyledTrack = styled.div`
    top: 10px;
    height: 15px;
    bottom: 0;
    background: ${props => props.index === 2 ? 'white' : props.index === 1 ? '#228B22' : 'white'};
    border-radius: 999px;
`;

const renderTooltipFeedback = props => (
    <Tooltip {...props}>Feedback</Tooltip>
);

const renderTooltipSoundOff = props => (
    <Tooltip {...props}>Sound Off</Tooltip>
);

const renderTooltipSoundOn = props => (
    <Tooltip {...props}>Sound On</Tooltip>
);

const renderTooltipHouseRules = props => (
    <Tooltip {...props}>Room Rules, Room Link</Tooltip>
);
class Game extends Component {
    constructor(props) {
        super(props);
        const {name, room, code, initBuy, spectate} = queryString.parse(this.props.location.search);
        this.state = {
            socketClient: {},
            clientName: name, roomName: room, roomCode: code, initialBuyAmount: initBuy,
            spectate: spectate === 'false' ? false : true,
            members: 0,
            messages: [],
            positions: [],
            stack: 0, pot: 0,
            raisedAmount: 0,
            gameOptions: [],
            selectedGame: {}, startDisable: true,
            selectedWildCardChoice: {},
            selectedWildCardBySuit: {},
            selectedWildCardByRank: {},
            gameConfig: [],
            gameName: '',
            noOfCards: 0,
            message: "",
            cards: [], sortedList: [], selectedCards: [], followTheQueenCards: [], doYaWildCards: [],
            players: [], bots: [],
            dealer: "",
            allowedActions: [],
            maxBet: 0, currentBet: 0,
            bettingEnabled: false,
            cardSelectionEnabled: false,
            cardPassingRoundActive: false,
            showWinningSelection: false,
            isFirstPlayer: false,
            gameMsg: "",
            everyonesWinningType: {},
            winner: "",
            winnerCards: [],
            playerMsgDict: {},
            actionMsg: '',
            cardsPassingStatus: [],
            winnerModalShow: false,
            winners: {},
            currentCardState: [],
            currentTurnPlayer: "",
            gamefinished: false,
            foldedPlayers: [],
            potDetails: {},
            potModalShow: false,
            betRoundOver: false,
            accountDetails: {},
            accountModalShow: false,
            accountFlag: false,
            sidePots: {},
            currentPlayerBets: [],
            myCurrentBet: {},
            gameStarted: false,
            voteoutPlayerName: '',
            voteOutActionEnabled: false,
            playerToVoteOut: '',
            allPlayerInfo: {},
            disconnectedPlayers: [],
            voteoutShow: false,
            selectInOutShow: false,
            details357: {},
            roundOver357: false,
            modalshow357: false,
            gameName357: '',
            startRound357: false,
            selectedIn: false,
            inPlayersCards: {},
            afterRoundModalShow: false,
            counterToShowModal: 0,
            showDealerCounter: false,
            gameEnds357: false,
            showModal357: false,
            current357Cards: 0,
            isAnacondaLive: false,
            is5CDLive: false,
            is357Live: false,
            noOfCardsToDiscard: 0,
            gameEnds5CD: false,
            showReady: false,
            readyData357: {},
            showReady357: false,
            readyPressed357: false,
            isTexasLive: false,
            allCommunityCards: [],
            gameEndsTexas: false,
            showExchangedCards: false,
            exchangedCards: {},
            is7CSLive: false,
            is5CSLive: false,
            allPlayerCards7CS: [],
            gameEnds7CS: false, gameEnds5CS: false,
            isCincinnatiLive: false,
            cardRevealRound: false,
            gameEndsCincinnati: false,
            passedTwoCards: false,
            leftCards: [],
            smallBlindPlayer: '',
            bigBlindPlayer: '',
            roundDealer: '',
            selectedCardsSortedList: [],
            localAnteValue: 5, localMaxNoRaises: 5, localMaxBetAllowed: 100, localMinBetAllowed: 5, localhandsMustHaveOneNaturalCard: false, localQueensWild: false, localRoyalFlushBeatsFiveOfKind: true, localPayForFaceUpWildCard: 10, localSplitHiLo: false, localStrictSum: false, localSplitPotIfStrictSumFalse: false, localTieGoesToLow: true, local727NoOfPasses: 3, localSimultaneousPassDrawRound: true, localDoYa: true,
            settingsShow: false,
            houseRulesEnabled: false,
            gameAnte: 0, gameMaxBet: 0, gameMinBet: 0, gameMaxNoRaises: 0, gameLoAce: false, gameLoFlush: false, gameBetOpening: false, gameMaxPotToReplace: 0, gameNoOfJokers: 0, gameWildCards: [], gameJokersInDeck: [], gameroyalFlushBeatsFiveOfKind: true, gamehandsMustHaveOneNaturalCard: false, gamePayForFaceUpWildCard: 0, gameSimultaneousPassDrawRound: true, gameDoYa: true, gameDefaultBaseballOpening: '',
            gameSplitHiLo: false, game727NoOfPasses: 0, gameSplitPotIfStrictSumFalse: false, gameStrictSum: false, gameTieGoesToLow: false, defSplitHiLo: false, def727NoOfPasses: 0, defSplitPotIfStrictSumFalse: false, defStrictSum: false, defTieGoesToLow: false, defSimultaneousPassDrawRound: true, defDoYa: true,
            defAnte: 0, defMaxBet: 0, defMinBet: 0, defMaxNoRaises: 0, defLoAce: false, defLoFlush: false, defBetOpening: false, defMaxPotToReplace: 0, defNoOfJokers: 0, defWildCards: [], defJokersinDeck: [], defroyalFlushBeatsFiveOfKind: true, defhandsMustHaveOneNaturalCard: false, defPayForFaceUpWildCard: 0,
            showList: [1, 2, 3, 4, 5],
            roomHouseRules: {},
            showHouseRules: false,
            playersPassedCardsCin: [],
            showPlayersPassedCin: false,
            showRevealStatus: false,
            playersRevealStatus: [],
            showCincinnatiList: ['Left', 'Left', 'Right'],
            showHomeRunList: ['Front', 'Front', 'Front', 'Mid', 'Mid', 'Mid', 'Mid', 'Mid', 'Back', 'Back', 'Back', 'Back', 'Back'],
            downCards7CS: [], upCards7CS: [],
            winningTypeAnaconda: '',
            selection357: '',
            isHomeRunLive: false,
            submitSettingsButton: false,
            settingsFront: [], settingsBack: [], settingsMiddle: [],
            gameEndsHomeRun: false,
            homeRunShowStatus: false,
            homeRunSubmitStatus: [],
            frontCards: [], middleCards: [], backCards: [],
            showModalHomeRun: false,
            buyInAmount: 500,
            showRoomReady: false,
            roomReadyData: [],
            playerReadyRoom: false,
            firstGameStarted: false,
            allPlayersReady: true,
            loHand3B7: {},
            showTexasModal: false,
            winningDataTexas: {},
            leaveAccess: false,
            autoReady: false,
            sound: true,
            brentLive: false,
            rulesAnaconda: false,
            rules357: false, rules3B7: false, rulesTexas: false, rules7CS: false, rules5CD: false, rulesCincinnati: false, rulesHomerun: false, rules727: false, rules5CS: false, rulesBaseball: false,
            showPause357: false,
            showFeedbackSheet: false,
            feedbackText: '',
            playerCardsTexas: [],
            setGameRules: false,
            showRemoveButton: false,
            resultHomeRun: [],
            currentGameName: '',
            restrict5CDOpening: true,
            restrictBaseballOpening: true,
            defaultBaseballOpening: 'J',
            spadeCardAllPlayers: [],
            boolHomeRun: false,
            loAce: true, loFlush: true,
            showAmountSelection: false,
            pencilAmount: 0,
            bustedPlayers: [],
            showLeaveOptions: false,
            anacondaPassedCards: [],
            spectatorList: [],
            showChatWindow: true,
            showSpectatorJoinReq: false,
            newSpectator: {},
            goBack: false,
            spectatorFirstEventReceived: false,
            showSpectatorToPlayerSheet: false,
            showVoteOutOptions: false,
            voteOutType: '',
            texasGameTypeOmaha8: false,
            kickedOutPlayers: [],
            playersDisconnected: [],
            showPencilStatus: false,
            pencilAmountData: [],
            allowKickOut: false,
            showRecallButton: false,
            submitSettingRoundOverReceived: false,
            currentDisconnectedPlayers: [],
            currentPlayersToKick: [],
            spectatorToPlayerConverted: false,
            localmaxPotToReplace: 1000,
            connectedPlayers: [],
            frequentFriends: [], lastPlayedWith: [], showInviteMenu: false,
            emails: [], emailValue: '', error: null,
            homeRunArrangements: {}, showHomeRunArrangements: false, loading: false,
            defaultArrangement: [], showDiabloBuy: false,
            selectedArrangement: {}, homeRunOptions: [{value: "T1_FBM", label: "Front-Back-Middle"}, {value: "T1_FMB", label: "Front-Middle-Back"}, {value: "T1_MBF", label: "Middle-Back-Front"}, {value: "T1_MFB", label: "Middle-Front-Back"},
                                                    {value: "T1_BFM", label: "Back-Front-Middle"}, {value: "T1_BMF", label: "Back-Middle-Front"}, {value: "T2_SORT_BY_SUIT", label: "By-Suit"}, {value: "T3_SORT_BY_CARDS_NO", label: "By-Cards-No"}],
            rollOnCardsShow: false, rollOnPlayerCards: [],
            diabloOpener: "", wildCardsBool: false, wildCardSelection: false, noOfJokers: 0,
            deckOfCards: ["As", "2s", "3s", "4s", "5s", "6s", "7s", "8s", "9s", "Ts", "Js", "Qs", "Ks",
                          "Ad", "2d", "3d", "4d", "5d", "6d", "7d", "8d", "9d", "Td", "Jd", "Qd", "Kd",
                          "Ah", "2h", "3h", "4h", "5h", "6h", "7h", "8h", "9h", "Th", "Jh", "Qh", "Kh",
                          "Ac", "2c", "3c", "4c", "5c", "6c", "7c", "8c", "9c", "Tc", "Jc", "Qc", "Kc"],
            wildCardOptions: [{value: "By Rank", label: "By Rank"}, {value: "One-Eye Jacks", label: "One-Eye Jacks"}, {value: "King with Axe", label: "King with Axe"}, {value: "Suicide King", label: "Suicide King"}],
            wildCardSelectedOption: "",
            wildCardOptionsByRank: [{value: "A", label: "A"}, {value: "K", label: "K"}, {value: "Q", label: "Q"}, {value: "J", label: "J"}, {value: "10", label: "10"},
                                    {value: "9", label: "9"}, {value: "8", label: "8"}, {value: "7", label: "7"}, {value: "6", label: "6"}, {value: "5", label: "5"},
                                    {value: "4", label: "4"}, {value: "3", label: "3"}, {value: "2", label: "2"}],
            wildCardOptionsBySuit: [{value: "♠︎", label: "♠︎"}, {value: "♥︎", label: "♥︎"}, {value: "♦︎", label: "♦︎"}, {value: "♣︎", label: "♣︎"}],
            selectedWildCardsList: [], addWildCardsBool: true, selectedWildCardsByRank: [], selectedWildCardsBySuit: [],
            showPayUpSheet: false, payUpAmount: 0, showRebuyInfo: true, myMoneyGain: 0, maxRebuyRoom: 0, showGameOptionsInfo: false,
            gameOverAllData: [], sideBetLowPress: false,
            is727Live: false, draw727CardsShow: false, drawRound727Show: false, draw727RoundDetails: [], gameEnds727: false, downCards727: [],
            sum727cards: [], noOfPassesPlayerDetails: [], lastBettingRound727: false,
            cardsFromLeft: [], cardsFromRight: [],
            doYaCards: [], showDoYaActions: false,
            drawBaseballDecisions: [], drawRoundBaseballShow: false, drawActionsBaseballShow: false,
            isBaseballLive: false, gameEndsBaseball: false,
            allPlayerCardsBaseball: [], cantSeeCardsBaseball: [], showSubmitBaseball: false,
            animatedWinners: []
        };
        this.audio = new Audio(MyTurn);
    }

    componentWillUnmount = async () => {
        this.state.socketClient.closeTab();
    }

    initReactGA = () => {
        ReactGA.pageview('poker4.us/game');
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    componentDidMount = async () => {

        this.initReactGA();

        firebase.auth().onAuthStateChanged( async function(user) {
            if (user) {
                let {clientName, roomName, roomCode, spectate} = this.state;
                let token = await auth().currentUser.getIdToken();
                const {initBuy} = queryString.parse(this.props.location.search);
                this.setState({
                    socketClient: socket(clientName, roomName, token, roomCode, initBuy, spectate)
                });

                if(spectate) {
                    this.setState({actionMsg: "Let the Dealer accept you in the room"});
                }

                setTimeout(() => {
                    if(spectate) {
                        if(!this.state.spectatorFirstEventReceived) {
                            alert(`You are not accepted to spectate the play`);
                            this.setState({goBack: true})
                        }
                    }
                }, 12000);

                this.state.socketClient.connect(this.establishConnection);
                this.state.socketClient.broadcastMessage(this.broadcastMessage);
                this.state.socketClient.someoneJoined(this.someoneJoined);
                this.state.socketClient.someoneLeavedTable(this.someoneLeavedTable);
                this.state.socketClient.tableDetails(this.tableDetails);
                this.state.socketClient.gameStarting(this.gameStarting);
                this.state.socketClient.roundStarting(this.roundStarting);
                this.state.socketClient.someoneBetted(this.someoneBetted);
                this.state.socketClient.doAction(this.doAction);
                this.state.socketClient.selectCards(this.selectCards);
                this.state.socketClient.getCardsFromNeighbour(this.getCardsFromNeighbour);
                this.state.socketClient.selectWinningType(this.selectWinningType);
                this.state.socketClient.everyonesWinningType(this.everyonesWinningType);
                this.state.socketClient.gameOver(this.gameOver);
                this.state.socketClient.someonePassedCards(this.someonePassedCards);
                this.state.socketClient.cardsPassingRoundOver(this.cardsPassingRoundOver);
                this.state.socketClient.currentBettingTurn(this.currentBettingTurn);
                this.state.socketClient.bettingRoundOver(this.bettingRoundOver);
                this.state.socketClient.someoneDisconnected(this.someoneDisconnected);
                this.state.socketClient.someoneReconnected(this.someoneReconnected);
                this.state.socketClient.someoneTimeout(this.someoneTimeout);
                this.state.socketClient.voteoutSomeone(this.voteoutSomeone);
                this.state.socketClient.votingResult(this.votingResult);
                this.state.socketClient.fetchCards(this.fetchCards);
                this.state.socketClient.selectInOut(this.selectInOut);
                this.state.socketClient.playersSelectionInOut(this.playersSelectionInOut);
                this.state.socketClient.roundOver(this.roundOver);
                this.state.socketClient.gameOver357(this.gameOver357);
                this.state.socketClient.startSelectInOutRound(this.startSelectInOutRound);
                this.state.socketClient.fetchCards5CD(this.fetchCards5CD);
                this.state.socketClient.bettingRoundStarting(this.bettingRoundStarting);
                this.state.socketClient.bet(this.bet);
                this.state.socketClient.discardCards(this.discardCards);
                this.state.socketClient.gameOver5CD(this.gameOver5CD);
                this.state.socketClient.selectReady(this.selectReady);
                this.state.socketClient.someoneSelectedReady(this.someoneSelectedReady);
                this.state.socketClient.roundOver5CD(this.roundOver5CD);
                this.state.socketClient.playersToKickOut(this.playersToKickOut);
                this.state.socketClient.fetchCardsTexasHoldem(this.fetchCardsTexasHoldem);
                this.state.socketClient.communityCards(this.communityCards);
                this.state.socketClient.gameOverTexasHoldem(this.gameOverTexasHoldem);
                this.state.socketClient.blindDeducted(this.blindDeducted);
                this.state.socketClient.someoneDiscardedCards(this.someoneDiscardedCards);
                this.state.socketClient.roundOverTexasHoldem(this.roundOverTexasHoldem);
                this.state.socketClient.fetchCards7CS(this.fetchCards7CS);
                this.state.socketClient.someoneFetchedCards(this.someoneFetchedCards);
                this.state.socketClient.gameOver7CS(this.gameOver7CS);
                this.state.socketClient.fetchCardsCincinnati(this.fetchCardsCincinnati);
                this.state.socketClient.passCards(this.passCards);
                this.state.socketClient.fetchPassedCards(this.fetchPassedCards);
                this.state.socketClient.selectCardsToReveal(this.selectCardsToReveal);
                this.state.socketClient.revealedCards(this.revealedCards);
                this.state.socketClient.gameOverCincinnati(this.gameOverCincinnati);
                this.state.socketClient.currentRoundDealer(this.currentRoundDealer);
                this.state.socketClient.gameSettings(this.gameSettings);
                this.state.socketClient.somoneSelectedCardsToReveal(this.somoneSelectedCardsToReveal);
                this.state.socketClient.cardsPassingRoundStarting(this.cardsPassingRoundStarting);
                this.state.socketClient.fetchCardsHomeRun(this.fetchCardsHomeRun);
                this.state.socketClient.submitSettings(this.submitSettings);
                this.state.socketClient.someoneSubmittedSetting(this.someoneSubmittedSetting);
                this.state.socketClient.frontSettingRevealed(this.frontSettingRevealed);
                this.state.socketClient.backSettingRevealed(this.backSettingRevealed);
                this.state.socketClient.middleSettingRevealed(this.middleSettingRevealed);
                this.state.socketClient.gameOverHomeRun(this.gameOverHomeRun);
                this.state.socketClient.someoneBuyIn(this.someoneBuyIn);
                this.state.socketClient.selectReadyRoom(this.selectReadyRoom);
                this.state.socketClient.readyRoundOver(this.readyRoundOver);
                this.state.socketClient.someonePaid(this.someonePaid);
                this.state.socketClient.stopSelectInOutRound(this.stopSelectInOutRound);
                this.state.socketClient.roundOverHomeRun(this.roundOverHomeRun);
                this.state.socketClient.choosePencilAmount(this.choosePencilAmount);
                this.state.socketClient.roomDetails(this.roomDetails);
                this.state.socketClient.someoneLeavedRoom(this.someoneLeavedRoom);
                this.state.socketClient.spectatorJoined(this.spectatorJoined);
                this.state.socketClient.spectatorRejoined(this.spectatorRejoined);
                this.state.socketClient.allowSpectatorToJoin(this.allowSpectatorToJoin);
                this.state.socketClient.aboutToLeaveRoom(this.aboutToLeaveRoom);
                this.state.socketClient.votingStarted(this.votingStarted);
                this.state.socketClient.someoneChoosePencilAmount(this.someoneChoosePencilAmount);
                this.state.socketClient.someoneWantsToReSubmitSetting(this.someoneWantsToReSubmitSetting);
                this.state.socketClient.submitSettingRoundOver(this.submitSettingRoundOver);
                this.state.socketClient.roomCrashed(this.roomCrashed);
                this.state.socketClient.someoneOpened(this.someoneOpened);
                this.state.socketClient.decideForFaceUpWildCardRoundStart(this.decideForFaceUpWildCardRoundStart);
                this.state.socketClient.decideForFaceUpWildCard(this.decideForFaceUpWildCard);
                this.state.socketClient.someoneDecideForFaceUpWildCard(this.someoneDecideForFaceUpWildCard);
                this.state.socketClient.followTheQueenWildCards(this.followTheQueenWildCards);
                this.state.socketClient.fetchCardsSevenTwoSeven(this.fetchCardsSevenTwoSeven);
                this.state.socketClient.decideToDrawCards(this.decideToDrawCards);
                this.state.socketClient.someoneDecidedForDrawCards(this.someoneDecidedForDrawCards);
                this.state.socketClient.gameOverSevenTwoSeven(this.gameOverSevenTwoSeven);
                this.state.socketClient.decideToDrawCardsRoundOver(this.decideToDrawCardsRoundOver);
                this.state.socketClient.decideToDrawCardsRoundStart(this.decideToDrawCardsRoundStart);
                this.state.socketClient.lastBettingRound(this.lastBettingRound);
                this.state.socketClient.fetchCards5CS(this.fetchCards5CS);
                this.state.socketClient.someoneFetchedCards5CS(this.someoneFetchedCards5CS);
                this.state.socketClient.someoneDecidedForDoYa(this.someoneDecidedForDoYa);
                this.state.socketClient.doYa(this.doYa);
                this.state.socketClient.gameOver5CS(this.gameOver5CS);
                this.state.socketClient.roundOver5CS(this.roundOver5CS);
                this.state.socketClient.decideFor4xCardRoundStart(this.decideFor4xCardRoundStart);
                this.state.socketClient.decideFor4xCard(this.decideFor4xCard);
                this.state.socketClient.someoneDecideFor4xCard(this.someoneDecideFor4xCard);
                this.state.socketClient.fetchCardsBaseball(this.fetchCardsBaseball);
                this.state.socketClient.layCards(this.layCards);
                this.state.socketClient.someoneLayedCards(this.someoneLayedCards);
                this.state.socketClient.gameOverBaseball(this.gameOverBaseball);
                this.state.socketClient.turnSkipped(this.turnSkipped);
                this.state.socketClient.newWildCardsAdded(this.newWildCardsAdded);
                this.fetchAvailableGames();
            } else {
                console.log('Firebase Signed Out user');
            }
        }.bind(this));
    }

    //Baseball-9CD

    turnSkipped = (data) => {
        console.log('WSEvent Baseball: turnSkipped', data);
        let messagebody = {
            messagetext: `${data.player}'s' turn skipped`,
            currentUser: this.state.clientName,
            messageUser: 'SERVER'
        }
        this.setState({
            messages: [ ...this.state.messages, messagebody ],
        });
        if(data.player === this.state.clientName) {
            this.setState({downCards7CS: []})
        }
    }

    fetchCardsBaseball = async (data) => {
        console.log('WSEvent Baseball: fetchCardsBaseball', data);
        let { roomName, clientName, positions, spectate, players } = this.state;
        this.commonSetStateVariables();
        let response = await getCurrentState(roomName);
        console.log('APICall Baseball CurrentState(fetchCardsBaseball): ', response);
        this.getBaseballCards(response);
        this.setState({
            players: updatePlayersPositions(positions, response.data.players),
            spadeCardAllPlayers: []
        });
        if(("GameState" in response.data)) {
            this.setState({
                pot: response.data.GameState.pot,
                currentGameName: response.data.GameState.gameNameText,
            })
        }
        if(!spectate) {
            let currentStack = players.filter(function(detail) {return detail.clientName === clientName})
            this.setState({
                stack: currentStack[0].stack
            })
        }
    }

    getBaseballCards = async (response) => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action: {
                name : "dealCards",
            }
        };
        let responseNew = await dealBaseballCards(body, roomName);
        console.log('APICall Baseball Cards', responseNew);
        if(responseNew) {
            this.setState({
                cards: responseNew.data.cards,
                sortedList: sortCards(getItems(responseNew.data.cards)),
                downCards7CS: sortCards(getItems(responseNew.data.faceDownCards)),
                upCards7CS: []
            });
        }
        if(response.data.GameState.currentGame === "Blind Baseball") {
            for(const player in responseNew.data.playersCardsNoInfo) {
                if(player === this.state.clientName) {
                    let mycards = []
                    for(let i = 0; i < responseNew.data.playersCardsNoInfo[player].noOfCantSeeCards; i++) {
                        mycards.push("CB");
                    }
                    this.setState({cards: mycards, upCards7CS: [], cantSeeCardsBaseball: sortCards(getItems(mycards))})
                }
            }
        }
    }

    layCards = (data) => {
        console.log('WSEvent Baseball: layCards', data);
        if(data.players.includes(this.state.clientName)) {
            this.setState({cardSelectionEnabled: true, actionMsg: 'Click cards to lay'})
        }
        else {
            this.setState({actionMsg: `${data.players.join()}'s Action`, cardSelectionEnabled: false, currentTurnPlayer: ""})
        }
    }

    someoneLayedCards = async (data) => {
        console.log('WSEvent Baseball: someoneLayedCards', data);
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall CurrentState(someoneLayedCards)', response);
        if("GameState" in response.data) {
            this.setState({
                foldedPlayers: response.data.players.filter(function (player) {
                    return player.status === 2;
                    }).map(item => item.clientName)
            })
            if(this.state.currentGameName === "Baseball-9CD") {
                let allCards = [];
                for(const player in data.playersFaceUpCards) {
                    let temp = [];
                    for(let i = 0; i < response.data.GameState.playersCardsNoInfo[player].noOfFaceDownCards; i++){temp.push("CB")}
                    let body = {
                        clientName: player,
                        upCards: data.playersFaceUpCards[player],
                        downCards: temp
                    }
                    allCards.push(body);
                    if(player === this.state.clientName) {
                        this.setState({
                            upCards7CS: sortCards(getItems(data.playersFaceUpCards[player])),
                        })
                    }
                }
                this.setState({allPlayerCardsBaseball: allCards})
            }
            if(this.state.currentGameName === "Blind Baseball") {
                let allCards = [];
                for(const player in data.playersFaceUpCards) {
                    let temp = [];
                    for(let i = 0; i < response.data.GameState.playersCardsNoInfo[player].noOfCantSeeCards; i++){temp.push("CB")}
                    let body = {
                        clientName: player,
                        upCards: data.playersFaceUpCards[player],
                        downCards: temp
                    }
                    allCards.push(body);
                    if(player === this.state.clientName) {
                        this.setState({
                            upCards7CS: sortCards(getItems(data.playersFaceUpCards[player])),
                        })
                    }
                }
                this.setState({allPlayerCardsBaseball: allCards})
            }
        }
    }

    gameOverBaseball = async (data) => {
        console.log('WSEvent Baseball: gameOverBaseball', data);
        this.setState({
            allPlayerCardsBaseball: [],
            winners: data,
            gameEndsBaseball: true,
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            gameStarted: false,
            isBaseballLive: false,
            winnerModalShow: true,
            showWinningSelection: false,
            voteoutShow: false,
            allowKickOut: false,
            upCards7CS: [],
            downCards7CS: [],
            allPlayerCards7CS: [],
            downCards727: [],
            lastBettingRound727: false,
            drawBaseballDecisions: [],
            drawRoundBaseballShow: false,
            actionMsg: ''
        });
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall 727 CurrentState(gameOverBaseball): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
            cards: []
        });
    }

    //BaseBall-7CS

    decideFor4xCardRoundStart = (data) => {
        console.log('WSEvent Baseball: decideFor4xCardRoundStart', data);
        this.setState({
            drawRoundBaseballShow: true
        })
    }

    decideFor4xCard = (data) => {
        console.log('WSEvent baseball: decideFor4xCard', data);
        if(data.players.includes(this.state.clientName)) {
            this.setState({drawActionsBaseballShow: true})
        }
        else {
            this.setState({actionMsg: `${data.players.join()}'s Action`, drawActionsBaseballShow: false})
        }
    }

    drawBaseballAction = async (action) => {
        let { clientName, roomName, is7CSLive, isBaseballLive } = this.state;
        let body = {
            clientName : clientName,
            action : {
                name : 'decideFor4xCard',
                data : {
                    choice : action
                }
            }
        };
        if(is7CSLive) {
            let response = deal7CSCards(body, roomName);
            console.log('APICall deal7CSCards(drawBaseballAction)', action, response);
            if(response) {
                this.setState({drawActionsBaseballShow: false})
            }
        }
        if(isBaseballLive) {
            let response = await dealBaseballCards(body, roomName);
            console.log('APICall dealBaseballCards(drawBaseballAction)', action, response);
            if(response) {
                this.setState({drawActionsBaseballShow: false})
                if(("data" in response)) {
                    if(("canSubmitCardsNow" in response.data)) {this.setState({showSubmitBaseball: response.data.canSubmitCardsNow})}
                }
            }
        }
    }

    someoneDecideFor4xCard = async (data) => {
        console.log('WSEvent Baseball: someoneDecideFor4xCard', data);
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall Baseball CurrentState(someoneDecideFor4xCard): ', response);
        this.setUpCardsBaseball(data.playersFaceUpCards, response);
        this.setState({drawRoundBaseballShow: true})
        if(("playersDecision" in data)) {
            this.setPlayerDecisionsBaseball(data.playersDecision);
        }
        if(response) {
            this.setState({
                players: updatePlayersPositions(this.state.positions, response.data.players),
                // betRoundOver: false
            })
            if("GameState" in response.data) {
                this.setState({pot: response.data.GameState.pot})
            }
        }
    }

    setUpCardsBaseball = (data, response) => {
        let upCards = [];
        for(const i in data) {
            let upBody = {
                clientName: i,
                cards: data[i]
            }
            if(i === this.state.clientName) {
                this.setState({
                    upCards7CS: sortCards(getItems(data[i]))
                })
            }
            upCards.push(upBody);
        }
        this.setState({allPlayerCards7CS: upCards})
        if(this.state.isBaseballLive && this.state.currentGameName === "Baseball-9CD") {
            let allCards = [];
            for(const player in data) {
                let temp = [];
                for(let i = 0; i < response.data.GameState.playersCardsNoInfo[player].noOfFaceDownCards; i++){temp.push("CB")}
                let body = {
                    clientName: player,
                    upCards: data[player],
                    downCards: temp
                }
                allCards.push(body);
            }
            this.setState({allPlayerCardsBaseball: allCards})
        }
        if(this.state.isBaseballLive && this.state.currentGameName === "Blind Baseball") {
            let allCards = [];
            for(const player in data) {
                let temp = [];
                for(let i = 0; i < response.data.GameState.playersCardsNoInfo[player].noOfCantSeeCards; i++){temp.push("CB")}
                let body = {
                    clientName: player,
                    upCards: data[player],
                    downCards: temp
                }
                allCards.push(body);
            }
            this.setState({allPlayerCardsBaseball: allCards})
        }
    }

    setPlayerDecisionsBaseball = (data) => {
        let des = [];
        for(const i in data) {
            let body = {
                clientName: i,
                decision: data[i]
            }
            des.push(body);
        }
        this.setState({drawBaseballDecisions: des})
    }

    //5-Card-Stud

    fetchCards5CS = async (data) => {
        console.log('WSEvent 5CS: fetchCards5CS', data);
        let { roomName, clientName, positions, spectate, players } = this.state;
        this.commonSetStateVariables();
        this.get5CSCards();
        let response = await getCurrentState(roomName);
        console.log('APICall 5CS CurrentState(fetchCards5CS): ', response);
        this.setState({
            players: updatePlayersPositions(positions, response.data.players),
            spadeCardAllPlayers: []
        });
        if(("GameState" in response.data)) {
            this.setState({
                pot: response.data.GameState.pot,
                currentGameName: response.data.GameState.gameNameText,
            })
        }
        if(!spectate) {
            let currentStack = players.filter(function(detail) {return detail.clientName === clientName})
            this.setState({
                stack: currentStack[0].stack
            })
        }
    }

    get5CSCards = async () => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action: {
                name : "dealCards",
            }
        };
        let response = await deal5CSCards(body, roomName);
        console.log('APICall 5CS Cards', response);
        if(response) {
            this.setState({
                cards: response.data.cards,
                sortedList: sortCards(getItems(response.data.cards)),
                upCards7CS: sortCards(getItems(response.data.faceUpCards)),
                downCards7CS: sortCards(getItems(response.data.faceDownCards))
            });
            if(this.state.currentGameName === "Do Ya") {
                this.setState({doYaWildCards: getAllDoYaWildCards(response.data.faceDownCards[0])})
            }
        }
    }

    setGeneralDownCards = (data) => {
        let temp = this.state.downCards7CS;
        let max = 0;
        for(const i in data) {
            if(data[i].noOfFaceDownCards > max) {max = data[i].noOfFaceDownCards}
        }
        console.log(this.state.downCards7CS);
        for(let i = temp.length; i < max; i++){temp.push({id: "oo", order: 99})}
        this.setState({downCards7CS: temp});
    }

    someoneFetchedCards5CS = async (data) => {
        console.log('WSEvent 5CS: someoneFetchedCards5CS', data);
        let body = {
            clientName: data.clientName,
            cards: data.faceUpCards
        };
        this.setState({
            allPlayerCards7CS: [ ...this.state.allPlayerCards7CS, body ],
            is5CSLive: true
        })
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall 7CS CurrentState(someoneFetchedCards5CS): ', response);
        if(this.state.spectate || this.state.foldedPlayers.includes(this.state.clientName)) {
            this.setGeneralDownCards(response.data.GameState.playersCardsNoInfo);
        }
    }

    someoneDecidedForDoYa = (data) => {
        console.log('WSEvent 5CS: someoneDecidedForDoYa', data);
        this.setUpCards727(data.playersFaceUpCards);
        if(data.playerName === this.state.clientName && data.decision === 'YES') {this.setState({showDoYaActions: false})}
    }

    doYa = (data) => {
        console.log('WSEvent 5CS: doYa', data);
        this.setState({
            doYaCards: data.faceUpCardsOnTable,
            currentTurnPlayer: data.playerName
        })
        if(data.playerName === this.state.clientName) {
            this.setState({actionMsg: "Your turn to choose", showDoYaActions: true})
        }
        else {
            this.setState({actionMsg: `${data.playerName}'s Turn`, showDoYaActions: false})
        }
    }

    doYaAction = async (action, card) => {
        let { clientName, roomName } = this.state;
        let body;
        if(action === "NO") {
            body = {
                clientName : clientName,
                action : {
                    name : 'decisionForDoYa',
                    data : {
                        decision : action
                    }
                }
            };
        }
        if(action === "YES") {
            body = {
                clientName : clientName,
                action : {
                    name : 'decisionForDoYa',
                    data : {
                        decision : action,
                        cardSelected : card
                    }
                }
            };
        }
        let response = await deal5CSCards(body, roomName);
        console.log('APICall deal5CSCards(doYaAction)', action, response);
        if(response) {
            this.setState({
                upCards7CS: sortCards(getItems(response.data.faceUpCards))
            })
        }
    }

    roundOver5CS = async (data) => {
        console.log('WSEvent 5CD: roundOver5CS', data);
        this.setState({
            winners: data,
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            actionMsg: '',
            currentTurnPlayer: "",
            selectedCards: [],
            selectedCardsSortedList: [],
            gameEnds5CS: true,
            exchangedCards: {},
            showExchangedCards: false,
            roundDealer: ''
        })
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall 5CD CurrentState(gameOver5CS): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
        });
    }

    gameOver5CS = async (data) => {
        console.log('WSEvent 5CS: gameOver5CS', data);
        if(data) {
            this.setState({
                winners: data
            })
        }
        this.setState({
            gameEnds5CS: true,
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            gameStarted: false,
            is5CSLive: false,
            winnerModalShow: true,
            showWinningSelection: false,
            voteoutShow: false,
            allowKickOut: false,
            upCards7CS: [],
            downCards7CS: [],
            allPlayerCards7CS: [],
            downCards727: [],
            doYaWildCards: []
        });
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall 727 CurrentState(gameOver5CS): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
            cards: []
        });
    }

    //727

    fetchCardsSevenTwoSeven = async (data) => {
        let { roomName, clientName, positions, spectate, players } = this.state;
        console.log('WSEvent 727: fetchCardsSevenTwoSeven', data);
        this.commonSetStateVariables();
        this.get727Cards();
        let response = await getCurrentState(roomName);
        console.log('APICall 727 CurrentState(fetchCardsSevenTwoSeven): ', response);
        this.setState({
            players: updatePlayersPositions(positions, response.data.players),
            pot: response.data.GameState.pot,
            myCurrentBet: {}, currentPlayerBets: {}
        });
        if(!spectate) {
            let currentStack = players.filter(function(detail) {return detail.clientName === clientName})
            this.setState({
                stack: currentStack[0].stack
            })
        }
    }

    calculate727CardsSum = (cards) => {
        this.setState({
            sum727cards: calculateCardsSum(cards)
        })
    }

    get727Cards = async () => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action: {
                name : "dealCards",
            }
        };
        let response = await deal727Cards(body, roomName);
        if(response) {
            console.log('APICall fetchCards727 Cards', response);
            this.setState({
                cards: response.data.cards,
                upCards7CS: sortCards(getItems(response.data.faceUpCards)),
                downCards7CS: sortCards(getItems(response.data.faceDownCards))
            });
            this.calculate727CardsSum(response.data.cards);
            this.setState({
                sortedList: sortCards(getItems(this.state.cards))
            });
        }
    }

    decideToDrawCards = (data) => {
        if(("playerName" in data)) {
            console.log('WSEvent 727 decideToDrawCards: ', data);
            this.setState({
                drawRound727Show: true,
            })
            if(data.playerName === this.state.clientName) {
                this.setState({
                    draw727CardsShow: true,
                    actionMsg: "Pass/Draw Round"
                })
            }
            else {this.setState({actionMsg: `${data.playerName}'s Decision`})}
        }
        else {
            console.log('WSEvent 727 decideToDrawCards: ', data);
            this.setState({
                draw727CardsShow: true,
                drawRound727Show: true,
                actionMsg: "Pass/Draw Round"
            })    
        }
    }

    draw727Action = async (action) => {
        let { clientName, roomName } = this.state;
        let body = {
            clientName : clientName,
            action : {
                name : 'decisionForDrawCards',
                data : {
                    selection : action
                }
            }
        };
        let response = await deal727Cards(body, roomName);
        console.log('APICall deal727Cards(draw727Action)', action, response);
        if(response) {
            this.setState({
                draw727CardsShow: false,
                downCards7CS: sortCards(getItems(response.data.faceDownCards))
            })
            // this.calculate727CardsSum(response.data.cards);
        }
    }

    someoneDecidedForDrawCards = (data) => {
        console.log('WSEvent 727 someoneDecidedForDrawCards: ', data);
        this.setState({
            drawRound727Show: true
        })
        // this.setDownCards727(data.playerCards);
        // this.setUpCards727(data.playerCards);
        this.setNoofPasses727(data.noOfPassesOfPlayers);
        if(("playersDecision" in data)) {
            this.setPlayerDecisions(data.playersDecision);
        }
        else {
            this.setState({
                draw727RoundDetails: data.playersWhoHaveDecided
            })
        }
    }

    setPlayerDecisions = (data) => {
        let des = [];
        for(const i in data) {
            let body = {
                clientName: i,
                decision: data[i]
            }
            des.push(body);
        }
        this.setState({draw727RoundDetails: des})
    }

    setNoofPasses727 = (data) => {
        let passes = [];
        for(const i in data) {
            let body = {
                clientName: i,
                passes: data[i]
            }
            passes.push(body);
        }
        this.setState({noOfPassesPlayerDetails: passes})
    }

    decideToDrawCardsRoundStart = (data) => {
        console.log('WSEvent 727 decideToDrawCardsRoundStart: ', data);
        this.setState({
            drawRound727Show: true
        })
        this.setNoofPasses727(data.noOfPassesOfPlayers);
    }

    setUpCards727 = (data) => {
        let upCards = [];
        for(const i in data) {
            let upBody = {
                clientName: i,
                cards: data[i]
            }
            upCards.push(upBody);
        }
        this.setState({allPlayerCards7CS: upCards})
    }

    setDownCards727 = (data) => {
        let downCards = [];
        for(const i in data) {
            let downBody = {
                clientName: i,
                cards: data[i]
            }
            downCards.push(downBody);
        }
        this.setState({downCards727: downCards})
    }

    decideToDrawCardsRoundOver = (data) => {
        console.log('WSEvent 727 decideToDrawCardsRoundOver: ', data);
        this.setState({
            drawRound727Show: false,
            draw727RoundDetails: []
        })
        for(const i in data.playerCards) {
            if(i === this.state.clientName) {
                this.setState({
                    cards: data.playerCards[i],
                    upCards7CS: sortCards(getItems(data.playerCards[i])),
                })
                this.calculate727CardsSum(data.playerCards[i].concat(this.state.downCards7CS[0].id))
            }
        }
        this.setUpCards727(data.playerCards)
        this.setNoofPasses727(data.noOfPassesOfPlayers);
    }

    lastBettingRound = (data) => {
        console.log('WSEvent 727 lastBettingRound: ', data);
        this.setState({
            lastBettingRound727: true            
        })
    }

    gameOverSevenTwoSeven = async (data) => {
        console.log('WSvent 727 gameOverSevenTwoSeven: ', data);
        this.setState({
            drawRound727Show: false,
            draw727RoundDetails: [],
            noOfPassesPlayerDetails: [],
            winners: data,
            gameEnds727: true,
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            gameStarted: false,
            is727Live: false,
            winnerModalShow: true,
            showWinningSelection: false,
            voteoutShow: false,
            allowKickOut: false,
            upCards7CS: [],
            downCards7CS: [],
            allPlayerCards7CS: [],
            downCards727: [],
            lastBettingRound727: false
        });
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall 727 CurrentState(gameOverSevenTwoSeven): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
            cards: []
        });
    }

    newWildCardsAdded = (data) => {
        console.log('WSEvent newWildCardsAdded: ', data);
        this.setState({
            followTheQueenCards: data.wildCardsAdded
            // gameWildCards: [...this.state.gameWildCards, data.wil]
        })
    }

    followTheQueenWildCards = (data) => {
        console.log('WSEvent followTheQueenWildCards: ', data);
        this.setState({
            followTheQueenCards: data.wildCards
        })
    }

    decideForFaceUpWildCardRoundStart = (data) => {
        console.log('WSEvent decideForFaceUpWildCardRoundStart: ', data);
        this.setState({
            actionMsg: "Payment for Face-Up Wild cards"
        });
    }

    decideForFaceUpWildCard = (data) => {
        console.log('WSEvent decideForFaceUpWildCard: ', data);
        this.setState({
            showPayUpSheet: true,
            payUpAmount: data.amountToPay
        })
    }

    someoneDecideForFaceUpWildCard = (data) => {
        console.log('WSEvent someoneDecideForFaceUpWildCard: ', data);
        let client = data.clientName;
        let msgString = "";
        for (const i in data.playersDecision) {
            if(i === client) {
                msgString = data.playersDecision[i]
            }
        }
        let answer = client.concat(msgString === "PAY" ? " PAYS" : " FOLDS")
        let messagebody = {
            messagetext: answer,
            currentUser: this.state.clientName,
            messageUser: "Pay for Face-Up Wild-Card"
        }
        this.setState({
            messages: [ ...this.state.messages, messagebody ],
        });
    }

    handlePayForFaceUpWildCard = async (action) => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action : { 
                name : "decideForFaceUpWildCard", 
                data : {
                    choice : action
                }, 
                clientName : clientName
            } 
        };
        let response = await deal7CSCards(body, roomName);
        if(response) {
            this.setState({showPayUpSheet: false})
        }
    }

    someoneOpened = (data) => {
        console.log('WSEvent someoneOpened: ', data);
        this.setState({diabloOpener: data.opener})
    }

    roomCrashed = () => {
        let messagebody = {
            messagetext: 'ROOM CRASH',
            currentUser: this.state.clientName,
            messageUser: 'SERVER'
        }
        this.setState({
            messages: [ ...this.state.messages, messagebody ],
        });
    }

    submitSettingRoundOver = (data) => {
        console.log('WSEvent submitSettingRoundOver: ', data);
        this.setState({showRecallButton: false, submitSettingRoundOverReceived: true})
    }

    someoneWantsToReSubmitSetting = (data) => {
        console.log('WSEvent someoneWantsToReSubmitSetting: ', data);
        this.setState({homeRunSubmitStatus: data})
    }


    votingStarted = (data) => {
        console.log('WSEvent votingStarted: ', data);
        let messagebody = {
            messagetext: `${data.playerWhoStartedVoting} wants to kick-out ${data.playerToVoteOut}`,
            currentUser: this.state.clientName,
            messageUser: 'KICK-OUT'
        }
        this.setState({
            messages: [ ...this.state.messages, messagebody ],
        });
    }

    commonSetStateVariables = () => {
        this.setState({
            winnerModalShow: false,
            actionMsg: "",
            currentTurnPlayer: "",
            gameStarted: true,
            foldedPlayers: [],
            betRoundOver: false,
            winners: {},
            gamefinished: false,
            showDealerCounter: false,
            roundOver357: false,
            gameEnds357: false,
            selectedIn: false,
            gameEnds5CD: false,
            showReady357: false,
            readyData357: {},
            gameEndsTexas: false,
            allCommunityCards: [],
            gameEnds7CS: false, gameEnds5CS: false,
            gameEndsCincinnati: false,
            gameEndsHomeRun: false,
            gameEnds727: false,
            gameEndsBaseball: false,
            settingsFront: [],
            settingsBack: [],
            settingsMiddle: [],
            homeRunSubmitStatus: [],
            frontCards: [],
            middleCards: [],
            backCards: [],
            showModalHomeRun: false,
            boolHomeRun: false,
            downCards727: [],
            bigBlindPlayer: '',
            smallBlindPlayer: '',
            animatedWinners: []
        })
    }

    aboutToLeaveRoom = (data) => {
        console.log('WSEvent aboutToLeaveRoom', data);
        if(data.cause !== 'leaved') {alert(`You have been kicked from the Room`);}
        this.setState({
            goBack: true
        })
    }

    allowSpectatorToJoin = (data) => {
        console.log('WSEvent allowSpectatorToJoin', data);
        this.setState({
            showSpectatorJoinReq: true,
            newSpectator: data
        });
        setTimeout(() => {
            this.setState({
                showSpectatorJoinReq: false
            })
        }, 10000);
    }

    kickSpectatorOut = async (spectatorEmail) => {
        let body = {
            spectatorToKickOut : spectatorEmail
        }
        let response = await kickOutSpectator(body, this.state.roomName);
        console.log('APICALL kickoutSpectator', response);
        if(response) {
            let response_new = await getCurrentState(this.state.roomName);
            console.log('APICall getCurrentState(kickSpectatorOut)', response_new);
            this.setState({
                spectatorList: getAllSpectators(response_new.data.spectators.spectators)
            })
        }
    }

    takeSpectatorDecision = async (action, spectator) => {
        let body = {
            decision : action === "YES" ? "ALLOW" : "NOT_ALLOW",
            spectatorEmail : spectator
        }
        let response = await spectatorDecision(body, this.state.roomName);
        console.log('APICAll spectatorDecision', response);
        if(response) {
            this.setState({newSpectator: {}})
        }
        this.setState({showSpectatorJoinReq: false})
    }

    spectatorRejoined = async (data) => {
        console.log('WSEvent spectatorRejoined', data);
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall getCurrentState(spectatorRejoined)', response);
        this.setState({
            spectatorList: getAllSpectators(response.data.spectators.spectators)
        })
    }

    spectatorJoined = async (data) => {
        console.log('WSEvent spectatorJoined', data);
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall getCurrentState(spectatorJoined)', response);
        this.setState({
            spectatorList: getAllSpectators(response.data.spectators.spectators)
        })
    }

    someoneLeavedRoom = async (data) => {
        console.log('WSEvent someoneLeavedRoom', data);
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall getCurrentState(spectatorJoined)', response);
        this.setState({
            spectatorList: getAllSpectators(response.data.spectators.spectators)
        })
    }

    someoneChoosePencilAmount = (data) => {
        console.log('WSEvent someoneChoosePencilAmount: ', data);
        let amount = [];
        for(const i in data) {
            amount.push(i);
        }
        this.setState({pencilAmountData: amount});
    }

    choosePencilAmount = (data) => {
        let { players, clientName } = this.state;
        console.log('WSEvent choosePencilAmount', data);
        this.setState({
            showAmountSelection: true,
            pencilAmount: players[players.findIndex(pl => pl.clientName === clientName)].stack,
            actionMsg: 'LET EVERYONE SELECT THE AMOUNT TO RISK',
            showPencilStatus: true,
            allowKickOut: true
        })
        this.commonSetStateVariables();
    }

    gameSettings = async (data) => {
        console.log('WSEvent gameSettings', data);
        this.setState({
            gameAnte: data.ante,
            gameMaxBet: data.maxRaiseAllowed,
            gameMinBet: data.minRaiseAllowed,
            gameMaxNoRaises: data.maximumNoOfRaisesAllowed,
            showModalHomeRun: false,
            playerReadyRoom: false,
            showRoomReady: false,
            roomReadyData: [],
            allPlayersReady: false,
            foldedPlayers: [],
            winningDataTexas: {},
            playerCardsTexas: [],
            selectedGame: {},
            resultHomeRun: [],
            restrict5CDOpening: true,
            loFlush: false,
            loAce: true,
            localSplitHiLo: false, localStrictSum: true, localSplitPotIfStrictSumFalse: true, localTieGoesToLow: true, local727NoOfPasses: 3, localSimultaneousPassDrawRound: true, localDoYa: true,
            bustedPlayers: [],
            allowKickOut: false,
            spectatorToPlayerConverted: false,
            showDiabloBuy: false,
            gameEnds357: false, gameEnds5CD: false, gameEnds7CS: false, gameEndsCincinnati: false, gameEnds727: false, gameEnds5CS: false, gameEndsBaseball: false,
            gameEndsHomeRun: false, gameEndsTexas: false, gamefinished: false, gameStarted: true,
            rollOnCardsShow: false, rollOnPlayerCards: [],
            diabloOpener: "",
            selectedWildCardsList: [],
            selectedWildCardsByRank: [],
            selectedWildCardsBySuit: [],
            selectedWildCardChoice: {},
            wildCardSelectedOption: "",
            followTheQueenCards: [],
            roundOver357: false,
            details357: {}, startDisable: true, showSubmitBaseball: false,
            animatedWinners: []
        })
        if(data.loDecision) {
            this.setState({
                gameLoAce: data.loDecision.considerAceLow,
                gameLoFlush: data.loDecision.countStraightAndFlushes
            })
        }
        if(("restrictBetOpening" in data)) {this.setState({gameBetOpening: data.restrictBetOpening})}
        if(("restrictBetOpeningRank" in data)) {this.setState({gameDefaultBaseballOpening: data.restrictBetOpeningRank})}
        if(("maxPotToReplace" in data)) {this.setState({gameMaxPotToReplace: data.maxPotToReplace})}
        let response = await getCurrentState(this.state.roomName);
        let gameCurrent = response.data.GameState.currentGame;
        console.log('APICall HomeRun CurrentState(gameSettings): ', response);
        this.setState({
            currentGameName: response.data.GameState.gameNameText,
            firstGameStarted: response.data.noOfGamesPlayed > 0 ? true : false,
            kickedOutPlayers: response.data.kickedOutPlayers.convertToSpectator.concat(response.data.kickedOutPlayers.keepOnTable.concat(response.data.kickedOutPlayers.kickOutFromRoom)),
            playersDisconnected: response.data.disconnectedPlayers
        });
        if(gameCurrent === '727') {
            this.setState({
                game727NoOfPasses: data.noOfPasses,
                gameSplitPotIfStrictSumFalse: data.splitPotIfStrictSumFalse,
                gameStrictSum: data.strictSum,
                gameTieGoesToLow: data.tieGoesToLow,
                gameSimultaneousPassDrawRound: data.simultaneousPassDrawRound
            })
        }
        if(gameCurrent === "Do Ya") {
            this.setState({gameDoYa: data.doYaHiLo})
        }
        if(gameCurrent === "Ironcross") {
            this.setState({
                gameSplitHiLo: data.splitHiLo
            })
        }
        else {
            this.setState({
                gameroyalFlushBeatsFiveOfKind: true, gamehandsMustHaveOneNaturalCard: false, gamePayForFaceUpWildCard: 10, gameNoOfJokers: 0, gameWildCards: [], gameJokersInDeck: [] 
            }, () => {this.setState({showGameOptionsInfo: true})})
        }
        if(("wildCardsSettings" in response.data.GameState)) {
            this.setState({
                gameroyalFlushBeatsFiveOfKind: response.data.GameState.wildCardsSettings.royalFlushBeatsFiveOfKind,
                gamehandsMustHaveOneNaturalCard: response.data.GameState.wildCardsSettings.handsMustHaveOneNaturalCard,
                gamePayForFaceUpWildCard: response.data.GameState.wildCardsSettings.payForFaceUpWildCard,
                gameNoOfJokers: response.data.GameState.wildCardsSettings.noOfJokers,
                gameWildCards: response.data.GameState.wildCardsSettings.wildCards,
                gameJokersInDeck: response.data.GameState.wildCardsSettings.jokersInDeck
            }, () => {this.setState({showGameOptionsInfo: true})})
        }
        setTimeout(() => {
            this.setState({showGameOptionsInfo: false})
        }, 30000);
        if(gameCurrent === "Texas-Holdem" || gameCurrent === "Texas-Holdem Round" || gameCurrent === "Omaha" || gameCurrent === "Omaha Round" || gameCurrent === "Omaha8" || gameCurrent === "Omaha8 Round" || gameCurrent === "Ironcross") {this.setState({isTexasLive: true})}
        if(gameCurrent === "7-Card-Stud" || gameCurrent === "Lo Chicago" || gameCurrent === "High Chicago" || gameCurrent === "Follow The Queen" || gameCurrent === "Baseball 7CS" || gameCurrent === "Countdown") {this.setState({is7CSLive: true})}
        if(gameCurrent === "5-Card-Stud" || gameCurrent === "Do Ya" || gameCurrent === "5-Card-Stud Round") {this.setState({is5CSLive: true})}
        if(gameCurrent === "357" || gameCurrent === "3-Brent-7") {
            this.setState({is357Live: true, roundOver357: false, details357: {}})
        }
        if(gameCurrent === "5-Card-Draw" || gameCurrent === "5-Card-Draw Round" || gameCurrent === "Diablo" || gameCurrent === "Frankenstein") {this.setState({is5CDLive: true})}
        if(gameCurrent === "HomeRun" || gameCurrent === "HomeRun Round") {this.setState({isHomeRunLive: true})}
        if(gameCurrent === "Cincinnati") {this.setState({isCincinnatiLive: true})}
        if(gameCurrent === "727") {this.setState({is727Live: true})}
        if(gameCurrent === "Baseball-9CD" || gameCurrent === "Blind Baseball") {this.setState({isBaseballLive: true})}
        this.setDefaultGameRules(response.data);
        window.ga('send', 'event', {eventAction: 'Start Game', eventCategory: 'Game', eventValue: '1', dimension1: 'xyz' })
        ReactGA.event({
            category: 'Game',
            action: 'Start game',
            label: 'Game started',
            value: 1
        });
    }

    setDefaultGameRules = (data) => {
        let gameCurrent = data.GameState.currentGame;
        let roomHouseRules = data.gamesSettings;
        for (const game in roomHouseRules) {
            if(roomHouseRules[game].maxRaiseAllowed === Number.MAX_SAFE_INTEGER) {
                roomHouseRules[game].maxRaiseAllowed = 0;
            }
            if(roomHouseRules[game].maximumNoOfRaisesAllowed === Number.MAX_SAFE_INTEGER) {
                roomHouseRules[game].maximumNoOfRaisesAllowed = 0;
            }
        }
        console.log('ROOM DEFAULTS debug', roomHouseRules, gameCurrent);
        for(const i in roomHouseRules) {
            if( i === gameCurrent ) {
                console.log('GAME CATCH', i, roomHouseRules[i]);
                this.setState({
                    defAnte: roomHouseRules[i].ante,
                    defMaxBet: roomHouseRules[i].maxRaiseAllowed,
                    defMinBet: roomHouseRules[i].minRaiseAllowed,
                    defMaxNoRaises: roomHouseRules[i].maximumNoOfRaisesAllowed
                })
                if(("wildCardsSettings" in roomHouseRules[i])) {
                    this.setState({
                        defroyalFlushBeatsFiveOfKind:  roomHouseRules[i].wildCardsSettings.royalFlushBeatsFiveOfKind,
                        defhandsMustHaveOneNaturalCard:  roomHouseRules[i].wildCardsSettings.handsMustHaveOneNaturalCard,
                        defPayForFaceUpWildCard:  roomHouseRules[i].wildCardsSettings.payForFaceUpWildCard,
                        defNoOfJokers:  roomHouseRules[i].wildCardsSettings.noOfJokers,
                        defWildCards:  roomHouseRules[i].wildCardsSettings.wildCards,
                        defJokersInDeck:  roomHouseRules[i].wildCardsSettings.jokersInDeck
                    })
                }
                else {
                    this.setState({
                        defroyalFlushBeatsFiveOfKind: true, defhandsMustHaveOneNaturalCard: false, defPayForFaceUpWildCard: 10, defNoOfJokers: 0, defWildCards: [], defJokersInDeck: [] 
                    })
                }
                if(("loDecision" in roomHouseRules[i])) {
                    this.setState({
                        defLoAce: roomHouseRules[i].loDecision.considerAceLow,
                        defLoFlush: roomHouseRules[i].loDecision.countStraightAndFlushes
                    })
                }
                if(("restrictBetOpening" in roomHouseRules[i])) {this.setState({defBetOpening: roomHouseRules[i].restrictBetOpening})}
                if(("maxPotToReplace" in roomHouseRules[i])) {this.setState({defMaxPotToReplace: roomHouseRules[i].maxPotToReplace})}
                if(gameCurrent === '727') {
                    this.setState({
                        def727NoOfPasses: roomHouseRules[i].noOfPasses,
                        defSplitPotIfStrictSumFalse: roomHouseRules[i].splitPotIfStrictSumFalse,
                        defStrictSum: roomHouseRules[i].strictSum,
                        defTieGoesToLow: roomHouseRules[i].tieGoesToLow,
                        defSimultaneousPassDrawRound: roomHouseRules[i].simultaneousPassDrawRound
                    })
                }
                if(gameCurrent === "Ironcross") {
                    this.setState({
                        defSplitHiLo: roomHouseRules[i].splitHiLo
                    })
                }
                if(gameCurrent === "Do Ya") {
                    this.setState({
                        defDoYa: roomHouseRules[i].doYaHiLo
                    })
                }
            }
        }
    }

    readyRoundOver = (data) => {
        console.log('WSEvent readyRoundOver', data);
        this.setState({
            allPlayersReady: true
        })
    }

    someonePaid = async (data) => {
        let {roomName, clientName, messages} = this.state;
        console.log('WSEvent someonePaid', data);
        let response = await getCurrentState(roomName);
        console.log('APICall SomeonePaid CurrentState: ', response);
        this.setState({
            players: updatePlayersPositions(response.data.positions, response.data.players),
        });
        let messagebody = {
            messagetext: `${data.playerWhichPaid} paid ${data.amount} to ${data.playerToPay}`,
            currentUser: clientName,
            messageUser: 'PAYMENT'
        }
        this.setState({
            messages: [ ...messages, messagebody ],
        });
    }

    someoneBuyIn = (data) => {
        console.log('WSEvent someoneBuyIn:', data);
        let {clientName, players, stack, messages} = this.state;
        if(data.clientName === clientName) {
            let idx = players.findIndex(pl => pl.clientName === data.clientName);
            if (idx > -1) {
                players[idx].stack = players[idx].stack + data.buyIn;
                this.setState({
                    players: players,
                    stack: stack + data.buyIn
                });
            }
        }
        else {
            let idx = players.findIndex(pl => pl.clientName === data.clientName);
            if (idx > -1) {
                players[idx].stack = players[idx].stack + data.buyIn;
                this.setState({
                    players: players,
                });
            }
        }
        if(data.buyIn > 0) {
            if(data.buyInType === "MANUAL") {
                let messagebody = {
                    messagetext: `Bought ${data.buyIn}`,
                    currentUser: clientName,
                    messageUser: data.clientName
                }
                this.setState({messages: [ ...messages, messagebody ]});
            }
            else {
                let messagebody = {
                    messagetext: `SERVER added ${data.buyIn}`,
                    currentUser: clientName,
                    messageUser: data.clientName
                }
                this.setState({messages: [ ...messages, messagebody ]});
            }
        }
    }

    getMyMoneyGain = async () => {
        let { roomName, clientName } = this.state;
        let response = await getAccountHistory(roomName);
        console.log('MY MONEY', response);
        for(const i in response.data.playersAccount) {
            if(i === clientName) {
                this.setState({ myMoneyGain: response.data.playersAccount[i].totalMoneyGained})
            }
        }
    }

    selectReadyRoom = (data) => {
        console.log('WSEvent selectReadyRoom: ', data);
        this.setState({
            showRoomReady: true,
            allPlayersReady: false,
            dealer: "",
            allowKickOut: true
        })
        this.getMyMoneyGain();
    }

    onRoomReadyClick = async () => {
        if(this.state.playerReadyRoom) {
            let body = {
                data : {
                    selection: "UNREADY"
                }
            }
            let response = await roomReady(body, this.state.roomName);
            console.log('API Call room UnReady pressed', response);
            if(response) {
                this.setState({
                    playerReadyRoom: false
                });
            }
        }
        else {
            let body = {
                data : {
                    selection: "READY"
                }
            }
            let response = await roomReady(body, this.state.roomName);
            console.log('API Call room Ready pressed', response);
            if(response) {
                this.setState({
                    playerReadyRoom: true
                });
            }
        }
        this.setState({showRebuyInfo: false})
    }

//Home-Run

    fetchCardsHomeRun = async (data) => {
        console.log('WSEvent HomeRun: fetchCardsHomeRun', data);
        let { roomName, clientName, positions, spectate, players } = this.state;
        this.commonSetStateVariables();
        this.getHomeRunCards();
        let response = await getCurrentState(roomName);
        console.log('APICall HomeRun CurrentState(fetchCardsHomeRun): ', response);
        this.setState({
            players: updatePlayersPositions(positions, response.data.players),
            pot: response.data.GameState.pot,
            currentGameName: response.data.GameState.gameNameText,
            myCurrentBet: {}, currentPlayerBets: {},
            homeRunShowStatus: true,
            isHomeRunLive: true,
            showRecallButton: false,
            submitSettingRoundOverReceived: false,
            homeRunArrangements: {}
        });
        if(!spectate) {
            let currentStack = players.filter(function(detail) {return detail.clientName === clientName})
            this.setState({
                stack: currentStack[0].stack
            })
        }
    }

    getHomeRunCards = async () => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action: {
                name : "dealCards",
            }
        };
        let response = await dealHomeRunCards(body, roomName);
        if(response) {
            console.log('APICall fetchCardsHomeRun Cards', response);
            this.setState({
                cards: response.data.cards,
            });
            this.setState({
                sortedList: sortCards(getItems(this.state.cards)),
                selectedCards: response.data.cards,
                selectedCardsSortedList: sortCards(getItems(response.data.cards)),
            });
        }
    }

    submitSettings = (data) => {
        console.log('WSEvent HomeRun: submitSettings', data);
        if(!this.state.foldedPlayers.includes(this.state.clientName)) {
            this.setState({
                cardSelectionEnabled: true,
            })
        }
        this.setState({
            actionMsg: `Select 3, 5, 5 Cards`,
            selectedCards: this.state.cards,
            selectedCardsSortedList: sortCards(getItems(this.state.cards)),
            submitSettingsButton: true,
            allowKickOut: true
        })
    }

    someoneSubmittedSetting = (data) => {
        console.log('WSEvent HomRun: someoneSubmittedSetting', data);
        this.setState({
            homeRunSubmitStatus: data
        })
        if(this.state.spectate) {this.setState({homeRunShowStatus: true})}
    }

    frontSettingRevealed = (data) => {
        console.log('WSEvent HomeRun: frontSettingRevealed', data);
        this.setState({
            readyData357: {},
            settingsFront: data.settingsOfAllPlayers,
            homeRunShowStatus: false
        });
    }

    backSettingRevealed = (data) => {
        console.log('WSEvent HomeRun: backSettingRevealed', data);
        this.setState({
            readyData357: {},
            settingsBack: data.settingsOfAllPlayers
        });
    }

    middleSettingRevealed = (data) => {
        console.log('WSEvent HomeRun: middleSettingRevealed', data);
        this.setState({
            readyData357: {},
            settingsMiddle: data.settingsOfAllPlayers
        });
    }

    roundOverHomeRun = async (data) => {
        console.log('WSEvent HomeRun: roundOverHomeRun', data);
        if(data) {
            this.setState({
                winners: data
            })
        }
        this.setState({
            actionMsg: '',
            currentTurnPlayer: "",
            gameEndsHomeRun: true,
            allowKickOut: false
        });
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall HomeRun CurrentState(gameOverHomeRun): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
        });
        
        let playersList = [];
        for(const player in data.playerPointsAndHits) {
            playersList.push(player);
        }
        console.log('All players', playersList);

        let finalResult = [];

        for(const player of playersList) {
            let temp = [];
            for(const x of data.whoBeatsWho) {
                let body = {};
                if(x.players.includes(player)) {
                    body = {
                        playername: player,
                        data: x 
                    }
                }
                if("data" in body) {
                    temp.push(body);
                }
            }
            finalResult.push(temp);
        }
        console.log('Final Result', finalResult);
        this.setState({
            resultHomeRun: finalResult
        })
        setTimeout(() => {
            this.setState({
                boolHomeRun: true
            })
        }, 10000);
    }

    gameOverHomeRun = async (data) => {
        console.log('WSEvent HomeRun: gameOverHomeRun', data);
        if(data) {
            this.setState({
                winners: data
            })
        }
        this.setState({
            isHomeRunLive: false,
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            gameStarted: false,
            actionMsg: '',
            currentTurnPlayer: "",
            cards: [],
            sortedList: [],
            selectedCardsSortedList: [],
            selectedCards: [],
            readyData357: {},
            showReady357: false,
            gameEndsHomeRun: true,
            homeRunArrangements : undefined
        });
    }

//Cincinnati

    somoneSelectedCardsToReveal = (data) => {
        console.log('WSEvent Cincinnati: somoneSelectedCardsToReveal', data);
        this.setState({
            playersRevealStatus: data.players
        })
        if(this.state.spectate) {
            this.setState({
                showRevealStatus: true
            })
        }
    }

    fetchCardsCincinnati = async (data) => {
        let { roomName, clientName, positions, spectate, players } = this.state;
        console.log('WSEvent Cincinnati: fetchCardsCincinnati', data);
        this.commonSetStateVariables();
        this.getCincinnatiCards();
        let response = await getCurrentState(roomName);
        console.log('APICall Cincinnati CurrentState(fetchCardsCincinnati): ', response);
        this.setState({
            players: updatePlayersPositions(positions, response.data.players),
            pot: response.data.GameState.pot,
            myCurrentBet: {}, currentPlayerBets: {}
        });
        if(!spectate) {
            let currentStack = players.filter(function(detail) {return detail.clientName === clientName})
            this.setState({
                stack: currentStack[0].stack
            })
        }
    }

    getCincinnatiCards = async () => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action: {
                name : "dealCards",
            }
        };
        let response = await dealCincinnatiCards(body, roomName);
        if(response) {
            console.log('APICall fetchCardsCincinnati Cards', response);
            this.setState({
                cards: response.data.cards,
            });
            this.setState({
                sortedList: sortCards(getItems(this.state.cards))
            });
        }
    }

    passCards = (data) => {
        console.log('WSEvent Cincinnati: passCards', data);
        this.setState({
            selectedCards: [],
            selectedCardsSortedList: [],
            allowKickOut: true
        })
        if(!this.state.foldedPlayers.includes(this.state.clientName)) {
            this.setState({
                cardSelectionEnabled: true,
            })
        }
        this.setState({
            actionMsg: `Pass 2 cards Left, 1 Right`,
            noOfCardsToDiscard: data.noOfCardsToPass,
            showPlayersPassedCin: true
        })
    }

    fetchPassedCards = (data) => {
        console.log('WSEvent Cincinnati: fetchPassedCards', data);
        // this.setState({
        //     noOfCards: data.noOfCards,
        // });
        this.getPassedCards();
    }

    selectCardsToReveal = (data) => {
        console.log('WSEvent Cincinnati: selectCardsToReveal', data);
        this.setState({
            cardSelectionEnabled: true,
            cardRevealRound: true,
            actionMsg: 'Set the order of cards to be displayed',
            showRevealStatus: true,
            allowKickOut: true,
            currentTurnPlayer: "",
            cardsFromLeft: [],
            cardsFromRight: []
        })  
    }

    revealedCards = (data) => {
        let allCards = [];
        for (const player in data) {
            let body = {
                clientName: player,
                cards: data[player].cardsRevealed
            };
            allCards.push(body);
        }
        this.setState({
            allPlayerCards7CS: allCards,
            allowKickOut: false
        })
        console.log('WSEvent Cincinnati: revealedCards', data, allCards);
    }

    gameOverCincinnati = async (data) => {
        console.log('WSEvent Cincinnati: gameOverCincinnati', data);
        this.setState({
            winners: data,
            isCincinnatiLive: false,
            allPlayerCards7CS: [],
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            gameStarted: false,
            actionMsg: '',
            currentTurnPlayer: "",
            cards: [],
            sortedList: [],
            gameEndsCincinnati: true,
            leftCards: [],
            showRevealStatus: false,
            playersRevealStatus: [],
            cardsFromLeft: [],
            cardsFromRight: []
        })
        let temp = [];
        let settings = this.getGameSettings();
        for (const i in data.cards) {
            let loSortedCards = sortLoHand(data.cards[i].cards, settings);
            let loHand = getLoHandStringDescription(loSortedCards, settings);
            let hiSortedCards = sortHiHand(data.cards[i].cards, settings);
            let hiHand = getHiHandStringDescription(hiSortedCards, settings);
            console.log('METHOD CALLED');
            let body = {
                clientName: data.cards[i].clientName,
                cards: data.cards[i].cards,
                loSortedCards: loSortedCards,
                loHand: loHand,
                hiSortedCards: hiSortedCards,
                hiHand: hiHand
            }
            temp.push(body);
        }
        this.setState({gameOverAllData: temp})
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall Cincinnati CurrentState(gameOverCincinnati): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
        });
    }

//7 Card Stud
    fetchCards7CS = async (data) => {
        let { roomName, clientName, positions, spectate, players } = this.state;
        console.log('WSEvent 7CS: fetchCards7CD', data);
        this.commonSetStateVariables();
        this.get7CSCards();
        let response = await getCurrentState(roomName);
        console.log('APICall 7CS CurrentState(fetchCards7CS): ', response);
        this.setState({
            players: updatePlayersPositions(positions, response.data.players),
            spadeCardAllPlayers: []
        });
        if(("GameState" in response.data)) {
            this.setState({
                pot: response.data.GameState.pot,
                currentGameName: response.data.GameState.gameNameText,
            })
        }
        if(!spectate) {
            let currentStack = players.filter(function(detail) {return detail.clientName === clientName})
            this.setState({
                stack: currentStack[0].stack
            })
        }
    }

    get7CSCards = async () => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action: {
                name : "dealCards",
            }
        };
        let response = await deal7CSCards(body, roomName);
        if(response) {
            console.log('APICall 7CS Cards', response);
            this.setState({
                cards: response.data.cards,
                upCards7CS: sortCards(getItems(response.data.faceUpCards)),
                downCards7CS: sortCards(getItems(response.data.faceDownCards))
            });
            if(!(response.data.faceUpCards.length === 1 && response.data.faceDownCards.length === 2)) {this.setState({betRoundOver: true})}
            this.setState({
                sortedList: sortCards(getItems(this.state.cards))
            });
        }
    }

    someoneFetchedCards = async (data) => {
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall currentState(someoneFetchedCards)', response);
        let gameCurrent = response.data.GameState.currentGame;
        if(gameCurrent === "727") {
            console.log('WSEvent 727: someoneFetchedCards', data);
            let body = {
                clientName: data.clientName,
                cards: data.faceUpCards
            };
            let downBody = {
                clientName: data.clientName,
                cards: 1
            }
            this.setState({
                allPlayerCards7CS: [ ...this.state.allPlayerCards7CS, body ],
                is727Live: true,
                downCards727: [ ...this.state.downCards727, downBody ]
            })
        }
        else if(gameCurrent === "Baseball-9CD") {
            console.log('WSEvent Baseball: someoneFetchedCards', data);
            let allCards = [];
            for (const player in response.data.GameState.playersCardsNoInfo) {
                let temp = [];
                for(let i = 0; i < response.data.GameState.playersCardsNoInfo[player].noOfFaceDownCards; i++)
                {temp.push("CB")}
                let body = {
                    clientName: player,
                    upCards: [],
                    downCards: temp
                };
                allCards.push(body);
            }
            this.setState({isBaseballLive: true, allPlayerCardsBaseball: allCards})
        }
        else if(gameCurrent === "Blind Baseball") {
            console.log('WSEvent Blind-Baseball: someoneFetchedCards', data);
            let allCards = [];
            for (const player in response.data.GameState.playersCardsNoInfo) {
                let temp = [];
                for(let i = 0; i < response.data.GameState.playersCardsNoInfo[player].noOfCantSeeCards; i++)
                {temp.push("CB")}
                let body = {
                    clientName: player,
                    upCards: [],
                    downCards: temp
                };
                allCards.push(body);
            }
            this.setState({isBaseballLive: true, allPlayerCardsBaseball: allCards})
        }
        else{
            console.log('WSEvent 7CS: someoneFetchedCards', data);
            let body = {
                clientName: data.clientName,
                cards: data.faceUpCards
            };
            this.setState({
                allPlayerCards7CS: [ ...this.state.allPlayerCards7CS, body ],
                is7CSLive: true
            })
            let response = await getCurrentState(this.state.roomName);
            console.log('APICall 7CS CurrentState(someoneFetchedCards5CS): ', response);
            if(this.state.spectate || this.state.foldedPlayers.includes(this.state.clientName)) {
                this.setGeneralDownCards(response.data.GameState.playersCardsNoInfo);
            }
        }
    }

    gameOver7CS = async (data) => {
        console.log('WSEvent 7CS: gameOver7CS', data);
        this.setState({
            winners: data,
            is7CSLive: false,
            allPlayerCards7CS: [],
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            gameStarted: false,
            actionMsg: '',
            currentTurnPlayer: "",
            cards: [],
            sortedList: [],
            gameEnds7CS: true,
            upCards7CS: [],
            downCards7CS: [],
            drawBaseballDecisions: [],
            drawRoundBaseballShow: false
        })
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall 7CS CurrentState(gameOver7CS): ', response);
        if(("playersBestSpadeCard" in data)) {
            let playerCard = [];
            for(const player in data.playersBestSpadeCard) {
                let body = {
                    clientName: player,
                    card: data.playersBestSpadeCard[player] 
                }
                playerCard.push(body);
            }
            this.setState({
                spadeCardAllPlayers: playerCard
            })
        }
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
        });
    }

//Texas-Holdem
    fetchCardsTexasHoldem = async (data) => {
        console.log('WSEvent Texas: fetchCardsTexasHoldem', data);
        this.commonSetStateVariables();
        this.getTexasCards();
    }

    setTexasVariantVariables = async () => {
        let { roomName, clientName, bustedPlayers, positions, spectate, players } = this.state;
        let response = await getCurrentState(roomName);
        console.log('APICall Texas CurrentState(blindDeducted): ', response);
        this.setState({
            players: updatePlayersPositions(positions, response.data.players),
            pot: response.data.GameState.pot,
            currentGameName: response.data.GameState.gameNameText,
            kickedOutPlayers: response.data.kickedOutPlayers.convertToSpectator.concat(response.data.kickedOutPlayers.keepOnTable.concat(response.data.kickedOutPlayers.kickOutFromRoom)),
            playersDisconnected: response.data.disconnectedPlayers
        });
        if(!spectate) {
            let currentStack = players.filter(function(detail) {return detail.clientName === clientName})
            this.setState({
                stack: currentStack[0].stack
            })
        }
        let playersKickedOut = response.data.kickedOutPlayers.convertToSpectator.concat(response.data.kickedOutPlayers.keepOnTable.concat(response.data.kickedOutPlayers.kickOutFromRoom))
        if(bustedPlayers.includes(clientName) || playersKickedOut.includes(clientName)) {
            this.setState({
                showReady357: false,
                readyData357: {},
                gameEndsTexas: false,
                allCommunityCards: [],
                cards: [],
                sortedList: [],
            })
        }
    }

    blindDeducted = async () => {
        console.log('WSEvent Texas: blindDeducted');
        this.setTexasVariantVariables();
    }

    getTexasCards = async () => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action: {
                name : "dealCards",
            }
        };
        let response = await dealTexasCards(body, roomName);
        if(response) {
            console.log('APICall Texas Cards', response.data);
            this.setState({
                cards: response.data.cards,
                isTexasLive: true,
                myCurrentBet: {}, currentPlayerBets: {}
            });
            this.setState({
                sortedList: sortCards(getItems(this.state.cards))
            });
            if(this.state.currentGameName === "Ironcross") {
                this.setTexasVariantVariables();
            }
        }
    }

    communityCards = (data) => {
        console.log('WSEvent Texas: communityCards', data);
        this.setState({
            allCommunityCards: data.communityCards
        });
    }

    roundOverTexasHoldem = async (data) => {
        console.log('WSEvent Texas: roundOverTexasHoldem', data, 'CurrentGame', this.state.currentGameName);
        if(this.state.currentGameName === "Omaha8" || this.state.currentGameName === "Omaha8 Round") {this.setState({texasGameTypeOmaha8: true})}
        else {this.setState({texasGameTypeOmaha8: false})}
        this.setState({
            gameEndsTexas: true,
            winners: data,
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            actionMsg: '',
            currentTurnPlayer: "",
            smallBlindPlayer: '',
            bigBlindPlayer: '',
            roundDealer: '',
            winningDataTexas: data,
            rollOnPlayerCards: [],
            rollOnCardsShow: false
        })
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall Texas CurrentState(roundOverTexas): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
        });
        const allCards = [];
        if(this.state.currentGameName !== 'Omaha8' && this.state.currentGameName !== 'Omaha8 Round') {
            for (const player in data.cards) {
                let body = {
                    type : "High",
                    cards : data.cards[player].concat(data.communityCards)
                }
                let response = await sortCardsAPI(body);
                console.log('Sorted Cards of ', player, response);
                let bodynew = {
                    clientName: player,
                    cards: response.data
                };
                allCards.push(bodynew);
            }
            this.setState({
                playerCardsTexas: allCards,
            })
        }
        if(this.state.currentGameName === 'Omaha8' || this.state.currentGameName === 'Omaha8 Round') {
            for (const player in data.cards) {
                let body = {
                    type : "High",
                    cards : data.cards[player].concat(data.communityCards)
                }
                let response = await sortCardsAPI(body);
                console.log('Sorted Cards of ', player, response);
                let bodynew = {
                    clientName: player,
                    cards: response.data
                };
                allCards.push(bodynew);
            }
            this.setState({
                playerCardsTexas: allCards,
            })
        }
        let settings = this.getGameSettings()
        if(this.state.currentGameName === "Ironcross") {
            let temp = []
            for (const i in data.cards) {
                let loSortedCards = sortLoHand(data.cards[i].concat(this.state.allCommunityCards), settings);
                let loHand = ironCrossLoHandDescription(data.cards[i], this.state.allCommunityCards, settings);
                let bestHand = getBestCardsOmaha(data.cards[i], this.state.allCommunityCards, settings);
                let bestHandDescription = ironCrossHiHandDescription(data.cards[i], this.state.allCommunityCards, settings);
                let body = {
                    clientName: i,
                    cards: data.cards[i],
                    loSortedCards: loSortedCards,
                    loHand: loHand,
                    hiSortedCards: bestHand,
                    hiHand: bestHandDescription
                }
                temp.push(body);
            }
            this.setState({gameOverAllData: temp})
        }
    }

    gameOverTexasHoldem = async (data) => {
        console.log('WSEvent Texas: gameOverTexasHoldem', data);
        if(data) {
            this.setState({
                winners: data
            })
        }
        this.setState({
            gameEndsTexas: true,
            isTexasLive: false,
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            gameStarted: false,
            actionMsg: '',
            currentTurnPlayer: "",
            cards: [],
            sortedList: [],
        })
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall Texas CurrentState(gameOverTexas): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
        });
    }

    currentRoundDealer = async (data) => {
        let { clientName, roomName, currentGameName, spectate } = this.state;
        if(currentGameName === "Diablo" || currentGameName === "Frankenstein") {this.setState({showDiabloBuy: false, gameEnds5CD: false, showReady357: false, readyData357: {}})}
        console.log('WSEvent Texas: currentRoundDealer', data);
        this.setState({
            roundDealer: data.clientName,
            showPencilStatus: false,
            pencilAmountData: [],
            allowKickOut: false
        });
        if(spectate) {
            console.log('ROOM READY DUBUG FALSE');
            this.setState({
                gameEndsTexas: false,
                allCommunityCards: [],
                winners: {},
                showRoomReady: false,
                showReady357: false,
                gameEndsHomeRun: false,
                gameEnds5CD: false,
                settingsFront: [],
                settingsMiddle: [],
                settingsBack: []
            })
        }
        let response = await getCurrentState(roomName);
        console.log('APICall Texas CurrentState(currentRoundDealer): ', response);
        if(response) {
            let temp = response.data.players;
            let bust = [];
            for(const i in temp) {
                if(("isOutOfGame" in temp[i])) {
                    if(temp[i].isOutOfGame) {
                        bust.push(temp[i].clientName)
                    }
                }
            }
            console.log('BUSTED PLAYERS', bust);
            this.setState({
                bustedPlayers: bust,
                foldedPlayers: response.data.players.filter(function (player) {
                    return player.status === 2;
                    }).map(item => item.clientName),
                kickedOutPlayers: response.data.kickedOutPlayers.convertToSpectator.concat(response.data.kickedOutPlayers.keepOnTable.concat(response.data.kickedOutPlayers.kickOutFromRoom)),
                playersDisconnected: response.data.disconnectedPlayers
            })
            let playersKickedOut = response.data.kickedOutPlayers.convertToSpectator.concat(response.data.kickedOutPlayers.keepOnTable.concat(response.data.kickedOutPlayers.kickOutFromRoom))
            if(currentGameName === "5-Card-Draw Round" || currentGameName === "Diablo" || currentGameName === "Frankenstein") {
                if(bust.includes(clientName) || playersKickedOut.includes(clientName)) {
                    this.setState({
                        showReady357: false,
                        readyData357: {},
                        gameEnds5CD: false,
                        cards: [],
                        sortedList: [],
                        showExchangedCards: true
                    })
                }
            }
        }
    }

//5 Card Draw
    fetchCards5CD = async (data) => {
        let { roomName, clientName, positions, spectate, players } = this.state;
        console.log('WSEvent 5CD: fetchCards5CD', data);
        this.commonSetStateVariables();
        this.get5CDCards();
        let response = await getCurrentState(roomName);
        console.log('APICall 5CD CurrentState(fetchCards5CD): ', response);
        this.setState({
            players: updatePlayersPositions(positions, response.data.players),
            pot: response.data.GameState.pot,
            currentGameName: response.data.GameState.gameNameText,
            diabloOpener: ""
        });
        if(!spectate) {
            let currentStack = players.filter(function(detail) {return detail.clientName === clientName})
            this.setState({
                stack: currentStack[0].stack
            })
        }
    }

    get5CDCards = async () => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action: {
                name : "dealCards",
            }
        };
        let response = await deal5CDCards(body, roomName);
        if(response) {
            console.log('APICall 5CD Cards', response.data);
            this.setState({
                cards: response.data.cards,
                is5CDLive: true
            });
            this.setState({
                sortedList: sortCards(getItems(this.state.cards))
            });
        }
    }

    bettingRoundStarting = (data) => {
        console.log('WSEvent allGames: bettingRoundStarting', data);
    }

    bet = (data) => {

        let { is5CDLive, isTexasLive, is7CSLive, isCincinnatiLive, is727Live, is5CSLive, isBaseballLive, sound } = this.state;
        if(isTexasLive) {
            console.log('WSEvent Texas: bet', data);
        }
        if(is5CDLive) {
            console.log('WSEvent 5CD: bet', data);
        }
        if(is7CSLive) {
            console.log('WSEvent 7CD: bet', data);
        }
        if(is5CSLive) {
            console.log('WSEvent 5CS: bet', data);
        }
        if(isCincinnatiLive) {
            console.log('WSEvent Cincinnati: bet', data);
        }
        if(is727Live) {
            console.log('WSEvent 727: bet', data);
        }
        if(isBaseballLive) {
            console.log('WSEvent Baseball: bet', data, this.state.cards);
        }
        this.setState({
            bettingEnabled: true,
            allowedActions: data.allowedActions,
            maxBet: data.maxBet,
            currentBet: data.bet || 0,
            actionMsg: 'Your Bet',
            isFirstPlayer: data.isFirst || false,
            // stack: data.stack
        });
        if(sound) {
            console.log(this.audio);
            this.audio.play();
        }
    }    

    discardCards = (data) => {
        console.log('WSEvent 5CD: discardCards', data);
        let { cards, clientName, foldedPlayers } = this.state;
        let bool = false;
        for(const card in cards) {
            if(cards[card].charAt(0)==='A') {
                bool = true;
            }
        }
        this.setState({
            selectedCards: [],
            selectedCardsSortedList: [],
            allowKickOut: true,
            currentTurnPlayer: ""
        })
        if(!foldedPlayers.includes(clientName)) {
            this.setState({
                cardSelectionEnabled: true,
                showExchangedCards: true
            })
        }
        if(this.state.currentGameName === "5-Card-Draw" || this.state.currentGameName === "5-Card-Draw Round") {
            if(bool) {
                this.setState({
                    actionMsg: `Choose upto ${data.noOfCardsToDiscard + 1} Cards to Discard`,
                    noOfCardsToDiscard: data.noOfCardsToDiscard + 1
                })
            }
            else {
                this.setState({
                    actionMsg: `Choose upto ${data.noOfCardsToDiscard} Cards to Discard`,
                    noOfCardsToDiscard: data.noOfCardsToDiscard
                })
            }
        }
        if(this.state.currentGameName === "Diablo" || this.state.currentGameName === "Frankenstein") {
            this.setState({
                actionMsg: `Choose upto ${data.noOfCardsToDiscard} Cards to Discard`,
                noOfCardsToDiscard: data.noOfCardsToDiscard
            })
        }
    }

    someoneDiscardedCards = (data) => {
        console.log('WSEvent 5CD: someoneDiscardedCards', data);
        this.setState({
            exchangedCards: data.cardsDiscardedByPlayers
        })
        if(this.state.spectate) {this.setState({showExchangedCards: true})}
    }

    gameOver5CD = async (data) => {
        console.log('WSEvent 5CD: gameOver5CD', data);
        if(data) {
            this.setState({
                winners: data
            })
        }
        this.setState({
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            gameStarted: false,
            is5CDLive: false,
            gameEnds5CD: true,
            actionMsg: '',
            currentTurnPlayer: "",
            cards: [],
            sortedList: [],
            selectedCards: [],
            selectedCardsSortedList: []
        })
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall 5CD CurrentState(gameOver5CD): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
        });
    }

    roundOver5CD = async (data) => {
        let { animatedWinners } = this.state;
        console.log('WSEvent 5CD: roundOver5CD', data);
        this.setState({
            winners: data,
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            actionMsg: '',
            currentTurnPlayer: "",
            selectedCards: [],
            selectedCardsSortedList: [],
            gameEnds5CD: true,
            exchangedCards: {},
            showExchangedCards: false,
            roundDealer: ''
        })
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall 5CD CurrentState(gameOver5CD): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
        });
        if((this.state.currentGameName === "Diablo" || this.state.currentGameName === "Frankenstein") && !this.state.foldedPlayers.includes(this.state.clientName)) {
            this.setState({showDiabloBuy: true})
        }
        if(this.state.currentGameName === "5-Card-Draw") {
            let temp = animatedWinners;
            for(const i in data.potsWinners) {
                temp = [ ...temp, ...data.potsWinners[i].winners ]
            }
            console.log(temp);
            this.setState({animatedWinners: temp});
        }
    }

//357
    fetchCards = async (data) => {
        let { roomName, positions } = this.state;
        console.log('WSEvent 357: fetchCards', data);
        this.commonSetStateVariables();
        this.get357cards();
        let response = await getCurrentState(roomName);
        console.log('APICall 357 CurrentState(fetchCards): ', response);
        if(response.data.GameState.currentGame === "3 Brent 7") {
            this.setState({
                brentLive: true
            })
        }
        this.setState({
            players: updatePlayersPositions(positions, response.data.players),
            pot: response.data.GameState.pot,
            gameName357: 'threefiveseven',
            counterToShowModal: 0,
            selectedIn: false,
            currentGameName: response.data.GameState.gameNameText,
            is357Live: true, readyPressed357: true, loHand3B7: {}
        });
    }

    get357cards = async () => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action: {
                name : "dealCards",
            }
        };
        let response = await dealThreeFiveSevenCards(body, roomName);
        if(response) {
            console.log('APICall 357 Cards', response.data);
            this.setState({
                cards: response.data.cards,
                current357Cards: response.data.cards.length,
                betRoundOver: false
            });
            this.setState({
                sortedList: sortCards(getItems(this.state.cards)),
                allowKickOut: true
            });
        }
    }

    selectInOut = (data) => {
        console.log('WSEvent 357: selectInOut: ', data);
        this.setState({
            selectInOutShow: true,
            actionMsg: 'Select In/Out in 5 seconds',
            selection357: '',
            sideBetLowPress: false
        })
    }

    stopSelectInOutRound = (data) => {
        console.log('WSEvent 357: stopSelectInOutRound: ', data);
        this.setState({
            selectInOutShow: false,
            selectedIn: false
        })
        if(this.state.clientName === this.state.dealer) {
            this.setState({
                actionMsg: `You stopped the counter`
            })
        }
        else {
            this.setState({
                actionMsg: `${this.state.dealer} stopped the counter`
            })
        }
    }

    playersSelectionInOut = (data) => {
        console.log('WSEvent 357: playersSelectionInOut', data);
        this.setState({
            selectInOutShow: false,
        })
    }

    sideBetLowIn = () => {
        let { clientName, roomName, selection357, cards, currentGameName } = this.state;
        if(selection357 === 'Hold' && ((cards.length >= 5 && currentGameName==="357") || (cards.length === 7 && currentGameName==="3 Brent 7"))) {
            let body = {
                clientName : clientName,
                action : {
                    name : 'selectInOut',
                    data: {
                        selection: 'IN',
                        selectionSideBetLow: 'IN'
                    }
                }
            }
            let response = dealThreeFiveSevenCards(body, roomName);
            console.log('APICall 357 sideBetLowInIn', response);
        }
        else {
            let body = {
                clientName : clientName,
                action : {
                    name : 'selectInOut',
                    data: {
                        selection: 'OUT',
                        selectionSideBetLow: 'IN'
                    }
                }
            }
            let response = dealThreeFiveSevenCards(body, roomName);
            console.log('APICall 357 sideBetLowOutIn', response);
        }
        this.setState({
            sideBetLowPress: true
        })
    }

    sideBetLowOut = () => {
        let { clientName, roomName, selection357, cards, currentGameName } = this.state;
        if(selection357 === 'Hold' && ((cards.length >= 5 && currentGameName==="357") || (cards.length === 7 && currentGameName==="3 Brent 7"))) {
            let body = {
                clientName : clientName,
                action : {
                    name : 'selectInOut',
                    data: {
                        selection: 'IN',
                        selectionSideBetLow: 'OUT'
                    }
                }
            }
            let response = dealThreeFiveSevenCards(body, roomName);
            console.log('APICall 357 sideBetLowInOut', response);
        }
        else {
            let body = {
                clientName : clientName,
                action : {
                    name : 'selectInOut',
                    data: {
                        selection: 'OUT',
                        selectionSideBetLow: 'OUT'
                    }
                }
            }
            let response = dealThreeFiveSevenCards(body, roomName);
            console.log('APICall 357 sideBetLowOutOut', response);
        }
        this.setState({
            sideBetLowPress: false
        })
    }

    onInPress = () => {
        let { clientName, roomName, sideBetLowPress, cards } = this.state;
        if(cards.length > 3) {
            if(sideBetLowPress) {
                let body = {
                    clientName : clientName,
                    action : {
                        name : 'selectInOut',
                        data: {
                            selection: 'IN',
                            selectionSideBetLow: 'IN'
                        }
                    }
                }
                let response = dealThreeFiveSevenCards(body, roomName);
                console.log('APICall 357 HoldPressedInIn', response);
            }
            else {
                let body = {
                    clientName : clientName,
                    action : {
                        name : 'selectInOut',
                        data: {
                            selection: 'IN',
                            selectionSideBetLow: 'OUT'
                        }
                    }
                }
                let response = dealThreeFiveSevenCards(body, roomName);
                console.log('APICall 357 HoldPressedInOut', response);
            }
        }
        else {
            let body = {
                clientName : clientName,
                action : {
                    name : 'selectInOut',
                    data: {
                        selection: 'IN',
                    }
                }
            }
            let response = dealThreeFiveSevenCards(body, roomName);
            console.log('APICall 357 HoldPressed', response);
        }
        this.setState({
            actionMsg: 'Selected Hold',
            selectedIn: true,
            selection357: 'Hold'
        });
    }

    onOutPress = () => {
        let { clientName, roomName, sideBetLowPress, cards } = this.state;
        if(cards.length > 3) {
            if(sideBetLowPress) {
                let body = {
                    clientName : clientName,
                    action : {
                        name : 'selectInOut',
                        data: {
                            selection: 'OUT',
                            selectionSideBetLow: 'IN'
                        }
                    }
                }
                let response = dealThreeFiveSevenCards(body, roomName);
                console.log('APICall 357 DropPressedOutIn', response);
            }
            else {
                let body = {
                    clientName : clientName,
                    action : {
                        name : 'selectInOut',
                        data: {
                            selection: 'OUT',
                            selectionSideBetLow: 'OUT'
                        }
                    }
                }
                let response = dealThreeFiveSevenCards(body, roomName);
                console.log('APICall 357 DropPressedOutOut', response);
            }
        }
        else {
            let body = {
                clientName : clientName,
                action : {
                    name : 'selectInOut',
                    data: {
                        selection: 'OUT',
                    }
                }
            }
            let response = dealThreeFiveSevenCards(body, roomName);
            console.log('APICall 357 DropPressed', response);
        }
        this.setState({
            actionMsg: 'Selected Drop',
            inPlayersCards: {},
            selectedIn: false,
            selection357: 'Drop'
        });
    }

    roundOver = async (data) => {
        let { selectedIn, roomName, current357Cards, sideBetLowPress } = this.state;
        console.log('WSEvent 357: roundOver', data, 'Current number of cards', current357Cards);
        if(current357Cards === 7) {
            if(data.eligiblePlayers.length === 1) {
                if(selectedIn) {
                    let response_details = await fetchInPlayersCards(roomName);
                    console.log('APICall 357 fetchInPlayersCards(roundOver)', response_details);
                    this.setState({
                        inPlayersCards: response_details.data,
                    });
                }
                if(!selectedIn) {
                    this.setState({
                        inPlayersCards: {}
                    });
                }
            }
            if(!data.eligiblePlayers.length === 1) {
                let response_details = await fetchInPlayersCards(roomName);
                console.log('APICall 357 fetchInPlayersCards(roundOver)', response_details);
                this.setState({
                    inPlayersCards: response_details.data,
                });
            }
            if(data.eligiblePlayers.length > 1) {
                let response_details = await fetchInPlayersCards(roomName);
                console.log('APICall 357 fetchInPlayersCards(roundOver)', response_details);
                this.setState({
                    inPlayersCards: response_details.data,
                });
            }
        }
        if(current357Cards !== 7) {
            if(selectedIn) {
                let response_details = await fetchInPlayersCards(roomName);
                console.log('APICall 357 fetchInPlayersCards(roundOver)', response_details);
                this.setState({
                    inPlayersCards: response_details.data,
                });
            }
            if(!selectedIn) {
                this.setState({
                    inPlayersCards: {}
                });
            }
        }
        if(sideBetLowPress || current357Cards === 7) {
            let response_details = await fetchInPlayersCards(roomName);
            console.log('APICall 357 fetchInPlayersCards(roundOver), sideBetLowPress', response_details);
            this.setState({
                inPlayersCards: response_details.data,
            });
        }
        if(this.state.spectate) {
            let response_details = await fetchInPlayersCards(roomName);
            console.log('APICall 357 fetchInPlayersCards(roundOver), spectate', response_details);
            if(response_details) {
                this.setState({
                    inPlayersCards: response_details.data,
                });
            }
        }
        let response = await getCurrentState(roomName);
        console.log('APICall 357 CurrentState(roundOver): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
            pot: response.data.GameState.pot,
            roundOver357: true,
            showModal357: true,
            details357: data,
            showDealerCounter: true,
            selectInOutShow: false,
            loHand3B7: data.lowHandDescriptions,
            showPause357: false,
            allowKickOut: false
        });
        setTimeout(() => {
            this.setState({
                afterRoundModalShow: false,
            })
        }, 15000);
    }

    gameOver357 = async (data) => {
        console.log('WSEvent 357: gameOver357', data);
        let { roomName } = this.state;
        let response = await getCurrentState(roomName);
        console.log('APICall 357 CurrentState(gameOver357):', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
            dealer: response.data.dealer,
            gameStarted: false,
            roundOver357: true,
            showModal357: false,
            actionMsg: '',
            gameName357: '',
            cards: [],
            sortedList: [],
            showDealerCounter: false,
            gameEnds357: true,
            winners: data,
            details357: data,
            inPlayersCards: data.cards,
            current357Cards: 0,
            showReady357: false,
            readyPressed357: false,
            is357Live: false,
            brentLive: false
        });
        if(response.data.GameState) {
            this.setState({
                pot: response.data.GameState.pot
            })
        }
    }

    startSelectInOutRound = (data) => {
        console.log('WSEvent 357: startSelectInOutRound', data);
        this.setState({
            startRound357: true,
        });
    }

    onStart357ButtonPress = async () => {
        this.setState({
            startRound357: false,
            showPause357: true
        })
        let { clientName, roomName } = this.state;
        let body = {
            clientName : clientName,
            action: {
                name : "startSelectInOutRound",
            }
        }
        let response = await dealThreeFiveSevenCards(body, roomName);
        console.log('APICall 357 Start Button Pressed', response);
    }

    onPause357ButtonPress = async () => {
        this.setState({
            showPause357: false,
        })
        let { clientName, roomName } = this.state;
        let body = {
            clientName : clientName,
            action: {
                name : "stopSelectInOutRound",
            }
        }
        let response = await pauseSelectionRound(body, roomName);
        console.log('APICall 357 Pause Button Pressed', response);
    }

    selectReady = () => {
        let {is357Live, is5CDLive, isTexasLive, isHomeRunLive, is5CSLive} = this.state;
        if(is357Live) {
            console.log('WSEvent 357: selectReady');
            this.setState({
                showReady: true,
                showReady357: true
            });
        }
        if(is5CDLive || is5CSLive) {
            console.log('WSEvent 5CD/5CS: selectReady');
            this.setState({
                showReady: true,
                showReady357: true,
                myCurrentBet: {}
            });
        }
        if(isTexasLive) {
            console.log('WSEvent Texas: selectReady');
            this.setState({
                showReady: true,
                showReady357: true,
                myCurrentBet: {}
            });
        }
        if(isHomeRunLive) {
            console.log('WSEvent Home-Run: selectReady');
            this.setState({
                showReady: true,
                showReady357: true
            });
        }
    }

    someoneSelectedReady = (data) => {
        console.log('WSEvent 357: someoneSelectedReady', data);
        if(this.state.gameStarted) {
            this.setState({
                readyData357: data,
                myCurrentBet: {},
                showReady357: true
            })
        }
        else {
            console.log('Ready pressed by someone');
            this.setState({
                roomReadyData: data,
                showRoomReady: true,
                allPlayersReady: false,
                dealer: ""
            })
            this.getMyMoneyGain();
        }
    }

    onReadyPress = async () => {
        let {is357Live, is5CDLive, isTexasLive, isHomeRunLive, is5CSLive} = this.state;
        if(is357Live) {
            this.setState({
                showReady: false,
                // selectedIn: false,
                readyPressed357: true
            });
            let { clientName, roomName } = this.state;
            let body = {
                clientName : clientName,
                action: {
                    name : "selectReady",
                }
            }
            let response = await dealThreeFiveSevenCards(body, roomName);
            console.log('APICall 357 Ready Pressed', response);
        }
        if(is5CSLive) {
            this.setState({
                showReady: false,
            });
            let { clientName, roomName } = this.state;
            let body = {
                clientName : clientName,
                action: {
                    name : "selectReady",
                }
            }
            let response = await deal5CSCards(body, roomName);
            console.log('APICall 5CS Ready Pressed', response);
        }
        if(is5CDLive) {
            this.setState({
                showReady: false,
            });
            let { clientName, roomName } = this.state;
            let body = {
                clientName : clientName,
                action: {
                    name : "selectReady",
                }
            }
            let response = await deal5CDCards(body, roomName);
            console.log('APICall 5CD Ready Pressed', response);
        }
        if(isTexasLive) {
            this.setState({
                showReady: false,
            });
            let { clientName, roomName } = this.state;
            let body = {
                clientName : clientName,
                action: {
                    name : "selectReady",
                }
            }
            let response = await dealTexasCards(body, roomName);
            console.log('APICall Texas Ready Pressed', response);
        }
        if(isHomeRunLive) {
            this.setState({
                showReady: false,
            });
            let { clientName, roomName } = this.state;
            let body = {
                clientName : clientName,
                action: {
                    name : "selectReady",
                }
            }
            let response = await dealHomeRunCards(body, roomName);
            console.log('APICall Home-Run Ready Pressed', response);
        }
    }

//ANACONDA
    votingResult = async (data) => {
        console.log('WSEvent votingResult: ', data);
        this.setState({
            voteOutActionEnabled: false
        })
        if(data.result === true) {
            let messagebody = {
                messagetext: 'Kicked Out',
                currentUser: data.playerToVoteOut,
                messageUser: data.playerToVoteOut
            }
            this.setState({
                messages: [ ...this.state.messages, messagebody ],
                foldedPlayers: [ ...this.state.foldedPlayers, data.playerToVoteOut ],
            });
            if(data.playerToVoteOut === this.state.clientName) {this.setState({bettingEnabled: false, actionMsg: ""})}
        }
        else {
            let messagebody = {
                messagetext: 'Not Kicked Out',
                currentUser: data.playerToVoteOut,
                messageUser: data.playerToVoteOut
            }
            this.setState({
                messages: [ ...this.state.messages, messagebody ],
            });
        }

        if(data.playerToVoteOut === this.state.clientName) {
            if(data.action === "kickOutFromRoom" || data.action === "keepOnTable") {
                alert('You have been kicked from the room');
                this.setState({goBack: true})
            }
        }

        let response = await getCurrentState(this.state.roomName);
        console.log('APICall VOTINGRESULT', response);
        this.setState({
            kickedOutPlayers: response.data.kickedOutPlayers.convertToSpectator.concat(response.data.kickedOutPlayers.keepOnTable.concat(response.data.kickedOutPlayers.kickOutFromRoom))
        })

    }

    playersToKickOut = (data) => {
        console.log('WSEvent: playersToKickOut', data);
        if(data.playersToKickOut.length > 0) {
            this.setState({
                voteoutShow: true,
                disconnectedPlayers: data.playersToKickOut.map(d => {
                    let player = {};
                    player["value"] = d;
                    player["label"] = d;
                    return player
                }),
                playersDisconnected: data.disconnectedPlayers
            });
        }
        else {
            this.setState({
                voteoutShow: false,
                disconnectedPlayers: []
            });
        }
        this.setState({
            currentDisconnectedPlayers: data.disconnectedPlayers,
            currentPlayersToKick: data.playersToKickOut
        })
    }

    voteoutSomeone = (data) => {
        console.log('WSEvent: voteoutSomeone', data);
        this.setState({
            voteOutActionEnabled: true,
            playerToVoteOut: data.playerToVoteOut,
            voteOutType: data.postGameOverAction
        })
        let messagebody = {
            messagetext: 'Voting Started to Kick',
            currentUser: data.playerToVoteOut,
            messageUser: data.playerToVoteOut
        }
        this.setState({
            messages: [ ...this.state.messages, messagebody ],
        });
    }

    voteOutOptionPressed = async (option) => {
        console.log('Vote Out option', option);
        let { roomName, voteoutPlayerName } = this.state;
        let body = {
            playerToVoteOut: voteoutPlayerName.value,
            postGameOverAction: option
        }
        let response = await playerVoteOutRequest(body, roomName);
        let messagebody = {
            messagetext: 'Voting Started to Kick',
            currentUser: voteoutPlayerName.value,
            messageUser: voteoutPlayerName.value
        }
        this.setState({
            messages: [ ...this.state.messages, messagebody ],
        });
        this.setState({
            voteoutPlayerName: ''
        })
        console.log('APICall VoteOut First Request', response);
        this.setState({showVoteOutOptions: false})
    }

    playerVoteActionYes = async () => {
        let { roomName } = this.state;
        let body = {
            vote: "YES"
        }
        let response = await playerVoteOutAction(body, roomName);
        console.log('APICall VoteOut Yes', response);
        this.setState({
            voteOutActionEnabled: false
        })
    }

    playerVoteActionNo = async () => {
        let { roomName } = this.state;
        let body = {
            vote: "NO"
        }
        let response = await playerVoteOutAction(body, roomName);
        console.log('APICall VoteOut NO', response);
        this.setState({
            voteOutActionEnabled: false
        })
    }

    someoneTimeout = (data) => {
        if(this.state.clientName === data.clientName) {
            this.setState({
                foldedPlayers: [ ...this.state.foldedPlayers, data.clientName ],
                bettingEnabled: false
            });
        }
        let messagebody = {
            messagetext: 'Timeout',
            currentUser: this.state.clientName,
            messageUser: data.clientName
        }
        this.setState({
            messages: [ ...this.state.messages, messagebody ],
        });
    }

    someoneDisconnected = async (data) => {
        console.log('WSEvent: someoneDisconnected', data);
        let messagebody = {
            messagetext: 'Disconnected',
            currentUser: this.state.clientName,
            messageUser: data.clientName
        }
        this.setState({
            messages: [ ...this.state.messages, messagebody ],
            playersDisconnected: data.disconnectedPlayers,
            connectedPlayers: data.connectedPlayers
        });
    }

    someoneReconnected = async (data) => {
        console.log('WSEvent : somoneReconnected: ', data);
        this.setState({
            connectedPlayers: data.connectedPlayers
        })
    }

    selectWinningType = (data) => {
        console.log("WSEvent Anaconda/727: selectWinningType", data);

        this.setState({
            showWinningSelection: true,
            actionMsg: '',
            currentTurnPlayer: "",
            allowKickOut: true
        });
    };

    cardsPassingRoundStarting = (data) => {
        console.log("WSEvent Anaconda: cardsPassingRoundStarting", data);
        this.setState({
            selectedCards: [],
            selectedCardsSortedList: [],
            cardPassingRoundActive: true,
            cardsPassingStatus: [],
            anacondaPassedCards: [],
            allowKickOut: true,
            currentTurnPlayer: ""
        })
    }

    someonePassedCards = (data) => {
        if(this.state.isAnacondaLive) {
            console.log("WSEvent Anaconda: someonePassedCards", data);

            this.setState({
                cardsPassingStatus: data,
                anacondaPassedCards: anacondaPassingCardStatus(data)
            });
        }
        if(this.state.isCincinnatiLive) {
            if(this.state.spectate) {
                this.setState({showPlayersPassedCin: true})
            }
            console.log("WSEvent Cincinnati: someonePassedCards", data);
            this.setState({
                playersPassedCardsCin: data.players
            })
        }
    };

    cardsPassingRoundOver = (data) => {
        this.setState({allowKickOut: false})
        if(this.state.isAnacondaLive) {
            console.log("WSEvent Anaconda: cardsPassingRoundOver", data);

            this.setState({
                cardPassingRoundActive: false
            });
        }
        if(this.state.isCincinnatiLive) {
            console.log("WSEvent Cincinnati: cardsPassingRoundOver", data);
            this.setState({
                showPlayersPassedCin: false,
                playersPassedCardsCin: []
            })
        }
    };

    currentBettingTurn = (data) => {
        console.log("WSEvent Anaconda: currentBettingTurn", data);

        this.setState({
            currentTurnPlayer: data.clientName,
            allowKickOut: false
        });
        if(this.state.isCincinnatiLive) {
            this.setState({
                showRevealStatus: false
            })
        }
        if(this.state.is727Live) {
            this.setState({
                drawRound727Show: false
            })
        }
        if(this.state.is7CSLive || this.state.isBaseballLive) {
            this.setState({
                drawRoundBaseballShow: false
            })
        }
        if(this.state.currentGameName === "Do Ya") {
            this.setState({
                showDoYaActions: false,
                doYaCards: []
            })
        }
        if(this.state.isBaseballLive) {
            this.setState({
                cardSelectionEnabled: false
            })
        }
        if(data.clientName === this.state.clientName) {
            this.setState({actionMsg: "Your Bet"})
        }
        else {
            this.setState({actionMsg: `${data.clientName}'s Bet`})
        }
    };

    everyonesWinningType = (data) => {
        console.log("WSEvent Anaconda: everyonesWinningType", data);

        this.setState({
            everyonesWinningType: data.playersWinningType
        });
    };

    getGameSettings = () => {
        let settings = {};
        let {gameEndsCincinnati, gamefinished, gameEndsTexas, gameLoFlush, gameLoAce, gameWildCards, gameJokersInDeck, gameroyalFlushBeatsFiveOfKind,
            gamehandsMustHaveOneNaturalCard} = this.state;
        if(gameWildCards.length > 0 || gameJokersInDeck.length > 0) {
            // console.log('ROYAL', gameroyalFlushBeatsFiveOfKind, typeof(gameroyalFlushBeatsFiveOfKind), 'NATURAL', gamehandsMustHaveOneNaturalCard, typeof(gamehandsMustHaveOneNaturalCard), 'TEST', 'ROYAL', Boolean(gameroyalFlushBeatsFiveOfKind), 'NATURAL', Boolean(gamehandsMustHaveOneNaturalCard));
            if(gameEndsCincinnati || gamefinished || gameEndsTexas) {
                settings = {
                    loSettings : {
                        countStraightAndFlushes : Boolean(gameLoFlush),
                        considerAceLow: Boolean(gameLoAce)
                    },
                    wildCardsSettings : {
                        wildCardsSet : new Set(gameWildCards),
                        jokersInDeckSet : new Set(gameJokersInDeck),
                        royalFlushBeatsFiveOfKind : Boolean(gameroyalFlushBeatsFiveOfKind),
                        handsMustHaveOneNaturalCard: Boolean(gamehandsMustHaveOneNaturalCard),
                        payForFaceUpWildCard: 0
                    }
                }
            }
            else {
                settings = {
                    wildCardsSettings : {
                        wildCardsSet : new Set(gameWildCards),
                        jokersInDeckSet : new Set(gameJokersInDeck),
                        royalFlushBeatsFiveOfKind : Boolean(gameroyalFlushBeatsFiveOfKind),
                        handsMustHaveOneNaturalCard: Boolean(gamehandsMustHaveOneNaturalCard),
                        payForFaceUpWildCard: 0
                    }
                }
            }
        }
        else {
            if(gameEndsCincinnati || gamefinished || gameEndsTexas) {
                settings = {
                    loSettings : {
                        countStraightAndFlushes : Boolean(gameLoFlush),
                        considerAceLow: Boolean(gameLoAce)
                    }
                }
            }
        }
        return settings;
    }

    gameOver = async (data) => {
        console.log("WSEvent Anaconda: gameOver", data);

        this.setState({
            cardSelectionEnabled: false,
            winners: data,
            gamefinished: true,
            raisedAmount: 0,
            currentPlayerBets: {},
            betRoundOver: false,
            sidePots: {},
            myCurrentBet: {},
            gameStarted: false,
            isAnacondaLive: false,
            winnerModalShow: true,
            showWinningSelection: false,
            voteoutShow: false,
            allowKickOut: false,
            currentTurnPlayer: ""
        });
        let temp = [];
        let settings = this.getGameSettings();
        for (const i in data.cards) {
            let loSortedCards = sortLoHand(data.cards[i].cards, settings);
            let loHand = getLoHandStringDescription(loSortedCards, settings);
            let hiSortedCards = sortHiHand(data.cards[i].cards, settings);
            let hiHand = getHiHandStringDescription(hiSortedCards, settings);
            console.log('METHOD CALLED');
            let body = {
                clientName: data.cards[i].clientName,
                cards: data.cards[i].cards,
                loSortedCards: loSortedCards,
                loHand: loHand,
                hiSortedCards: hiSortedCards,
                hiHand: hiHand
            }
            temp.push(body);
        }
        this.setState({gameOverAllData: temp})
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall Anaconda CurrentState(gameOverAnaconda): ', response);
        this.setState({
            players: updatePlayersPositions(this.state.positions, response.data.players),
        });
    };

    bettingRoundOver = async (data) => {
        let { isAnacondaLive, is5CDLive, gameEnds5CD, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive } = this.state;
        if(is727Live || isBaseballLive) {
            console.log("WSEvent 727/Baseball: bettingRoundOver", data);
            this.setState({
                pot: data.pots.mainPot.amount,
                potDetails: data.pots,
                betRoundOver: true,
                raisedAmount: 0,
                sidePots: data.pots.sidePots,
                myCurrentBet: {},
                // currentPlayerBets: {}
            });
            setTimeout(() => {
                this.setState({
                    myCurrentBet : {},
                    currentPlayerBets: {}
                })
            }, 500);
        }
        if(isCincinnatiLive) {
            console.log("WSEvent Cincinnati: bettingRoundOver", data);
            this.setState({
                pot: data.pots.mainPot.amount,
                potDetails: data.pots,
                betRoundOver: true,
                raisedAmount: 0,
                sidePots: data.pots.sidePots,
                myCurrentBet: {},
                allPlayerCards7CS: [],
                // currentPlayerBets: {}
            });
            setTimeout(() => {
                this.setState({
                    myCurrentBet : {},
                    currentPlayerBets: {}
                })
            }, 500);
        }
        if(is7CSLive || is5CSLive) {
            console.log("WSEvent 7CS, 5CS: bettingRoundOver", data);
            this.setState({
                pot: data.pots.mainPot.amount,
                potDetails: data.pots,
                betRoundOver: true,
                raisedAmount: 0,
                sidePots: data.pots.sidePots,
                myCurrentBet: {},
            });
            if(is7CSLive || this.state.currentGameName === "5-Card-Stud" || this.state.currentGameName === "5-Card-Stud Round") {this.setState({allPlayerCards7CS: []})}
            setTimeout(() => {
                this.setState({
                    myCurrentBet : {},
                    currentPlayerBets: {}
                })
            }, 500);
        }
        if(isTexasLive) {
            console.log("WSEvent Texas: bettingRoundOver", data);
            this.setState({
                pot: data.pots.mainPot.amount,
                potDetails: data.pots,
                betRoundOver: true,
                raisedAmount: 0,
                sidePots: data.pots.sidePots,
                myCurrentBet: {}
            });
            if(("doesRoundEndedWithoutBetting" in data)) {
                if(data.doesRoundEndedWithoutBetting) {
                    this.setState({rollOnCardsShow: true, rollOnPlayerCards: rollOnPlayerCardsFunction(data.playerCards)})
                }
                else {
                    this.setState({rollOnCardsShow: false, rollOnPlayerCards: []})
                }
            }
            setTimeout(() => {
                this.setState({
                    myCurrentBet : {},
                    currentPlayerBets: {}
                })
            }, 500);
        }
        if(isAnacondaLive) {
            console.log("WSEvent Anaconda: bettingRoundOver", data);
            this.setState({
                pot: data.pots.mainPot.amount,
                potDetails: data.pots,
                betRoundOver: true,
                raisedAmount: 0,
                sidePots: data.pots.sidePots,
                myCurrentBet: {}
            });
            setTimeout(() => {
                this.setState({
                    myCurrentBet : {},
                    currentPlayerBets: {}
                })
            }, 500);
        }
        if(is5CDLive && !gameEnds5CD) {
            console.log("WSEvent 5CD: bettingRoundOver", data);
            this.setState({
                potDetails: data.pots,
                betRoundOver: true,
                raisedAmount: 0,
                sidePots: data.pots.sidePots,
            });
            setTimeout(() => {
                this.setState({
                    myCurrentBet : {},
                    currentPlayerBets: {}
                })
            }, 500);
        }
    };

    fetchAvailableGames = async () => {
        let response_new = await getAllowedGames(this.state.roomName);
        console.log('Availabale Games', response_new);
        let allowedGames = response_new.data;
        let response = await getAvailableGames();
        console.log('Available Games Rules', response);
        let games = response.data;
        if(allowedGames.length === 0) {
            this.setState({
                gameOptions: games.map(g => {
                    let game = {};
                    game["value"] = g.gameName;
                    game["label"] = g.gameName;
                    game["minPlayers"] = g.minPlayers;
                    game["maxPlayers"] = g.maxPlayers;
                    return game;
                }),
                gameConfig: games
            });
        }
        else {
            this.setState({
                gameOptions: allowedGames.map(g => {
                    let game = {};
                    if(g === "Cincinnati") {
                        game["value"] = g;
                        game["label"] = g;
                        game["maxPlayers"] = 7;
                        game["minPlayers"] = 3;
                    }
                    if(g === "HomeRun" || g === "HomeRun Round") {
                        game["value"] = g;
                        game["label"] = g;
                        game["maxPlayers"] = 4;
                        game["minPlayers"] = 4;
                    }
                    if(g === "Baseball-9CD" || g === "Blind Baseball") {
                        game["value"] = g;
                        game["label"] = g;
                        game["maxPlayers"] = 5;
                        game["minPlayers"] = 2;
                    }
                    else {
                        game["value"] = g;
                        game["label"] = g;
                        game["maxPlayers"] = 7;
                        game["minPlayers"] = 2;
                    }
                    return game;
                }),
                gameConfig: games
            })
        }
        console.log("APICall Anaconda games(fetchAvailableGames): ", this.state.gameOptions);
    };

    preStartGame = () => {
        this.setState({
            setGameRules: !this.state.setGameRules
        })
    }

    startGameWildCards = async () => {

        let {selectedGame, localAnteValue, localMaxBetAllowed, localMinBetAllowed, localMaxNoRaises, localPayForFaceUpWildCard, localhandsMustHaveOneNaturalCard, localRoyalFlushBeatsFiveOfKind, restrict5CDOpening, loAce, loFlush, clientName, roomName, selectedWildCardsList, noOfJokers, localSplitHiLo, localStrictSum, localSplitPotIfStrictSumFalse, localTieGoesToLow, local727NoOfPasses, localSimultaneousPassDrawRound, localDoYa, restrictBaseballOpening, defaultBaseballOpening} = this.state;
        let response;
        if(selectedGame.value) {
            if(selectedGame.value === "Ironcross")
            {
                if((localMaxBetAllowed >= 2*localAnteValue || localMaxBetAllowed === 0) && (localMinBetAllowed <= localMaxBetAllowed || localMaxBetAllowed === 0)) {
                    let body;
                    if(noOfJokers > 0 || selectedWildCardsList.length > 0) {
                        body = {
                            clientName: clientName,
                            gameName: selectedGame.value,
                            gamefinished: false,
                            GAME_CONFIG : { 
                                ante: localAnteValue,
                                maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                minRaiseAllowed : localMinBetAllowed,
                                splitHiLo: localSplitHiLo,
                                wildCardsSettings : {
                                    royalFlushBeatsFiveOfKind : localRoyalFlushBeatsFiveOfKind,
                                    handsMustHaveOneNaturalCard : localhandsMustHaveOneNaturalCard,
                                    payForFaceUpWildCard : localPayForFaceUpWildCard,
                                    noOfJokers : noOfJokers,
                                    wildCards : selectedWildCardsList
                                },
                                loDecision : { 
                                    countStraightAndFlushes : loFlush, 
                                    considerAceLow: loAce 
                                }
                            }
                        };
                    }
                    else {
                        body = {
                            clientName: clientName,
                            gameName: selectedGame.value,
                            gamefinished: false,
                            GAME_CONFIG : { 
                                ante: localAnteValue,
                                maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                minRaiseAllowed : localMinBetAllowed,
                                splitHiLo : localSplitHiLo
                            }
                        };
                    }
                    response = await postStartGame(body, roomName);
                    console.log("APICall Any Game started", response);
                    console.log('HOUSE RULES BODY', body);
                }
                else {
                    alert(`Max Bet must be greater-than/equal-to ${2*localAnteValue}(2*Ante)/Min-Bet Value Problem`);
                }
            }
            if(selectedGame.value === "Texas-Holdem" || selectedGame.value === "Texas-Holdem Round" || selectedGame.value === "Omaha" || selectedGame.value === "Omaha Round")
            {
                if((localMaxBetAllowed >= 2*localAnteValue || localMaxBetAllowed === 0) && (localMinBetAllowed <= localMaxBetAllowed || localMaxBetAllowed === 0)) {
                    let body;
                    if(noOfJokers > 0 || selectedWildCardsList.length > 0) {
                        body = {
                            clientName: clientName,
                            gameName: selectedGame.value,
                            gamefinished: false,
                            GAME_CONFIG : { 
                                ante: localAnteValue,
                                maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                minRaiseAllowed : localMinBetAllowed,
                                wildCardsSettings : {
                                    royalFlushBeatsFiveOfKind : localRoyalFlushBeatsFiveOfKind,
                                    handsMustHaveOneNaturalCard : localhandsMustHaveOneNaturalCard,
                                    payForFaceUpWildCard : localPayForFaceUpWildCard,
                                    noOfJokers : noOfJokers,
                                    wildCards : selectedWildCardsList
                                }
                            }
                        };
                    }
                    else {
                        body = {
                            clientName: clientName,
                            gameName: selectedGame.value,
                            gamefinished: false,
                            GAME_CONFIG : { 
                                ante: localAnteValue,
                                maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                minRaiseAllowed : localMinBetAllowed,
                            }
                        };
                    }
                    response = await postStartGame(body, roomName);
                    console.log("APICall Any Game started", response);
                    console.log('HOUSE RULES BODY', body);
                }
                else {
                    alert(`Max Bet must be greater-than/equal-to ${2*localAnteValue}(2*Ante)/Min-Bet Value Problem`);
                }
            }
            if(selectedGame.value === "Omaha8" || selectedGame.value === "Omaha8 Round")
            {
                if((localMaxBetAllowed >= 2*localAnteValue || localMaxBetAllowed === 0) && (localMinBetAllowed <= localMaxBetAllowed || localMaxBetAllowed === 0)) {
                    let body;
                    if(noOfJokers > 0 || selectedWildCardsList.length > 0) {
                        body = {
                            clientName: clientName,
                            gameName: selectedGame.value,
                            gamefinished: false,
                            GAME_CONFIG : { 
                                ante: localAnteValue,
                                maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                minRaiseAllowed : localMinBetAllowed,
                                wildCardsSettings : {
                                    royalFlushBeatsFiveOfKind : localRoyalFlushBeatsFiveOfKind,
                                    handsMustHaveOneNaturalCard : localhandsMustHaveOneNaturalCard,
                                    payForFaceUpWildCard : localPayForFaceUpWildCard,
                                    noOfJokers : noOfJokers,
                                    wildCards : selectedWildCardsList
                                },
                                loDecision : { 
                                    countStraightAndFlushes : loFlush, 
                                    considerAceLow: loAce 
                                }
                            }
                        };
                    }
                    else {
                        body = {
                            clientName: clientName,
                            gameName: selectedGame.value,
                            gamefinished: false,
                            GAME_CONFIG : { 
                                ante: localAnteValue,
                                maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                minRaiseAllowed : localMinBetAllowed,
                                loDecision : { 
                                    countStraightAndFlushes : loFlush, 
                                    considerAceLow: loAce 
                                }
                            }
                        };
                    }
                    response = await postStartGame(body, roomName);
                    console.log("APICall Any Game started", response);
                    console.log('HOUSE RULES BODY', body);
                }
                else {
                    alert(`Max Bet must be greater-than/equal-to ${2*localAnteValue}(2*Ante)/Min-Bet Value Problem`);
                }
            }
            if(selectedGame.value === "Diablo") {
                if(localAnteValue > 0 && localMinBetAllowed > 0) {
                    if((localMinBetAllowed <= localMaxBetAllowed || localMaxBetAllowed === 0)) {
                        let body;
                        if(noOfJokers > 0 || selectedWildCardsList.length > 0) {
                            body = {
                                clientName: clientName,
                                gameName: selectedGame.value,
                                gamefinished: false,
                                GAME_CONFIG : { 
                                    ante: localAnteValue,
                                    maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                    maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                    minRaiseAllowed : localMinBetAllowed,
                                    restrictBetOpening: restrict5CDOpening,
                                    maxPotToReplace: this.state.localmaxPotToReplace,
                                    wildCardsSettings : {
                                        royalFlushBeatsFiveOfKind : localRoyalFlushBeatsFiveOfKind,
                                        handsMustHaveOneNaturalCard : localhandsMustHaveOneNaturalCard,
                                        payForFaceUpWildCard : localPayForFaceUpWildCard,
                                        noOfJokers : noOfJokers,
                                        wildCards : selectedWildCardsList
                                    }
                                }
                            };
                        }
                        else {
                            body = {
                                clientName: clientName,
                                gameName: selectedGame.value,
                                gamefinished: false,
                                GAME_CONFIG : { 
                                    ante: localAnteValue,
                                    maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                    maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                    minRaiseAllowed : localMinBetAllowed,
                                    restrictBetOpening: restrict5CDOpening,
                                    maxPotToReplace: this.state.localmaxPotToReplace
                                }
                            };
                        }
                        response = await postStartGame(body, roomName);
                        console.log("APICall Any Game started", response);
                        console.log('HOUSE RULES BODY', body);
                    }
                    else {
                        alert(`Max Bet must be greater-than/equal-to ${localAnteValue}(Ante)/Min-Bet Value Problem`);
                    }
                }
                else {
                    alert(`Ante and Minimum Bet must be greater than 0`);
                }     
            }
            if(selectedGame.value === "Follow The Queen") {
                if(localAnteValue > 0 && localMinBetAllowed > 0) {
                    if((localMinBetAllowed <= localMaxBetAllowed || localMaxBetAllowed === 0)) {
                        let body;
                        if(noOfJokers > 0 || selectedWildCardsList.length > 0 || localPayForFaceUpWildCard > 0) {
                            body = {
                                clientName: clientName,
                                gameName: selectedGame.value,
                                gamefinished: false,
                                GAME_CONFIG : { 
                                    ante: localAnteValue,
                                    maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                    maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                    minRaiseAllowed : localMinBetAllowed,
                                    restrictBetOpening: restrict5CDOpening,
                                    wildCardsSettings : {
                                        royalFlushBeatsFiveOfKind : localRoyalFlushBeatsFiveOfKind,
                                        handsMustHaveOneNaturalCard : localhandsMustHaveOneNaturalCard,
                                        payForFaceUpWildCard : localPayForFaceUpWildCard,
                                        noOfJokers : noOfJokers,
                                        wildCards : selectedWildCardsList
                                    },
                                    loDecision : { 
                                        countStraightAndFlushes : loFlush, 
                                        considerAceLow: loAce 
                                    }
                                }
                            };
                        }
                        else {
                            body = {
                                clientName: clientName,
                                gameName: selectedGame.value,
                                gamefinished: false,
                                GAME_CONFIG : { 
                                    ante: localAnteValue,
                                    maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                    maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                    minRaiseAllowed : localMinBetAllowed,
                                    restrictBetOpening: restrict5CDOpening,
                                    loDecision : { 
                                        countStraightAndFlushes : loFlush, 
                                        considerAceLow: loAce 
                                    }
                                }
                            };
                        }
                        response = await postStartGame(body, roomName);
                        console.log("APICall Any Game started", response);
                        console.log('HOUSE RULES BODY', body);
                    }
                    else {
                        alert(`Max Bet must be greater-than/equal-to ${localAnteValue}(Ante)/Min-Bet Value Problem`);
                    }  
                }
                else {
                    alert(`Ante and Minimum Bet must be greater than 0`);
                }
            }
            if(selectedGame.value === "Do Ya") {
                    if(localAnteValue > 0 && localMinBetAllowed > 0) {
                        if((localMinBetAllowed <= localMaxBetAllowed || localMaxBetAllowed === 0)) {
                            let body;
                            if(noOfJokers > 0 || selectedWildCardsList.length > 0) {
                                body = {
                                    clientName: clientName,
                                    gameName: selectedGame.value,
                                    gamefinished: false,
                                    GAME_CONFIG : { 
                                        ante: localAnteValue,
                                        maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                        maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                        minRaiseAllowed : localMinBetAllowed,
                                        doYaHiLo: localDoYa,
                                        wildCardsSettings : {
                                            royalFlushBeatsFiveOfKind : localRoyalFlushBeatsFiveOfKind,
                                            handsMustHaveOneNaturalCard : localhandsMustHaveOneNaturalCard,
                                            payForFaceUpWildCard : localPayForFaceUpWildCard,
                                            noOfJokers : noOfJokers,
                                            wildCards : selectedWildCardsList
                                        },
                                        loDecision : { 
                                            countStraightAndFlushes : loFlush, 
                                            considerAceLow: loAce 
                                        }
                                    }
                                };
                            }
                            else {
                                body = {
                                    clientName: clientName,
                                    gameName: selectedGame.value,
                                    gamefinished: false,
                                    GAME_CONFIG : { 
                                        ante: localAnteValue,
                                        maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                        maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                        minRaiseAllowed : localMinBetAllowed,
                                        doYaHiLo: localDoYa,
                                        loDecision : { 
                                            countStraightAndFlushes : loFlush, 
                                            considerAceLow: loAce 
                                        }
                                    }
                                };
                            }
                            response = await postStartGame(body, roomName);
                            console.log("APICall Any Game started", response);
                            console.log('HOUSE RULES BODY', body);
                        }
                        else {
                            alert(`Max Bet must be greater-than/equal-to ${localAnteValue}(Ante)/Min-Bet Value Problem`);
                        }  
                    }
                    else {
                        alert(`Ante and Minimum Bet must be greater than 0`);
                    }
                     
            }
            if(selectedGame.value === "Anaconda" || selectedGame.value === "7-Card-Stud" || selectedGame.value === "Baseball 7CS" ||
                selectedGame.value === "5-Card-Stud" || selectedGame.value === "5-Card-Stud Round" ||
                selectedGame.value === "5-Card-Draw" || selectedGame.value === "Cincinnati" || selectedGame.value === "Frankenstein" || selectedGame.value === "Countdown" ||
                selectedGame.value === "5-Card-Draw Round" || selectedGame.value === "Lo Chicago" || selectedGame.value === "High Chicago") {
                    if(localAnteValue > 0 && localMinBetAllowed > 0) {
                        if((localMinBetAllowed <= localMaxBetAllowed || localMaxBetAllowed === 0)) {
                            let body;
                            if(noOfJokers > 0 || selectedWildCardsList.length > 0) {
                                body = {
                                    clientName: clientName,
                                    gameName: selectedGame.value,
                                    gamefinished: false,
                                    GAME_CONFIG : { 
                                        ante: localAnteValue,
                                        maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                        maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                        minRaiseAllowed : localMinBetAllowed,
                                        restrictBetOpening: restrict5CDOpening,
                                        wildCardsSettings : {
                                            royalFlushBeatsFiveOfKind : localRoyalFlushBeatsFiveOfKind,
                                            handsMustHaveOneNaturalCard : localhandsMustHaveOneNaturalCard,
                                            payForFaceUpWildCard : localPayForFaceUpWildCard,
                                            noOfJokers : noOfJokers,
                                            wildCards : selectedWildCardsList
                                        },
                                        loDecision : { 
                                            countStraightAndFlushes : loFlush, 
                                            considerAceLow: loAce 
                                        }
                                    }
                                };
                            }
                            else {
                                if(selectedGame.value === "Baseball 7CS" || selectedGame.value === "Baseball-9CD" || selectedGame.value === "Blind Baseball" || selectedGame.value === "Countdown") {
                                    body = {
                                        clientName: clientName,
                                        gameName: selectedGame.value,
                                        gamefinished: false,
                                        GAME_CONFIG : { 
                                            ante: localAnteValue,
                                            maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                            maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                            minRaiseAllowed : localMinBetAllowed,
                                            restrictBetOpening: restrict5CDOpening,
                                            wildCardsSettings : {
                                                royalFlushBeatsFiveOfKind : true,
                                                handsMustHaveOneNaturalCard : false,
                                                payForFaceUpWildCard : localPayForFaceUpWildCard,
                                                noOfJokers : 0,
                                                wildCards : []
                                            },
                                            loDecision : { 
                                                countStraightAndFlushes : loFlush, 
                                                considerAceLow: loAce 
                                            }
                                        }
                                    };
                                }
                                else {
                                    body = {
                                        clientName: clientName,
                                        gameName: selectedGame.value,
                                        gamefinished: false,
                                        GAME_CONFIG : { 
                                            ante: localAnteValue,
                                            maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                            maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                            minRaiseAllowed : localMinBetAllowed,
                                            restrictBetOpening: restrict5CDOpening,
                                            loDecision : { 
                                                countStraightAndFlushes : loFlush, 
                                                considerAceLow: loAce 
                                            }
                                        }
                                    };
                                }
                            }
                            response = await postStartGame(body, roomName);
                            console.log("APICall Any Game started", response);
                            console.log('HOUSE RULES BODY', body);
                        }
                        else {
                            alert(`Max Bet must be greater-than/equal-to ${localAnteValue}(Ante)/Min-Bet Value Problem`);
                        }  
                    }
                    else {
                        alert(`Ante and Minimum Bet must be greater than 0`);
                    }
                     
            }
            if(selectedGame.value === "Baseball-9CD" || selectedGame.value === "Blind Baseball") {
                    if(localAnteValue > 0 && localMinBetAllowed > 0) {
                        if((localMinBetAllowed <= localMaxBetAllowed || localMaxBetAllowed === 0)) {
                            let body;
                            if(noOfJokers > 0 || selectedWildCardsList.length > 0) {
                                body = {
                                    clientName: clientName,
                                    gameName: selectedGame.value,
                                    gamefinished: false,
                                    GAME_CONFIG : { 
                                        ante: localAnteValue,
                                        maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                        maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                        minRaiseAllowed : localMinBetAllowed,
                                        restrictBetOpening: restrictBaseballOpening,
                                        restrictBetOpeningRank: restrictBaseballOpening ? defaultBaseballOpening : '',
                                        wildCardsSettings : {
                                            royalFlushBeatsFiveOfKind : localRoyalFlushBeatsFiveOfKind,
                                            handsMustHaveOneNaturalCard : localhandsMustHaveOneNaturalCard,
                                            payForFaceUpWildCard : localPayForFaceUpWildCard,
                                            noOfJokers : noOfJokers,
                                            wildCards : selectedWildCardsList
                                        },
                                        loDecision : { 
                                            countStraightAndFlushes : loFlush, 
                                            considerAceLow: loAce 
                                        }
                                    }
                                };
                            }
                            else {
                                body = {
                                    clientName: clientName,
                                    gameName: selectedGame.value,
                                    gamefinished: false,
                                    GAME_CONFIG : { 
                                        ante: localAnteValue,
                                        maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                        maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                        minRaiseAllowed : localMinBetAllowed,
                                        restrictBetOpening: restrictBaseballOpening,
                                        restrictBetOpeningRank: restrictBaseballOpening ? defaultBaseballOpening : '',
                                        loDecision : { 
                                            countStraightAndFlushes : loFlush, 
                                            considerAceLow: loAce 
                                        }
                                    }
                                };
                            }
                            response = await postStartGame(body, roomName);
                            console.log("APICall Any Game started", response);
                            console.log('HOUSE RULES BODY', body);
                        }
                        else {
                            alert(`Max Bet must be greater-than/equal-to ${localAnteValue}(Ante)/Min-Bet Value Problem`);
                        }  
                    }
                    else {
                        alert(`Ante and Minimum Bet must be greater than 0`);
                    }
                     
            }
            if(selectedGame.value === "727") {
                    if(localAnteValue > 0 && localMinBetAllowed > 0) {
                        if((localMinBetAllowed <= localMaxBetAllowed || localMaxBetAllowed === 0)) {
                            let body;
                            if(noOfJokers > 0 || selectedWildCardsList.length > 0) {
                                body = {
                                    clientName: clientName,
                                    gameName: selectedGame.value,
                                    gamefinished: false,
                                    GAME_CONFIG : { 
                                        ante: localAnteValue,
                                        maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                        maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                        minRaiseAllowed : localMinBetAllowed,
                                        restrictBetOpening: restrict5CDOpening,
                                        strictSum: localStrictSum,
                                        splitPotIfStrictSumFalse: localSplitPotIfStrictSumFalse,
                                        tieGoesToLow: localTieGoesToLow,
                                        simultaneousPassDrawRound: localSimultaneousPassDrawRound,
                                        noOfPasses: local727NoOfPasses,
                                        wildCardsSettings : {
                                            royalFlushBeatsFiveOfKind : localRoyalFlushBeatsFiveOfKind,
                                            handsMustHaveOneNaturalCard : localhandsMustHaveOneNaturalCard,
                                            payForFaceUpWildCard : localPayForFaceUpWildCard,
                                            noOfJokers : noOfJokers,
                                            wildCards : selectedWildCardsList
                                        },
                                        loDecision : { 
                                            countStraightAndFlushes : loFlush, 
                                            considerAceLow: loAce 
                                        }
                                    }
                                };
                            }
                            else {
                                body = {
                                    clientName: clientName,
                                    gameName: selectedGame.value,
                                    gamefinished: false,
                                    GAME_CONFIG : { 
                                        ante: localAnteValue,
                                        maximumNoOfRaisesAllowed : (localMaxNoRaises === 0) ? Number.MAX_SAFE_INTEGER : localMaxNoRaises,
                                        maxRaiseAllowed : (localMaxBetAllowed === 0) ? Number.MAX_SAFE_INTEGER : localMaxBetAllowed,
                                        minRaiseAllowed : localMinBetAllowed,
                                        restrictBetOpening: restrict5CDOpening,
                                        strictSum: localStrictSum,
                                        splitPotIfStrictSumFalse: localSplitPotIfStrictSumFalse,
                                        tieGoesToLow: localTieGoesToLow,
                                        simultaneousPassDrawRound: localSimultaneousPassDrawRound,
                                        noOfPasses: local727NoOfPasses,
                                        loDecision : { 
                                            countStraightAndFlushes : loFlush, 
                                            considerAceLow: loAce 
                                        }
                                    }
                                };
                            }
                            response = await postStartGame(body, roomName);
                            console.log("APICall Any Game started", response);
                            console.log('HOUSE RULES BODY', body);
                        }
                        else {
                            alert(`Max Bet must be greater-than/equal-to ${localAnteValue}(Ante)/Min-Bet Value Problem`);
                        }  
                    }
                    else {
                        alert(`Ante and Minimum Bet must be greater than 0`);
                    }
                     
            }
            if(selectedGame.value === "357" || selectedGame.value === "3-Brent-7" || selectedGame.value === "HomeRun" || selectedGame.value === "HomeRun Round") {
                let body;
                if(noOfJokers > 0 || selectedWildCardsList.length > 0) {
                    body = {
                        clientName: clientName,
                        gameName: selectedGame.value,
                        gamefinished: false,
                        GAME_CONFIG : { 
                            ante: localAnteValue,
                            wildCardsSettings : {
                                royalFlushBeatsFiveOfKind : localRoyalFlushBeatsFiveOfKind,
                                handsMustHaveOneNaturalCard : localhandsMustHaveOneNaturalCard,
                                payForFaceUpWildCard : localPayForFaceUpWildCard,
                                noOfJokers : noOfJokers,
                                wildCards : selectedWildCardsList
                            },
                            loDecision : { 
                                countStraightAndFlushes : loFlush, 
                                considerAceLow: loAce 
                            }
                        }
                    };
                }
                else {
                    body = {
                        clientName: clientName,
                        gameName: selectedGame.value,
                        gamefinished: false,
                        GAME_CONFIG : { 
                            ante: localAnteValue,
                            loDecision : { 
                                countStraightAndFlushes : loFlush, 
                                considerAceLow: loAce 
                            }
                        }
                    };
                }
                response = await postStartGame(body, roomName);
                console.log("APICall Any Game started", response);
                console.log('HOUSE RULES BODY', body);
            }
            if(response) {
                this.setState({
                    roundOver357: false,
                    showModal357: false,
                    gameName357: '',
                    winners: {},
                    gamefinished: false,
                    everyonesWinningType: {},
                    gameEnds357: false,
                    gameEnds5CD: false,
                    gameEndsTexas: false,
                    allCommunityCards: [],
                    gameEnds7CS: false,
                    gameEnds5CS: false,
                    gameEndsCincinnati: false,
                    gameEnds727: false,
                    gameEndsBaseball: false,
                    settingsShow: false,
                    localAnteValue: 5,
                    localMaxNoRaises: 5,
                    localMaxBetAllowed: 100,
                    localMinBetAllowed: 5,
                    houseRulesEnabled: false,
                    gameEndsHomeRun: false,
                    setGameRules: false,
                    wildCardSelection: false,
                    selectedWildCardsList: []
                })
                ReactGA.event({
                    category: 'Game',
                    action: 'Start game',
                    label: 'Game started by dealer',
                    value: 1
                });
                window.ga('send', 'event', {eventAction: 'Start Game', eventCategory: 'Game', eventValue: 1, dimension1: 'xyz' })
            }
            else {
                this.setState({
                    showRemoveButton: true
                })
            }   
        }
        else {
            alert('Select a Game to Play');
            this.setState({
                roundOver357: false,
                showModal357: false,
                gameName357: '',
                winners: {},
                gamefinished: false,
                everyonesWinningType: {},
                gameEnds357: false,
                gameEnds5CD: false,
                gameEndsTexas: false,
                allCommunityCards: [],
                gameEnds7CS: false,
                gameEnds5CS: false,
                gameEndsCincinnati: false,
                gameEnds727: false,
                gameEndsBaseball: false,
                settingsShow: false,
                localAnteValue: 5,
                localMaxNoRaises: 5,
                localMaxBetAllowed: 100,
                localMinBetAllowed: 5,
                houseRulesEnabled: false,
                gameEndsHomeRun: false,
                setGameRules: false
            })
        }
    };

    handleArrangementChange = selectedArrangement => {
        this.setState(
            { selectedArrangement },
            () => console.log(`Option selected:`, this.state.selectedArrangement)
        );
        let { homeRunArrangements } = this.state;
        if(selectedArrangement.value === "T1_FBM") {this.setState({defaultArrangement: homeRunArrangements.T1_FBM.Front.concat(homeRunArrangements.T1_FBM.Middle.concat(homeRunArrangements.T1_FBM.Back))})}
        if(selectedArrangement.value === "T1_FMB") {this.setState({defaultArrangement: homeRunArrangements.T1_FMB.Front.concat(homeRunArrangements.T1_FMB.Middle.concat(homeRunArrangements.T1_FMB.Back))})}
        if(selectedArrangement.value === "T1_MBF") {this.setState({defaultArrangement: homeRunArrangements.T1_MBF.Front.concat(homeRunArrangements.T1_MBF.Middle.concat(homeRunArrangements.T1_MBF.Back))})}
        if(selectedArrangement.value === "T1_MFB") {this.setState({defaultArrangement: homeRunArrangements.T1_MFB.Front.concat(homeRunArrangements.T1_MFB.Middle.concat(homeRunArrangements.T1_MFB.Back))})}
        if(selectedArrangement.value === "T1_BFM") {this.setState({defaultArrangement: homeRunArrangements.T1_BFM.Front.concat(homeRunArrangements.T1_BFM.Middle.concat(homeRunArrangements.T1_BFM.Back))})}
        if(selectedArrangement.value === "T1_BMF") {this.setState({defaultArrangement: homeRunArrangements.T1_BMF.Front.concat(homeRunArrangements.T1_BMF.Middle.concat(homeRunArrangements.T1_BMF.Back))})}
        if(selectedArrangement.value === "T2_SORT_BY_SUIT") {this.setState({defaultArrangement: homeRunArrangements.T2_SORT_BY_SUIT.Front.concat(homeRunArrangements.T2_SORT_BY_SUIT.Middle.concat(homeRunArrangements.T2_SORT_BY_SUIT.Back))})}
        if(selectedArrangement.value === "T3_SORT_BY_CARDS_NO") {this.setState({defaultArrangement: homeRunArrangements.T3_SORT_BY_CARDS_NO.Front.concat(homeRunArrangements.T3_SORT_BY_CARDS_NO.Middle.concat(homeRunArrangements.T3_SORT_BY_CARDS_NO.Back)).reverse()})}
    }

    removeDuplicates = (data) => {
        let temp = data.filter((value, index) => data.indexOf(value) == index);
        this.setState({selectedWildCardsList: temp})
    }

    removeWildCardsItem = (data) => {
        console.log(data, "TO REMOVE");
        let { selectedWildCardsList } = this.state;
        let temp = selectedWildCardsList.filter(function(value){ 
            return value !== data.id;
        });
        this.setState({selectedWildCardsList: temp})
    }

    addWildCards = () => {
        let { selectedWildCardChoice, selectedWildCardBySuit, selectedWildCardByRank, selectedWildCardsByRank, selectedWildCardsBySuit, selectedWildCardsList, addWildCardsBool } = this.state;
        if(selectedWildCardChoice.value === "By Rank" && selectedWildCardsByRank.length > 0 && selectedWildCardsBySuit.length > 0) {
            // let rankList = selectedWildCardByRank.map(({ value }) => value);
            let rankList = selectedWildCardsByRank;
            let suitTemp = selectedWildCardsBySuit;
            let suitList = [];
            if(suitTemp.includes("♠︎")) {suitList.push('s')}
            if(suitTemp.includes("♥︎")) {suitList.push('h')}
            if(suitTemp.includes("♦︎")) {suitList.push('d')}
            if(suitTemp.includes("♣︎")) {suitList.push('c')}
            let temp = [];
            for (const i in rankList) {
                for(const j in suitList) {
                    if(rankList[i] === "10") {temp.push('T'.concat(suitList[j]))}
                    else {temp.push(rankList[i].concat(suitList[j]))}
                }
            }
            console.log('TEMP', temp);
            this.removeDuplicates(selectedWildCardsList.concat(temp));
        }
        if(selectedWildCardChoice.value === "One-Eye Jacks") {
            this.removeDuplicates(selectedWildCardsList.concat(["Js", "Jh"]));
        }
        if(selectedWildCardChoice.value === "King with Axe") {
            this.removeDuplicates(selectedWildCardsList.concat(["Kd"]));
        }
        if(selectedWildCardChoice.value === "Suicide King") {
            this.removeDuplicates(selectedWildCardsList.concat(["Kh"]));
        }
    }

    handleWildCardsChange = selectedWildCardChoice => {
        this.setState(
            { selectedWildCardChoice },
            () => console.log(`Option selected:`, this.state.selectedWildCardChoice)
        );
        if(selectedWildCardChoice.value === "By Rank") {
            this.setState({wildCardSelectedOption: "By Rank"})
        }
        if(selectedWildCardChoice.value === "By Suit") {
            this.setState({wildCardSelectedOption: "By Suit"})
        }
        if(selectedWildCardChoice.value === "One-Eye Jacks") {
            this.setState({wildCardSelectedOption: "One-Eye Jacks"})
        }
        if(selectedWildCardChoice.value === "King with Axe") {
            this.setState({wildCardSelectedOption: "King with Axe"})
        }
        if(selectedWildCardChoice.value === "Suicide King") {
            this.setState({wildCardSelectedOption: "Suicide King"})
        }
    }

    handleWildCardsByRankChange = selectedWildCardByRank => {
        this.setState(
            { selectedWildCardByRank },
            () => console.log(`Option selected:`, this.state.selectedWildCardByRank)
        );
    }

    handleWildCardsBySuitChangeTable = suit => {
        if(this.state.selectedWildCardsBySuit.includes(suit)) {
            let x = this.state.selectedWildCardsBySuit;
            const index = x.indexOf(suit);
            if (index > -1) {
                x.splice(index, 1);
            }
            this.setState({selectedWildCardsBySuit: x})
        }
        else {
            this.state.selectedWildCardsBySuit.push(suit);
            let y = [...new Set(this.state.selectedWildCardsBySuit)]
            this.setState({selectedWildCardsBySuit: y})
        }
    }

    handleWildCardsByRankChangeTable = card => {
        if(this.state.selectedWildCardsByRank.includes(card)) {
            let x = this.state.selectedWildCardsByRank;
            const index = x.indexOf(card);
            if (index > -1) {
                x.splice(index, 1);
            }
            this.setState({selectedWildCardsByRank: x})
        }
        else {
            this.state.selectedWildCardsByRank.push(card);
            let y = [...new Set(this.state.selectedWildCardsByRank)]
            this.setState({selectedWildCardsByRank: y})
        }
    }

    handleWildCardsBySuitChange = selectedWildCardBySuit => {
        this.setState(
            { selectedWildCardBySuit },
            () => console.log(`Option selected:`, this.state.selectedWildCardBySuit)
        );
    }

    handleGameChange = selectedGame => {
        this.setState(
            { startDisable: false ,selectedGame },
            () => console.log(`Option selected:`, this.state.selectedGame)
        );
        let { roomHouseRules } = this.state;
        for (const game in roomHouseRules) {
            if(game === selectedGame.value) {
                console.log('Loop', game, 'Option', selectedGame.value, roomHouseRules[game].ante || 0, 
                roomHouseRules[game].maxRaiseAllowed || 0, roomHouseRules[game].maximumNoOfRaisesAllowed || 0, roomHouseRules[game])
                this.setState({
                    localAnteValue: roomHouseRules[game].ante || 0,
                    localMaxBetAllowed: roomHouseRules[game].maxRaiseAllowed || 0,
                    localMinBetAllowed: roomHouseRules[game].minRaiseAllowed || 0,
                    localMaxNoRaises: roomHouseRules[game].maximumNoOfRaisesAllowed || 0
                })
                if(roomHouseRules[game].loDecision) {
                    this.setState({
                        loAce: roomHouseRules[game].loDecision.considerAceLow,
                        loFlush: roomHouseRules[game].loDecision.countStraightAndFlushes
                    })
                }
                if(roomHouseRules[game].wildCardsSettings) {
                    this.setState({
                        localRoyalFlushBeatsFiveOfKind : roomHouseRules[game].wildCardsSettings.royalFlushBeatsFiveOfKind,
                        localhandsMustHaveOneNaturalCard : roomHouseRules[game].wildCardsSettings.handsMustHaveOneNaturalCard,
                        localPayForFaceUpWildCard : roomHouseRules[game].wildCardsSettings.payForFaceUpWildCard,
                        noOfJokers : roomHouseRules[game].wildCardsSettings.noOfJokers,
                        selectedWildCardsList : roomHouseRules[game].wildCardsSettings.wildCards
                    })
                }
                if(("restrictBetOpening" in roomHouseRules[game])) {
                    this.setState({
                        restrict5CDOpening: roomHouseRules[game].restrictBetOpening,
                        restrictBaseballOpening: roomHouseRules[game].restrictBetOpening
                    })
                }
                if(game === "Frankenstein") {this.setState({restrict5CDOpening: false})}
                if(game === "Follow The Queen") {
                    this.setState({localPayForFaceUpWildCard: 0})
                }
                if(game === "Do Ya") {
                    this.setState({localDoYa: roomHouseRules[game].doYaHiLo})
                }
                if(game === "727") {
                    this.setState({
                        noOfJokers : 0,
                        selectedWildCardsList : [],
                        localSplitPotIfStrictSumFalse: roomHouseRules[game].splitPotIfStrictSumFalse,
                        localStrictSum: roomHouseRules[game].strictSum,
                        localTieGoesToLow: roomHouseRules[game].tieGoesToLow,
                        localSimultaneousPassDrawRound: roomHouseRules[game].simultaneousPassDrawRound,
                        local727NoOfPasses: roomHouseRules[game].noOfPasses,
                    })
                }
                if(game === "Baseball-9CD" || game === "Blind Baseball") {
                    this.removeDuplicates(this.state.selectedWildCardsList.concat(["3s", "3d", "3c", "3h", "9s", "9d", "9c", "9h"]));
                }
                else {
                    this.setState({selectedWildCardsList: []});
                }
            }
        }
    };

    handleVoteChange = voteoutPlayerName => {
        this.setState(
            { voteoutPlayerName },
            () => console.log('Vote Out Player selected', this.state.voteoutPlayerName)
        );
    };

    establishConnection = async () => {
        let response = await getMembersInfo(this.state.roomName);
        console.log("connection established: ", response);
        this.setState({
            members: response.data.clients.length
        });
        let response_new = await getFriends();
        console.log('APICall Friend List', response_new);
        if(response_new) {
            let frequentFriendsTemp = [];
            let lastPlayedTemp = [];
            let x = 1;
            let y = 1;
            for( const i in response_new.data.frequentPlayers ) {
                let body = {
                    id: x,
                    name: response_new.data.frequentPlayers[i],
                    isChecked: false,
                    friendType: 'frequent'
                }
                x = x + 1;
                frequentFriendsTemp.push(body)
            }
            for( const i in response_new.data.lastPlayedWith ) {
                let body = {
                    id: y,
                    name: response_new.data.lastPlayedWith[i],
                    isChecked: false,
                    friendType: 'last'
                }
                y = y + 1;
                lastPlayedTemp.push(body)
            }
            console.log('FREQUESNT', frequentFriendsTemp, 'LAST', lastPlayedTemp);
            this.setState({
                frequentFriends: frequentFriendsTemp,
                lastPlayedWith: lastPlayedTemp
            })
        }
    };

    broadcastMessage = ({clientName, roomName, message}) => {
        let messagebody = {
            messagetext: message,
            currentUser: this.state.clientName,
            messageUser: clientName
        }
        this.setState({
            messages: [ ...this.state.messages, messagebody ],
        });
    };

    someoneJoined = async (data) => {
        console.log('WSEvent someoneJoined: ', data);
        let messagebody = {
            messagetext: 'Joined the Room',
            currentUser: this.state.clientName,
            messageUser: data.clientName
        }
        this.setState({
            members: data.membersInRoom,
            messages: [ ...this.state.messages, messagebody ]
        });
        let response = await getPlayerRejoinTableDetails(data.clientName, data.roomName);
        console.log('APICall Player rejoined details', response);
        this.setState({
            foldedPlayers: response.data.players.filter(function (player) {
            return player.status === 2;
            }).map(item => item.clientName),
            kickedOutPlayers: response.data.kickedOutPlayers.convertToSpectator.concat(response.data.kickedOutPlayers.keepOnTable.concat(response.data.kickedOutPlayers.kickOutFromRoom)),
            playersDisconnected: response.data.disconnectedPlayers
        })
    };

    someoneLeavedTable = async (data) => {
        console.log('WSEvent someoneLeavedTable', data);
        let messagebody = {
            messagetext: 'Left the Room',
            currentUser: this.state.clientName,
            messageUser: data.clientName
        }
        this.setState({
            messages: [ ...this.state.messages, messagebody ],
        });
        let response = await getCurrentState(this.state.roomName);
        console.log('APICall someoneLeavedTable CurrentState: ', response);
        this.setState({
            players: updatePlayersPositions(response.data.positions, response.data.players),
            bots: response.data.bots,
            positions: response.data.positions,
            members: response.data.positions.length,
            dealer: response.data.dealer,
            kickedOutPlayers: response.data.kickedOutPlayers.convertToSpectator.concat(response.data.kickedOutPlayers.keepOnTable.concat(response.data.kickedOutPlayers.kickOutFromRoom)),
            playersDisconnected: response.data.disconnectedPlayers
        });
    };

    tableDetails = async (data) => {
        console.log("WSEvent Anaconda: tableDetails", data);

        let {clientName, roomName} = this.state;

        if(data.isRejoin === true)
        {
            let response = await getPlayerRejoinTableDetails(clientName, roomName);
            console.log('APICall Player rejoined details', response);
            console.log('Player rejoin', data);
            let currentStack = data.players.filter(function(detail) {return detail.clientName === clientName})
            this.setState({
                stack: currentStack[0].stack
            })
            this.setState({
                pot: data.pot,
                dealer: data.dealer,
                positions: [ ...data.positions ],
                members: data.positions.length,
                players: updatePlayersPositions(data.positions, data.players),
                bots: data.bots,
                foldedPlayers: response.data.players.filter(function (player) {
                return player.status === 2;
                }).map(item => item.clientName),
                firstGameStarted: response.data.noOfGamesPlayed > 0 ? true : false,
                spectatorList: getAllSpectators(data.spectators.spectators),
                connectedPlayers: data.connectedPlayers,
                kickedOutPlayers: response.data.kickedOutPlayers.convertToSpectator.concat(response.data.kickedOutPlayers.keepOnTable.concat(response.data.kickedOutPlayers.kickOutFromRoom)),
                maxRebuyRoom: data.roomSettings.MAX_REBUYS
            });

            if(("votingRound" in data)) {
                if(data.votingRound.isVoteRequired) {
                    this.setState({
                        playerToVoteOut: data.votingRound.clientToVoteOut,
                        voteOutActionEnabled: true
                    })
                }
            }

            if("playersReady" in response.data) {
                if(response.data.playersReady.noOfPlayersReady !== response.data.playersReady.playersParticipating.length) {
                    this.setState({
                        showRoomReady: true,
                        allPlayersReady: false,
                        dealer: ""
                    })
                    this.getMyMoneyGain();
                }
                let readyPlayers = [];
                for(const player in response.data.playersReady.playersSelection) {
                    if(response.data.playersReady.playersSelection[player] === "READY") {
                        if(player === clientName) {
                            this.setState({
                                playerReadyRoom: true
                            })
                        }
                        readyPlayers.push(player);
                    }
                } 
                this.setState({
                    roomReadyData: readyPlayers
                })
            }

            this.commonRejoinDetails(response.data);

            if(("GameState" in response.data)) {
                if(("playerCards" in response.data.GameState)) {
                    this.setState({
                        cards: response.data.GameState.playerCards,
                        sortedList: sortCards(getItems(response.data.GameState.playerCards)),
                    })
                    if(response.data.GameState.currentGame === "HomeRun" || response.data.GameState.currentGame === "HomeRun Round") {
                        this.setState({
                            selectedCards: response.data.GameState.playerCards,
                            selectedCardsSortedList: sortCards(getItems(response.data.GameState.playerCards)),
                        })
                    }
                    if(response.data.GameState.currentGame === "Baseball-9CD") {
                        if(("faceUpCardsOfAllPlayers" in response.data.GameState)) {
                            this.setUpCardsBaseball(response.data.GameState.faceUpCardsOfAllPlayers, response);
                            for(const i in response.data.GameState.faceUpCardsOfAllPlayers) {
                                if(i === this.state.clientName) {
                                    this.setState({
                                        downCards7CS: sortCards(getItems(response.data.GameState.playerCards.filter(item => !response.data.GameState.faceUpCardsOfAllPlayers[i].includes(item))))
                                    })
                                }
                            }
                        }
                    }
                    if(response.data.GameState.currentGame === "Blind Baseball") {
                        if(("faceUpCardsOfAllPlayers" in response.data.GameState)) {
                            this.setUpCardsBaseball(response.data.GameState.faceUpCardsOfAllPlayers, response);
                            for(const player in response.data.GameState.playersCardsNoInfo) {
                                if(player === this.state.clientName) {
                                    let mycards = []
                                    for(let i = 0; i < response.data.GameState.playersCardsNoInfo[player].noOfCantSeeCards; i++) {
                                        mycards.push("CB");
                                    }
                                    this.setState({cards: mycards, cantSeeCardsBaseball: sortCards(getItems(mycards))})
                                }
                            }
                        }
                    }
                    if(response.data.GameState.currentGame === "Baseball-9CD" || response.data.GameState.currentGame === "Blind Baseball") {
                        if(("expectedActionsInfo" in response.data)) {
                            if(("allowedActions" in response.data.expectedActionsInfo) && ("allowedPlayers" in response.data.expectedActionsInfo)) {
                                if(response.data.expectedActionsInfo.allowedActions.includes('layCards')) {
                                    if(response.data.expectedActionsInfo.allowedPlayers.includes(this.state.clientName)) {
                                        this.setState({cardSelectionEnabled: true, actionMsg: 'Click cards to lay'})
                                    }
                                    else {
                                        this.setState({actionMsg: `${response.data.expectedActionsInfo.allowedPlayers.join()}'s Action`, cardSelectionEnabled: false})
                                    }
                                }
                                if(response.data.expectedActionsInfo.allowedActions.includes('submitLaidCards')) {
                                    if(response.data.expectedActionsInfo.allowedPlayers.includes(this.state.clientName)) {
                                        this.setState({showSubmitBaseball: true})
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if(("GameState" in response.data)) {
                if(("round" in response.data.GameState)) {
                    if(!("roundInfo" in response.data.GameState.round)) {
                        console.log('Player Folds winning type');
                        this.setState({
                            foldedPlayers: response.data.players.filter(function (player) {
                                return player.status === 2;
                            }).map(item => item.clientName),
                        });
                    }
                }
            }

            if(("GameState" in response.data)) {
                if(("round" in response.data.GameState)) {
                    if (("roundInfo" in response.data.GameState.round) && response.data.GameState.currentGame === "Anaconda") {
                        if(("cardsFetched" in response.data.GameState.round.roundInfo)) {
                            if(response.data.GameState.round.roundInfo.cardsFetched === false) {
                                if(response.data.GameState.playerCards.length === 7) {
                                    let str1 = `Select ${response.data.GameState.round.roundInfo.noOfCardsToPass} `;
                                    let str2 = response.data.GameState.round.roundInfo.noOfCardsToPass === 1 ? 'card' : 'cards';
                                    this.setState({
                                        cardPassingRoundActive: true,
                                        cardSelectionEnabled: true,
                                        noOfCards: response.data.GameState.round.roundInfo.noOfCardsToPass,
                                        selectedCards: [],
                                        selectedCardsSortedList: [],
                                        actionMsg: str1.concat(str2),
                                        cardsPassingStatus: response.data.GameState.round.roundInfo.renderArrows,
                                        anacondaPassedCards: anacondaPassingCardStatus(response.data.GameState.round.roundInfo.renderArrows)
                                    })
                                }
                                if(response.data.GameState.playerCards.length < 7) {
                                    this.setState({
                                        currentCardState: response.data.GameState.playerCards,
                                        noOfCards: response.data.GameState.round.roundInfo.noOfCardsToPass,
                                        cardsPassingStatus: response.data.GameState.round.roundInfo.renderArrows,
                                        anacondaPassedCards: anacondaPassingCardStatus(response.data.GameState.round.roundInfo.renderArrows),
                                        cardPassingRoundActive: true,
                                    });
                                    this.getPassedCards();
                                }
                            }
                        }
                    }
                }
            }
            if(("GameState" in response.data)) {
                if(("round" in response.data.GameState)) {
                    if (("roundInfo" in response.data.GameState.round)) {
                        if(!("cardsFetched" in response.data.GameState.round.roundInfo) &&
                            !("allowedActions" in response.data.GameState.round.roundInfo)) {
                            if(("winningTypeOfPlayer" in response.data.GameState.round.roundInfo)) {
                                this.setState({
                                    showWinningSelection: false,
                                    actionMsg: `Selected winning type is ${response.data.GameState.round.roundInfo.winningTypeOfPlayer}`
                                })
                            }
                            else {
                                if((!("nextPlayerToBet" in response.data.GameState.round.roundInfo) && !("noOfCardsToPass" in response.data.GameState.round.roundInfo)) && (response.data.GameState.currentGame === "Anaconda" || response.data.GameState.currentGame === "727")) {
                                    this.setState({
                                        actionMsg: '',
                                        currentTurnPlayer: ""
                                    });
                                    if(response.data.GameState.round.roundName === 3) {this.setState({showWinningSelection: true})}
                                }
                            }
                        }
                    }
                }
            }
            if(("GameState" in response.data)) {
                if(("currentGame" in response.data.GameState)) {
                    if(response.data.GameState.currentGame === "357") {
                        this.setState({
                            gameName357: "threefiveseven",
                            gameStarted: true
                        });
                    }
                }
            }
            if(("GameState" in response.data)) {
                if(("round" in response.data.GameState)) {
                    if(("roundInfo" in response.data.GameState.round)) {
                        if(("hasDealerStartedRound" in response.data.GameState.round.roundInfo)) {
                            if(response.data.dealer === clientName) {
                                if(response.data.GameState.round.roundInfo.hasDealerStartedRound === false) {
                                    this.setState({
                                        startRound357: true
                                    });
                                }
                            }
                        }
                    }
                }
            }
            if(("GameState" in response.data)) {
                if(("round" in response.data.GameState)) {
                    if(("roundInfo" in response.data.GameState.round)) {
                        if(("hasDealerStartedRound" in response.data.GameState.round.roundInfo)) {
                            if(response.data.GameState.round.roundInfo.hasDealerStartedRound === true) {
                                if(response.data.GameState.round.roundInfo.hasEveryOneSelected === false) {
                                    if(!("selectionOfPlayer" in response.data.GameState.round.roundInfo)) {
                                        this.setState({
                                            selectInOutShow: true
                                        })
                                    }
                                    if(("selectionOfPlayer" in response.data.GameState.round.roundInfo)) {
                                        this.setState({
                                            actionMsg: `Selected ${response.data.GameState.round.roundInfo.selectionOfPlayer}`,
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if(("GameState" in response.data)) {
                if(("round" in response.data.GameState)) {
                    if(("roundName" in response.data.GameState.round)) {
                        if(response.data.GameState.round.roundName === 0) {
                            if(("roundInfo" in response.data.GameState.round)) {
                                if(!("cardsDealToPlayer" in response.data.GameState.round.roundInfo)) {
                                    console.log('Call API for 357 cards');
                                    if(response.data.GameState.currentGame === "Anaconda") {this.getCards()}
                                    if(response.data.GameState.currentGame === "357" || response.data.GameState.currentGame === "3-Brent-7") {this.get357cards()}
                                    if(response.data.GameState.currentGame === "5-Card-Draw" || response.data.GameState.currentGame === "5-Card-Draw Round" || 
                                    response.data.GameState.currentGame === "Diablo" || response.data.GameState.currentGame === "Frankenstein") {this.get5CDCards()}
                                    if(response.data.GameState.currentGame === "Cincinnati") {this.getCincinnatiCards()}
                                    if(response.data.GameState.currentGame === "727") {this.get727Cards()}
                                    if(response.data.GameState.currentGame === "Homerun" || response.data.GameState.currentGame === "Homerun Round") {this.getHomeRunCards()}
                                    if(response.data.GameState.currentGame === "Texas-Holdem" || response.data.GameState.currentGame === "Texas-Holdem Round" ||
                                    response.data.GameState.currentGame === "Omaha" || response.data.GameState.currentGame === "Omaha Round" ||
                                    response.data.GameState.currentGame === "Omaha8" || response.data.GameState.currentGame === "Omaha8 Round" || response.data.GameState.currentGame === "Ironcross") {this.getTexasCards()}
                                    if(response.data.GameState.currentGame === "7-Card-Stud" || response.data.GameState.currentGame === "Lo Chicago" || response.data.GameState.currentGame === "Baseball 7CS" ||
                                    response.data.GameState.currentGame === "High Chicago" || response.data.GameState.currentGame === "Follow The Queen" || response.data.GameState.currentGame === "Countdown") {this.get7CSCards()}
                                    if(response.data.GameState.currentGame === "5-Card-Stud" || response.data.GameState.currentGame === "Do Ya" || response.data.GameState.currentGame === "5-Card-Stud Round") {this.get5CSCards()}
                                }
                            }
                        }
                    }
                }
            }            

            if(("GameState" in response.data)) {
                if(("round" in response.data.GameState)) {
                    if(("roundInfo" in response.data.GameState.round)) {
                        if(("isCardsDiscarded" in response.data.GameState.round.roundInfo)) {
                            if(response.data.GameState.round.roundInfo.isCardsDiscarded === false) {
                                this.setState({
                                    cardSelectionEnabled: true,
                                    selectedCards: [],
                                    selectedCardsSortedList: [],
                                })
                                let { cards } = response.data.GameState.playerCards;
                                let bool = false;
                                for(const card in cards) {
                                    if(cards[card].charAt(0)==='A') {
                                        bool = true;
                                    }
                                }
                                if(response.data.GameState.currentGame === "5-Card-Draw" || response.data.GameState.currentGame === "5-Card-Draw Round") {
                                    if(bool) {
                                        this.setState({
                                            actionMsg: `Choose upto 4 Cards to Discard`,
                                            noOfCardsToDiscard: 4
                                        })
                                    }
                                    else {
                                        this.setState({
                                            actionMsg: `Choose upto 3 Cards to Discard`,
                                            noOfCardsToDiscard: 3
                                        })
                                    }
                                }
                                if(response.data.GameState.currentGame === "Diablo" || response.data.GameState.currentGame === "Frankenstein") {
                                    this.setState({
                                        actionMsg: `Choose upto 3 Cards to Discard`,
                                        noOfCardsToDiscard: 3
                                    })
                                }
                            }
                        }
                        if(("noOfCardsDiscardedByPlayers" in response.data.GameState.round.roundInfo)) {
                            this.setState({
                                exchangedCards: response.data.GameState.round.roundInfo.noOfCardsDiscardedByPlayers,
                                showExchangedCards: true
                            })
                        }
                    }
                }
            }

            if(("GameState" in response.data)) {
                if(("round" in response.data.GameState)) {
                    if (("roundInfo" in response.data.GameState.round) && response.data.GameState.currentGame === "Cincinnati") {
                        if(("cardsFetched" in response.data.GameState.round.roundInfo)) {
                            if(response.data.GameState.round.roundInfo.cardsFetched === false) {
                                if(response.data.GameState.playerCards.length === 7) {
                                    this.setState({
                                        cardSelectionEnabled: true,
                                        selectedCards: [],
                                        selectedCardsSortedList: [],
                                        actionMsg: `Pass 2 cards Left, 1 Right`,
                                        passedTwoCards: false,
                                    })
                                }
                                if(response.data.GameState.playerCards.length < 7) {
                                    this.setState({
                                        currentCardState: response.data.GameState.playerCards,
                                    });
                                    this.getPassedCards();
                                }
                            }
                            this.setState({
                                showPlayersPassedCin: true,
                                playersPassedCardsCin: response.data.GameState.round.roundInfo.whoHasPassedCards
                            })
                        }
                    }
                }
            }

            if(("GameState" in response.data)) {
                if(("round" in response.data.GameState)) {
                    if (("roundInfo" in response.data.GameState.round)) {
                        if(("amountPlayersWantToPlayWith" in response.data.GameState.round.roundInfo)) {
                            let bool = false;
                            for(const i in response.data.GameState.round.roundInfo.amountPlayersWantToPlayWith) {
                                if(i === this.state.clientName) {bool = true}
                            }
                            if(!bool) {
                                this.setState({
                                    showAmountSelection: true,
                                    pencilAmount: response.data.players[response.data.players.findIndex(pl => pl.clientName === this.state.clientName)].stack})
                            }
                        }
                    }
                }
            }

        }

        else{

            this.setState({
                positions: [ ...data.positions ],
                members: data.positions.length,
                players: updatePlayersPositions(data.positions, data.players),
                bots: data.bots,
                pot: data.pot,
                dealer: data.dealer,
                spectatorList: getAllSpectators(data.spectators.spectators),
                firstGameStarted: data.noOfGamesPlayed > 0 ? true : false,
                connectedPlayers: data.connectedPlayers,
                maxRebuyRoom: data.roomSettings.MAX_REBUYS
                // showRoomReady: false
            });
        }

        if(!this.state.spectate) {
            let response_details = await getAllPlayerInfo(roomName);
            console.log('APICALL all player info, TD', response_details);
            this.setState({
                allPlayerInfo: response_details.data,
                autoReady: data.playersSettings[clientName].AUTO_READY
            });
        }
        this.setHouseRules();
        const role = await getCustomClaimRole().catch(err => console.log("getCustomClaimRole", err))
        console.log("Roollll",role)
    };

    setHouseRules = async () => {
        let roomHouseRulesDetails = await getPlayerRejoinTableDetails(this.state.clientName, this.state.roomName);
        console.log('APICall Current Room House Rules', roomHouseRulesDetails);
        let settings = roomHouseRulesDetails.data.gamesSettings;
        console.log('Before change', settings);
        for (const game in settings) {
            if(settings[game].maxRaiseAllowed === Number.MAX_SAFE_INTEGER) {
                settings[game].maxRaiseAllowed = 0;
            }
            if(settings[game].maximumNoOfRaisesAllowed === Number.MAX_SAFE_INTEGER) {
                settings[game].maximumNoOfRaisesAllowed = 0;
            }
        }
        console.log('After change', settings);
        this.setState({
            roomHouseRules: settings
        });
    }

    setUpDownCards = (data) => {
        let { clientName } = this.state;
        if(("faceUpCardsOfAllPlayers" in data.GameState)) {
            let all7CSCards = [];
            let temp_allCards = data.GameState.faceUpCardsOfAllPlayers;
            for (const player in temp_allCards) {
                let body = {
                    clientName: player,
                    cards: temp_allCards[player]
                };
                all7CSCards.push(body);
                if(player === clientName) {
                    let down = data.GameState.playerCards.filter(x => !temp_allCards[player].includes(x));
                    this.setState({
                        upCards7CS: sortCards(getItems(temp_allCards[player])),
                        downCards7CS: sortCards(getItems(down)),
                        sum727cards: calculateCardsSum(data.GameState.playerCards)
                    })
                    if(data.GameState.currentGame === "Do Ya") {
                        this.setState({doYaWildCards: getAllDoYaWildCards(down[0])})
                    }
                }
            }
            this.setState({
                allPlayerCards7CS: all7CSCards,
            })
        }
        if(("faceUpCardsOfAllPlayers" in data.GameState)) {
            let downCards = [];
            let temp = data.GameState.faceUpCardsOfAllPlayers;
            for(const i in temp) {
                let downBody = {
                    clientName: i,
                    cards: 1
                }
                downCards.push(downBody);
            }
            this.setState({downCards727: downCards})
        }
    }

    commonRejoinDetails = (data) => {
        let { clientName } = this.state;
        if(("GameState" in data)) {
            let temp = data.players;
            let bust = [];
            for(const i in temp) {
                if(("isOutOfGame" in temp[i])) {
                    if(temp[i].isOutOfGame) {
                        bust.push(temp[i].clientName)
                    }
                }
            }
            this.setState({bustedPlayers: bust});
            if(("gameNameText" in data.GameState)) {
                this.setState({
                    currentGameName: data.GameState.gameNameText
                });
            }
            if(("pots" in data.GameState)) {
                this.setState({potDetails: data.GameState.pots})
                if(("sidePots" in data.GameState.pots)) {
                    this.setState({betRoundOver: true, sidePots: data.GameState.pots.sidePots})
                }
            }
            if(("currentGame" in data.GameState)) {
                this.setDefaultGameRules(data);
                if(data.GameState.currentGame === "357") {
                    this.setState({
                        is357Live: true,
                        gameName357: "threefiveseven",
                        gameStarted: true
                    });
                }
                if(data.GameState.currentGame === "3-Brent-7") {
                    this.setState({
                        is357Live: true,
                        gameName357: "threefiveseven",
                        gameStarted: true,
                        brentLive: true
                    })
                }
                if(data.GameState.currentGame === "Anaconda") {
                    this.setState({
                        isAnacondaLive: true,
                        gameStarted: true
                    });
                }
                if(data.GameState.currentGame === "5-Card-Draw" || data.GameState.currentGame === "Diablo" || data.GameState.currentGame === "5-Card-Draw Round" || data.GameState.currentGame === "Frankenstein") {
                    this.setState({
                        is5CDLive: true,
                        gameStarted: true
                    });
                }
                if(data.GameState.currentGame === "Texas-Holdem" || data.GameState.currentGame === "Omaha" || data.GameState.currentGame === "Omaha8" || data.GameState.currentGame === "Ironcross" ||
                data.GameState.currentGame === "Texas-Holdem Round" || data.GameState.currentGame === "Omaha Round" || data.GameState.currentGame === "Omaha8 Round") {
                    this.setState({
                        isTexasLive: true,
                        gameStarted: true,
                        allCommunityCards: data.GameState.communityCards
                    })
                }
                if(data.GameState.currentGame === "7-Card-Stud" || data.GameState.currentGame === "Lo Chicago" || 
                    data.GameState.currentGame === "High Chicago" || data.GameState.currentGame === "Follow The Queen" ||
                    data.GameState.currentGame === "Baseball 7CS" || data.GameState.currentGame === "Countdown") {
                    this.setState({
                        is7CSLive: true,
                        gameStarted: true,
                    })
                    this.setUpDownCards(data);
                }
                if(data.GameState.currentGame === "5-Card-Stud" || data.GameState.currentGame === "Do Ya" || data.GameState.currentGame === "5-Card-Stud Round") {
                    this.setState({
                        is5CSLive: true,
                        gameStarted: true,
                    })
                    this.setUpDownCards(data);
                }
                if(data.GameState.currentGame === "Cincinnati") {
                    this.setState({
                        isCincinnatiLive: true,
                        gameStarted: true
                    })
                }
                if(data.GameState.currentGame === "Baseball-9CD" || data.GameState.currentGame === "Blind Baseball") {
                    this.setState({
                        isBaseballLive: true,
                        gameStarted: true
                    })
                }
                if(data.GameState.currentGame === "727") {
                    this.setState({
                        is727Live: true,
                        gameStarted: true
                    })
                    if(("isLastBettingRound" in data.GameState)) {
                        this.setState({
                            lastBettingRound727: data.GameState.isLastBettingRound
                        })
                    }
                    if(("noOfPassesOfPlayers" in data.GameState)) {
                        this.setNoofPasses727(data.GameState.noOfPassesOfPlayers);
                    }
                    this.setUpDownCards(data);
                    if(("round" in data.GameState)) {
                        if(("roundInfo" in data.GameState.round)) {
                            if(("playersWhoHaveDecided" in data.GameState.round.roundInfo)) {
                                this.setState({
                                    drawRound727Show: true
                                })
                                if(!data.GameState.round.roundInfo.playersWhoHaveDecided.includes(clientName)) {
                                    this.setState({
                                        draw727CardsShow: true
                                    })
                                }
                                if(("playersDecision") in data.GameState.round.roundInfo) {
                                    this.setPlayerDecisions(data.GameState.round.roundInfo.playersDecision);
                                }
                                else {
                                    this.setState({draw727RoundDetails: data.GameState.round.roundInfo.playersWhoHaveDecided})
                                }
                                
                            }
                        }
                    }
                }
                if(data.GameState.currentGame === "HomeRun" || data.GameState.currentGame === "HomeRun Round") {
                    this.setState({
                        isHomeRunLive: true,
                        gameStarted: true
                    })
                }
            }
            if(("gameSettings" in data.GameState)) {
                this.setState({
                    gameAnte: data.GameState.gameSettings.ante,
                    gameMaxBet: data.GameState.gameSettings.maxRaiseAllowed,
                    gameMinBet: data.GameState.gameSettings.minRaiseAllowed,
                    gameMaxNoRaises: data.GameState.gameSettings.maximumNoOfRaisesAllowed
                })
                if(("wildCardsSettings" in data.GameState)) {
                    this.setState({
                        gameroyalFlushBeatsFiveOfKind: data.GameState.wildCardsSettings.royalFlushBeatsFiveOfKind,
                        gamehandsMustHaveOneNaturalCard: data.GameState.wildCardsSettings.handsMustHaveOneNaturalCard,
                        gamePayForFaceUpWildCard: data.GameState.wildCardsSettings.payForFaceUpWildCard,
                        gameNoOfJokers: data.GameState.wildCardsSettings.noOfJokers,
                        gameWildCards: data.GameState.wildCardsSettings.wildCards,
                        gameJokersInDeck: data.GameState.wildCardsSettings.jokersInDeck
                    })
                }
                else {
                    this.setState({
                        gameroyalFlushBeatsFiveOfKind: true, gamehandsMustHaveOneNaturalCard: false, gamePayForFaceUpWildCard: 10, gameNoOfJokers: 0, gameWildCards: [], gameJokersInDeck: [] 
                    })
                }
                if(("loDecision" in data.GameState.gameSettings)) {
                    this.setState({
                        gameLoAce: data.GameState.gameSettings.loDecision.considerAceLow,
                        gameLoFlush: data.GameState.gameSettings.loDecision.countStraightAndFlushes
                    })
                }
                if(("restrictBetOpening" in data.GameState.gameSettings)) {this.setState({gameBetOpening: data.GameState.gameSettings.restrictBetOpening})}
                if(("restrictBetOpeningRank" in data.GameState.gameSettings)) {this.setState({gameDefaultBaseballOpening: data.GameState.gameSettings.restrictBetOpeningRank})}
                if(("maxPotToReplace" in data.GameState.gameSettings)) {this.setState({gameMaxPotToReplace: data.GameState.gameSettings.maxPotToReplace})}
                if(data.GameState.currentGame === '727') {
                    this.setState({
                        game727NoOfPasses: data.GameState.gameSettings.noOfPasses,
                        gameSplitPotIfStrictSumFalse: data.GameState.gameSettings.splitPotIfStrictSumFalse,
                        gameStrictSum: data.GameState.gameSettings.strictSum,
                        gameTieGoesToLow: data.GameState.gameSettings.tieGoesToLow,
                        gameSimultaneousPassDrawRound: data.GameState.gameSettings.simultaneousPassDrawRound
                    })
                }
                if(data.GameState.currentGame === "Do Ya") {
                    this.setState({
                        gameDoYa: data.GameState.gameSettings.doYaHiLo
                    })
                }
                if(data.GameState.currentGame === "Ironcross") {
                    this.setState({
                        gameSplitHiLo: data.GameState.gameSettings.splitHiLo
                    })
                }
            }
        }

        if(("GameState" in data)) {
            if(("round" in data.GameState)) {
                if(("roundInfo" in data.GameState.round) && (!("allowedActions" in data.GameState.round.roundInfo) && !("cardsFetched" in data.GameState.round.roundInfo))) {
                    console.log('Player Folds bet/cardpassing');
                    this.setState({
                        foldedPlayers: data.players.filter(function (player) {
                            return player.status === 2;
                        }).map(item => item.clientName),
                    });
                    if(("whoHasSelectedCards" in data.GameState.round.roundInfo)) {
                        this.setState({
                            showRevealStatus: true,
                            playersRevealStatus: data.GameState.round.roundInfo.whoHasSelectedCards
                        })
                    }
                    if(!("cardsSelectedByPlayers" in data.GameState.round.roundInfo)) {
                        if(data.GameState.round.roundName === 3 && data.GameState.currentGame !== "Baseball 7CS") {
                            this.setState({
                                cardSelectionEnabled: true,
                                cardRevealRound: true
                            })
                        }
                    }
                    if(("nextPlayerToBet" in data.GameState.round.roundInfo)) {
                        this.setState({
                            maxBet: data.GameState.round.roundInfo.maxBet,
                            currentTurnPlayer: data.GameState.round.roundInfo.nextPlayerToBet,
                            currentPlayerBets: Object.entries(data.GameState.round.roundInfo.betsMap),
                        })
                    }
                    if(("noOfCardsToPass" in data.GameState.round.roundInfo)) {
                        this.setState({
                            cardPassingRoundActive: true,
                            noOfCards: data.GameState.round.roundInfo.noOfCardsToPass,
                            actionMsg: 'card Passing Round',
                            cardsPassingStatus: data.GameState.round.roundInfo.renderArrows,
                            anacondaPassedCards: anacondaPassingCardStatus(data.GameState.round.roundInfo.renderArrows)
                        })
                    }
                }
            }
        }

        if(("GameState" in data)) {
            if(("round" in data.GameState)) {
                if (("roundInfo" in data.GameState.round)) {
                    if(("nextPlayerToBet" in data.GameState.round.roundInfo)) {
                        if(data.GameState.round.roundInfo.nextPlayerToBet === clientName) {
                            this.setState({
                                bettingEnabled: true,
                                actionMsg: 'Your Bet',
                            });
                            console.log("BET DEBUG");
                            this.audio.play();
                        }
                        else {
                            console.log('DEBUG BET');
                            this.setState({
                                actionMsg: `${data.GameState.round.roundInfo.nextPlayerToBet}'s Bet`
                            })
                        }
                    }
                    if(("allowedActions" in data.GameState.round.roundInfo)) {
                        this.setState({
                            allowedActions: data.GameState.round.roundInfo.allowedActions,
                            maxBet: data.GameState.round.roundInfo.maxBet,
                            currentTurnPlayer: data.GameState.round.roundInfo.nextPlayerToBet,
                            currentPlayerBets: Object.entries(data.GameState.round.roundInfo.betsMap),
                        })
                    }
                    if(("bigBlindPlayerName" in data.GameState.round.roundInfo)) {
                        this.setState({
                            bigBlindPlayer: data.GameState.round.roundInfo.bigBlindPlayerName,
                            smallBlindPlayer: data.GameState.round.roundInfo.smallBlindPlayerName
                        })
                    }
                }
            }
        }

        if(("GameState" in data)) {
            if(("revealedCards" in data.GameState)) {
                let allCards = [];
                for (const player in data.GameState.revealedCards) {
                    let body = {
                        clientName: player,
                        cards: data.GameState.revealedCards[player].cardsRevealed
                    };
                    allCards.push(body);
                }
                this.setState({
                    allPlayerCards7CS: allCards
                })
            }
        }

        if(("GameState" in data)) {
            if(("playerSettings" in data.GameState)) {
                this.setState({
                    submitSettingsButton: false,
                    cardSelectionEnabled: false,
                    frontCards: data.GameState.playerSettings.Front,
                    middleCards: data.GameState.playerSettings.Middle,
                    backCards: data.GameState.playerSettings.Back
                })
            }
            if(("round" in data.GameState)) {
                if(("roundInfo" in data.GameState.round)) {
                    if(("playersSubmitted" in data.GameState.round.roundInfo)) {
                        this.setState({
                            homeRunShowStatus: true,
                            homeRunSubmitStatus: data.GameState.round.roundInfo.playersSubmitted
                        })
                        if(data.GameState.round.roundInfo.playersSubmitted.includes(clientName)) {
                            this.setState({ showRecallButton: true })
                        }
                        if(!data.GameState.round.roundInfo.playersSubmitted.includes(clientName) && !this.state.spectate) {
                            this.setState({
                                submitSettingsButton: true,
                                cardSelectionEnabled: true
                            })
                        }
                        else {
                            this.setState({
                                submitSettingsButton: false,
                                cardSelectionEnabled: false
                            })
                        }
                    }
                }
            }
            if(("settings" in data.GameState)) {
                if(("Front" in data.GameState.settings)) {
                    this.setState({
                        settingsFront: data.GameState.settings.Front
                    })
                }
                if(("Middle" in data.GameState.settings)) {
                    this.setState({
                        settingsMiddle: data.GameState.settings.Middle
                    })
                }
                if(("Back" in data.GameState.settings)) {
                    this.setState({
                        settingsBack: data.GameState.settings.Back
                    })
                }
            }
        }

        if(("GameState" in data)) {
            if(("round" in data.GameState)) {
                if(("roundInfo" in data.GameState.round)) {
                    if(("playersReady" in data.GameState.round.roundInfo)) {
                        this.setState({ 
                            showReady357: true,
                            readyData357: data.GameState.round.roundInfo.playersReady,
                        })
                        if(!(`${clientName}` in data.GameState.round.roundInfo.playersReady) && !this.state.spectate) {
                            this.setState({
                                showReady: true
                            })
                        }
                    }
                    if(("playersFaceUpWildCards" in data.GameState.round.roundInfo)) {
                        for(const i in data.GameState.round.roundInfo.playersFaceUpWildCards) {
                            if(i === clientName) {
                                let bool = true;
                                for(const j in data.GameState.round.roundInfo.playersDecision) {
                                    if(j === clientName) {
                                        bool = false
                                    }
                                }
                                if(bool) {
                                    this.setState({
                                        showPayUpSheet: true,
                                        payUpAmount: data.GameState.wildCardsSettings.payForFaceUpWildCard*data.GameState.round.roundInfo.playersFaceUpWildCards[i]
                                    })
                                }
                            }
                        }
                    }
                    if(("faceUpCardsOnTable" in data.GameState.round.roundInfo)) {
                        this.setState({
                            doYaCards: data.GameState.round.roundInfo.faceUpCardsOnTable
                        })
                    }
                    if(("currentPlayerTurn" in data.GameState.round.roundInfo)) {
                        if(data.GameState.round.roundInfo.currentPlayerTurn === this.state.clientName) {
                            this.setState({actionMsg: "Your turn to choose", showDoYaActions: true})
                        }
                        else {
                            this.setState({actionMsg: `${data.GameState.round.roundInfo.currentPlayerTurn}'s Turn`, showDoYaActions: false})
                        }
                    }
                    if(("players4xCard" in data.GameState.round.roundInfo) && ("playersDecision" in data.GameState.round.roundInfo)) {
                        this.setState({drawRoundBaseballShow: true})
                        this.setPlayerDecisionsBaseball(data.GameState.round.roundInfo.playersDecision);
                    }
                }
            }
        }

        if(("expectedActionsInfo" in data)) {
            if(("allowedActions" in data.expectedActionsInfo) && ("allowedPlayers" in data.expectedActionsInfo)) {
                if(data.expectedActionsInfo.allowedActions.includes('decideFor4xCard')) {
                    this.setState({drawRoundBaseballShow: true})
                    if(data.expectedActionsInfo.allowedPlayers.includes(this.state.clientName)) {
                        this.setState({drawActionsBaseballShow: true})
                    }
                    else {
                        if(data.expectedActionsInfo.allowedPlayers.length > 0)
                            this.setState({actionMsg: `${data.expectedActionsInfo.allowedPlayers.join()}'s Action`, drawActionsBaseballShow: false})   
                    }
                }
            }
        }

        if(this.state.is7CSLive || this.state.is5CSLive) {
            if(this.state.spectate || this.state.foldedPlayers.includes(this.state.clientName)) {
                if(("GameState" in data)) {
                    if(("playersCardsNoInfo" in data.GameState)) {
                        this.setGeneralDownCards(data.GameState.playersCardsNoInfo);
                    }
                }
            }
        }

    }

    roomDetails = (data) => {
        let { clientName } = this.state;
        console.log('ESEvent roomDetails', data);
        this.setState({
            positions: [ ...data.positions ],
            spectate: true,
            members: data.positions.length,
            players: updatePlayersPositions(data.positions, data.players),
            bots: data.bots,
            dealer: data.dealer,
            spectatorList: getAllSpectators(data.spectators.spectators),
            spectatorFirstEventReceived: true,
            actionMsg: "",
            firstGameStarted: data.noOfGamesPlayed > 0 ? true : false
        });
        this.setHouseRules();
        this.commonRejoinDetails(data);
        if("GameState" in data) {
            if("currentGame" in data.GameState) {
                if("round" in data.GameState) {
                    if("roundName" in data.GameState.round) {
                        if(data.GameState.currentGame === "Anaconda" && data.GameState.round.roundName === 3) {
                            this.setState({actionMsg: "Players selecting High/Low/Both"})
                        }
                    }
                }
            }
        }
    }

    gameStarting = (data) => {
        let {clientName} = this.state
        console.log("WSEvent Anaconda: gameStarting", data);
        this.commonSetStateVariables();
        if(data.action === "dealFaceDownCards" && !this.state.spectate) {
            this.getCards(data);
        }
        this.setState({
            players: updatePlayersPositions(this.state.positions, data.players),
            pot: data.pot,
            isAnacondaLive: true
        });
        if(!this.state.spectate) {
            let currentStack = this.state.players.filter(function(detail) {return detail.clientName === clientName})
            this.setState({
                stack: currentStack[0].stack
            })
        }
    };

    doAction = (data) => {
        console.log("WSEvent Anaconda: doAction", data);
        this.setState({
            bettingEnabled: true,
            allowedActions: data.allowedActions,
            maxBet: data.maxBet,
            currentBet: data.bet || 0,
            actionMsg: 'Your Bet',
            isFirstPlayer: data.isFirst || false,
            isAnacondaLive: true
        });
        if(this.state.sound) {
            console.log(this.audio);
            this.audio.play();
        }
    };

    getActionMsg = (data) => {
        let mapping = {
            "call": `bets ${data.action.maxBet}`,
            "raise": data.action.name === "raise" ? `raises ${data.action.data.amount} and bets ${data.action.maxBet}` : "",
            "fold": `folded`,
            "pass": `passed his turn`
        };
        return `${data.clientName} ${mapping[data.action.name]}`
    };

    someoneBetted = (data) => {
        let { isTexasLive } = this.state;
        console.log("WSEvent Anaconda: someoneBetted", data);
        let msg = this.getActionMsg(data);
        let msgDict = {};
        msgDict[data.clientName] = msg;
        this.setState({
            players: updatePlayersPositions(this.state.positions, data.players),
            pot: data.pot,
            gameMsg: this.getActionMsg(data),
            playerMsgDict: msgDict,
            foldedPlayers: data.players.filter(function (player) {
                return player.status === 2;
            }).map(item => item.clientName),
            currentPlayerBets: Object.entries(data.currentBets)
        });
        setTimeout(() => {
            this.setState({
                playerMsgDict: {}
            })
        }, 5000);
        if(isTexasLive) {
            if(("data" in data.action)) {
                if(("type" in data.action.data)) {
                    if(data.action.data.type === "smallBlind") {
                        this.setState({
                            smallBlindPlayer: data.clientName
                        })
                    }
                    if(data.action.data.type === "bigBlind") {
                        this.setState({
                            bigBlindPlayer: data.clientName
                        })
                    }
                }
            }
        }
    };

    selectCards = (data) => {
        console.log("WSEvent Anaconda: selectCards", data);
        let str1 = `Select ${data.noOfCards} `;
        let str2 = data.noOfCards === 1 ? 'card' : 'cards';
        this.setState({
            noOfCards: data.noOfCards,
            selectedCards: [],
            selectedCardsSortedList: [],
            cardPassingRoundActive: true,
            actionMsg: str1.concat(str2),
            cardsPassingStatus: [],
            anacondaPassedCards: []
        })
        if(!this.state.foldedPlayers.includes(this.state.clientName)) {
            this.setState({
                cardSelectionEnabled: true
            })
        }
    };

    getCardsFromNeighbour = (data) => {
        console.log("WSEvent Anaconda: getCardsFromNeighbour", data);
        this.setState({
            noOfCards: data.noOfCards,
        });
        this.getPassedCards();
    };

    updatedLeaveHandler = async (action) => {
        let body = {
            action: action
        };
        let response = await leaveRoom(this.state.roomName, body);
        if(response) {
            if(action === 'kickOutFromRoom' || action === 'keepOnTable') {
                // alert('You Left the room');
                this.setState({leaveAccess: true});
            }
        }
    }

    leaveHandler = async (action) => {
        if(!this.state.gameStarted) {
            let { roomName } = this.state;
            let body = {action: action}
            let response = await leaveRoom(roomName, body);
            console.log('APICall Leave Presses', response);
            if(response) {
                this.setState({
                    leaveAccess: true
                })
            }
        }
        else{
            this.setState({
                leaveAccess: true
            })
        }
    };

    handleChange = (event) => {
        const { target: { name, value } } = event;
        this.setState({ [name]: value })
    };

    getCards = async (data) => {
        let {clientName, roomName} = this.state;
        let body = {
            clientName: clientName,
            action: {
                name : "dealFaceDownCards",
            }
        };
        let response = await dealCards(body, roomName);
        if(response) {
            console.log('APICall Anaconda(getCards)', response);
            this.setState({
                cards: response.data.cards,
            });
            this.setState({
                sortedList: sortCards(getItems(this.state.cards))
            });
        }
    };

    sendMessage = (event) => {
        event.preventDefault();
        let {clientName, roomName, members, message, messages} = this.state;

        if(message) {
            this.state.socketClient.message(clientName, roomName, message);
            let messagebody = {
                messagetext: message,
                currentUser: this.state.clientName,
                messageUser: clientName
            }
            console.log('Message emmited', message);
            this.setState({
                members: members,
                messages: [ ...messages, messagebody ],
                message: ""
            });
        }
    };

    onEnterPress = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.sendMessage(e);
        }
    };

    updateTableState = (data) => {
        let { clientName, players } = this.state;
        let idx = players.findIndex(pl => pl.clientName === clientName);
        if (idx > -1) {
            players[idx].stack = data.playerState.stack;
            players[idx].status = data.playerState.status;
            this.setState({
                players: players,
                stack: data.playerState.stack
            });
        }
        this.setState({
            pot: data.pot,
            bettingEnabled: false
        });
    };

    onPassPress = async () => {
        let { clientName, roomName, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive } = this.state;
        let body = {
            clientName : clientName,
            action : {
                name : 'pass',
            }
        };
        let response;
        this.setState({
            actionMsg: ''
        });
        if(is7CSLive) {
            response = await deal7CSCards(body, roomName);
            console.log('APICall 7CS Pass Pressed', response);
        }
        if(is5CSLive) {
            response = await deal5CSCards(body, roomName);
            console.log('APICall 5CS Pass Pressed', response);
        }
        if(isTexasLive) {
            response = await dealTexasCards(body, roomName);
            console.log('APICall Texas Pass Pressed', response);
        }
        if(isAnacondaLive) {
            response = await bettingAction(body, roomName);
            console.log('APICall Anaconda Pass Pressed', response);
        }
        if(is5CDLive) {
            response = await deal5CDCards(body, roomName);
            console.log('APICall 5CD Pass Pressed', response);
        }
        if(isCincinnatiLive) {
            response = await dealCincinnatiCards(body, roomName);
            console.log('APICall Cincinnati Pass Pressed', response);
        }
        if(is727Live) {
            response = await deal727Cards(body, roomName);
            console.log('APICall 727 Pass Pressed', response);
        }
        if(isBaseballLive) {
            response = await dealBaseballCards(body, roomName);
            console.log('APICall Baseball Pass Pressed', response);
        }
        this.updateTableState(response.data);
        let amountBody = {
            clientName: clientName,
            myAmount: response.data.playerState.amountBettedInRound
        }
        this.setState({
            myCurrentBet: amountBody
        })
    };

    onCallPress = async () => {
        let { clientName, roomName, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive } = this.state;
        let body = {
            clientName : clientName,
            action : {
                name : 'call',
            }
        }
        let response;
        this.setState({
            actionMsg: ''
        });
        if(is7CSLive) {
            response = await deal7CSCards(body, roomName);
            console.log('APICall 7CS Call Pressed', response);
        }
        if(is5CSLive) {
            response = await deal5CSCards(body, roomName);
            console.log('APICall 5CS Call Pressed', response);
        }
        if(isTexasLive) {
            response = await dealTexasCards(body, roomName);
            console.log('APICall Texas Call Pressed', response);
        }
        if(isAnacondaLive) {
            response = await bettingAction(body, roomName);
            console.log('APICall Anaconda Call Pressed', response);
        }
        if(is5CDLive) {
            response = await deal5CDCards(body, roomName);
            console.log('APICall 5CD Call Pressed', response);
        }
        if(isCincinnatiLive) {
            response = await dealCincinnatiCards(body, roomName);
            console.log('APICall Cincinnati Call Pressed', response); 
        }
        if(is727Live) {
            response = await deal727Cards(body, roomName);
            console.log('APICall 727 Call Pressed', response);
        }
        if(isBaseballLive) {
            response = await dealBaseballCards(body, roomName);
            console.log('APICall Baseball Call Pressed', response);
        }
        let amountBody = {
            clientName: clientName,
            myAmount: response.data.playerState.amountBettedInRound
        }
        this.setState({
            myCurrentBet: amountBody
        })
        this.updateTableState(response.data);
    };

    onMaxRaisePress = async () => {
        let { clientName, roomName, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive } = this.state;
        let body = {
            clientName : clientName,
            action : {
                name : 'raise',
                data : {
                    isRaiseMax : true
                }
            }
        }
        let response;
        this.setState({
            actionMsg: ''
        });
        if(is7CSLive) {
            response = await deal7CSCards(body, roomName);
            console.log('APICall 7CS Raise Pressed', response);
        }
        if(is5CSLive) {
            response = await deal5CSCards(body, roomName);
            console.log('APICall 5CS Raise Pressed', response);
        }
        if(isTexasLive) {
            response = await dealTexasCards(body, roomName);
            console.log('APICall Texas Raise Pressed', response);
        }
        if(isAnacondaLive) {
            response = await bettingAction(body, roomName);
            console.log('APICall Anaconda Raise Pressed', response);
        }
        if(is5CDLive) {
            response = await deal5CDCards(body, roomName);
            console.log('APICall 5CD Raise Pressed', response);
        }
        if(isCincinnatiLive) {
            response = await dealCincinnatiCards(body, roomName);
            console.log('APICall Cincinnati Raise Pressed', response);
        }
        if(is727Live) {
            response = await deal727Cards(body, roomName);
            console.log('APICall 727 Raise Pressed', response);
        }
        if(isBaseballLive) {
            response = await dealBaseballCards(body, roomName);
            console.log('APICall Baseball Raise Pressed', response);
        }
        if(response) {
            let amountBody = {
                clientName: clientName,
                myAmount: response.data.playerState.amountBettedInRound
            }
            this.setState({
                myCurrentBet: amountBody,
                raisedAmount: 0
            })
            this.updateTableState(response.data);
        }
    }

    onRaisePress = async (raisedAmount) => {
        let { clientName, roomName, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, gameMaxBet, gameMinBet, is727Live, isBaseballLive } = this.state;
        let body = {
            clientName : clientName,
            action : {
                name : 'raise',
                data : {
                    amount : raisedAmount,
                    isRaiseMax : false
                }
            }
        }
        let response;
        this.setState({
            actionMsg: ''
        });
        if(raisedAmount >= 1 && raisedAmount <= gameMaxBet && raisedAmount >= this.state.gameMinBet) {
            if(is7CSLive) {
                response = await deal7CSCards(body, roomName);
                console.log('APICall 7CS Raise Pressed', response);
            }
            if(is5CSLive) {
                response = await deal5CSCards(body, roomName);
                console.log('APICall 5CS Raise Pressed', response);
            }
            if(isTexasLive) {
                response = await dealTexasCards(body, roomName);
                console.log('APICall Texas Raise Pressed', response);
            }
            if(isAnacondaLive) {
                response = await bettingAction(body, roomName);
                console.log('APICall Anaconda Raise Pressed', response);
            }
            if(is5CDLive) {
                response = await deal5CDCards(body, roomName);
                console.log('APICall 5CD Raise Pressed', response);
            }
            if(isCincinnatiLive) {
                response = await dealCincinnatiCards(body, roomName);
                console.log('APICall Cincinnati Raise Pressed', response);
            }
            if(is727Live) {
                response = await deal727Cards(body, roomName);
                console.log('APICall 727 Raise Pressed', response);
            }
            if(isBaseballLive) {
                response = await dealBaseballCards(body, roomName);
                console.log('APICall Baseball Raise Pressed', response);
            }
            if(response) {
                let amountBody = {
                    clientName: clientName,
                    myAmount: response.data.playerState.amountBettedInRound
                }
                this.setState({
                    myCurrentBet: amountBody,
                    raisedAmount: 0
                })
                this.updateTableState(response.data);
            }
        }
        else if(raisedAmount <= 0) {
            alert('Enter a positive amount to raise');
        }
        else if(raisedAmount > gameMaxBet) {
            alert(`Amount exceeding MaxBet${gameMaxBet}`);
        }
        else if(raisedAmount > 0 && raisedAmount < this.state.gameMinBet) {
            alert(`Raise must be greater than MinBet ${gameMinBet}`);
        }
    };

    onFoldPress = async () => {
        let { clientName, roomName, foldedPlayers, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive } = this.state;
        let body = {
            clientName : clientName,
            action : {
                name : 'fold',
            }
        };
        let response;
        this.setState({
            actionMsg: '',
            foldedPlayers: [ ...foldedPlayers, clientName ],
            showWinningSelection: false,
        });
        if(is7CSLive) {
            response = await deal7CSCards(body, roomName);
            console.log('APICall 7CS Fold Pressed', response);
        }
        if(is5CSLive) {
            response = await deal5CSCards(body, roomName);
            console.log('APICall 5CS Fold Pressed', response);
        }
        if(isTexasLive) {
            response = await dealTexasCards(body, roomName);
            console.log('APICall Texas Fold Pressed', response);
        }
        if(isAnacondaLive) {
            response = await bettingAction(body, roomName);
            console.log('APICall Anaconda Fold Pressed', response);
        }
        if(is5CDLive) {
            response = await deal5CDCards(body, roomName);
            console.log('APICall 5CD Fold Pressed', response);
        }
        if(isCincinnatiLive) {
            response = await dealCincinnatiCards(body, roomName);
            console.log('APICall Cincinnati Fold Pressed', response);
        }
        if(is727Live) {
            response = await deal727Cards(body, roomName);
            console.log('APICall 727 Fold Pressed', response);
        }
        if(isBaseballLive) {
            response = await dealBaseballCards(body, roomName);
            console.log('APICall Baseball Fold Pressed', response);
        }
        this.updateTableState(response.data);
    };

    onSelectCard = async (card) => {
        if (!this.state.cardSelectionEnabled) {
            return;
        }
        let { noOfCards, selectedCards, sortedList, isAnacondaLive, is5CDLive, noOfCardsToDiscard, isCincinnatiLive, cardRevealRound, isHomeRunLive, isBaseballLive, downCards7CS, cantSeeCardsBaseball } = this.state;
        if(isAnacondaLive) {
            if ( selectedCards.length < noOfCards) {
                let tmp = sortedList.map(item => item.id)
                let newcards = tmp.filter(function(c) {
                    return c !== card
                })
                this.setState({
                    currentCardState: newcards,
                    selectedCards: [ ...selectedCards, card ],
                    selectedCardsSortedList: sortCards(getItems([ ...selectedCards, card ])),
                    cards: newcards,
                    sortedList: sortCards(getItems(newcards))
                });
            }
            else {
                alert(`Select only ${noOfCards}`);
            }
        }
        if(is5CDLive) {
            if ( selectedCards.length < noOfCardsToDiscard) {
                let tmp = sortedList.map(item => item.id)
                let newcards = tmp.filter(function(c) {
                    return c !== card
                })
                this.setState({
                    currentCardState: newcards,
                    selectedCards: [ ...selectedCards, card ],
                    selectedCardsSortedList: sortCards(getItems([ ...selectedCards, card ])),
                    cards: newcards,
                    sortedList: sortCards(getItems(newcards))
                });
            }
            else {
                alert(`Select only ${noOfCardsToDiscard}`);
            }
        }
        if(isCincinnatiLive && !cardRevealRound) {
            if ( selectedCards.length < 3) {
                let tmp = sortedList.map(item => item.id)
                let newcards = tmp.filter(function(c) {
                    return c !== card
                })
                this.setState({
                    currentCardState: newcards,
                    selectedCards: [ ...selectedCards, card ],
                    selectedCardsSortedList: sortCards(getItems([ ...selectedCards, card ])),
                    cards: newcards,
                    sortedList: sortCards(getItems(newcards))
                });
            }
            else {
                alert(`Select only 3`);
            }
        }
        if(isCincinnatiLive && cardRevealRound) {
            if ( selectedCards.length < 5) {
                let tmp = sortedList.map(item => item.id)
                let newcards = tmp.filter(function(c) {
                    return c !== card
                })
                this.setState({
                    currentCardState: newcards,
                    selectedCards: [ ...selectedCards, card ],
                    selectedCardsSortedList: sortCards(getItems([ ...selectedCards, card ])),
                    cards: newcards,
                    sortedList: sortCards(getItems(newcards))
                });
            }
            else {
                alert(`Select only 5`);
            }
        }
        if(isBaseballLive && this.state.currentGameName === "Baseball-9CD") {
            let tmp = downCards7CS.map(item => item.id)
            let newcards = tmp.filter(function(c) {
                return c !== card
            })
            this.setState({cardSelectionEnabled: false})
            let temp = [];
            temp.push(card);
            let body = {
                clientName: this.state.clientName,
                action : {
                    name: 'layCards',
                    data: {
                        cards: temp
                    }
                }
            };
            let response = await dealBaseballCards(body, this.state.roomName);
            console.log('APICall Baseball: layCards (sendCards)', response);
            if(response) {
                if (response.status === "success") {
                    this.setState({
                        selectedCardsSortedList: [],
                        selectedCards: [],
                        currentCardState: newcards,
                        // selectedCards: [ ...selectedCards, card ],
                        // selectedCardsSortedList: sortCards(getItems([ ...selectedCards, card ])),
                        cards: newcards,
                        downCards7CS: sortCards(getItems(newcards)),
                        cardSelectionEnabled: true
                    })
                }
                if(("data" in response)) {
                    if(("canSubmitCardsNow" in response.data)) {this.setState({showSubmitBaseball: response.data.canSubmitCardsNow})}
                }
            }
            this.setState({cardSelectionEnabled: true})
        }
        if(isBaseballLive && this.state.currentGameName === "Blind Baseball") {
            let temp = [];
            for(let i = 0; i < cantSeeCardsBaseball.length - 1; i++) {
                temp.push("CB");
            }
            this.setState({cardSelectionEnabled: false})
            let body = {
                clientName: this.state.clientName,
                action : {
                    name: 'layCards',
                }
            };
            let response = await dealBaseballCards(body, this.state.roomName);
            console.log('APICall Blind Baseball: layCards (sendCards)', response);
            if(response) {
                if (response.status === "success") {
                    this.setState({
                        selectedCardsSortedList: [],
                        selectedCards: [],
                        // selectedCards: [ ...selectedCards, card ],
                        // selectedCardsSortedList: sortCards(getItems([ ...selectedCards, card ])),
                        cards: temp,
                        cantSeeCardsBaseball: sortCards(getItems(temp)),
                        cardSelectionEnabled: true
                    })
                }
                if(("data" in response)) {
                    if(("canSubmitCardsNow" in response.data)) {this.setState({showSubmitBaseball: response.data.canSubmitCardsNow})}
                }
            }
            this.setState({cardSelectionEnabled: true})
        }
    };

    revertSelectedCard = (card) => {
        let { selectedCards, cards, currentGameName } = this.state;
        if(this.state.isBaseballLive && currentGameName === "Blind Baseball") {
            this.setState({
                cantSeeCardsBaseball: sortCards(getItems([ ...cards, card ]))
            })
            let myCards = [];
            for(let i = 0; i < selectedCards.length - 1; i++) {
                myCards.push("CB");
            }
            this.setState({
                selectedCards: myCards,
                selectedCardsSortedList: sortCards(getItems(myCards)),
                cards: [ ...cards, card ],
                sortedList: sortCards(getItems([ ...cards, card ]))
            })
        }
        else {
            let newcards = selectedCards.filter(function(c) {
                return c !== card
            })
            this.setState({
                selectedCards: newcards,
                selectedCardsSortedList: sortCards(getItems(newcards)),
                cards: [ ...cards, card ],
                sortedList: sortCards(getItems([ ...cards, card ]))
            })
            if(this.state.isBaseballLive && currentGameName === "Baseball-9CD") {
                this.setState({
                    downCards7CS: sortCards(getItems([ ...cards, card ]))
                })
            }
        }
    };

    sendTwoCards = () => {
        if(this.state.selectedCards.length === 2) {
            this.setState({
                leftCards: this.state.selectedCards,
                selectedCards: [],
                selectedCardsSortedList: [],
                passedTwoCards: true
            })
        }
        else{
            alert(`Select 2 cards to pass left`);
        }
    }

    submitBaseballCards = async () => {
        let body = {
            clientName: this.state.clientName,
            action : {
                name: 'submitLaidCards'
            }
        };
        let response = await dealBaseballCards(body, this.state.roomName);
        console.log('APICall Baseball: layCards (submitBaseballCards)', response);
        if(response) {
            if (response.status === "success") {
                this.setState({
                    selectedCardsSortedList: [],
                    selectedCards: [],
                    cardSelectionEnabled: false,
                    showSubmitBaseball: false
                })
            }
        }
    }

    sendCards = async () => {
        let { clientName, roomName, selectedCards, noOfCards, isAnacondaLive, is5CDLive, noOfCardsToDiscard, isCincinnatiLive,
             cardRevealRound, selectedCardsSortedList, isHomeRunLive, isBaseballLive, currentGameName } = this.state;
        if(isAnacondaLive) {
            if(selectedCards.length !== noOfCards)
            {
                alert(`Select ${noOfCards} cards to pass`);
            }
            else {
                let body = {
                    clientName: clientName,
                    action : {
                        name: 'passCards',
                        data: {
                            cards: selectedCards
                        }
                    }
                };
                this.setState({
                    selectedCards: [],
                    selectedCardsSortedList: [],
                    cardSelectionEnabled: false,
                    actionMsg: '',
                    currentPlayerBets: {},
                    myCurrentBet: {}
                });
                let response = await bettingAction(body, roomName);
                console.log('APICall Anaconda: Cards sent to the next player(sendCards)');
                if (response.data) {
                    this.setState({
                        cards: response.data.cards,
                        sortedList: sortCards(getItems(response.data.cards))
                    })
                }
            }
        }
        if(isBaseballLive && currentGameName === "Baseball-9CD") {
            if(selectedCards.length > 1) {
                alert('Select only one card');
            }
            else {
                let body = {
                    clientName: clientName,
                    action : {
                        name: 'layCards',
                        data: {
                            cards: selectedCards
                        }
                    }
                };
                let response = await dealBaseballCards(body, roomName);
                console.log('APICall Baseball: layCards (sendCards)', response);
                if(response) {
                    if (response.status === "success") {
                        this.setState({
                            selectedCardsSortedList: [],
                            selectedCards: [],
                        })
                    }
                    if(("data" in response)) {
                        if(("canSubmitCardsNow" in response.data)) {this.setState({showSubmitBaseball: response.data.canSubmitCardsNow})}
                    }
                }
            }
        }
        if(isBaseballLive && currentGameName === "Blind Baseball") {
            var arr = [];
            while(arr.length < selectedCards.length){
                var r = Math.floor(Math.random() * 9);
                if(arr.indexOf(r) === -1) arr.push(r);
            }
            let body = {
                clientName: clientName,
                action : {
                    name: 'layCards',
                    data: {
                        randomCardNos: arr
                    }
                }
            };
            let response = await dealBaseballCards(body, roomName);
            console.log('APICall Blind Baseball: layCards (sendCards)', response);
            if(response) {
                if (response.status === "success") {
                    this.setState({
                        selectedCardsSortedList: [],
                        selectedCards: [],
                    })
                }
                if(("data" in response)) {
                    if(("canSubmitCardsNow" in response.data)) {this.setState({showSubmitBaseball: response.data.canSubmitCardsNow})}
                }
            }
        }
        if(is5CDLive) {
            if(selectedCards.length > noOfCardsToDiscard)
            {
                alert(`Select a maximum of ${noOfCardsToDiscard} cards to pass`);
            }
            else {
                let body = {
                    clientName: clientName,
                    action : {
                        name: 'discardCards',
                        data: {
                            cardsToDiscard: selectedCards
                        }
                    }
                };
                this.setState({
                    selectedCards: [],
                    selectedCardsSortedList: [],
                    cardSelectionEnabled: false,
                    actionMsg: '',
                    currentPlayerBets: {},
                    myCurrentBet: {}
                });
                let response = await deal5CDCards(body, roomName);
                console.log('APICall 5CD: Cards discarded(sendCards)', response);
                if (response.data) {
                    this.setState({
                        cards: response.data.cards,
                        sortedList: sortCards(getItems(response.data.cards))
                    })
                }
            }
        }
        if(isCincinnatiLive && !cardRevealRound) {
            if(selectedCards.length !== 3)
            {
                alert(`Select 3 cards to pass right`);
            }
            else {
                let finalOrder = []
                for (const i of selectedCardsSortedList) {
                    finalOrder.push(i.id);
                }
                let lCards = [finalOrder[0], finalOrder[1]];
                let rCards = [finalOrder[2]];
                let body = {
                    clientName: clientName,
                    action : {
                        name: 'passCards',
                        data: {
                            cardsToPassedToLeft: lCards,
                            cardsToPassedToRight: rCards
                        }
                    }
                };
                console.log('Cincinnati Send Cards', body);
                this.setState({
                    selectedCards: [],
                    selectedCardsSortedList: [],
                    cardSelectionEnabled: false,
                    actionMsg: '',
                    currentPlayerBets: {},
                    myCurrentBet: {}
                });
                let response = await dealCincinnatiCards(body, roomName);
                console.log('APICall Cincinnati: Cards Passed(sendCards)', response);
                if (response.data) {
                    this.setState({
                        cards: response.data.cards,
                        sortedList: sortCards(getItems(response.data.cards))
                    })
                    if(("cardsPassedByLeftNeighbour" in response.data)) {
                        this.setState({cardsFromLeft: response.data.cardsPassedByLeftNeighbour})
                    }
                    if(("cardsPassedByRightNeighbour" in response.data)) {
                        this.setState({cardsFromRight: response.data.cardsPassedByRightNeighbour})
                    }
                }
            }
        }
        if(isCincinnatiLive && cardRevealRound) {
            if(selectedCards.length !== 5)
            {
                alert(`Select 5 cards to reveal`);
            }
            else {
                console.log('Cards', selectedCards, 'Sorted Cards', selectedCardsSortedList);
                let cardsToReveal = [];
                for (const index in selectedCardsSortedList){
                    cardsToReveal.push(selectedCardsSortedList[index].id);
                }
                console.log('Cards To Reveal', cardsToReveal);
                let body = {
                    clientName: clientName,
                    action : {
                        name: 'cardsToReveal',
                        data: {
                            cards: cardsToReveal
                        }
                    }
                };
                this.setState({
                    selectedCards: [],
                    selectedCardsSortedList: [],
                    cardSelectionEnabled: false,
                    actionMsg: '',
                    currentPlayerBets: {},
                    myCurrentBet: {},
                    cardRevealRound: false
                });
                let response = await dealCincinnatiCards(body, roomName);
                console.log('APICall Cincinnati: Cards Revealed(sendCards)', response);
                if (response.data) {
                    this.setState({
                        cards: response.data,
                        sortedList: sortCards(getItems(response.data))
                    })
                }
            }
        }
        if(isHomeRunLive) {
            if(selectedCards.length === 13) {
                let submitCards = selectedCardsSortedList.map(item => item.id);
                let body = {
                    clientName: clientName,
                    action : {
                        name: 'submitSettings',
                        data: {
                            settings: {
                                Front: [submitCards[0], submitCards[1], submitCards[2]],
                                Back: [submitCards[8], submitCards[9], submitCards[10], submitCards[11], submitCards[12]],
                                Middle: [submitCards[3], submitCards[4], submitCards[5], submitCards[6], submitCards[7],],
                            } 
                        }
                    }
                };
                let response = await dealHomeRunCards(body, roomName);
                console.log('APICall HomeRun: Settings Submitted(sendCards)', response, 'BODY', body);
                if(response) {
                    this.setState({
                        cardSelectionEnabled: false,
                        frontCards: body.action.data.settings.Front,
                        middleCards: body.action.data.settings.Middle,
                        backCards: body.action.data.settings.Back,
                        submitSettingsButton: false
                    });
                    if(!this.state.submitSettingRoundOverReceived) {this.setState({showRecallButton: true})}
                }
            }
            else {
                alert(`Select 13 cards`);
            }
        }
    };

    onRecallPress = async () => {
        let body = {};
        let response = await reSubmitSettings(body, this.state.roomName);
        console.log('APICall onrecallpress', response);
        if(response) {
            this.setState({
                showRecallButton: false,
                submitSettingsButton: true,
                cardSelectionEnabled: true
            })
        }
    }

    getPassedCards = async () => {
        let { clientName, roomName, currentCardState, isAnacondaLive, isCincinnatiLive, sortedList } = this.state;
        let body = {
            clientName: clientName,
            action: {
                name: 'fetchPassedCards'
            }
        };
        if(isAnacondaLive) {
            let tmp = sortedList.map(item => item.id);
            let response = await bettingAction(body, roomName);
            console.log('APICall Anaconda getPassedCards', response, 'CurrentCards', this.state.sortedList);
            if(response) {
                let newOrder = response.data.cards.filter( el => !currentCardState.includes(el) );
                let finalcards = tmp.concat(newOrder);
                this.setState({
                    cards: finalcards,
                    sortedList: sortCards(getItems(finalcards))
                });
            }
        }
        if(isCincinnatiLive) {
            let tmp = sortedList.map(item => item.id);
            console.log('Old Order', tmp);
            let response = await dealCincinnatiCards(body, roomName);
            console.log('APICall Cincinnati getPassedCards', response);
            if(response) {
                let newOrder = response.data.cards.filter( el => !tmp.includes(el) );
                let finalcards = tmp.concat(newOrder);
                this.setState({
                    cards: finalcards,
                    sortedList: sortCards(getItems(finalcards))
                });
                if(("cardsPassedByLeftNeighbour" in response.data)) {
                    this.setState({cardsFromLeft: response.data.cardsPassedByLeftNeighbour})
                }
                if(("cardsPassedByRightNeighbour" in response.data)) {
                    this.setState({cardsFromRight: response.data.cardsPassedByRightNeighbour})
                }
            }
        }
    };

    selectedWinningType = (type) => {
        this.setState({
            winningTypeAnaconda: type,
            actionMsg: `Selected winning type is ${type === 'Mixed' ? 'Both' : type}`
        });
    }

    submitWinningType = async (type) => {

        let { isAnacondaLive, is727Live } = this.state;

        if(type) {
            let { clientName, roomName } = this.state;
            let body = {
                clientName : clientName,
                action : {
                    name : "selectWinningType",
                    data : {
                        winningType : type
                    }
                }
            };
            let response;
            if(isAnacondaLive) {
                response = await bettingAction(body, roomName);
            }
            if(is727Live) {
                response = await deal727Cards(body, roomName);
            }
            console.log('APICall Anaconda/727 submitWinningType', response);
            this.setState({
                showWinningSelection: false,
                winningTypeAnaconda: '',
            })
            if(isAnacondaLive) {
                this.setState({actionMsg: `Selected winning type is ${type === 'Mixed' ? 'Both' : type}`})
            }
            if(is727Live) {
                let option = '';
                if(type === 'Mixed') option = 'Both'
                if(type === 'High') option = '27'
                if(type === 'Low') option = '7'
                this.setState({actionMsg: `Selected winning type is ${option}`})
            }
        }
        else{
            alert(`Select Winning Type`);
        }
    };

    sortList = () => {
        this.setState({
            ...this.state,
            sortedList: sortCards(this.state.sortedList)
        })
    };

    sortNewList = () => {
        this.setState({
            ...this.state,
            selectedCardsSortedList: sortCards(this.state.selectedCardsSortedList)
        })
    };

    sortUpCards = () => {
        this.setState({
            ...this.state,
            upCards7CS: sortCards(this.state.upCards7CS)
        })
    };

    sortDownCards = () => {
        this.setState({
            ...this.state,
            downCards7CS: sortCards(this.state.downCards7CS)
        })
    };

    reorderList = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        const list = this.state.sortedList;
        if (destinationIndex === 0) {
            list[sourceIndex].order = list[0].order - 1;
            this.sortList();
            return;
        }
        if (destinationIndex === list.length - 1) {
            list[sourceIndex].order = list[list.length - 1].order + 1;
            this.sortList();
            return;
        }
        if (destinationIndex < sourceIndex) {
            list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
            this.sortList();
            return;
        }
        list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
        this.sortList();
    };

    reorderNewList = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        const list = this.state.selectedCardsSortedList;
        if (destinationIndex === 0) {
            list[sourceIndex].order = list[0].order - 1;
            this.sortNewList();
            return;
        }
        if (destinationIndex === list.length - 1) {
            list[sourceIndex].order = list[list.length - 1].order + 1;
            this.sortNewList();
            return;
        }
        if (destinationIndex < sourceIndex) {
            list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
            this.sortNewList();
            return;
        }
        list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
        this.sortNewList();
    };

    reorderUpCardsList = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        const list = this.state.upCards7CS;
        if (destinationIndex === 0) {
            list[sourceIndex].order = list[0].order - 1;
            this.sortUpCards();
            return;
        }
        if (destinationIndex === list.length - 1) {
            list[sourceIndex].order = list[list.length - 1].order + 1;
            this.sortUpCards();
            return;
        }
        if (destinationIndex < sourceIndex) {
            list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
            this.sortUpCards();
            return;
        }
        list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
        this.sortUpCards();
    };

    reorderDownCardsList = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        const list = this.state.downCards7CS;
        if (destinationIndex === 0) {
            list[sourceIndex].order = list[0].order - 1;
            this.sortDownCards();
            return;
        }
        if (destinationIndex === list.length - 1) {
            list[sourceIndex].order = list[list.length - 1].order + 1;
            this.sortDownCards();
            return;
        }
        if (destinationIndex < sourceIndex) {
            list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
            this.sortDownCards();
            return;
        }
        list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
        this.sortDownCards();
    };

    listElement = (item) => {
        if (item) {
            let id = item.id;
            return <div className="normalcard" onClick={() => this.onSelectCard(id)}>
                        <Cards suit={id[id.length - 1]} value={id.slice(0, id.length - 1)}/>
                        {this.state.gameWildCards.includes(id) && <div className="isWildCard"><i class="fa fa-star" aria-hidden="true"/></div>}
                        {this.state.followTheQueenCards.includes(id) && <div className="isWildCard"><i class="fa fa-star" aria-hidden="true"/></div>}
                        {this.state.doYaWildCards.includes(id) && <div className="isWildCard"><i class="fa fa-star" aria-hidden="true"/></div>}
                        {this.state.cardsFromLeft.includes(id) && <div className="isPassedCard">LEFT</div>}
                        {this.state.cardsFromRight.includes(id) && <div className="isPassedCard">RIGHT</div>}
                        {id[id.length - 1] === "w" && <div className="isWildCard"><i class="fa fa-star" aria-hidden="true"/></div>}
                </div>;
        }
        else
            return ""
    };

    listNewElement = (item) => {
        if (item) {
            let id = item.id;
            return <div className="normalcard" onClick={() => this.revertSelectedCard(id)}>
                        <Cards suit={id[id.length - 1]} value={id.slice(0, id.length - 1)}/>
                        {this.state.gameWildCards.includes(id) && <div className="isWildCard"><i class="fa fa-star" aria-hidden="true"/></div>}
                        {id[id.length - 1] === "w" && <div className="isWildCard"><i class="fa fa-star" aria-hidden="true"/></div>}
                </div>;
        }
        else
            return ""
    };

    listHomeRunElement = (item) => {
        if (item) {
            let id = item.id;
            return <div className="normalcard">
                        <Cards suit={id[id.length - 1]} value={id.slice(0, id.length - 1)}/>
                        {this.state.gameWildCards.includes(id) && <div className="isWildCard"><i class="fa fa-star" aria-hidden="true"/></div>}
                        {id[id.length - 1] === "w" && <div className="isWildCard"><i class="fa fa-star" aria-hidden="true"/></div>}
                </div>;
        }
        else
            return ""
    };

    listWildCardElement = (item) => {
        if (item) {
            let id = item.id;
            return <div className="wildCard">
                    <Cards suit={id[id.length - 1]} value={id.slice(0, id.length - 1)}/>
                    <div className="wildCardClose" onClick={() => this.removeWildCardsItem(item)}><i class="fa fa-window-close" aria-hidden="true"/></div>
                </div>;
        }
        else
            return ""
    };

    setModalShow = (status) => {
        this.setState({
            winnerModalShow: status,
            actionMsg: "",
            currentTurnPlayer: "",
            sortedList: [],
        });
    };

    setPotModalShow = () => {
        this.setState({
            potModalShow: true
        });
    }

    setAccountShow = async () => {
        let { roomName } = this.state;
        let response = await getAccountHistory(roomName)
        this.setState({
            accountDetails: response,
            accountFlag: true
        })
        this.setAccountStatus();
    }

    setAccountStatus = () => {
        this.setState({
            accountModalShow: true
        })
    }

    handleAmountChange = value => {
        this.setState({
          raisedAmount: value
        })
    };

    handleRaiseChange = (event) => {
        let value = Math.abs(parseInt(event.target.value, 10) || 0);
        this.setState({
            raisedAmount: value - this.state.maxBet + this.state.currentBet
        })
    }

    handleBuyInChange = (event) => {
        let value = parseInt(event.target.value, 10) || 0;
        this.setState({
            buyInAmount: value
        })
    }

    handleHouseRulesChange = (event) => {
        let value = parseInt(event.target.value, 10) || 0;
        this.setState({
            [event.target.name]: value
        })
    }

    handleJokerChange = (operation) => {
        if(operation === '+') {
            if(this.state.noOfJokers < 4) {this.setState({noOfJokers: this.state.noOfJokers + 1})}
        }
        if(operation === '-') {
            if(this.state.noOfJokers > 0) {this.setState({noOfJokers: this.state.noOfJokers - 1})}
        }
    }

    handleNoOfPassesChange = (operation) => {
        if(operation === '+') {
            if(this.state.local727NoOfPasses < 4) {this.setState({local727NoOfPasses: this.state.local727NoOfPasses + 1})}
        }
        if(operation === '-') {
            if(this.state.local727NoOfPasses > 1) {this.setState({local727NoOfPasses: this.state.local727NoOfPasses - 1})}
        }
    }

    onSettingsClick = () => {
        this.setState({
            settingsShow: !this.state.settingsShow
        })
    }

    houseRulesHandler = () => {
        let { roomHouseRules } = this.state;
        // let allGames = [];
        for (const game in roomHouseRules) {
            let body = {
                gameName: game,
                ante: roomHouseRules[game].ante || 0,
                maxRaise: roomHouseRules[game].maxRaiseAllowed || 0,
                maximumNoOfRaisesAllowed: roomHouseRules[game].maximumNoOfRaisesAllowed || 0,
            };
            // allGames.push(body);
            if(game === this.state.selectedGame.value) {
                this.setState({
                    localAnteValue: roomHouseRules[game].ante || 0,
                    localMaxBetAllowed: roomHouseRules[game].maxRaiseAllowed || 0,
                    localMinBetAllowed: roomHouseRules[game].minRaiseAllowed || 0,
                    localMaxNoRaises: roomHouseRules[game].maximumNoOfRaisesAllowed || 0
                })
                if(roomHouseRules[game].loDecision) {
                    this.setState({
                        loAce: roomHouseRules[game].loDecision.considerAceLow,
                        loFlush: roomHouseRules[game].loDecision.countStraightAndFlushes
                    })
                }
                if(roomHouseRules[game].wildCardsSettings) {
                    this.setState({
                        localRoyalFlushBeatsFiveOfKind : roomHouseRules[game].wildCardsSettings.royalFlushBeatsFiveOfKind,
                        localhandsMustHaveOneNaturalCard : roomHouseRules[game].wildCardsSettings.handsMustHaveOneNaturalCard,
                        localPayForFaceUpWildCard : roomHouseRules[game].wildCardsSettings.payForFaceUpWildCard,
                        noOfJokers : roomHouseRules[game].wildCardsSettings.noOfJokers,
                        selectedWildCardsList : roomHouseRules[game].wildCardsSettings.wildCards
                    })
                }
                if(("restrictBetOpening" in roomHouseRules[game])) {
                    this.setState({
                        restrict5CDOpening: roomHouseRules[game].restrictBetOpening,
                        restrictBaseballOpening: roomHouseRules[game].restrictBetOpening
                    })
                }
            }
        }
    }

    onbuyInClick = async () => {
        let {roomName, initialBuyAmount} = this.state;
        if(this.state.buyInAmount <= initialBuyAmount) {
            let body = {
                amount: this.state.buyInAmount
            }
            let response = await buyIn(body, roomName);
            console.log('APICall Buy-IN', response);
        }
        else{
            alert(`Max Buy-In is ${initialBuyAmount}`);
        }
    }

    handleAutoReady = async (event) => {
        let {autoReady, roomName} = this.state;
        if(autoReady) {
            let body = {
                setting: "AUTO_READY",
                value: false       
            };
            let response = await selectAutoReady(body, roomName);
            console.log('Auto Ready False', response);
            if(response) {
                this.setState({
                    autoReady: false,
                    playerReadyRoom: false
                })
            }
        }
        else {
            let body = {
                setting: "AUTO_READY",
                value: true       
            };
            let response = await selectAutoReady(body, roomName);
            console.log('Auto Ready True', response);

            if(response) {

                if(this.state.showRoomReady && ! this.state.allPlayersReady){
                    let body = {
                        data : {
                            selection: "READY"
                        }
                    }
                    let response = await roomReady(body, this.state.roomName);
                    console.log('API Call room auto-ready selected', response);
                    if(response) {
                        this.setState({
                            playerReadyRoom: true
                        });
                    }

                }

                this.setState({
                    autoReady: true,
                    playerReadyRoom: true
                })

            }

        }

        {(this.state.is5CDLive || this.state.isTexasLive || this.state.isHomeRunLive || this.state.is357Live) && this.state.showReady &&
        this.state.autoReady && await this.onReadyPress()}
    }

    soundHandler = () => {
        this.setState({
            sound: !this.state.sound
        })
    }

    feedbackHandler = () => {
        this.setState({
            showFeedbackSheet: !this.state.showFeedbackSheet
        })
    }

    handleFeedbackChange = (event) => {
        const { target: { name, value } } = event;
        this.setState({ [name]: value })
    }

    handleFeedbackSubmit = async () => {
        let body = {
            feedback : this.state.feedbackText
        };
        let response = await feedbackAPI(body);
        if(response) {
            console.log('Feedback Submitted', this.state.feedbackText);
            this.setState({
                feedbackText: '',
                showFeedbackSheet: false
            });
        }
    }

    kickDisconnectedPlayers = async () => {
        let response = await removeDisconnectedPlayers(this.state.roomName);
        console.log('Remove Disconnected Players', response);
        this.setState({
            showRemoveButton: false
        })
    }

    handle5CDOpening = (event) => {
        this.setState({
            restrict5CDOpening: event.target.checked
        })
    }

    handleBaseballOpening = (event) => {
        this.setState({
            restrictBaseballOpening: event.target.checked
        })
    }

    handleLoAce = (event) => {
        this.setState({
            loAce: event.target.checked
        })
    }

    handleLoFlush = (event) => {
        this.setState({
            loFlush: event.target.checked
        })
    }

    handleRoyalFlush = (event) => {
        this.setState({
            localRoyalFlushBeatsFiveOfKind: event.target.checked
        })
    }

    handleNaturalCard = (event) => {
        this.setState({localhandsMustHaveOneNaturalCard: event.target.checked})
    }

    handleSplitHiLo = (event) => {
        this.setState({localSplitHiLo: event.target.checked})
    }

    handleDoYa = (event) => {
        this.setState({localDoYa: event.target.checked})
    }

    handle727HouseRules = (event) => {
        if(event.target.name === "simultaneousPassDrawRound") {
            this.setState({
                localSimultaneousPassDrawRound: event.target.checked
            })
        }
        if(event.target.name === "localStrictSum") {
            if(event.target.value === 'on') {
                this.setState({
                    localStrictSum: true,
                    localSplitPotIfStrictSumFalse: false,
                    localTieGoesToLow: false
                })
            }
        }
        if(event.target.name === "localSplitPotIfStrictSumFalse") {
            if(event.target.value === 'on') {
                this.setState({
                    localStrictSum: false,
                    localSplitPotIfStrictSumFalse: true,
                    localTieGoesToLow: false
                })
            }
        }
        if(event.target.name === "priceRight") {
            if(event.target.value === 'on') {
                this.setState({
                    localTieGoesToLow: true,
                    localStrictSum: false,
                    localSplitPotIfStrictSumFalse: false
                })
            }
            else {
                this.setState({localTieGoesToLow: false})
            }
        }
        if(event.target.name === "priceWrong") {
            if(event.target.value === 'on') {
                this.setState({
                    localTieGoesToLow: false,
                    localStrictSum: false,
                    localSplitPotIfStrictSumFalse: false
                })
            }
            else {
                this.setState({localTieGoesToLow: true})
            }
        }
    }

    handleQueensWild = (event) => {
        this.setState({localQueensWild: event.target.checked})
        if(event.target.checked) {
            this.removeDuplicates(this.state.selectedWildCardsList.concat(["Qs", "Qd", "Qc", "Qh"]));
        }
        else {
            console.log('REMOVE');
            let toRemove = ["Qs", "Qd", "Qc", "Qh"]
            let myArray = this.state.selectedWildCardsList.filter( function( el ) {
                return !toRemove.includes( el );
            });
            this.setState({selectedWildCardsList: myArray})
        }
    }

    handleWildCards = (event) => {
        this.setState({
            wildCardsBool: event.target.checked
        })
    }

    handleAddWildCards = (event) => {
        this.setState({
            addWildCardsBool: event.target.checked
        })
    }

    onOrderCardsPress = async () => {
        let { selectedCards, homeRunArrangements } = this.state;
        let arrangements;
        // Checking homeRunArrangements = {}  or undefined
        if(!homeRunArrangements || Object.keys(homeRunArrangements).length === 0 && homeRunArrangements.constructor === Object ){
            console.log("uncached arrangements")
            this.setState({loading: true, showHomeRunArrangements: true})

            setTimeout(() => {
                arrangements = getAllArrangements(selectedCards);
                console.log('Order HomeRun cards', arrangements);

                const BMF = arrangements[CARDS_ORDER_TYPE.T1_BMF]
                this.setState({
                    loading: false,
                    homeRunArrangements: arrangements,
                    defaultArrangement: BMF[SETTINGS.FRONT].concat(BMF[SETTINGS.MIDDLE].concat(BMF[SETTINGS.BACK]))
                })
            }, 1000);
        }
        else{
            console.log("cached arrangements")
            arrangements = homeRunArrangements
            this.setState({loading: false})

            console.log('Order HomeRun cards', arrangements);

            const BMF = arrangements[CARDS_ORDER_TYPE.T1_BMF]
            this.setState({
                homeRunArrangements: arrangements,
                showHomeRunArrangements: true,
                defaultArrangement: BMF[SETTINGS.FRONT].concat(BMF[SETTINGS.MIDDLE].concat(BMF[SETTINGS.BACK]))
            })
        }
        
        
        // this.setState({
        //     selectedCards: response.data.Front.concat(response.data.Middle.concat(response.data.Back)),
        //     selectedCardsSortedList: sortCards(getItems(response.data.Front.concat(response.data.Middle.concat(response.data.Back)))),
        // })
    }

    onGameRulesClick = () => {
        const {isAnacondaLive, is357Live, is7CSLive, is5CSLive, is5CDLive, isCincinnatiLive, isHomeRunLive, isTexasLive, is727Live, isBaseballLive, currentGameName} = this.state;
        if(isAnacondaLive) {this.setState({rulesAnaconda: true})}
        if(is357Live) {this.setState({rules357: true})}
        if(is7CSLive) {this.setState({rules7CS: true})}
        if(is5CSLive) {this.setState({rules5CS: true})}
        if(is5CDLive) {this.setState({rules5CD: true})}
        if(isCincinnatiLive) {this.setState({rulesCincinnati: true})}
        if(isHomeRunLive) {this.setState({rulesHomerun: true})}
        if(isTexasLive) {this.setState({rulesTexas: true})}
        if(is727Live) {this.setState({rules727: true})}
        if(isBaseballLive) {this.setState({rulesBaseball: true})}
        if(currentGameName==="3-brent-7") {this.setState({rules3B7: true})}
    }

    onSelectPencilAmount = async () => {
        let { pencilAmount, clientName, roomName, gameAnte, players } = this.state;
        let myStack = players[players.findIndex(pl => pl.clientName === clientName)].stack;
        if(pencilAmount >= 2*gameAnte && pencilAmount<=myStack) {
            let body = {
                clientName: clientName,
                action : {
                    name : "choosePencilAmount", 
                    data : {
                        amount: pencilAmount
                    }
                }
            }
            let response = await dealTexasCards(body, roomName);
            console.log('API CALL choosePencilAmount', response);
            if(response) {
                this.setState({
                    pencilAmount: 0,
                    showAmountSelection: false
                })
            }
        }
        else {
            alert(`Amount must be greater than 2*Ante ${2*gameAnte} and less than current stack ${myStack}`)
        }
    }

    noLimithandler = () => {
        this.setState({
            localMaxBetAllowed: 0,
            localMaxNoRaises: 0
        })
    }

    playerToSpectatorhandler = async () => {
        let response = await playerToSpectator(this.state.roomName);
        console.log('APICall player to spectator conversion', response);
        if(response) {
            this.setState({spectate: true, cards: [], showLeaveOptions: false})

        }
    }

    spectatorToPlayerhandler = async () => {

        let {clientName, roomName, roomCode, initialBuyAmount} = this.state;

        let res = await getCurrentState(roomName);
        console.log('APICall getCurrentState(spectatorToplayerhandler)', res);

        let token = await auth().currentUser.getIdToken();

        let playerPresentPreviously = false;
        let playerPreviousStack = 0;

        for(const i in res.data.spectators.spectators) {
            if(i === clientName) {
                for(const y in res.data.spectators.playersStack) {
                    if(y === res.data.spectators.spectators[i]) {
                        playerPreviousStack = res.data.spectators.playersStack[y];
                        playerPresentPreviously = true
                    }
                }
            }
        }

        if(playerPresentPreviously) {
            let body = {
                roomName: roomName,
                clientName: clientName,
                token: token,
                roomCode: roomCode,
                initialBuyIn: playerPreviousStack,
                joinAsSpectator: false
            }
            let response = await spectatorToPlayer(body, roomName);
            console.log('APICall spectator to player conversion', response);
            if(response.status === "success") {
                let response_new = await getCurrentState(roomName);
                this.setState({
                    spectatorList: getAllSpectators(response_new.data.spectators.spectators),
                    spectate: false,
                    spectatorToPlayerConverted: true
                })
            }
        }
        else {
            this.setState({showSpectatorToPlayerSheet: true})
        }
    }

    onSpectatorJoinTable = async () => {

        let {clientName, roomName, roomCode, initialBuyAmount} = this.state;

        let token = await auth().currentUser.getIdToken();

        let body = {
            roomName: roomName,
            clientName: clientName,
            token: token,
            roomCode: roomCode,
            initialBuyIn: initialBuyAmount,
            joinAsSpectator: false
        }
        let response = await spectatorToPlayer(body, roomName);
        console.log('APICall spectator to player conversion', response);
        if(response.status === "success") {
            let response_new = await getCurrentState(roomName);
            this.setState({
                spectatorList: getAllSpectators(response_new.data.spectators.spectators),
                spectate: false,
                showSpectatorToPlayerSheet: false,
                spectatorToPlayerConverted: true
            })
        }
    }

    onDestroyRoom = async () => {
        let response = await destroyRoom(this.state.roomName);
        console.log('APICall destroy Room', response);
        if(response) {
            this.setState({
                leaveAccess: true
            })
        }
    }

    handleFriendCheck = (event, type) => {
        console.log('EVENt', event.target, type);
        if(type === 'frequent') {
            let players = this.state.frequentFriends
            players.forEach(player => {
                if (player.name === event.target.value)
                    player.isChecked =  !player.isChecked
            })
            this.setState({frequentFriends: players})
        }
        else {
            let players = this.state.lastPlayedWith
            players.forEach(player => {
                if (player.name === event.target.value)
                    player.isChecked =  !player.isChecked
            })
            this.setState({lastPlayedWith: players})
        }
    }

    inviteFriends = async () => {
        let friendList = [];
        let { frequentFriends, lastPlayedWith, emails } = this.state;
        for ( const i in frequentFriends ) {
            if(frequentFriends[i].isChecked) {friendList.push(frequentFriends[i].name)}
        }
        for ( const i in lastPlayedWith ) {
            if(lastPlayedWith[i].isChecked) {friendList.push(lastPlayedWith[i].name)}
        }

        friendList = friendList.concat(emails);
        let body = {
            receivers: friendList
        }

        let response = await inviteAllFriends(body, this.state.roomName);
        console.log('INVITE FRIEND LIST', friendList, response);
        this.setState({showInviteMenu: false})
        if(response) {this.setState({actionMsg: 'Friends Invited Succesfully'})}
    }

    newBot = async () => {
        let body = [];
        let temp = {};
        body.push(temp);
        let response = await createBots(body, this.state.roomName)
        console.log('APICall createBots', response, body);
    }

    render() {
        let {clientName, roomName, members, messages, message, cards, maxBet, allowedActions, sum727cards,
            selectedGame, selectedCards, players, dealer, pot, raisedAmount, bettingEnabled,
            cardSelectionEnabled, showWinningSelection, everyonesWinningType, winner, draw727CardsShow, showDoYaActions, drawActionsBaseballShow,
            winnerCards, playerMsgDict, stack, cardsPassingStatus,anacondaPassedCards, cardPassingRoundActive,
            winnerModalShow, winners, currentBet, currentTurnPlayer, isFirstPlayer, gamefinished,
            foldedPlayers, potDetails, sidePots, currentPlayerBets, allPlayerInfo, disconnectedPlayers, 
            showReady, isTexasLive, allCommunityCards, selectedCardsSortedList, winningTypeAnaconda, selection357, sideBetLowPress, 
            gameAnte, gameMaxBet, gameMinBet, gameMaxNoRaises, gameLoAce, gameLoFlush, gameBetOpening, gameMaxPotToReplace, gameStarted, gameNoOfJokers, gameWildCards, gameJokersInDeck,
            gameroyalFlushBeatsFiveOfKind, gamehandsMustHaveOneNaturalCard, gamePayForFaceUpWildCard, game727NoOfPasses, gameSplitHiLo, gameSplitPotIfStrictSumFalse, gameStrictSum, gameTieGoesToLow, gameSimultaneousPassDrawRound, gameDoYa,
            defAnte, defMaxBet, defMinBet, defMaxNoRaises, defLoAce, defLoFlush, defBetOpening, defMaxPotToReplace, defNoOfJokers, defWildCards, defJokersInDeck,
            defroyalFlushBeatsFiveOfKind, defhandsMustHaveOneNaturalCard, defPayForFaceUpWildCard, def727NoOfPasses, defSplitHiLo, defSplitPotIfStrictSumFalse, defStrictSum, defTieGoesToLow, defSimultaneousPassDrawRound, defDoYa,
            selectedWildCardsByRank, selectedWildCardsBySuit, brentLive, followTheQueenCards, is727Live, localStrictSum, local727NoOfPasses, localSplitPotIfStrictSumFalse, localTieGoesToLow, localSimultaneousPassDrawRound, localDoYa,
            isAnacondaLive, is357Live, is5CDLive, is7CSLive, is5CSLive, isCincinnatiLive, isHomeRunLive, isBaseballLive, currentGameName, newSpectator, positions, homeRunArrangements, emails, loading,
            defaultBaseballOpening, gameDefaultBaseballOpening} = this.state;

        let bettingGameSelected = (selectedGame.value === "Anaconda" || selectedGame.value === "Texas-Holdem" || selectedGame.value === "Texas-Holdem Round" ||
        selectedGame.value === "Omaha" || selectedGame.value === "Omaha Round" || selectedGame.value === "Omaha8" || selectedGame.value === "Omaha8 Round" || selectedGame.value === "Frankenstein" ||
        selectedGame.value === "7-Card-Stud" || selectedGame.value === "Lo Chicago" || selectedGame.value === "High Chicago" || selectedGame.value === "Follow The Queen" || selectedGame.value === "Baseball 7CS" ||
        selectedGame.value === "Cincinnati" || selectedGame.value === "727" || selectedGame.value === "5-Card-Draw" || selectedGame.value === "5-Card-Draw Round" || selectedGame.value === "Diablo" ||
        selectedGame.value === "5-Card-Stud" || selectedGame.value === "Do Ya" || selectedGame.value === "5-Card-Stud Round" || selectedGame.value === "Baseball-9CD" || selectedGame.value === "Blind Baseball" ||
        selectedGame.value === "Countdown");

        let descriptionWildCardsList = descriptionWildCards(gameWildCards);

        return (
            <div className="d-flex flex-column overflow-auto">
                <div className="roomHeader d-flex">
                    <Row className="h-100 w-100 mx-0 px-4 align-items-center">
                        <div className="">
                            <span>Cards4Us / {roomName}</span>
                            <div>
                            <OverlayTrigger placement="bottom" overlay={renderTooltipHouseRules}>
                                <Button onClick={() => this.setState({showHouseRules: true})} className="GameBtn" type="submit">Room-Rules</Button>
                            </OverlayTrigger>
                            </div>
                        </div>
                        {dealer === clientName && this.state.gameStarted === false && this.state.allPlayersReady &&
                        <div className="mx-auto gameSelection">
                            <Select placeholder="Select game"
                                    value={this.state.selectedGame.name}
                                    onChange={this.handleGameChange}
                                    options={this.state.gameOptions}
                            />
                            <div>
                                <Button className="GameBtn"
                                        disabled={members < selectedGame.minPlayers || members > selectedGame.maxPlayers || this.state.startDisable}
                                        onClick={this.preStartGame}>
                                    START GAME
                                </Button>
                            </div>
                            {this.state.showRemoveButton && false && <Button className="removeBtn" onClick={this.kickDisconnectedPlayers}>REMOVE DISCONNECTED PLAYERS</Button>}
                            {/* <img className="mr-2" height="20" width="20" src={Settings} alt="Settings" onClick={this.onSettingsClick}/> */}
                            {this.state.settingsShow && <div><div className="countdown-timer"><span>Ante</span></div>
                            <div>
                                <input 
                                    className="sendRaiseArea p-2"
                                    style={{resize: "none"}}
                                    type="number"
                                    name="localAnteValue"
                                    placeholder={this.state.localAnteValue}
                                    value={Number(this.state.localAnteValue).toString()}
                                    onChange={this.handleHouseRulesChange}
                                    // disabled={!this.state.houseRulesEnabled}
                                />
                            </div></div>}
                            {this.state.settingsShow && <div><div className="countdown-timer"><span>Max No. of Raise</span></div>
                            <div>
                                <input 
                                    className="sendRaiseArea p-2"
                                    style={{resize: "none"}}
                                    type="number"
                                    name="localMaxNoRaises"
                                    placeholder={this.state.localMaxNoRaises}
                                    value={this.state.localMaxNoRaises}
                                    onChange={this.handleHouseRulesChange}
                                    // disabled={!this.state.houseRulesEnabled}
                                />
                            </div></div>}
                            {this.state.settingsShow && <div><div className="countdown-timer"><span>Max Bet</span></div>
                            <div>
                                <input 
                                    className="sendRaiseArea p-2"
                                    style={{resize: "none"}}
                                    type="number"
                                    name="localMaxBetAllowed"
                                    placeholder={this.state.localMaxBetAllowed}
                                    value={Number(this.state.localMaxBetAllowed).toString()}
                                    onChange={this.handleHouseRulesChange}
                                    // disabled={!this.state.houseRulesEnabled}
                                />
                            </div></div>}
                            {this.state.settingsShow && <div><div className="countdown-timer"><span>Min Bet</span></div>
                            <div>
                                <input 
                                    className="sendRaiseArea p-2"
                                    style={{resize: "none"}}
                                    type="number"
                                    name="localMinBetAllowed"
                                    placeholder={this.state.localMinBetAllowed}
                                    value={Number(this.state.localMinBetAllowed).toString()}
                                    onChange={this.handleHouseRulesChange}
                                    // disabled={!this.state.houseRulesEnabled}
                                />
                            </div></div>}
                            {this.state.settingsShow && <Button onClick={this.houseRulesHandler} className="GameBtn" type="submit">Default</Button>}
                            {/* {this.state.settingsShow && this.state.houseRulesEnabled && <Button onClick={this.houseRulesHandler} className="GameBtn" type="submit">No-Limit</Button>} */}
                        </div>}
                        {this.state.gameStarted===false && dealer !== clientName && dealer.length > 0 && this.state.allPlayersReady && <div className="mx-auto">
                            <h5><strong>{dealer} is selecting the Game</strong></h5>
                        </div>}
                        {!this.state.gameStarted && this.state.allPlayersReady && this.state.members<7 && <Row>
                                <Button onClick={this.newBot} className="GameBtn">Add Bot</Button>
                                <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Tooltip>
                                            <span>A BOT will take random actions for each game you want to play. You can add or remove a bot before starting a game. BOT will not be given the option to start a game.</span>
                                        </Tooltip>
                                    }>
                                    <div><i class="fa fa-info-circle" aria-hidden="true"/></div>
                                </OverlayTrigger>
                            </Row>}
                        {/* {this.state.gameEndsHomeRun && <Button className="GameBtn" onClick={() => this.setState({showModalHomeRun: true})}>ALL-DETAILS</Button>} */}
                        {gameStarted===true && isAnacondaLive && <div className="mx-auto gameTitle" onClick={() => this.setState({rulesAnaconda: true})}>
                            <h5><strong>{dealer} started {currentGameName}</strong></h5>
                        </div>}
                        {gameStarted===true && is357Live && <div className="mx-auto gameTitle" onClick={() => this.setState({rules3B7: true})}>
                            <h5><strong>{dealer} started {currentGameName}</strong></h5>
                        </div>}
                        {gameStarted===true && is5CDLive && <div className="mx-auto gameTitle" onClick={() => this.setState({rules5CD: true})}>
                            <h5><strong>{dealer} started {currentGameName}</strong></h5>
                        </div>}
                        {gameStarted===true && isTexasLive && <div className="mx-auto gameTitle" onClick={() => this.setState({rulesTexas: true})}>
                            <h5><strong>{dealer} started {currentGameName}</strong></h5>
                        </div>}
                        {gameStarted===true && is7CSLive && <div className="mx-auto gameTitle" onClick={() => this.setState({rules7CS: true})}>
                            <h5><strong>{dealer} started {currentGameName}</strong></h5>
                        </div>}
                        {gameStarted===true && is5CSLive && <div className="mx-auto gameTitle" onClick={() => this.setState({rules5CS: true})}>
                            <h5><strong>{dealer} started {currentGameName}</strong></h5>
                        </div>}
                        {gameStarted===true && isCincinnatiLive && <div className="mx-auto gameTitle" onClick={() => this.setState({rulesCincinnati: true})}>
                            <h5><strong>{dealer} started {currentGameName}</strong></h5>
                        </div>}
                        {gameStarted===true && isHomeRunLive && <div className="mx-auto gameTitle" onClick={() => this.setState({rulesHomerun: true})}>
                            <h5><strong>{dealer} started {currentGameName}</strong></h5>
                        </div>}
                        {gameStarted===true && is727Live && <div className="mx-auto gameTitle" onClick={() => this.setState({rules727: true})}>
                            <h5><strong>{dealer} started {currentGameName}</strong></h5>
                        </div>}
                        {gameStarted===true && isBaseballLive && <div className="mx-auto gameTitle" onClick={() => this.setState({rulesBaseball: true})}>
                            <h5><strong>{dealer} started {currentGameName}</strong></h5>
                        </div>}
                        {this.state.gameStarted && <Row className="mx-auto bothrulesdiv">
                            <span onClick={this.onGameRulesClick}><h6><strong>HOW-TO-PLAY &nbsp; &nbsp;</strong></h6></span>
                            {/* <span>
                                <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip`}>
                                            {gameAnte && <span>Ante: {gameAnte}<br/></span>}
                                            {gameMaxBet && <span>Max Bet: {gameMaxBet >= 9007199254740991 ? 'No Limit' : gameMaxBet}<br/></span>}
                                            {gameMinBet && <span>Min Bet: {gameMinBet >= 9007199254740991 ? 'No Limit' : gameMinBet}<br/></span>}
                                            {gameMaxNoRaises && <span>No of Raises: {gameMaxNoRaises >= 9007199254740991 ? 'No Limit' : gameMaxNoRaises}<br/></span>}
                                            {(isAnacondaLive || currentGameName==="Lo Chicago" || isCincinnatiLive || currentGameName==="3-Brent-7" || currentGameName==="Omaha8" || currentGameName==="Omaha8 Round") && <span>
                                                Consider Ace Low: {gameLoAce===true ? 'YES' : 'NO'}<br/>
                                            </span>}
                                            {(isAnacondaLive || currentGameName==="Lo Chicago" || isCincinnatiLive || currentGameName==="3-Brent-7" || currentGameName==="Omaha8" || currentGameName==="Omaha8 Round") && <span>
                                                Count Flush and Straight: {gameLoFlush===true ? 'YES' : 'NO'}<br/>
                                            </span>}
                                            {(is5CDLive) && <span>Min Pair J's Opening: {gameBetOpening===true ? 'YES' : 'NO'}<br/></span>}
                                            {(is5CDLive && currentGameName === "Diablo") && <span>Max Pot To Replace: {gameMaxPotToReplace}<br/></span>}
                                            {gameNoOfJokers > 0 && <span>Jokers: {gameNoOfJokers}<br/></span>}
                                            {(gameNoOfJokers > 0 || gameWildCards.length > 0) && <span>Royal-Flush beats 5-Of-A-Kind: {gameroyalFlushBeatsFiveOfKind===true ? 'YES' : 'NO'}<br/></span>}
                                            {(gameNoOfJokers > 0 || gameWildCards.length > 0) && <span>Hand must have 1 Natural Card: {gamehandsMustHaveOneNaturalCard===true ? 'YES' : 'NO'}<br/></span>}
                                            {((gameNoOfJokers > 0 || gameWildCards.length > 0) && (currentGameName === "7-Card-Stud" || currentGameName === "Lo Chicago" || currentGameName === "High Chicago")) && <span>Pay for Face-Up Wild-Card: {gamePayForFaceUpWildCard}<br/></span>}
                                            {gameWildCards.length > 0 && <span>Wild Cards: </span>}
                                            {gameWildCards.length > 0 && descriptionWildCardsList.map((card, index) => <span key={index}>{card}{index!==descriptionWildCardsList.length-1 && <span>,</span>} </span>)}
                                        </Tooltip>
                                    }>
                                    <h6><strong>GAME-OPTIONS</strong></h6>
                                </OverlayTrigger>
                            </span> */}
                            <span onMouseOver={() => this.setState({showGameOptionsInfo: true})} onMouseEnter={() => this.setState({showGameOptionsInfo: true})} onMouseLeave={() => this.setState({showGameOptionsInfo: false})}><h6><strong>GAME-OPTIONS</strong></h6></span>
                            {this.state.showGameOptionsInfo && <div className="gameoptions-info-top">
                                {gameAnte && <span className={`${gameAnte !== defAnte ? "gameoptions-info-top-change" : ""}`}>Ante: {gameAnte} &nbsp; &nbsp;</span>}
                                {gameMaxBet && <span className={`${gameMaxBet !== defMaxBet ? "gameoptions-info-top-change" : ""}`}>Max Bet: {gameMaxBet >= 9007199254740991 ? 'No Limit' : gameMaxBet}&nbsp; &nbsp;</span>}
                                {gameMinBet && <span className={`${gameMinBet !== defMinBet ? "gameoptions-info-top-change" : ""}`}>Min Bet: {gameMinBet >= 9007199254740991 ? 'No Limit' : gameMinBet}&nbsp; &nbsp;</span>}
                                {gameMaxNoRaises && <span className={`${gameMaxNoRaises !== defMaxNoRaises ? "gameoptions-info-top-change" : ""}`}>No of Raises: {gameMaxNoRaises >= 9007199254740991 ? 'No Limit' : gameMaxNoRaises}&nbsp; &nbsp;</span>}
                                {gameNoOfJokers > 0 && <span className={`${gameNoOfJokers !== defNoOfJokers ? "gameoptions-info-top-change" : ""}`}>Jokers: {gameNoOfJokers}</span>}
                                {(is727Live) && <span className={`${game727NoOfPasses !== def727NoOfPasses ? "gameoptions-info-top-change" : ""}`}>
                                    <br/>No Of Passes: {game727NoOfPasses} &nbsp; &nbsp;
                                </span>}
                                {(is727Live) && !gameTieGoesToLow && !gameSplitPotIfStrictSumFalse && <span className={`${gameStrictSum !== defStrictSum ? "gameoptions-info-top-change" : ""}`}>
                                    Strict Sum: {gameStrictSum===true ? 'YES' : 'NO'} &nbsp; &nbsp;
                                </span>}
                                {(is727Live) && !gameStrictSum && !gameSplitPotIfStrictSumFalse && <span className={`${gameTieGoesToLow !== defTieGoesToLow ? "gameoptions-info-top-change" : ""}`}>
                                    {gameTieGoesToLow===true ? 'Price is Right' : 'Price is Wrong'} &nbsp; &nbsp;
                                </span>}
                                {(is727Live) && !gameStrictSum && <span className={`${gameSimultaneousPassDrawRound !== defSimultaneousPassDrawRound ? "gameoptions-info-top-change" : ""}`}>
                                    <br/>{gameSimultaneousPassDrawRound===true ? 'Simultaneous Pass/Draw' : 'Turn-by-Turn Pass/Draw'} &nbsp; &nbsp;
                                </span>}
                                {(is727Live) && !gameStrictSum && !gameTieGoesToLow && <span className={`${gameSplitPotIfStrictSumFalse !== defSplitPotIfStrictSumFalse ? "gameoptions-info-top-change" : ""}`}>
                                    <br/>Split Pot: {gameSplitPotIfStrictSumFalse===true ? 'YES' : 'NO'} &nbsp; &nbsp;
                                </span>}
                                {(isTexasLive && currentGameName === "Ironcross") && <span className={`${gameSplitHiLo !== defSplitHiLo ? "gameoptions-info-top-change" : ""}`}>
                                    <br/>Split High Low: {gameSplitHiLo===true ? 'YES' : 'NO'} &nbsp; &nbsp;
                                </span>}
                                {(isAnacondaLive || currentGameName==="Lo Chicago" || isCincinnatiLive || currentGameName==="3-Brent-7" || currentGameName==="Omaha8" || currentGameName==="Omaha8 Round" || currentGameName === "Ironcross" || currentGameName === "Do Ya") && <span className={`${gameLoAce !== defLoAce ? "gameoptions-info-top-change" : ""}`}>
                                    <br/>Consider Ace Low: {gameLoAce===true ? 'YES' : 'NO'} &nbsp; &nbsp;
                                </span>}
                                {(isAnacondaLive || currentGameName==="Lo Chicago" || isCincinnatiLive || currentGameName==="3-Brent-7" || currentGameName==="Omaha8" || currentGameName==="Omaha8 Round" || currentGameName === "Ironcross" || currentGameName === "Do Ya") && <span className={`${gameLoFlush !== defLoFlush ? "gameoptions-info-top-change" : ""}`}>
                                    Count Flush and Straight: {gameLoFlush===true ? 'YES' : 'NO'}
                                </span>}
                                {(is5CSLive && currentGameName === "Do Ya") && <span className={`${gameDoYa !== defDoYa ? "gameoptions-info-top-change" : ""}`}><br/>Do-Ya High Lo: {gameDoYa===true ? 'YES' : 'NO'}</span>}
                                {(is5CDLive) && <span className={`${gameBetOpening !== defBetOpening ? "gameoptions-info-top-change" : ""}`}><br/>Min Pair J's Opening: {gameBetOpening===true ? 'YES' : 'NO'}</span>}
                                {(currentGameName === "Baseball-9CD" || currentGameName === "Blind Baseball") && <span className={`${gameBetOpening !== defBetOpening ? "gameoptions-info-top-change" : ""}`}><br/>Min Pair Opening: {gameBetOpening===true ? 'YES' : 'NO'}</span>}
                                {(currentGameName === "Baseball-9CD" || currentGameName === "Blind Baseball") && gameBetOpening && <span className={`${gameDefaultBaseballOpening !== defaultBaseballOpening ? "gameoptions-info-top-change" : ""}`}><br/>Min Pair: {gameDefaultBaseballOpening}'s</span>}
                                {(is5CDLive && currentGameName === "Diablo") && <span className={`${gameMaxPotToReplace !== defMaxPotToReplace ? "gameoptions-info-top-change" : ""}`}><br/>Max Pot To Replace: {gameMaxPotToReplace}</span>}
                                {(gameNoOfJokers > 0 || gameWildCards.length > 0) && <span className={`${gameroyalFlushBeatsFiveOfKind !== defroyalFlushBeatsFiveOfKind ? "gameoptions-info-top-change" : ""}`}><br/>Royal-Flush beats 5-Of-A-Kind: {gameroyalFlushBeatsFiveOfKind===true ? 'YES' : 'NO'}<br/></span>}
                                {(gameNoOfJokers > 0 || gameWildCards.length > 0) && <span className={`${gamehandsMustHaveOneNaturalCard !== defhandsMustHaveOneNaturalCard ? "gameoptions-info-top-change" : ""}`}>Hand must have 1 Natural Card: {gamehandsMustHaveOneNaturalCard===true ? 'YES' : 'NO'}<br/></span>}
                                {((gameNoOfJokers > 0 || gameWildCards.length > 0) && (currentGameName === "7-Card-Stud" || currentGameName === "Lo Chicago" || currentGameName === "High Chicago" || currentGameName === "Follow The Queen" || currentGameName === "Baseball 7CS" || currentGameName === "Countdown")) && <span className={`${gamePayForFaceUpWildCard !== defPayForFaceUpWildCard ? "gameoptions-info-top-change" : ""}`}>Pay for Face-Up Wild-Card: {gamePayForFaceUpWildCard}<br/></span>}
                                {gameWildCards.length > 0 && <span>Wild Cards: </span>}
                                {gameWildCards.length > 0 && descriptionWildCardsList.map((card, index) => <span key={index} className={`${JSON.stringify(gameWildCards) !== JSON.stringify(defWildCards) ? "gameoptions-info-top-change" : ""}`}>{card}{index!==descriptionWildCardsList.length-1 && <span>,</span>} </span>)}
                                <br/>
                                <Button className="rebuyinfoBtn" onClick={() => this.setState({showGameOptionsInfo: false})}>Got it</Button>
                            </div>}
                        </Row>}
                        {!this.state.gameStarted && this.state.firstGameStarted && <div className="mx-auto">
                            {this.state.spectate && <Button onClick={this.spectatorToPlayerhandler} className="GameBtn">JOIN THE TABLE</Button>}
                        </div>}
                        <div className="mx-auto gameSelection">
                            {this.state.voteoutShow && false &&
                                <Select placeholder="Player"
                                    value={this.state.voteoutPlayerName}
                                    onChange={this.handleVoteChange}
                                    options={disconnectedPlayers}
                                />
                            }
                            {this.state.voteoutShow && false &&
                            <div className="voteoutSomeone">
                                <Button onClick={() => this.setState({showVoteOutOptions: true})} className="GameBtn">Vote to Kick</Button>
                            </div>}
                            {!this.state.spectate && <div>
                            {((!this.state.gameStarted && this.state.showRoomReady) || this.state.showDiabloBuy) && players[players.findIndex(pl => pl.clientName === clientName)].stack < (this.state.initialBuyAmount/2) && <div>
                                <span className="inforebuyicon"><i class="fa fa-rebuyicon fa-info-circle" aria-hidden="true" onClick={() => this.setState({showRebuyInfo: !this.state.showRebuyInfo})}></i></span> &nbsp;
                                <input 
                                className="sendRaiseArea p-2"
                                style={{resize: "none"}}
                                type="number"
                                placeholder={this.state.buyInAmount}
                                value={Number(this.state.buyInAmount).toString()}
                                onChange={this.handleBuyInChange}
                            /></div>}</div>}
                            {!this.state.spectate && <div>
                            {((!this.state.gameStarted && this.state.showRoomReady) || this.state.showDiabloBuy) && players[players.findIndex(pl => pl.clientName === clientName)].stack < (this.state.initialBuyAmount/2) && <div className="voteoutSomeone">
                                <Button onClick={this.onbuyInClick} className="GameBtn">Re-Buy</Button>    
                                {((!this.state.gameStarted && this.state.showRoomReady) || this.state.showDiabloBuy) && players[players.findIndex(pl => pl.clientName === clientName)].stack < (this.state.initialBuyAmount/2) && this.state.showRebuyInfo && <div className="rebuy-info-top">
                                    <Row>
                                        <div className="inforebuytext">
                                            You have lost {Math.abs(this.state.myMoneyGain)} coins. Your current stack is less than the half of your starting amount. The maximum re-buy limit is {this.state.initialBuyAmount} and you are allowed to re-buy {this.state.maxRebuyRoom} times.
                                        </div>
                                        <div>
                                            <Button className="rebuyinfoBtn" onClick={() => this.setState({showRebuyInfo: false})}>Got it</Button>
                                        </div>
                                        {/* <div><button className="infocloseBtn">Got it</button></div> */}
                                    </Row>
                                </div>}
                            </div>}</div>}
                            {!this.state.spectate && <div>
                            {!this.state.gameStarted && this.state.showRoomReady && !this.state.playerReadyRoom && !this.state.allPlayersReady && !this.state.spectatorToPlayerConverted && <div className="voteoutSomeone">
                                <Button onClick={this.onRoomReadyClick} className="GameBtn">Ready</Button>    
                            </div>}</div>}
                            {!this.state.spectate && <div>
                            {!this.state.gameStarted && this.state.showRoomReady && this.state.playerReadyRoom && !this.state.allPlayersReady && !this.state.spectatorToPlayerConverted && <div className="voteoutSomeone">
                                <Button onClick={this.onRoomReadyClick} className="GameBtn">Not-Ready</Button>    
                            </div>}</div>}
                        </div>
                        <div className="ml-auto d-flex flex-row">
                            <span><div>{clientName}</div></span>
                            {!this.state.firstGameStarted && <Button onClick={() => this.setState({showInviteMenu: !this.state.showInviteMenu})} className="GameBtn" type="submit">Invite</Button>}
                            {true && <Button onClick={() => this.setState({showLeaveOptions: true})} className="GameBtn" type="submit">Leave</Button>}
                            {!this.state.sound && <OverlayTrigger placement="bottom" overlay={renderTooltipSoundOn}>
                                <div className="soundOffBtn" onClick={this.soundHandler}></div>
                            </OverlayTrigger>}
                            {this.state.sound && <OverlayTrigger placement="bottom" overlay={renderTooltipSoundOff}>
                                <div className="soundOnBtn" onClick={this.soundHandler}></div>
                            </OverlayTrigger>}
                            {true && <OverlayTrigger placement="bottom" overlay={renderTooltipFeedback}>
                                <div className="feedbackBtn" onClick={this.feedbackHandler}></div>
                            </OverlayTrigger>}
                                {this.state.showChatWindow && <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip`}>
                                            Hide Chat
                                        </Tooltip>
                                    }>
                                    <div className="showChatBtn" onClick={() => this.setState({showChatWindow: !this.state.showChatWindow})}></div>
                                    {/* <img className="chatIcons" src={ChatR} onClick={() => this.setState({showChatWindow: !this.state.showChatWindow})}/> */}
                                </OverlayTrigger>}
                                {!this.state.showChatWindow && <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip`}>
                                            Show Chat
                                        </Tooltip>
                                    }>
                                    <div className="hideChatBtn" onClick={() => this.setState({showChatWindow: !this.state.showChatWindow})}></div>
                                    {/* <img className="chatIcons" src={ChatG} onClick={() => this.setState({showChatWindow: !this.state.showChatWindow})}/> */}
                                </OverlayTrigger>}
                            {this.state.leaveAccess && <Redirect to={`/`}/>}
                            {this.state.goBack && <Redirect to={`/join?joinRoom`}/>}
                        </div>
                    </Row>
                </div>
                <div className="outerContainer">
                    <div className={` d-flex flex-column ${this.state.showChatWindow ? "gameContainer" : "newgameContainer"}`}>
                        <GameTable players={players} currentPlayer={clientName} roomName={roomName} potValue={pot}
                                    winner={winner} winnerCards={winnerCards} dealer={dealer}
                                    playerMsgDict={playerMsgDict} actionMsg={this.state.actionMsg}
                                    cardsPassingStatus={cardsPassingStatus}
                                    anacondaPassedCards={anacondaPassedCards}
                                    cardPassingRoundActive={cardPassingRoundActive}
                                    currentTurnPlayer={currentTurnPlayer}
                                    everyonesWinningType={everyonesWinningType}
                                    foldedPlayers={foldedPlayers}
                                    potDetails={potDetails}
                                    sidePots={sidePots}
                                    betRoundOver={this.state.betRoundOver}
                                    currentPlayerBets={currentPlayerBets}
                                    myCurrentBet={this.state.myCurrentBet}
                                    allPlayerInfo={allPlayerInfo}
                                    gameName357={this.state.gameName357}
                                    winners={winners}
                                    gamefinished={gamefinished}
                                    gameEnds357={this.state.gameEnds357}
                                    details357={this.state.details357}
                                    roundOver357={this.state.roundOver357}
                                    inPlayersCards={this.state.inPlayersCards}
                                    selectedIn={this.state.selectedIn}
                                    sideBetLowPress={this.state.sideBetLowPress}
                                    afterRoundModalShow={this.state.afterRoundModalShow}
                                    current357Cards={this.state.current357Cards}
                                    is5CDLive={this.state.is5CDLive}
                                    isAnacondaLive={this.state.isAnacondaLive}
                                    gameEnds5CD={this.state.gameEnds5CD}
                                    showReady357={this.state.showReady357}
                                    readyData357={this.state.readyData357} 
                                    readyPressed357={this.state.readyPressed357}
                                    isTexasLive={isTexasLive}
                                    allCommunityCards={allCommunityCards}
                                    gameEndsTexas={this.state.gameEndsTexas}
                                    showExchangedCards={this.state.showExchangedCards}
                                    exchangedCards={this.state.exchangedCards}
                                    allPlayerCards7CS={this.state.allPlayerCards7CS}
                                    is7CSLive={this.state.is7CSLive}
                                    is5CSLive={is5CSLive}
                                    gameEnds7CS={this.state.gameEnds7CS}
                                    gameEnds5CS={this.state.gameEnds5CS}
                                    isCincinnatiLive={this.state.isCincinnatiLive}
                                    gameEndsCincinnati={this.state.gameEndsCincinnati}
                                    smallBlindPlayer={this.state.smallBlindPlayer}
                                    bigBlindPlayer={this.state.bigBlindPlayer}
                                    roundDealer={this.state.roundDealer}
                                    showPlayersPassedCin={this.state.showPlayersPassedCin}
                                    playersPassedCardsCin={this.state.playersPassedCardsCin}
                                    showRevealStatus={this.state.showRevealStatus}
                                    playersRevealStatus={this.state.playersRevealStatus}
                                    isHomeRunLive={this.state.isHomeRunLive}
                                    settingsFront={this.state.settingsFront}
                                    settingsMiddle={this.state.settingsMiddle}
                                    settingsBack={this.state.settingsBack}
                                    gameEndsHomeRun={this.state.gameEndsHomeRun}
                                    cardSelectionEnabled={cardSelectionEnabled}
                                    homeRunSubmitStatus={this.state.homeRunSubmitStatus}
                                    homeRunShowStatus={this.state.homeRunShowStatus}
                                    showRoomReady={this.state.showRoomReady}
                                    roomReadyData={this.state.roomReadyData}
                                    firstGameStarted={this.state.firstGameStarted}
                                    positions={this.state.positions}
                                    loHand3B7={this.state.loHand3B7}
                                    gameAnte={this.state.gameAnte}
                                    mainPlayer={this.state.clientName}
                                    resultHomeRun={this.state.resultHomeRun}
                                    spadeCardAllPlayers={this.state.spadeCardAllPlayers}
                                    currentGameName={this.state.currentGameName}
                                    boolHomeRun={this.state.boolHomeRun}
                                    bustedPlayers={this.state.bustedPlayers}
                                    texasGameTypeOmaha8={this.state.texasGameTypeOmaha8}
                                    playersDisconnected={this.state.playersDisconnected}
                                    showPencilStatus={this.state.showPencilStatus}
                                    pencilAmountData={this.state.pencilAmountData}
                                    allowKickOut={this.state.allowKickOut}
                                    allPlayersReady={this.state.allPlayersReady}
                                    gameStarted={this.state.gameStarted}
                                    kickedOutPlayers={this.state.kickedOutPlayers}
                                    currentPlayersToKick={this.state.currentPlayersToKick}
                                    currentDisconnectedPlayers={this.state.currentDisconnectedPlayers}
                                    rollOnPlayerCards={this.state.rollOnPlayerCards}
                                    rollOnCardsShow={this.state.rollOnCardsShow}
                                    bots={this.state.bots}
                                    diabloOpener={this.state.diabloOpener}
                                    downCards7CS={this.state.downCards7CS}
                                    gameWildCards={gameWildCards}
                                    gameJokersInDeck={gameJokersInDeck}
                                    gameroyalFlushBeatsFiveOfKind={gameroyalFlushBeatsFiveOfKind}
                                    gamehandsMustHaveOneNaturalCard={gamehandsMustHaveOneNaturalCard}
                                    gameLoAce={gameLoAce} gameLoFlush={gameLoFlush}
                                    gameOverAllData={this.state.gameOverAllData}
                                    followTheQueenCards={followTheQueenCards}
                                    drawRound727Show={this.state.drawRound727Show}
                                    draw727RoundDetails={this.state.draw727RoundDetails}
                                    noOfPassesPlayerDetails={this.state.noOfPassesPlayerDetails}
                                    is727Live={is727Live}
                                    gameEnds727={this.state.gameEnds727}
                                    downCards727={this.state.downCards727}
                                    gameSplitHiLo={gameSplitHiLo}
                                    lastBettingRound727={this.state.lastBettingRound727}
                                    gameSimultaneousPassDrawRound={gameSimultaneousPassDrawRound}
                                    doYaCards={this.state.doYaCards}
                                    doYaCallback={this.doYaAction}
                                    showDoYaActions={this.state.showDoYaActions}
                                    is357Live={is357Live}
                                    spectate={this.state.spectate}
                                    drawBaseballDecisions={this.state.drawBaseballDecisions}
                                    drawRoundBaseballShow={this.state.drawRoundBaseballShow}
                                    drawActionsBaseballShow={drawActionsBaseballShow}
                                    baseballCallback={this.drawBaseballAction}
                                    gameEndsBaseball={this.state.gameEndsBaseball}
                                    isBaseballLive={isBaseballLive}
                                    allPlayerCardsBaseball={this.state.allPlayerCardsBaseball}
                                    animatedWinners={this.state.animatedWinners}
                        />
                        {this.state.showSpectatorJoinReq && 
                        <div className="typeselection">
                            <Col>
                                <h5><span className="startgametext">Allow {!newSpectator.isAnonymous ? `${newSpectator.spectatorName} (${newSpectator.spectatorEmail})` : `Anonymous User (${newSpectator.spectatorName})`} to join as spectator? <CounterApp time={10}/></span></h5>
                                <Button className="kickBtn" onClick={() => this.takeSpectatorDecision("YES", newSpectator.spectatorEmail)}>YES</Button>
                                <Button className="kickBtn" onClick={() => this.takeSpectatorDecision("NO", newSpectator.spectatorEmail)}>NO</Button>
                            </Col>
                        </div>}
                        {this.state.showLeaveOptions && 
                            <div className="typeselection">
                                {!this.state.spectate && <Col>
                                    <Button onClick={() => this.updatedLeaveHandler('keepOnTable')} className="kickBtn">BE RIGHT BACK</Button>
                                    <OverlayTrigger placement="bottom"
                                        overlay={
                                            <Tooltip id={`tooltip`}>
                                                You will be able to rejoin the room with your current amount
                                            </Tooltip>
                                        }>
                                        <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                    </OverlayTrigger>
                                    <br/>
                                    {this.state.gameStarted && <span className="startgametext">Click here to become a Spectator</span>}<br/>
                                    {this.state.gameStarted && <Button onClick={() => this.updatedLeaveHandler('convertToSpectator')} className="kickBtn">SPECTATE</Button>}
                                    {!this.state.gameStarted && this.state.firstGameStarted && <span className="startgametext">Click here to join as a Spectator</span>}<br/>
                                    {!this.state.gameStarted && this.state.firstGameStarted && <Button onClick={this.playerToSpectatorhandler} className="kickBtn">SPECTATE</Button>}
                                    <br/>
                                    <h5><span className="startgametext">Are you sure you want to leave the room?</span></h5>
                                    {!(this.state.connectedPlayers.length < 2 && !this.state.gameStarted && this.state.allPlayersReady) && <Button className="kickBtn" onClick={() => this.updatedLeaveHandler('kickOutFromRoom')}>YES</Button>}
                                    {this.state.connectedPlayers.length < 2 && !this.state.gameStarted && this.state.allPlayersReady && <Button className="kickBtn" onClick={this.onDestroyRoom}>DESTROY</Button>}
                                    <Button className="kickBtn" onClick={() => this.setState({showLeaveOptions: false})}>NO</Button>
                                    <br/>
                                </Col>}
                                {this.state.spectate && <Col>
                                    <h5><span className="startgametext">Are you sure you want to leave the room?</span></h5>
                                    <Button className="kickBtn" onClick={() => this.leaveHandler('kickOutFromRoom')}>YES</Button>
                                    <Button className="kickBtn" onClick={() => this.setState({showLeaveOptions: false})}>NO</Button>
                                    <br/>
                                </Col>}
                            </div>}
                        {showWinningSelection && this.state.gameName357!=="threefiveseven" &&
                            <div className="typeselection">
                                <Col>
                                    {winningTypeAnaconda.length === 0 && <h4><span className="startgametext">Select Winning Type</span></h4>}
                                    {winningTypeAnaconda.length > 0 && <h4><span className="startgametext">Select Winning Type is {winningTypeAnaconda !== "Mixed" ? winningTypeAnaconda : "Both"}</span></h4>}
                                    {isAnacondaLive && <Row>
                                        {winningTypeAnaconda === 'High' && <Button className="Anaconda-winning" onClick={() => this.selectedWinningType("High")}>HIGH</Button>}
                                        {winningTypeAnaconda !== 'High' && <Button className="GameBtn" onClick={() => this.selectedWinningType("High")}>HIGH</Button>}
                                        {winningTypeAnaconda === 'Low' && <Button className="Anaconda-winning" onClick={() => this.selectedWinningType("Low")}>LOW</Button>}
                                        {winningTypeAnaconda !== 'Low' && <Button className="GameBtn" onClick={() => this.selectedWinningType("Low")}>LOW</Button>}
                                        {winningTypeAnaconda === 'Mixed' && <Button className="Anaconda-winning" onClick={() => this.selectedWinningType("Mixed")}>BOTH</Button>}
                                        {winningTypeAnaconda !== 'Mixed' && <Button className="GameBtn" onClick={() => this.selectedWinningType("Mixed")}>BOTH</Button>}
                                    </Row>}
                                    {is727Live && <Row>
                                        {winningTypeAnaconda === 'Low' && <Button className="Anaconda-winning" onClick={() => this.selectedWinningType("Low")}>7</Button>}
                                        {winningTypeAnaconda !== 'Low' && <Button className="GameBtn" onClick={() => this.selectedWinningType("Low")}>7</Button>}
                                        {winningTypeAnaconda === 'High' && <Button className="Anaconda-winning" onClick={() => this.selectedWinningType("High")}>27</Button>}
                                        {winningTypeAnaconda !== 'High' && <Button className="GameBtn" onClick={() => this.selectedWinningType("High")}>27</Button>}
                                        {winningTypeAnaconda === 'Mixed' && <Button className="Anaconda-winning" onClick={() => this.selectedWinningType("Mixed")}>BOTH</Button>}
                                        {winningTypeAnaconda !== 'Mixed' && <Button className="GameBtn" onClick={() => this.selectedWinningType("Mixed")}>BOTH</Button>}
                                    </Row>}
                                    <Button className="SubmitBtn" onClick={() => this.submitWinningType(winningTypeAnaconda)}>SUBMIT</Button>
                                </Col>
                            </div>
                        }
                        {this.state.voteOutActionEnabled &&
                            <div className="typeselection">
                                <Col>
                                    <h5><span className="startgametext">Vote to Kick {this.state.playerToVoteOut} - {this.state.voteOutType ==='keepOnTable' && <span>AUTO-FOLD/BE-RIGHT-BACK</span>}{this.state.voteOutType ==='convertToSpectator' && <span>CONVERT TO SPECTATOR</span>}{this.state.voteOutType ==='kickOutFromRoom' && <span>KICK OUT FROM ROOM</span>}</span></h5>
                                    <Button className="kickBtn" onClick={this.playerVoteActionYes}>YES</Button>
                                    <Button className="kickBtn" onClick={this.playerVoteActionNo}>NO</Button>
                                </Col>
                            </div>
                        }
                        {this.state.showVoteOutOptions && <div className="amountSelect">
                            <Col>
                                <h4><span className="startgametext">VOTE OUT {this.state.voteoutPlayerName.value}</span></h4>
                                <div><Button className="StartGameBtn"
                                        onClick={() => this.voteOutOptionPressed('convertToSpectator')}>
                                    Convert to Spectator
                                </Button></div>
                                <div><Button className="StartGameBtn"
                                        onClick={() => this.voteOutOptionPressed('kickOutFromRoom')}>
                                    Kick-Out from room
                                </Button></div>
                                <div><Button className="StartGameBtn"
                                        onClick={() => this.voteOutOptionPressed('keepOnTable')}>
                                    Keep on Table
                                </Button></div>
                            </Col>
                        </div>}
                        {this.state.showInviteMenu && <div className="invitemenu">
                            <div className="topinvite">
                                <Col>
                                    <div className="inviteheader">Invite Your Friends</div>
                                    <div className="inviteinfo">Get $1 credit when they make their first purchase</div>
                                    <ReactMultiEmail
                                        placeholder="Type or paste email addresses and press `Enter`..."
                                        emails={emails}
                                        onChange={(emails) => {
                                            this.setState({ emails: emails });
                                        }}
                                        validateEmail={email => {
                                            return isEmail(email); // return boolean
                                        }}
                                        getLabel={(
                                            email,
                                            index,
                                            removeEmail,
                                        ) => {
                                            return (
                                            <div data-tag key={index}>
                                                {email}
                                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                                ×
                                                </span>
                                            </div>
                                            );
                                        }}
                                    />
                                    <div>
                                        <Button className="inviteBtn" onClick={this.inviteFriends}>
                                            SEND INVITE
                                        </Button>
                                        <Button className="inviteBtn" onClick={() => this.setState({showInviteMenu: false})}>
                                            CLOSE
                                        </Button>
                                    </div>
                                </Col>
                            </div>
                            <div className="bottominvite">
                                <Row>
                                    <div>
                                        <h5><span className="invitelist">FREQUENTLY PLAYED WITH</span></h5>
                                        {this.state.frequentFriends.map((friend) => {return(
                                            <div className="invitefriend">
                                                <input key={friend.id} type="checkbox" onClick={(e) => this.handleFriendCheck(e, friend.friendType)} checked={friend.isChecked} value={friend.name} /> {friend.name}        
                                            </div>
                                        )})}
                                    </div>
                                    <div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
                                    <div>
                                        <h5><span className="invitelist">RECENTLY PLAYED WITH</span></h5>
                                        {this.state.lastPlayedWith.map((friend) => {return(
                                            <div className="invitefriend">
                                                <input key={friend.id} type="checkbox" onClick={(e) => this.handleFriendCheck(e, friend.friendType)} checked={friend.isChecked} value={friend.name} /> {friend.name}        
                                            </div>
                                        )})}
                                    </div>
                                </Row>
                            </div>
                        </div>}
                        {this.state.showHomeRunArrangements && <div className="homeRunSelect">
                            <Col>
                                <Select placeholder="Arrangement"
                                        value={this.state.selectedArrangement.name}
                                        onChange={this.handleArrangementChange}
                                        options={this.state.homeRunOptions}
                                />
                                <div className="cardarrangement">
                                {!loading && 
                                // <ListManager
                                //         items={sortCards(getItems(this.state.defaultArrangement))} direction="horizontal" maxItems={13} render={(item) => this.listHomeRunElement(item)} 
                                // />
                                <Row>{this.state.defaultArrangement.map((card, index) => (<div>
                                    <div key={index}><Cards suit={card[card.length - 1]}
                                                            value={card.slice(0, card.length - 1)}/></div></div>
                                ))}</Row>}
                                {loading && <div className="arrangetext">Arranging your cards in all the ways. Please wait...</div>}
                                {loading && (
                                    <img src={LoadingGIF} alt="Loading..." className='loading-image'/>
                                )}
                                </div>
                                <br/>
                                <div>
                                    <Button className="StartGameBtn" onClick={() => this.setState({showHomeRunArrangements: false, selectedCards: this.state.defaultArrangement,
                                                                                                selectedCardsSortedList: sortCards(getItems(this.state.defaultArrangement))})}>
                                        SELECT
                                    </Button>
                                </div>
                                <div>
                                    <Button className="StartGameBtn" onClick={() => this.setState({showHomeRunArrangements: false})}>
                                        CLOSE
                                    </Button>
                                </div>
                            </Col>
                        </div>}
                        {this.state.showSpectatorToPlayerSheet && <div className="amountSelect">
                            <Col>
                                <h4><span className="startgametext">Select your starting amount</span></h4>
                                <input 
                                    className="textboxchooseamount p-2"
                                    style={{resize: "none"}}
                                    type="number"
                                    name="initialBuyAmount"
                                    placeholder={this.state.initialBuyAmount}
                                    value={Number(this.state.initialBuyAmount).toString()}
                                    onChange={this.handleHouseRulesChange}
                                />
                                <div><Button className="StartGameBtn"
                                        onClick={this.onSpectatorJoinTable}>
                                    JOIN TABLE
                                </Button></div>
                                <div><Button className="StartGameBtn"
                                        onClick={() => {this.setState({showSpectatorToPlayerSheet: false})}}>
                                    CLOSE
                                </Button></div>
                            </Col>
                        </div>}
                        {this.state.showPayUpSheet && <div className="amountSelect">
                            <Col>
                                <h4><span className="startgametext">Pay {this.state.payUpAmount} for Face-Up WIld Cards</span></h4>
                                <div><Button className="StartGameBtn" onClick={() => this.handlePayForFaceUpWildCard("PAY")}>
                                    PAY
                                </Button></div>
                                <div><Button className="StartGameBtn" onClick={() => this.handlePayForFaceUpWildCard("FOLD")}>
                                    FOLD
                                </Button></div>
                            </Col>
                        </div>}
                        {this.state.showAmountSelection && <div className="amountSelect">
                            <Col>
                                <h4><span className="startgametext">{dealer} started NO-LIMIT game</span></h4>
                                <Row>
                                <span className="startgametext"><h6>Choose amount to risk in this game &nbsp; &nbsp;</h6></span>
                                <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip`}>
                                            The amount you choose to risk in this NO-LIMIT game. The remaining amount will be returned after the end of the game
                                        </Tooltip>
                                    }>
                                    <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                </OverlayTrigger>
                                </Row>
                                <input 
                                    className="textboxchooseamount p-2"
                                    style={{resize: "none"}}
                                    type="number"
                                    name="pencilAmount"
                                    placeholder={this.state.pencilAmount}
                                    value={Number(this.state.pencilAmount).toString()}
                                    onChange={this.handleHouseRulesChange}
                                />
                                <div><Button className="StartGameBtn"
                                        onClick={this.onSelectPencilAmount}>
                                    PLAY
                                </Button></div>
                            </Col>
                        </div>}
                        {this.state.setGameRules && <div className="gameRules wildcardrules">
                            <button className={'wildCardBackBtn mb-10 p-1 mr-1'} type="submit" onClick={() => this.setState({setGameRules: false})}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                            <Col>
                                <h3><span className="startgametext">GAME RULES</span></h3>
                                <div className="wildCardSelection">
                                    <Row>
                                        <div>
                                            <div className="font-weight-bold"><span className="startgametext">Ante</span></div>
                                            <input 
                                                className="textboxstartgame p-2"
                                                style={{resize: "none"}}
                                                type="number"
                                                name="localAnteValue"
                                                placeholder={this.state.localAnteValue}
                                                value={Number(this.state.localAnteValue).toString()}
                                                onChange={this.handleHouseRulesChange}
                                                // disabled={!this.state.houseRulesEnabled}
                                            />
                                        </div>
                                        <div>&nbsp; &nbsp;</div>
                                        <div>
                                            <div className="font-weight-bold"><span className="startgametext">Max No. of Raise</span></div>
                                            <input 
                                                    className="textboxstartgame p-2"
                                                    style={{resize: "none"}}
                                                    type="number"
                                                    name="localMaxNoRaises"
                                                    placeholder={this.state.localMaxNoRaises}
                                                    value={Number(this.state.localMaxNoRaises).toString()}
                                                    onChange={this.handleHouseRulesChange}
                                                    // disabled={!this.state.houseRulesEnabled}
                                            />
                                        </div>
                                        <div>&nbsp; &nbsp;</div>
                                        <div>
                                            <div className="font-weight-bold"><span className="startgametext">Max Bet</span></div>
                                            <input 
                                                className="textboxstartgame p-2"
                                                style={{resize: "none"}}
                                                type="number"
                                                name="localMaxBetAllowed"
                                                placeholder={this.state.localMaxBetAllowed}
                                                value={Number(this.state.localMaxBetAllowed).toString()}
                                                onChange={this.handleHouseRulesChange}
                                                // disabled={!this.state.houseRulesEnabled}
                                            />
                                        </div>
                                        <div>&nbsp; &nbsp;</div>
                                        <div>
                                            <div className="font-weight-bold"><span className="startgametext">Min Bet</span></div>
                                            <input 
                                                className="textboxstartgame p-2"
                                                style={{resize: "none"}}
                                                type="number"
                                                name="localMinBetAllowed"
                                                placeholder={this.state.localMinBetAllowed}
                                                value={Number(this.state.localMinBetAllowed).toString()}
                                                onChange={this.handleHouseRulesChange}
                                                // disabled={!this.state.houseRulesEnabled}
                                            />
                                        </div>
                                        <div>&nbsp; &nbsp;</div>
                                        <div>
                                            <Button onClick={this.houseRulesHandler} className="DefaultBtn" type="submit">Default</Button>
                                        </div>
                                        {bettingGameSelected && <div>&nbsp; &nbsp;</div>}
                                        {bettingGameSelected && <div>
                                            <Button onClick={this.noLimithandler} className="DefaultBtn" type="submit">No-Limit</Button>
                                        </div>}
                                    </Row>
                                </div>
                                {(selectedGame.value === "5-Card-Draw" || selectedGame.value === "5-Card-Draw Round" || selectedGame.value === "Diablo" || selectedGame.value === "Frankenstein") && <span className="startgametext">
                                    <input type="checkbox" onClick={this.handle5CDOpening} checked={this.state.restrict5CDOpening}/>&nbsp; Min Pair J's<br/>
                                </span>}
                                {(selectedGame.value === "Baseball-9CD" || selectedGame.value === "Blind Baseball") && <span className="startgametext">
                                    <input type="checkbox" onClick={this.handleBaseballOpening} checked={this.state.restrictBaseballOpening}/>&nbsp; Restrict Bet Opening<br/>
                                </span>}
                                {(selectedGame.value === "Baseball-9CD" || selectedGame.value === "Blind Baseball") && this.state.restrictBaseballOpening && <div className="wildCardSelection"><table className="wildcardtable">
                                    <tr>
                                        <td className={`byrankelement ${defaultBaseballOpening === '2' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: '2'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === '2' ? "rankbuttonselected" : ""}`}>2</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === '3' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: '3'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === '3' ? "rankbuttonselected" : ""}`}>3</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === '4' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: '4'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === '4' ? "rankbuttonselected" : ""}`}>4</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === '5' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: '5'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === '5' ? "rankbuttonselected" : ""}`}>5</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === '6' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: '6'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === '6' ? "rankbuttonselected" : ""}`}>6</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === '7' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: '7'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === '7' ? "rankbuttonselected" : ""}`}>7</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === '8' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: '8'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === '8' ? "rankbuttonselected" : ""}`}>8</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={`byrankelement ${defaultBaseballOpening === '9' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: '9'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === '9' ? "rankbuttonselected" : ""}`}>9</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === 'T' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: ''})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === 'T' ? "rankbuttonselected" : ""}`}>10</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === 'J' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: 'J'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === 'J' ? "rankbuttonselected" : ""}`}>J</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === 'Q' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: 'Q'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === 'Q' ? "rankbuttonselected" : ""}`}>Q</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === 'K' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: 'K'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === 'K' ? "rankbuttonselected" : ""}`}>K</button>
                                        </td>
                                        <td className={`byrankelement ${defaultBaseballOpening === 'A' ? "rankselected" : ""}`} onClick={() => this.setState({defaultBaseballOpening: 'A'})}>
                                            <button className={`byrankbutton ${defaultBaseballOpening === 'A' ? "rankbuttonselected" : ""}`}>A</button>
                                        </td>
                                    </tr>
                                </table>
                                <span className="startgametext"> &nbsp; Pair</span>
                                </div>}
                                {(selectedGame.value === "Diablo") && <span className="startgametext font-weight-bold">
                                    Max Pot To Replace &nbsp; &nbsp;
                                    <input 
                                        className="textboxstartgame p-2"
                                        style={{resize: "none"}}
                                        type="number"
                                        name="localmaxPotToReplace"
                                        placeholder={this.state.localmaxPotToReplace}
                                        value={Number(this.state.localmaxPotToReplace).toString()}
                                        onChange={this.handleHouseRulesChange}
                                        min="1" max="20"
                                        // disabled={!this.state.houseRulesEnabled}
                                    />
                                </span>}
                                {(selectedGame.value === "Ironcross") && <span className="startgametext">
                                    <input type="checkbox" onClick={this.handleSplitHiLo} checked={this.state.localSplitHiLo}/>&nbsp; Split Hi Lo<br/>
                                </span>}
                                {(selectedGame.value === "Do Ya") && <span className="startgametext">
                                    <input type="checkbox" onClick={this.handleDoYa} checked={this.state.localDoYa}/>&nbsp; Do-Ya High Lo<br/>
                                </span>}
                                {(selectedGame.value === "727") && <div className="startgametext">
                                    No of Passes
                                    &nbsp; &nbsp;
                                    <button className={'jokersmodifyBtn mb-10 p-1 mr-1'} type="submit" onClick={() => this.handleNoOfPassesChange('-')}>
                                        -
                                    </button>
                                    &nbsp;
                                    <span className="jokertext">{this.state.local727NoOfPasses}</span>
                                    &nbsp; &nbsp;
                                    <button className={'jokersmodifyBtn mb-10 p-1 mr-1'} type="submit" onClick={() => this.handleNoOfPassesChange('+')}>
                                        +
                                    </button>
                                </div>}
                                {(selectedGame.value === "727") && <span className="startgametext">
                                    <input type="checkbox" onClick={this.handle727HouseRules} name="simultaneousPassDrawRound" checked={localSimultaneousPassDrawRound}/>&nbsp; Simultaneous Pass/Draw &nbsp;
                                    <OverlayTrigger placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    All players gets to choose PASS/DRAW together. Unchecking it means PASS/DRAW will be asked one-by-one
                                                </Tooltip>
                                                    }>
                                        <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                    </OverlayTrigger><br/>
                                </span>}
                                {(selectedGame.value === "727") && <span className="startgametext">
                                    <input type="radio" onClick={this.handle727HouseRules} name="localStrictSum" checked={localStrictSum}/>&nbsp; Strict Sum &nbsp;
                                    <OverlayTrigger placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    You have to be below or equal to the target to win. You can't win the 7 pot if you are over 7 or anything at all if you are over 27
                                                </Tooltip>
                                                    }>
                                        <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                    </OverlayTrigger><br/>
                                </span>}
                                {/* {selectedGame.value === "727" && !localStrictSum && <h5><span className="startgametext">STRICT SUM: False<br/></span></h5>} */}
                                {(selectedGame.value === "727") && <span className="startgametext">
                                    <input type="radio" onClick={this.handle727HouseRules} name="localSplitPotIfStrictSumFalse" checked={localSplitPotIfStrictSumFalse}/>&nbsp; Split Pot &nbsp;
                                    <OverlayTrigger placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    Just the difference from 7 or 27, above or below, counts: Pot gets split between 6.5 and 7.5
                                                </Tooltip>
                                                    }>
                                        <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                    </OverlayTrigger><br/>
                                </span>}
                                {(selectedGame.value === "727") && <span className="startgametext">
                                    <input type="radio" onClick={this.handle727HouseRules} name="priceRight" checked={!localStrictSum && !localSplitPotIfStrictSumFalse ? localTieGoesToLow : false}/>&nbsp; Price is Right &nbsp;
                                    <OverlayTrigger placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    6.5 beats 7.5, 26.5 beats 27.5
                                                </Tooltip>
                                                    }>
                                        <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                    </OverlayTrigger><br/>
                                </span>}
                                {(selectedGame.value === "727") && <span className="startgametext">
                                    <input type="radio" onClick={this.handle727HouseRules} name="priceWrong" checked={!localStrictSum && !localSplitPotIfStrictSumFalse ? !localTieGoesToLow : false}/>&nbsp; Price is Wrong &nbsp;
                                    <OverlayTrigger placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    7.5 beats 6.5, 27.5 beats 26.5
                                                </Tooltip>
                                                    }>
                                        <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                    </OverlayTrigger><br/>
                                </span>}
                                {(selectedGame.value === "3-Brent-7" || selectedGame.value === "Anaconda" || selectedGame.value === "Cincinnati" || selectedGame.value === "Lo Chicago" || selectedGame.value === "Omaha8" || selectedGame.value === "Omaha8 Round" || selectedGame.value === "Ironcross" || selectedGame.value === "Do Ya") && <span className="startgametext">
                                    <input type="checkbox" onClick={this.handleLoAce} checked={this.state.loAce}/>&nbsp; Consider Ace Low<br/>
                                </span>}
                                
                                {(selectedGame.value === "3-Brent-7" || selectedGame.value === "Anaconda" || selectedGame.value === "Cincinnati" || selectedGame.value === "Lo Chicago" || selectedGame.value === "Omaha8" || selectedGame.value === "Omaha8 Round" || selectedGame.value === "Ironcross" || selectedGame.value === "Do Ya") && <span className="startgametext">
                                    <input type="checkbox" onClick={this.handleLoFlush} checked={this.state.loFlush}/>&nbsp; Count Straight/Flush &nbsp;
                                    <OverlayTrigger placement="bottom"
                                                    overlay={
                                                        <Tooltip id={`tooltip`}>
                                                            Checking it will consider Straight/Flush hand as it is and not a lo-hand.
                                                        </Tooltip>
                                                    }>
                                        <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                    </OverlayTrigger><br/>
                                </span>}
                                <br/>
                                {selectedGame.value !== "727" && <div>
                                    <h3><span className="startgametext">WILD CARD RULES</span></h3>
                                    <div className="startgametext">
                                        No of Jokers
                                        &nbsp; &nbsp;
                                        <button className={'jokersmodifyBtn mb-10 p-1 mr-1'} type="submit" onClick={() => this.handleJokerChange('-')}>
                                            -
                                        </button>
                                        &nbsp;
                                        <span className="jokertext">{this.state.noOfJokers}</span>
                                        &nbsp; &nbsp;
                                        <button className={'jokersmodifyBtn mb-10 p-1 mr-1'} type="submit" onClick={() => this.handleJokerChange('+')}>
                                            +
                                        </button>
                                    </div>
                                    <br/>
                                    <div className="startgametext">
                                        <input type="checkbox" onClick={this.handleRoyalFlush} checked={this.state.localRoyalFlushBeatsFiveOfKind}/>&nbsp; Royal-Flush beats 5-Of-A-Kind<br/>
                                    </div>
                                    <div className="startgametext">
                                        <input type="checkbox" onClick={this.handleNaturalCard} checked={this.state.localhandsMustHaveOneNaturalCard}/>&nbsp; Hand must have 1 Natural Card<br/>
                                    </div>
                                    {selectedGame.value === "Follow The Queen" && <div className="startgametext">
                                        <input type="checkbox" onClick={this.handleQueensWild} checked={this.state.localQueensWild}/>&nbsp; Make Queens Wild<br/>
                                    </div>}
                                    {(selectedGame.value === "7-Card-Stud" || selectedGame.value === "Lo Chicago" || selectedGame.value === "High Chicago" || selectedGame.value === "Follow The Queen" || 
                                        selectedGame.value === "Baseball 7CS" || selectedGame.value === "Countdown") && <div className="startgametext font-weight-bold">
                                        Pay for Face-Up Wild-Cards &nbsp; &nbsp;
                                        <input 
                                            className="textboxstartgame p-2"
                                            style={{resize: "none"}}
                                            type="number"
                                            name="localPayForFaceUpWildCard"
                                            placeholder={this.state.localPayForFaceUpWildCard}
                                            value={Number(this.state.localPayForFaceUpWildCard).toString()}
                                            onChange={this.handleHouseRulesChange}
                                        />
                                    </div>}
                                    <br/>
                                    <div className="wildCardSelection">
                                        <Row>
                                            <div>
                                                <Select placeholder="Wild Cards"
                                                    value={this.state.selectedWildCardChoice.name}
                                                    onChange={this.handleWildCardsChange}
                                                    options={this.state.wildCardOptions}
                                                />
                                            </div>
                                            {this.state.wildCardSelectedOption === "By Rank" && <div>&nbsp; &nbsp;</div>}
                                            {this.state.wildCardSelectedOption === "By Rank" && <div>
                                                {/* <Select placeholder="Choose Rank"
                                                    isMulti={true}
                                                    onChange={this.handleWildCardsByRankChange}
                                                    options={this.state.wildCardOptionsByRank}
                                                    closeMenuOnSelect={false}
                                                /> */}
                                                <table className="wildcardtable">
                                                    <tr>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('2') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('2')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('2') ? "rankbuttonselected" : ""}`}>2</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('3') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('3')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('3') ? "rankbuttonselected" : ""}`}>3</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('4') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('4')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('4') ? "rankbuttonselected" : ""}`}>4</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('5') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('5')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('5') ? "rankbuttonselected" : ""}`}>5</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('6') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('6')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('6') ? "rankbuttonselected" : ""}`}>6</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('7') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('7')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('7') ? "rankbuttonselected" : ""}`}>7</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('8') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('8')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('8') ? "rankbuttonselected" : ""}`}>8</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('9') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('9')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('9') ? "rankbuttonselected" : ""}`}>9</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('10') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('10')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('10') ? "rankbuttonselected" : ""}`}>10</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('J') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('J')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('J') ? "rankbuttonselected" : ""}`}>J</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('Q') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('Q')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('Q') ? "rankbuttonselected" : ""}`}>Q</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('K') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('K')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('K') ? "rankbuttonselected" : ""}`}>K</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsByRank.includes('A') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsByRankChangeTable('A')}>
                                                            <button className={`byrankbutton ${selectedWildCardsByRank.includes('A') ? "rankbuttonselected" : ""}`}>A</button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>}
                                            {this.state.wildCardSelectedOption === "By Rank" && <div>&nbsp; &nbsp; &nbsp;</div>}
                                            {this.state.wildCardSelectedOption === "By Rank" && <div>
                                                {/* <Select placeholder="Choose Suit"
                                                    isMulti={true}
                                                    onChange={this.handleWildCardsBySuitChange}
                                                    options={this.state.wildCardOptionsBySuit}
                                                    closeMenuOnSelect={false}
                                                /> */}
                                                <table className="wildcardtable">
                                                    <tr>
                                                        <td className={`byrankelement ${selectedWildCardsBySuit.includes('♦︎') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsBySuitChangeTable('♦︎')}>
                                                            <button className={`byrankbutton ${selectedWildCardsBySuit.includes('♦︎') ? "rankbuttonselected" : ""}`}>Diamond ♦︎</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsBySuit.includes('♣︎') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsBySuitChangeTable('♣︎')}>
                                                            <button className={`byrankbutton ${selectedWildCardsBySuit.includes('♣︎') ? "rankbuttonselected" : ""}`}>Club ♣︎</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={`byrankelement ${selectedWildCardsBySuit.includes('♥︎') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsBySuitChangeTable('♥︎')}>
                                                            <button className={`byrankbutton ${selectedWildCardsBySuit.includes('♥︎') ? "rankbuttonselected" : ""}`}>Heart ♥︎</button>
                                                        </td>
                                                        <td className={`byrankelement ${selectedWildCardsBySuit.includes('♠︎') ? "rankselected" : ""}`} onClick={() => this.handleWildCardsBySuitChangeTable('♠︎')}>
                                                            <button className={`byrankbutton ${selectedWildCardsBySuit.includes('♠︎') ? "rankbuttonselected" : ""}`}>Spade ♠︎</button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>}
                                        </Row>
                                    </div>
                                    {<div><Button className="StartGameBtn" onClick={this.addWildCards}>
                                        {this.state.addWildCardsBool && <span>ADD CARDS</span>}{!this.state.addWildCardsBool && <span>REMOVE CARDS</span>}
                                    </Button></div>}
                                    {this.state.selectedWildCardsList.length === 0 && <span className="startgametext">NO WILD CARDS SELECTED</span>}
                                    {this.state.selectedWildCardsList.length > 0 && <span className="startgametext">WILD CARDS</span>}
                                    <br/>
                                    <div>
                                        <ListManager
                                            items={sortCards(getItems(this.state.selectedWildCardsList))}
                                            direction="horizontal"
                                            maxItems={17}
                                            render={(item) => this.listWildCardElement(item)}
                                        />
                                    </div>
                                </div>}
                                <div><Button className="StartGameBtn"
                                        disabled={members < selectedGame.minPlayers || members > selectedGame.maxPlayers}
                                        onClick={this.startGameWildCards}>
                                    START GAME
                                </Button></div>
                            </Col>
                        </div>}
                        {this.state.showFeedbackSheet && <div className="feedbackSheet">
                            <Col>
                                <div className="feedbackheading">FEEDBACK</div>
                                <br/>
                                <div>
                                <textarea name="feedbackText"
                                    className="sendMsgArea p-2"
                                    style={{resize: "none"}}
                                    type="text"
                                    placeholder="Write your experience"
                                    value={this.state.feedbackText}
                                    onChange={this.handleFeedbackChange}
                                />
                                </div>
                                <br/>
                                <Button onClick={() => this.setState({showFeedbackSheet: false})} className="feedbackClose">CLOSE</Button>
                                <Button onClick={this.handleFeedbackSubmit} className="feedbackClose">SUBMIT</Button>
                            </Col>
                        </div>}
                        {(this.state.isAnacondaLive || this.state.is5CDLive || 
                            this.state.is7CSLive || this.state.isCincinnatiLive || is727Live || is5CSLive || isBaseballLive ||
                            this.state.isTexasLive) && <div className="potdetails"><Button className="GameBtn" onClick={this.setPotModalShow}>POTS</Button></div>}
                        {this.state.gameEndsHomeRun && <div><Button className="GameBtn" onClick={() => this.setState({showModalHomeRun: true})}>ALL-DETAILS</Button></div>}
                        <div><Button className="GameBtn" onClick={this.setAccountShow}>Account</Button></div>
                        <div className="countdown-timer"><input type="checkbox" onClick={this.handleAutoReady} value="autoReady" checked={this.state.autoReady}/>AUTO-READY</div>
                    </div>
                    {this.state.showChatWindow && <div className="chatContainer ml-auto" onClick={() => this.setState({setGameRules: false})}>
                        {this.state.spectatorList.length > 0 && <div className="spectatorcontainer">
                            <div className="spectator-title">
                                <span>SPECTATORS</span>
                            </div>
                            <div>
                                {this.state.spectatorList.map((spectator, index) =>{
                                        return(
                                            <div className="spectator-row">
                                                <Avatar name={spectator.name} maxInitials={1} size={20} round={true}
                                                textSizeRatio={2}/>
                                                <span className="spectator-details" key={index}>{spectator.name}</span>
                                                {!this.state.spectate && <span className="spectator-remove" onClick={() => this.kickSpectatorOut(spectator.email)}>Remove</span>}
                                                <br/>
                                            </div>)}
                                )}
                            </div>
                        </div>}
                        <div className="chatMessages p-2">
                            <Messages messages={messages}/>
                        </div>
                        <form className="form p-2" onSubmit={this.sendMessage}>
                            <Row className="w-100">
                                <textarea name="message"
                                            className="sendMsgArea p-2"
                                            style={{resize: "none"}}
                                            type="text"
                                            placeholder="Type a message..."
                                            value={message}
                                            onChange={this.handleChange}
                                            onKeyDown={this.onEnterPress}
                                />
                            </Row>
                        </form>
                    </div>}
                </div>
                <div className="roomFooter d-flex" onClick={() => this.setState({setGameRules: false})}>
                    <div className="d-flex w-100 h-100">
                        {/* {this.state.gameEndsHomeRun && <div className="d-flex w-100">
                            <Row className="mx-auto">
                                <div className="d-flex my-auto ml-4">
                                    <Button className="GameBtn" onClick={() => this.setState({showModalHomeRun: true})}>ALL-DETAILS</Button>
                                </div>
                            </Row>
                        </div>} */}
                        {(cards.length > 0 || selectedCards.length > 0 || ((currentGameName === "Baseball-9CD" || currentGameName === "Blind Baseball") && (this.state.upCards7CS.length > 0 || this.state.downCards7CS.length > 0 || this.state.cantSeeCardsBaseball.length > 0))) &&
                            <div className="d-flex w-100">
                                <Row className="mx-auto">
                                    {this.state.selectInOutShow &&
                                        <Col className="my-auto">
                                            <div className="d-flex"><div className="countdown-timer"><h1>Time: &nbsp;</h1></div>
                                                <div className="countdown-timer"><h1><CounterApp time={5}/></h1></div>
                                                {cards.length >= 5 && !sideBetLowPress && currentGameName==="357" && <Button className="GameBtn" onClick={this.sideBetLowIn}>SIDE BET LOW</Button>}
                                                {cards.length >= 5 && sideBetLowPress && currentGameName==="357" && <Button className="Anaconda-winning" onClick={this.sideBetLowOut}>UNDO SIDE BET</Button>}
                                                {cards.length === 7 && !sideBetLowPress && currentGameName==="3 Brent 7" && <Button className="GameBtn" onClick={this.sideBetLowIn}>SIDE BET LOW</Button>}
                                                {cards.length === 7 && sideBetLowPress && currentGameName==="3 Brent 7" && <Button className="Anaconda-winning" onClick={this.sideBetLowOut}>UNDO SIDE BET</Button>}
                                                {selection357 !== 'Hold' && <Button className="GameBtn" onClick={this.onInPress}>HOLD</Button>}
                                                {selection357 === 'Hold' && <Button className="Anaconda-winning" onClick={this.onInPress}>HOLD</Button>}
                                                {selection357 !== 'Drop' && <Button className="GameBtn" onClick={this.onOutPress}>DROP</Button>}
                                                {selection357 === 'Drop' && <Button className="Anaconda-winning" onClick={this.onOutPress}>DROP</Button>}
                                            </div>
                                        </Col>
                                    }
                                    {bettingEnabled &&
                                        <Row>
                                            {allowedActions.includes("raise") && stack >= gameMinBet && <div className="my-auto d-flex">
                                            <Button className="GameBtn" onClick={() => this.onRaisePress(gameMinBet)}>MIN-RAISE {gameMinBet}</Button></div>}
                                            {allowedActions.includes("raise") && stack >= gameMinBet && <div className="my-auto d-flex">
                                            <Button className="GameBtn" onClick={this.onMaxRaisePress}>MAX-BET</Button></div>}
                                        <Col className="my-auto">
                                            <div className="d-flex">
                                                {allowedActions.includes("raise") && stack >= gameMinBet && <input 
                                                    className="sendRaiseArea p-2"
                                                    style={{resize: "none"}}
                                                    type="number"
                                                    placeholder={raisedAmount + maxBet - currentBet}
                                                    value={Number(this.state.raisedAmount + maxBet - currentBet).toString()}
                                                    onChange={this.handleRaiseChange}
                                                />}
                                                {allowedActions.includes("raise") &&  stack >= gameMinBet && <div className='myslider slider custom-labels'>
                                                    <StyledSlider
                                                        min={gameMinBet}
                                                        step={5}
                                                        max={(stack - (maxBet - currentBet)) < ((maxBet - currentBet) + this.state.gameMaxBet) ? (stack - (maxBet - currentBet)) : (this.state.gameMaxBet)}
                                                        onChange={this.handleAmountChange}                                                        
                                                        renderThumb={(props, state) => <StyledThumb {...props}>{this.state.raisedAmount + maxBet - currentBet}</StyledThumb>}
                                                        renderTrack={(props, state) => <StyledTrack {...props} index={state.index} />}
                                                    />
                                                </div>
                                                }
                                            </div>
                                            <div className="d-flex">
                                            {allowedActions.includes("pass") && <Button className="GameBtn" onClick={this.onPassPress}>CHECK</Button>}
                                            {allowedActions.includes("call") && <Button className="GameBtn" onClick={this.onCallPress}>{stack - (maxBet - currentBet) <= 0 ? `ALL-IN` : `CALL  ${maxBet - currentBet}`}</Button>}
                                            {allowedActions.includes("raise") && stack >= gameMinBet && <Button className="GameBtn" disabled={stack - (maxBet - currentBet) < 0 || raisedAmount + (maxBet - currentBet) > stack || raisedAmount === 0} onClick={() => this.onRaisePress(raisedAmount)}>{isFirstPlayer ? `BET  ${raisedAmount + maxBet}` : `RAISE  ${raisedAmount}`}  </Button>}
                                            {allowedActions.includes("fold") && <Button className="GameBtn" onClick={this.onFoldPress}>FOLD</Button>}
                                            </div>
                                        </Col>
                                        </Row>
                                    }
                                    {!this.state.is7CSLive && !is727Live && !is5CSLive && !this.state.isHomeRunLive && !isBaseballLive && <div className="d-flex my-auto ml-4">
                                        <ListManager
                                            items={this.state.sortedList}
                                            direction="horizontal"
                                            maxItems={7}
                                            render={(item) => this.listElement(item)}
                                            onDragEnd={this.reorderList}
                                        />
                                    </div>}
                                    {this.state.isHomeRunLive && !this.state.submitSettingsButton && <div className="countdown-timer d-flex my-auto ml-4">Front</div>}
                                    {this.state.isHomeRunLive && !this.state.submitSettingsButton && <div className="d-flex my-auto ml-4">
                                        <ListManager
                                            items={sortCards(getItems(this.state.frontCards))}
                                            direction="horizontal"
                                            maxItems={3}
                                            render={(item) => this.listHomeRunElement(item)}
                                        />
                                    </div>}
                                    {this.state.isHomeRunLive && !this.state.submitSettingsButton && <div className="countdown-timer d-flex my-auto ml-4">Middle</div>}
                                    {this.state.isHomeRunLive && !this.state.submitSettingsButton && <div className="d-flex my-auto ml-4">
                                        <ListManager
                                            items={sortCards(getItems(this.state.middleCards))}
                                            direction="horizontal"
                                            maxItems={5}
                                            render={(item) => this.listHomeRunElement(item)}
                                        />
                                    </div>}
                                    {this.state.isHomeRunLive && !this.state.submitSettingsButton && <div className="countdown-timer d-flex my-auto ml-4">Back</div>}
                                    {this.state.isHomeRunLive && !this.state.submitSettingsButton && <div className="d-flex my-auto ml-4">
                                        <ListManager
                                            items={sortCards(getItems(this.state.backCards))}
                                            direction="horizontal"
                                            maxItems={5}
                                            render={(item) => this.listHomeRunElement(item)}
                                        />
                                    </div>}
                                    {this.state.isHomeRunLive && this.state.submitSettingsButton && <div className="d-flex my-auto ml-4">
                                        <Row className="GameBtn my-auto">Selected</Row>
                                        <div>
                                        <ListManager
                                            items={this.state.selectedCardsSortedList}
                                            direction="horizontal"
                                            maxItems={13}
                                            render={(item) => this.listHomeRunElement(item)}
                                            onDragEnd={this.reorderNewList}
                                        />
                                        <Row>{this.state.isHomeRunLive && this.state.submitSettingsButton && this.state.selectedCards.length > 0 && this.state.showHomeRunList.map((number, index) => {
                                        let lt = this.state.selectedCards.length;
                                        if(index + 1 <= lt) {
                                            return <div key={index} className="showhomerunindex">{number}</div>
                                        }
                                        else{return <div key={index}></div>}
                                        })}</Row>
                                        </div>
                                    </div>}
                                    {is727Live && sum727cards.length > 0 && <div className="countdown-timer d-flex my-auto ml-4">Sum: {sum727cards.map((sum, index) => <span key={index}>{sum}{index<sum727cards.length - 1 && <span>/</span>}</span>)}</div>}
                                    {((this.state.is7CSLive || is727Live || is5CSLive || isBaseballLive) && this.state.upCards7CS.length > 0) && <div className="countdown-timer d-flex my-auto ml-4">Up</div>}
                                    {((this.state.is7CSLive || is727Live || is5CSLive || isBaseballLive) && this.state.upCards7CS.length > 0) && <div className="d-flex my-auto ml-4">
                                        <ListManager
                                            items={this.state.upCards7CS}
                                            direction="horizontal"
                                            maxItems={7}
                                            render={(item) => this.listElement(item)}
                                            onDragEnd={this.reorderUpCardsList}
                                        />
                                    </div>}
                                    {((this.state.is7CSLive || is727Live || is5CSLive || isBaseballLive) && this.state.downCards7CS.length > 0) && <div className="countdown-timer d-flex my-auto ml-4">Down</div>}
                                    {((this.state.is7CSLive || is727Live || is5CSLive || isBaseballLive) && this.state.downCards7CS.length > 0) && <div className="d-flex my-auto ml-4">
                                        <ListManager
                                            items={this.state.downCards7CS}
                                            direction="horizontal"
                                            maxItems={13}
                                            render={(item) => this.listElement(item)}
                                            onDragEnd={this.reorderDownCardsList}
                                        />
                                    </div>}
                                    {((currentGameName === "Blind Baseball") && this.state.cantSeeCardsBaseball.length > 0) && <div className="d-flex my-auto ml-4">
                                        <ListManager
                                            items={this.state.cantSeeCardsBaseball}
                                            direction="horizontal"
                                            maxItems={13}
                                            render={(item) => this.listElement(item)}
                                        />
                                    </div>}
                                    {cardSelectionEnabled &&
                                        <div className="d-flex my-auto ml-4">
                                            {(!this.state.isHomeRunLive && !isBaseballLive) && <Row className="GameBtn my-auto">Selected</Row>}
                                            <div>
                                            {!this.state.isHomeRunLive && <ListManager
                                                items={this.state.selectedCardsSortedList}
                                                direction="horizontal"
                                                maxItems={7}
                                                render={(item) => this.listNewElement(item)}
                                                onDragEnd={this.reorderNewList}
                                            />}
                                            <Row>{this.state.isCincinnatiLive && this.state.cardRevealRound && this.state.showList.map((number, index) => {
                                                let lt = this.state.selectedCards.length;
                                                if(number <= lt) {
                                                    return <div key={index} className="showList-number">{number}</div>
                                                }
                                                else{return <div key={index}></div>}
                                                })}</Row>
                                            <Row>{this.state.isCincinnatiLive && !this.state.cardRevealRound && this.state.selectedCards.length > 0 && this.state.showCincinnatiList.map((number, index) => {
                                            let lt = this.state.selectedCards.length;
                                            if(index + 1 <= lt) {
                                                return <div key={index} className="showList-number">{number}</div>
                                            }
                                            else{return <div key={index}></div>}
                                            })}</Row>
                                            </div>
                                        </div>
                                    }
                                    {draw727CardsShow && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={() => this.draw727Action('pass')}>PASS</Button>
                                    </div>} 
                                    {draw727CardsShow && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={() => this.draw727Action('draw')}>DRAW</Button>
                                    </div>}
                                    {/* {cardSelectionEnabled && isBaseballLive && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={e => this.sendCards()}>LAY</Button>
                                    </div>} */}
                                    {/* {cardSelectionEnabled && isBaseballLive && this.state.showSubmitBaseball && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={e => this.submitBaseballCards()}>SUBMIT</Button>
                                    </div>} */}
                                    {cardSelectionEnabled && this.state.isAnacondaLive && <div className="d-flex my-auto ml-4">
                                        <Button disabled={this.state.selectedCards.length !== this.state.noOfCards} className="GameBtn" onClick={e => this.sendCards()}>SEND</Button>
                                    </div>}
                                    {cardSelectionEnabled && this.state.is5CDLive && <div className="d-flex my-auto ml-4">
                                        {this.state.selectedCards.length === 0 && <Button disabled={this.state.selectedCards.length > this.state.noOfCardsToDiscard} className="GameBtn" onClick={e => this.sendCards()}>STAND PAT</Button>}
                                        {this.state.selectedCards.length > 0 && <Button disabled={this.state.selectedCards.length > this.state.noOfCardsToDiscard} className="GameBtn" onClick={e => this.sendCards()}>EXCHANGE</Button>}
                                    </div>}
                                    {cardSelectionEnabled && this.state.isCincinnatiLive && !this.state.cardRevealRound && <div className="d-flex my-auto ml-4">
                                        <Button disabled={this.state.selectedCards.length !== 3} className="GameBtn" onClick={e => this.sendCards()}>Send</Button>
                                    </div>}
                                    {cardSelectionEnabled && this.state.isCincinnatiLive && this.state.cardRevealRound && <div className="d-flex my-auto ml-4">
                                        <Button disabled={this.state.selectedCards.length !== 5} className="GameBtn" onClick={e => this.sendCards()}>CONFIRM</Button>
                                    </div>}
                                    {cardSelectionEnabled && this.state.isHomeRunLive && this.state.submitSettingsButton && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={e => this.sendCards()}>Submit</Button>
                                    </div>}
                                    {cardSelectionEnabled && this.state.isHomeRunLive && this.state.submitSettingsButton && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={this.onOrderCardsPress} disabled={loading}>
                                            {loading && (
                                                <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            ARRANGE
                                        </Button>
                                    </div>}
                                    {this.state.isHomeRunLive && this.state.showRecallButton && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={this.onRecallPress}>RE-ARRANGE</Button>
                                    </div>}
                                    {this.state.gameName357 === 'threefiveseven' && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={() => this.setState({afterRoundModalShow: true})}>HAND-HISTORY</Button>
                                    </div>}
                                    {this.state.gameName357 === 'threefiveseven' && this.state.startRound357 && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={this.onStart357ButtonPress}>START HOLD/DROP COUNTER</Button>
                                    </div>}
                                    {this.state.gameName357 === 'threefiveseven' && this.state.showPause357 && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={this.onPause357ButtonPress}>PAUSE</Button>
                                    </div>}
                                    {this.state.gameName357 === 'threefiveseven' && showReady && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={this.onReadyPress}>READY</Button>
                                    </div>}
                                    {(this.state.is5CDLive || this.state.isTexasLive || this.state.isHomeRunLive || is5CSLive) && showReady && <div className="d-flex my-auto ml-4">
                                        <Button className="GameBtn" onClick={this.onReadyPress}>READY</Button>
                                    </div>}
                                </Row>
                            </div>
                        }
                        {this.state.isTexasLive && <div className="d-flex mr-20%">
                            <Row className="mx-auto">
                                <div className="d-flex my-auto ml-4">
                                    <Button className="GameBtn" onClick={() => this.setState({showTexasModal: true})}>LAST-HAND</Button>
                                </div>
                            </Row>
                        </div>}
                    </div>
                </div>
                {false && <WinnerModal winners={winners} show={winnerModalShow} everyonesWinningType={everyonesWinningType}
                            onHide={() => this.setModalShow(false)}
                />}
                <PotDetailsModal potDetails={potDetails} betRoundOver={this.state.betRoundOver} show={this.state.potModalShow}
                    onHide={() => this.setState({potModalShow: false})}
                />
                <AccountDetailsModal accountFlag={this.state.accountFlag} accountDetails={this.state.accountDetails} show={this.state.accountModalShow}
                    onHide={() => this.setState({accountModalShow: false})}
                />
                <GameDetails357
                    show={this.state.modalshow357}
                    onHide={() => this.setState({modalshow357: false})}
                    details357={this.state.details357}
                    roundOver357={this.state.roundOver357}
                    showModal357={this.state.showModal357}
                />
                <AfterRoundDetails357
                    show={this.state.afterRoundModalShow}
                    details357={this.state.details357}
                    roundOver357={this.state.roundOver357}
                    showModal357={this.state.showModal357}
                    inPlayersCards={this.state.inPlayersCards}
                    selectedIn={this.state.selectedIn}
                    onHide={() => this.setState({afterRoundModalShow: false})}
                />
                <HouseRulesModal
                    show={this.state.showHouseRules}
                    onHide={() => this.setState({showHouseRules: false})}
                    roomHouseRules={this.state.roomHouseRules}
                    showHouseRules={this.state.showHouseRules}
                    roomName={this.state.roomName}
                    roomCode={this.state.roomCode}
                />
                <HomeRunModal
                    show={this.state.showModalHomeRun}
                    onHide={() => this.setState({showModalHomeRun: false})}
                    winners={this.state.winners}
                    showModalHomeRun={this.state.showModalHomeRun}
                />
                <TexasHoldemModal
                    show={this.state.showTexasModal}
                    onHide={() => this.setState({showTexasModal: false})}
                    showTexasModal={this.state.showTexasModal}
                    winningDataTexas={this.state.winningDataTexas}
                    playerCardsTexas={this.state.playerCardsTexas}
                    currentGameName={this.state.currentGameName}
                />
                <RulesModalAnaconda
                    show={this.state.rulesAnaconda}
                    onHide={() => this.setState({rulesAnaconda: false})}
                    rulesAnaconda={this.state.rulesAnaconda}
                />
                <RulesModal357
                    show={this.state.rules357}
                    onHide={() => this.setState({rules357: false})}
                    rules357={this.state.rules357}
                />
                <RulesModal3B7
                    show={this.state.rules3B7}
                    onHide={() => this.setState({rules3B7: false})}
                    rules3B7={this.state.rules3B7}
                />
                <RulesModal5CD
                    show={this.state.rules5CD}
                    onHide={() => this.setState({rules5CD: false})}
                    rules5CD={this.state.rules5CD}
                    currentGameName={this.state.currentGameName}
                />
                <RulesModal7CS
                    show={this.state.rules7CS}
                    onHide={() => this.setState({rules7CS: false})}
                    rules7CS={this.state.rules7CS}
                    currentGameName={this.state.currentGameName}
                />
                <RulesModal5CS
                    show={this.state.rules5CS}
                    onHide={() => this.setState({rules5CS: false})}
                    rules5CS={this.state.rules5CS}
                    currentGameName={this.state.currentGameName}
                />
                <RulesModalCincinnati
                    show={this.state.rulesCincinnati}
                    onHide={() => this.setState({rulesCincinnati: false})}
                    rulesCincinnati={this.state.rulesCincinnati}
                />
                <RulesModalTexas
                    show={this.state.rulesTexas}
                    onHide={() => this.setState({rulesTexas: false})}
                    rulesTexas={this.state.rulesTexas}
                    currentGameName={this.state.currentGameName}
                />
                <RulesModalHomerun
                    show={this.state.rulesHomerun}
                    onHide={() => this.setState({rulesHomerun: false})}
                    rulesHomerun={this.state.rulesHomerun}
                />
                <RulesModal727
                    show={this.state.rules727}
                    onHide={() => this.setState({rules727: false})}
                    rules727={this.state.rules727}
                />
                <RulesModalBaseball
                    show={this.state.rulesBaseball}
                    onHide={() => this.setState({rulesBaseball: false})}
                    rulesBaseball={this.state.rulesBaseball}
                    currentGameName={this.state.currentGameName}
                />
            </div>
        );
    }
}

export default withRouter(Game);
