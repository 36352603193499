import React, {Component} from "react";
import Avatar from "react-avatar";
import {Card, Col, Modal, Row, Button} from "react-bootstrap";
import coinsImage from "../../assets/coins.png"
import "../Game/Player.css"
import dealerImage from "../../assets/dealer.svg"
import OpenerImage from "../../assets/Opener.png";
import historyImage from "../../assets/history.svg"
import betImage from "../../assets/bet.svg"
import raiseImage from "../../assets/raise.svg"
import {getBettingHistory, payToPlayer, playerVoteOutRequest, dealerKickOut} from "../../services/room";
import {getActionMsg} from "../../utils/utils";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import WinnerCards from "../Cards/WinnerCards";
import foldImage from "../../assets/FoldFinal.svg";
import bustedImage from "../../assets/Busted.svg";
import CoinImage from "../../assets/Coin.svg";
import SmallBlind from "../../assets/SMALL_BLIND.svg";
import BigBlind from "../../assets/BIG_BLIND.svg";
import DealerRound from "../../assets/DEALER_TEMP.svg";
import Tooltip from "react-bootstrap/Tooltip";
import Dragon from "../../assets/dragon.gif";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Arrow from "@elsdoerfer/react-arrow";
import {sortHiHand, getHiHandStringDescription, getBestCardsOmaha} from '../../utils/modules/HandEvaluation/HiHandEvaluation';
import {sortLoHand, getLoHandStringDescription} from '../../utils/modules/HandEvaluation/LoHandEvaluation';
import { getAllDoYaWildCards } from "../../utils/modules/GameFunctions";
import calculateCardsSum from "../../utils/modules/HandEvaluation/727";
const { evaluateCards, rankCards } = require('phe'); 
var Hand = require('pokersolver').Hand;

function PlayerCoinsStatus(props) {

    let player = props.player;
    let currentPlayerBets = props.currentPlayerBets;
    let myCurrentBet = props.myCurrentBet;

    if(player && currentPlayerBets) {

        let thisPlayerBet;
        if(player && currentPlayerBets.length > 0) {
            let betPlayer = currentPlayerBets.filter(function (element) {
                return (element[0] === player.clientName);
            });
            thisPlayerBet = betPlayer[0];
            if(thisPlayerBet) {
            // console.log('My Bet', thisPlayerBet[1]);
        }
        }

        return(
            <div>
                {player.clientName === myCurrentBet.clientName && myCurrentBet && <div>{myCurrentBet.myAmount}</div>}
                {player.clientName !== myCurrentBet.clientName && player && currentPlayerBets.length > 0 && <div>
                    {thisPlayerBet && <div>{thisPlayerBet[1]}</div>}
                </div>}
            </div>
        );
    }
}

function PlayerCoinsImage(props) {

    let player = props.player;
    let currentPlayerBets = props.currentPlayerBets;
    let myCurrentBet = props.myCurrentBet;

    if(player && currentPlayerBets) {

        let thisPlayerBet;
        if(player && currentPlayerBets.length > 0) {
            let betPlayer = currentPlayerBets.filter(function (element) {
                return (element[0] === player.clientName);
            });
            thisPlayerBet = betPlayer[0];
            if(thisPlayerBet) {
            // console.log('My Bet', thisPlayerBet[1]);
        }
        }

        return(
            <div>
                {player.clientName === myCurrentBet.clientName && myCurrentBet && <div><img src={CoinImage} alt="Coins"/></div>}
                {player.clientName !== myCurrentBet.clientName && player && currentPlayerBets.length > 0 && <div>
                    {thisPlayerBet && <div><img src={CoinImage} alt="Coins"/></div>}
                </div>}
            </div>
        )
    }
}

function HomeRunModal(props) {

    let resultHomeRun = props.resultHomeRun;
    let player = props.player;
    let winnersHomeRun = props.winnersHomeRun;
    let winners = props.winners;

    if(props.showHomeRunModal && player.length > 0) {
        let rs = [];
        for(const x of resultHomeRun) {
            for(const y of x) {
                if(y.playername === player) {
                    rs.push(x)
                }
            }
        }
        let result = rs[0];
        console.log('Result of player', player, result);
        let player0;
        if(result[0]) {
            player0 = result[0].data.players[0] === player ? result[0].data.players[1] : result[0].data.players[0];
        }
        let player1;
        if(result[1]) {
            player1 = result[1].data.players[0] === player ? result[1].data.players[1] : result[1].data.players[0];
        }
        let player2;
        if(result[2]) {
            player2 = result[2].data.players[0] === player ? result[2].data.players[1] : result[2].data.players[0];
        }
        

        let summary = [];

        for(const i in winners.pointsSummary) {
            if(i === player) {
                for(const y in winners.pointsSummary[i]) {
                    let z = winners.pointsSummary[i]
                    let body = {
                        playerName: y,
                        points: (z[y] === 0) ? winners.pointsSummary[y][i]*-1 : z[y]
                    }
                    summary.push(body);
                }
            }
        }
        console.log('Summary', summary);

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        {player} :- {winnersHomeRun.map((playerName, index) => (
                            <span key={index}>
                                {playerName.clientName === player && <span>Hits: {playerName.hits}, Points: {playerName.points}</span>}
                            </span>
                            ))} &nbsp; &nbsp; {winners.bonusPayments.length > 0 && <th>{winners.bonusPayments.map((playerName, index) => (
                                <div key={index}>
                                    {playerName.player === player && <div>{playerName.points}: {playerName.bonusType}</div>}
                                </div>
                            ))}</th>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    <table className="resulthr">
                        <tr>
                            <td className="namehr">{player}</td>
                            <td className="fronthr"><strong>Front</strong></td>
                            <td className="midhr"><strong>Middle</strong></td>
                            <td className="backhr"><strong>Back</strong></td>
                            <td className="pointshr"><strong>Points</strong></td>
                        </tr>
                        {result[0] && <tr>
                            <td className="namehr">{player0}</td>
                            <td className="fronthr">{result[0].data.settingsWinner.Front}</td>
                            <td className="midhr">{result[0].data.settingsWinner.Middle}</td>
                            <td className="backhr">{result[0].data.settingsWinner.Back}</td>
                            <td className="pointshr">{summary.map((player, index) => (<span key={index}>{player.playerName === player0 && <span>{player.points}</span>}</span>))}</td>
                        </tr>}
                        {result[1] && <tr>
                            <td className="namehr">{player1}</td>
                            <td className="fronthr">{result[1].data.settingsWinner.Front}</td>
                            <td className="midhr">{result[1].data.settingsWinner.Middle}</td>
                            <td className="backhr">{result[1].data.settingsWinner.Back}</td>
                            <td className="pointshr">{summary.map((player, index) => (<span key={index}>{player.playerName === player1 && <span>{player.points}</span>}</span>))}</td>
                        </tr>}
                        {result[2] && <tr>
                            <td className="namehr">{player2}</td>
                            <td className="fronthr">{result[2].data.settingsWinner.Front}</td>
                            <td className="midhr">{result[2].data.settingsWinner.Middle}</td>
                            <td className="backhr">{result[2].data.settingsWinner.Back}</td>
                            <td className="pointshr">{summary.map((player, index) => (<span key={index}>{player.playerName === player2 && <span>{player.points}</span>}</span>))}</td>
                        </tr>}
                    </table>
                </Modal.Body>
            </Modal>
        );
    }
    else {
        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        Home-Run
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    Result Table
                </Modal.Body>
            </Modal>
        );
    }
}

function PlayerHistoryModal(props) {

    let renderHistory = props.history.filter(hist => hist.clientName === props.player).map(hist => {
        let msg = getActionMsg(hist);
        let img = null;
        if (hist.action.name === "raise") {
            img = raiseImage;
        } else if (hist.action.name === "call") {
            img = betImage;
        }
        return (
            <Row className="d-inline-block">
                <img className="mr-2" src={img} alt=""/>
                <span>{msg}</span>
            </Row>
        );
    });

    let modalTitle = props.player ? `${props.player} - Game History` : "Game History";

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="HistoryModalBody">
                {renderHistory.length > 0 ? renderHistory : "History not available at the moment"}
            </Modal.Body>
        </Modal>
    );
}

const renderTooltipPaySheet = props => (
    <Tooltip {...props}>PAY</Tooltip>
);

const renderTooltipKickOptions = props => (
    <Tooltip {...props}>KICK</Tooltip>
);

const renderTooltipDealerKick = props => (
    <Tooltip {...props}>KICK FROM ROOM</Tooltip>
);

class Player extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showToast: true,
            modalShow: false,
            payAmount: 0,
            showPaySheet: false,
            history: [],
            showHomeRunModal: false,
            showKickOptions: false,
            showDealerKick: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {player, playerMsgDict} = this.props;
        let {showToast} = this.state;
        if (!showToast && playerMsgDict[player.clientName]) {
            this.setState({
                showToast: true
            });
        }
    }

    setModalShow = (status) => {
        this.setState({
            modalShow: status
        });
    };

    payMoneyToPlayer = async () => {
        const {room} = queryString.parse(this.props.location.search);
        let {player} = this.props;
        let body = {
            amount: this.state.payAmount,
            playerToPay: player.clientName
        };
        let response = await payToPlayer(body, room);
        if(response) {
            this.setState({
                showPaySheet: false,
                payAmount: 0
            })
        }
        console.log('Payment Done', response, body);
    }

    showHomeRunDetails = () => {
        // let {resultHomeRun, player} = this.props;
        // let rs = [];
        // for(const x of resultHomeRun) {
        //     for(const y of x) {
        //         if(y.playername === player.clientName) {
        //             rs.push(x)
        //         }
        //     }
        // }
        // console.log('Player result', rs[0]);
        this.setState({
            showHomeRunModal: true
        })
    }

    fetchPlayerHistory = async () => {
        const {room} = queryString.parse(this.props.location.search);
        let { isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive } = this.props;
        if(isAnacondaLive) {
            let response = await getBettingHistory(room, isAnacondaLive, is5CDLive. isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive);
            console.log("APICall Anaconda bettingHistory:", response);
            if (response.status === "success") {
                this.setState({
                    history: response.data
                });
            } else if (response.status === "error") {
            }
            this.setModalShow(true);
        }
        if(is5CDLive) {
            let response = await getBettingHistory(room, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive);
            console.log("APICall 5CD bettingHistory:", response);
            if (response.status === "success") {
                this.setState({
                    history: response.data
                });
            } else if (response.status === "error") {
            }
            this.setModalShow(true);
        }
        if(isTexasLive) {
            let response = await getBettingHistory(room, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive);
            console.log("APICall Texas bettingHistory:", response);
            if (response.status === "success") {
                this.setState({
                    history: response.data
                });
            } else if (response.status === "error") {
            }
            this.setModalShow(true);
        }
        if(is7CSLive) {
            let response = await getBettingHistory(room, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive);
            console.log("APICall 7CS bettingHistory:", response);
            if (response.status === "success") {
                this.setState({
                    history: response.data
                });
            } else if (response.status === "error") {
            }
            this.setModalShow(true);
        }
        if(is5CSLive) {
            let response = await getBettingHistory(room, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive);
            console.log("APICall 5CS bettingHistory:", response);
            if (response.status === "success") {
                this.setState({
                    history: response.data
                });
            } else if (response.status === "error") {
            }
            this.setModalShow(true);
        }
        if(isCincinnatiLive) {
            let response = await getBettingHistory(room, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive);
            console.log("APICall Cininnati bettingHistory:", response);
            if (response.status === "success") {
                this.setState({
                    history: response.data
                });
            } else if (response.status === "error") {
            }
            this.setModalShow(true);
        }
        if(is727Live) {
            let response = await getBettingHistory(room, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive);
            console.log("APICall 727 bettingHistory:", response);
            if (response.status === "success") {
                this.setState({
                    history: response.data
                });
            } else if (response.status === "error") {
            }
            this.setModalShow(true);
        }
        if(isBaseballLive) {
            let response = await getBettingHistory(room, isAnacondaLive, is5CDLive, isTexasLive, is7CSLive, is5CSLive, isCincinnatiLive, is727Live, isBaseballLive);
            console.log("APICall 727 bettingHistory:", response);
            if (response.status === "success") {
                this.setState({
                    history: response.data
                });
            } else if (response.status === "error") {
            }
            this.setModalShow(true);
        }
    };

    handleAmountChange = (event) => {
        let value = parseInt(event.target.value, 10) || 0;
        this.setState({
            payAmount: value
        })
    }

    kickPlayerOut = async (option, name) => {
        let { roomName } = this.props;
        let body = {
            playerToVoteOut: name,
            postGameOverAction: option
        }
        let response = await playerVoteOutRequest(body, roomName);
        if(response) {
            this.setState({showKickOptions: false})
        }
    }

    getBestHandandStrengthForOmaha = (playerCards, communityCards) => {

        const threeChooseFive = [
            [ 3, 4, 5 ], [ 2, 4, 5 ],
            [ 2, 3, 5 ], [ 2, 3, 4 ],
            [ 1, 4, 5 ], [ 1, 3, 5 ],
            [ 1, 3, 4 ], [ 1, 2, 5 ],
            [ 1, 2, 4 ], [ 1, 2, 3 ]
        ]

        /**
         * We must use 2 cards from player cards. And hence we can only exactly use 3 cards from community
         */
        let minHandStrength = Number.MAX_SAFE_INTEGER
        let minHand;
        for(let i = 0; i <= 2; i++){
            for(let j = i+1; j <= 3; j++){
                for(const communityCombination of threeChooseFive){
                    const hand = [playerCards[i], playerCards[j], ...communityCombination.map(i => communityCards[i-1])]
                    const cardsStrength = evaluateCards(hand)
                    if(cardsStrength < minHandStrength){
                        minHandStrength = cardsStrength
                        minHand = hand 
                    }
                }
            }
        }
        return [minHandStrength, minHand]
    }

    onKickClick = (player) => {
        let { allPlayersReady, gameStarted, mainPlayer, dealer } = this.props;
        if(!gameStarted && allPlayersReady) {
            if(dealer === player)
            this.setState({
                showKickOptions: !this.state.showKickOptions,
                showPaySheet: false
            })
        }
        else {
            this.setState({
                showKickOptions: !this.state.showKickOptions,
                showPaySheet: false
            })
        }
    }

    dealerKickOut = async () => {
        let { player } = this.props;
        let body = {
            playerToKickOut : player.clientName
        }
        let response = await dealerKickOut(body, this.props.roomName);
        console.log('APICALL player kick out by dealer', response);
        if(response) {
            this.setState({showDealerKick: false})
        }
    }

    render() {
        let {dealer, player, currentTurnPlayer, toastPosition, playerMsgDict, currentPlayerBets,
            allPlayerInfo, winners, gameName357, roundOver357, allCards, foldedPlayers, bool, myCurrentBet,
            gamefinished, everyonesWinningType, gameEnds357, playersSelection, is5CDLive, isAnacondaLive, gameEnds5CD,
            showReady357, readyData357, readyPressed357, isTexasLive, gameEndsTexas, showExchangedCards, exchangedCards,
            allPlayerCards7CS, is7CSLive, gameEnds7CS, is5CSLive, gameEnds5CS, allCommunityCards, isCincinnatiLive, gameEndsCincinnati,
            smallBlindPlayer, bigBlindPlayer, roundDealer,  showPlayersPassedCin, playersPassedCardsCin,
            showRevealStatus, playersRevealStatus, isHomeRunLive, settingsFront, settingsMiddle, settingsBack,
            gameEndsHomeRun, cardSelectionEnabled, homeRunShowStatus, homeRunSubmitStatus, 
            roomReadyData, showRoomReady, firstGameStarted, loHand3B7, mainPlayer, resultHomeRun, winnersHomeRun, bustedPlayers,
            anacondaPassedCards, cardPassingRoundActive, anacondaCss, cinArrLeft, cinArrRight, texasGameTypeOmaha8, playersDisconnected,
            showPencilStatus, pencilAmountData, allowKickOut, kickedOutPlayers, currentDisconnectedPlayers, currentPlayersToKick, allPlayersReady, gameStarted,
            rollOnPlayerCards, rollOnCardsShow, bots, diabloOpener, downCards7CS, gameWildCards, gameJokersInDeck, 
            gameroyalFlushBeatsFiveOfKind, gamehandsMustHaveOneNaturalCard, gameLoAce, gameLoFlush, gameOverAllData, followTheQueenCards, 
            drawRound727Show, draw727RoundDetails, is727Live, gameEnds727, downCards727, currentGameName, gameSplitHiLo, noOfPassesPlayerDetails,
            gameSimultaneousPassDrawRound, is357Live, drawBaseballDecisions, drawRoundBaseballShow, isBaseballLive, gameEndsBaseball, allPlayerCardsBaseball,
            animatedWinners} = this.props;

        let settings = {};
        if(gameWildCards.length > 0 || gameJokersInDeck.length > 0 || followTheQueenCards.length > 0) {
            // console.log('ROYAL', gameroyalFlushBeatsFiveOfKind, typeof(gameroyalFlushBeatsFiveOfKind), 'NATURAL', gamehandsMustHaveOneNaturalCard, typeof(gamehandsMustHaveOneNaturalCard), 'TEST', 'ROYAL', Boolean(gameroyalFlushBeatsFiveOfKind), 'NATURAL', Boolean(gamehandsMustHaveOneNaturalCard));
            if(gameEndsCincinnati || gamefinished) {
                settings = {
                    loSettings : {
                        countStraightAndFlushes : Boolean(gameLoFlush),
                        considerAceLow: Boolean(gameLoAce)
                    },
                    wildCardsSettings : {
                        wildCardsSet : new Set(gameWildCards),
                        jokersInDeckSet : new Set(gameJokersInDeck),
                        royalFlushBeatsFiveOfKind : Boolean(gameroyalFlushBeatsFiveOfKind),
                        handsMustHaveOneNaturalCard: Boolean(gamehandsMustHaveOneNaturalCard),
                        payForFaceUpWildCard: 0
                    }
                }
            }
            else {
                if(followTheQueenCards.length > 0) {
                    settings = {
                        wildCardsSettings : {
                            wildCardsSet : new Set(gameWildCards.concat(followTheQueenCards)),
                            jokersInDeckSet : new Set(gameJokersInDeck),
                            royalFlushBeatsFiveOfKind : Boolean(gameroyalFlushBeatsFiveOfKind),
                            handsMustHaveOneNaturalCard: Boolean(gamehandsMustHaveOneNaturalCard),
                            payForFaceUpWildCard: 0
                        }
                    } 
                }
                else {
                    settings = {
                        wildCardsSettings : {
                            wildCardsSet : new Set(gameWildCards),
                            jokersInDeckSet : new Set(gameJokersInDeck),
                            royalFlushBeatsFiveOfKind : Boolean(gameroyalFlushBeatsFiveOfKind),
                            handsMustHaveOneNaturalCard: Boolean(gamehandsMustHaveOneNaturalCard),
                            payForFaceUpWildCard: 0
                        }
                    }
                }
            }
        }
        else {
            if(gameEndsCincinnati || gamefinished) {
                settings = {
                    loSettings : {
                        countStraightAndFlushes : Boolean(gameLoFlush),
                        considerAceLow: Boolean(gameLoAce)
                    }
                }
            }
        }

        let gameReady = [];
        for (const player in roomReadyData) {
            if(roomReadyData[player]) {
                gameReady.push(player);
            }
        }

        let cardsExchanged5CD = [];
        let allPlayersExchangedCards = [];
        if(is5CDLive && showExchangedCards) {
            for (const player in exchangedCards) {
                let body = {
                    clientName: player,
                    status: exchangedCards[player]
                };
                cardsExchanged5CD.push(body);
                allPlayersExchangedCards.push(player)
            }
        }
        
        let playerReady = [];
            if(showReady357) {
                for (const player in readyData357) {
                    let body = {
                        clientName: player,
                        status: readyData357[player] === true ? 'READY' : ''
                    };
                    playerReady.push(body);
                }
            }
        let showImage = false;
        let photoURL = '';
        if(allPlayerInfo[`${player.clientName}`]) {
            let DetailsPlay = Object.entries(allPlayerInfo);
            if(allPlayerInfo[`${player.clientName}`].photoUrl) {
            photoURL = allPlayerInfo[`${player.clientName}`].photoUrl.toString();
          }
        }
        let {showToast, modalShow, history} = this.state;
        let playerMsg = playerMsgDict[player.clientName];
        history = history ? history : [];
        let thisPlayerBet;
        if(player && currentPlayerBets.length > 0) {
            let betPlayer = currentPlayerBets.filter(function (element) {
                return (element[0] === player.clientName);
            });
            thisPlayerBet = betPlayer[0];
            if(thisPlayerBet) {
                // console.log('My Bet', thisPlayerBet[1]);
            }
        }

        let myTurn = firstGameStarted && currentTurnPlayer && currentTurnPlayer === player.clientName;

        let showKickButton = firstGameStarted && currentDisconnectedPlayers.includes(player.clientName) && currentPlayersToKick.includes(player.clientName) && !foldedPlayers.includes(player.clientName) && !kickedOutPlayers.includes(player.clientName);
        let showDealerUIKickButton = (firstGameStarted &&
            allPlayersReady &&
            !gameStarted &&
            (mainPlayer === dealer) &&
            playersDisconnected.includes(player.clientName));
        let showDealerUIKickOnHover = (firstGameStarted &&
            gameStarted===false &&
            allPlayersReady===true &&
            player.clientName!==dealer &&
            mainPlayer===dealer);

        return (
            <div>
            {player.clientName && <div className="tableContainer d-flex m-auto flex-column">
                {animatedWinners.includes(player.clientName) && <div className="winnergif">
                    <iframe src="https://giphy.com/embed/fW4qzqnk9WluHMbAtZ" frameBorder="0" width="380" height="200" class="giphy-embed"></iframe>
                </div>}
                {/* {toastPosition === "top" && playerMsg &&
                <div className="mb-1" style={{zIndex: 10}}>
                    {<Toast className="PlayerToast" onClose={() => this.setState({showToast: false})} show={this.state.showToast} delay={10000000000} autohide={false}>
                        <ToastHeader closeButton={false}>
                            <strong className="mr-auto">{playerMsg}</strong>
                        </ToastHeader>
                    </Toast>}
                </div>
                } */}
                {firstGameStarted && <div className="d-flex">
                    {dealer && dealer === player.clientName && <img className="dealerImg" src={dealerImage} alt="Table"/>}
                    {currentTurnPlayer && currentTurnPlayer === player.clientName && <div>
                        <i className="starImg fa fa-star" aria-hidden="true"></i>
                    </div>}
                </div>}
                {firstGameStarted && <div className="d-flex flex-row">
                    {!gameEndsHomeRun && <img onClick={this.fetchPlayerHistory} className="historyImg" title="Game History" src={historyImage} alt="History"/>}
                    {gameEndsHomeRun && <img onClick={this.showHomeRunDetails} className="historyImg" title="RESULT" src={historyImage} alt="History"/>}
                    {/* {toastPosition === "left"  && playerMsg &&
                        <div className="mr-1" style={{zIndex: 10}}>
                            {<Toast className="PlayerToast" onClose={() => this.setState({showToast: false})} show={this.state.showToast} delay={10000000000} autohide={false}>
                                <ToastHeader closeButton={false}>
                                    <strong className="mr-auto">{playerMsg}</strong>
                                </ToastHeader>
                            </Toast>}
                        </div>
                    } */}
                    <Card className={`p-2 justify-content-center align-items-center ${(firstGameStarted && playersDisconnected.includes(player.clientName)) ? "opacitycontroller" : "nothing"} ${(firstGameStarted && currentTurnPlayer && currentTurnPlayer === player.clientName && !cardSelectionEnabled) ? "currentPlayerCard" : "playerCard"}`} >
                        {player &&
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <div className="d-flex flex-column justify-content-center align-items-start mt-2 pl-1">

                                {showDealerUIKickOnHover ? <OverlayTrigger placement="bottom" overlay={renderTooltipDealerKick}>
                                    <div className={`playerKick flex-wrap text-break text-center d-flex ml-2 ${player.clientName.length >= 11? "fnt-11px" : ""}`}
                                         onClick={() => this.setState({showDealerKick: true})}>
                                        {player.clientName.length >= 25?  player.clientName.substring(0,25).concat('...'): player.clientName}
                                    </div>
                                </OverlayTrigger> :  !currentPlayersToKick.includes(player.clientName) && <div className={`flex-wrap text-break text-center d-flex ml-2 ${player.clientName.length >= 11? "fnt-11px" : ""}`}>
                                {player.clientName.length >= 25?  player.clientName.substring(0,25).concat('...'): player.clientName}</div> }

                                {currentPlayersToKick.includes(player.clientName) && !foldedPlayers.includes(mainPlayer) && <OverlayTrigger placement="bottom" overlay={renderTooltipKickOptions}>
                                <div className={`playerKick flex-wrap text-break text-center d-flex ml-2 ${player.clientName.length >= 11? "fnt-11px" : ""}`}
                                     onClick={() => this.onKickClick(player.clientName)}>
                                    {player.clientName.length >= 25?  player.clientName.substring(0,25).concat('...'): player.clientName}
                                </div>
                                </OverlayTrigger>}
                                {currentPlayersToKick.includes(player.clientName) && foldedPlayers.includes(mainPlayer) &&
                                <div className={`playerKick flex-wrap text-break text-center d-flex ml-2 ${player.clientName.length >= 11? "fnt-11px" : ""}`}>
                                    {player.clientName.length >= 25?  player.clientName.substring(0,25).concat('...'): player.clientName}
                                </div>}

                                <div className="d-flex align-items-center">
                                    <div>
                                        <img src={coinsImage} width={45} alt="Coins"/>
                                    </div>
                                    {
                                        firstGameStarted &&
                                        <div>
                                            <OverlayTrigger placement="bottom" overlay={renderTooltipPaySheet}>
                                                <div className="playerPay" onClick={() => {this.setState({showPaySheet: !this.state.showPaySheet, showKickOptions:false})}}>{player.stack}</div>
                                            </OverlayTrigger>
                                        </div>
                                    }
                                    {!firstGameStarted && <div>0</div>}
                                </div>
                            </div>
                            <div className={`d-flex align-items-center justify-content-center`}>
                                {photoURL.length > 0 && firstGameStarted && <img className="profileImg" src={photoURL}/>}
                                {photoURL.length === 0 && firstGameStarted && <Avatar name={player.clientName} maxInitials={1} size={60} round={true}
                                                                                      textSizeRatio={2}/>}
                                {!firstGameStarted && <Avatar name='' maxInitials={1} size={60} round={true}
                                                              textSizeRatio={2}/>}
                            </div>
                        </div>}
                    </Card>
                    {/* {toastPosition === "right" && playerMsg &&
                        <div className="ml-1" style={{zIndex: 10}}>
                            {<Toast className="PlayerToast" onClose={() => this.setState({showToast: false})} show={this.state.showToast} delay={10000000000} autohide={false}>
                                <ToastHeader closeButton={false}>
                                    <strong className="mr-auto">{playerMsg}</strong>
                                </ToastHeader>
                            </Toast>}
                        </div>
                    } */}
                </div>}
                <PlayerHistoryModal
                    player={player.clientName}
                    show={modalShow}
                    history={history}
                    onHide={() => this.setModalShow(false)}
                />
                <HomeRunModal
                    player={player.clientName}
                    resultHomeRun={resultHomeRun}
                    winnersHomeRun={winnersHomeRun}
                    winners={winners}
                    show={this.state.showHomeRunModal}
                    showHomeRunModal={this.state.showHomeRunModal}
                    onHide={() => this.setState({showHomeRunModal: false})}
                />
                {this.state.showPaySheet && mainPlayer!==player.clientName && player.clientName.length > 0 && <Card className="paymentCard">
                    <Row className="align-items-center">
                        <input 
                            className="payAmountArea p-2"
                            style={{resize: "none"}}
                            type="number"
                            placeholder={this.state.payAmount}
                            value={Number(this.state.payAmount).toString()}
                            onChange={this.handleAmountChange}
                        />
                        <Button className="payButton" onClick={this.payMoneyToPlayer}>PAY</Button>
                        <div className="closePayBtn">
                            <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({showPaySheet: false})}></i>
                        </div>
                    </Row>
                </Card>}
                {showKickButton && <Button className="kicknewBtn" onClick={() => this.setState({showKickOptions: true})}>KICK</Button>}
                {showDealerUIKickButton && <Button className="kicknewBtn redcolor" onClick={this.dealerKickOut}>REMOVE</Button>}
                {this.state.showDealerKick && player.clientName.length > 0 && <Card className="KickCard">

                    <div className="d-flex flex-column w-100">


                        <div className="d-flex justify-content-end closeKickBtn">
                            <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({showDealerKick: false})}></i>
                        </div>

                        <div className="d-flex justify-content-between">
                            <Button className="kickButton" onClick={this.dealerKickOut}>KICK OUT</Button>
                            <OverlayTrigger placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    Player will be removed from room immediately.
                                                </Tooltip>
                                            }>
                                <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                            </OverlayTrigger>
                        </div>
                    </div>
                </Card>}






                        {this.state.showKickOptions && mainPlayer!==player.clientName && player.clientName.length > 0 && <Card className="KickCard">

                    <div className="d-flex flex-column w-100">


                        <div className="d-flex justify-content-end closeKickBtn">
                            <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({showKickOptions: false})}></i>
                        </div>

                        {!bots.includes(player.clientName) && <div className="d-flex justify-content-between">
                            <Button className="kickButton" onClick={() => this.kickPlayerOut('convertToSpectator', player.clientName)}>Convert to Spectator</Button>
                                <OverlayTrigger placement="bottom"
                                                overlay={
                                                    <Tooltip id={`tooltip`}>
                                                        Player will be converted to spectator. Player can rejoin the table once the game is over or continue to spectate the next games.
                                                    </Tooltip>
                                                }>
                                    <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                </OverlayTrigger>
                        </div>}

                        <div className="d-flex justify-content-between">
                            <Button className="kickButton" onClick={() => this.kickPlayerOut('kickOutFromRoom', player.clientName)}>Kick-Out from room</Button>
                            <OverlayTrigger placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    Player will be removed from the table when the game ends. Player can join the table again with new starting amount.
                                                </Tooltip>
                                            }>
                                <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                            </OverlayTrigger>

                        </div>

                        <div className="d-flex justify-content-between">
                            <Button className="kickButton" onClick={() => this.kickPlayerOut('keepOnTable', player.clientName)}>Auto-fold/Be-Right-Back</Button>
                            <OverlayTrigger placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    Player will remain on the table with Ante deducted for every game/round. Player can rejoin with the current amount.
                                                </Tooltip>
                                        }>
                                <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                            </OverlayTrigger>
                        </div>


                    </div>
                </Card>}
                {is357Live && player.clientName.length > 0 && <div className="legs-text">Legs Won: </div>}
                {<div className="legs-number">{player.noOfLegsWon && player.clientName.length > 0 &&
                                <div>{player.noOfLegsWon}</div>}</div>}
                {roundOver357 && <div>
                        {allCards.length > 0 && <div className="displaycards357">
                            {allCards.map(player357 => {
                                let sortedCards = player357.cards;
                                let hand = Hand.solve(sortedCards);
                                let bool = false;
                                if(loHand3B7) {
                                    bool = true
                                }
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}{!bool && <div className="hand-description">{hand.descr}</div>}
                                        {bool && <div className="hand-description">{loHand3B7[player357.clientName]}</div>}
                                    </div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {gameEndsBaseball && <div>
                        {allCards.length > 0 && <div className="cards-display">
                            {allCards.map(player357 => {
                                let sortedCards = player357.cards;
                                let hand = Hand.solve(sortedCards).descr;
                                if(gameJokersInDeck.length > 0 || gameWildCards.length > 0) {
                                    // sortedCards = sortHiHand(sortedCards, settings);
                                    hand = getHiHandStringDescription(sortedCards, settings);
                                }
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}<div className="hand-description">{hand}</div></div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {gameEnds5CD && <div>
                        {allCards.length > 0 && <div className="cards-display">
                            {allCards.map(player357 => {
                                let sortedCards = player357.cards;
                                let hand = Hand.solve(sortedCards).descr;
                                if(gameJokersInDeck.length > 0 || gameWildCards.length > 0) {
                                    sortedCards = sortHiHand(sortedCards, settings);
                                    hand = getHiHandStringDescription(sortedCards, settings);
                                }
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}<div className="hand-description">{hand}</div></div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {gameEndsTexas && currentGameName === "Ironcross" && <div>
                        {allCards.length > 0 && <div className="cards-display">
                            {gameOverAllData.map(playercards => {
                                let cards = playercards.cards
                                let loSortedCards = playercards.loSortedCards
                                let loHand = playercards.loHand
                                let hiSortedCards = playercards.hiSortedCards
                                let hiHand = playercards.hiHand
                                return <div className="">
                                {playercards.clientName === player.clientName && <div className="d-flex flex-row">
                                {cards.map((card, index) => (<div>
                                        <div key={index} className="winnerWildCards">
                                            <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                            {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                        </div>
                                    </div>
                                ))}<div className="hand-description">{hiHand} {gameSplitHiLo && <span>Lo: {loHand}</span>}</div>
                                </div>}
                            </div>})
                            }  
                        </div>}
                </div>} 
                {gameEndsTexas && !texasGameTypeOmaha8 && currentGameName !== "Ironcross" && <div>
                        {allCards.length > 0 && <div className="cards-display">
                            {allCards.map(player357 => {
                                let sortedCards = player357.cards;
                                let finalCards = sortedCards.concat(allCommunityCards);
                                let [strength, bestHand] = [{}, {}];
                                let hand, bestHandDescription;
                                let bool = false;
                                if(finalCards.length > 7) {
                                    // [strength, bestHand] = this.getBestHandandStrengthForOmaha(sortedCards, allCommunityCards);
                                    let bestHand = getBestCardsOmaha(sortedCards, allCommunityCards, settings);
                                    bool = true;
                                    bestHandDescription = getHiHandStringDescription(bestHand, settings);
                                }
                                else {
                                    hand = Hand.solve(sortedCards.concat(allCommunityCards)).descr;
                                    if(gameJokersInDeck.length > 0 || gameWildCards.length > 0) {
                                        // sortedCards = sortHiHand(sortedCards, settings);
                                        hand = getHiHandStringDescription(sortedCards.concat(allCommunityCards), settings);
                                    }
                                }
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}{!bool && <div className="hand-description">{hand}</div>}
                                        {bool && <div className="hand-description">{bestHandDescription}</div>}</div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {gameEndsTexas && texasGameTypeOmaha8 && <div>
                        {allCards.length > 0 && <div className="cards-display">
                            {allCards.map(player357 => {
                                let sortedCards = player357.cards;
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}{player357.lo && <div className="hand-description">High: {player357.descriptionHi}, Low: {player357.descriptionLo}</div>}
                                    {!player357.lo && <div className="hand-description">High: {player357.descriptionHi}</div>}</div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {rollOnCardsShow && isTexasLive && <div>
                        {rollOnPlayerCards.length > 0 && <div className="rolloncards-display">
                            {rollOnPlayerCards.map(player357 => {
                                let sortedCards = player357.cards;
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}
                                    </div>}
                                </div>
                            })
                        }
                        </div>}
                </div>}
                {(gamefinished || gameEndsCincinnati) && winners.cards && <div>
                          {winners.cards.length > 0 && <div className="cards-display">
                            {gameOverAllData.map(playercards => {
                                let loSortedCards = playercards.loSortedCards
                                let loHand = playercards.loHand
                                let hiSortedCards = playercards.hiSortedCards
                                let hiHand = playercards.hiHand
                                return <div className="">
                                    {playercards.clientName === player.clientName && <div>
                                        {((gamefinished && everyonesWinningType[player.clientName] === 'High') || (gamefinished && everyonesWinningType[player.clientName] === 'Mixed') || gameEndsCincinnati) && <div className="d-flex flex-row">
                                            {hiSortedCards.map((card, index) => (<div>
                                                    <div key={index} className="winnerWildCards">
                                                        <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                        {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                        {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>}
                                        {gamefinished && everyonesWinningType[player.clientName] === 'Low' && <div className="d-flex flex-row">
                                            {loSortedCards.map((card, index) => (<div>
                                                    <div key={index} className="winnerWildCards">
                                                        <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                        {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                        {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>}
                                        {gamefinished && everyonesWinningType[player.clientName] === 'High' && <div className="hand-description">High: {hiHand}</div>}
                                        {gamefinished && everyonesWinningType[player.clientName] === 'Low' && <div className="hand-description">Low: {loHand}</div>}
                                        {gamefinished && everyonesWinningType[player.clientName] === 'Mixed' && <div className="hand-description">High: {hiHand}, &nbsp; Low: {loHand}</div>}
                                        {gameEndsCincinnati && hiHand!==loHand && <div className="hand-description">High: {hiHand}, &nbsp; Low: {loHand}</div>}
                                        {gameEndsCincinnati && hiHand===loHand && <div className="hand-description">{hiHand}</div>}
                                    </div>}
                                </div>})
                        }
                    </div>}
                </div>}
                {is727Live && <div>
                        {downCards727.length > 0 && <div className="cards-display">
                            {downCards727.map(player357 => {
                                let sortedCards = [];
                                if(player357.clientName === mainPlayer) {
                                    let temp = [];
                                    for(const i in downCards7CS) {
                                        temp.push(downCards7CS[i].id);
                                    }
                                    sortedCards = temp;
                                }
                                else {
                                    for (let i = 0; i < player357.cards; i++) {
                                        sortedCards.push("CB");
                                    }
                                }
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                </div>
                                            </div>
                                    ))}</div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {isBaseballLive && <div>
                    {allPlayerCardsBaseball.length > 0 && <div className="cards-display">
                        {allPlayerCardsBaseball.map(player357 => {
                            let playerCards = player357.upCards.concat(player357.downCards);
                            return <div className="">
                                {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                {playerCards.map((card, index) => (<div>
                                        <div key={index} className="winnerWildCards">
                                            <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                            {(gameWildCards.includes(card) || followTheQueenCards.includes(card))&& <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                        </div>
                                    </div>
                                ))}</div>}
                            </div>
                        })}
                    </div>}
                </div>}
                {is727Live && <div>
                        {allPlayerCards7CS.length > 0 && <div className="downcards-display-727">
                            {allPlayerCards7CS.map(player357 => {
                                let sortedCards = player357.cards;
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {(gameWildCards.includes(card) || followTheQueenCards.includes(card))&& <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}</div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {(is7CSLive || is5CSLive || isCincinnatiLive) && <div>
                        {allPlayerCards7CS.length > 0 && <div className="cards-display">
                            {allPlayerCards7CS.map(player357 => {
                                let sortedCards = player357.cards;
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {/* {sortedCards.map((card, index) => (<div className={`${(index === sortedCards.length - 1) ? "cardanimation" : ""}`}> */}
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {(gameWildCards.includes(card) || followTheQueenCards.includes(card))&& <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}</div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {(is7CSLive || is5CSLive) && <div>
                        {allPlayerCards7CS.length > 0 && <div className="downcards-display">
                            {allPlayerCards7CS.map(player357 => {
                                let sortedCards = [];
                                if(player357.clientName === mainPlayer) {
                                    let temp = [];
                                    for(const i in downCards7CS) {
                                        temp.push(downCards7CS[i].id);
                                    }
                                    sortedCards = temp;
                                }
                                else {
                                    for (let i = 0; i < downCards7CS.length; i++) {
                                        sortedCards.push("CB");
                                    }
                                }
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                </div>
                                            </div>
                                    ))}</div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {gameEnds7CS && <div>
                        {allCards.length > 0 && <div className="cards-display">
                            {allCards.map(player357 => {
                                let sortedCards = player357.cards;
                                let up = sortedCards.slice(3);
                                let down = sortedCards.slice(0,3);
                                sortedCards = up.concat(down);
                                let hand = Hand.solve(sortedCards).descr;
                                if(gameJokersInDeck.length > 0 || gameWildCards.length > 0 || followTheQueenCards.length > 0) {
                                    // sortedCards = sortHiHand(sortedCards, settings);
                                    hand = getHiHandStringDescription(sortedCards, settings);
                                }
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            {index<(sortedCards.length - down.length) && <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {(gameWildCards.includes(card) || followTheQueenCards.includes(card))&& <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>}
                                            {index>=(sortedCards.length - down.length) && <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)} border={true}/>
                                                {(gameWildCards.includes(card) || followTheQueenCards.includes(card))&& <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>}
                                        </div>
                                    ))}<div className="hand-description">{hand}</div></div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {gameEnds5CS && <div>
                        {allCards.length > 0 && <div className="cards-display">
                            {allCards.map(player357 => {
                                let sortedCards = player357.cards;
                                let doYaWildCards = [];
                                if(currentGameName === "Do Ya") {
                                    let up = [sortedCards[1], sortedCards[2], sortedCards[3], sortedCards[4]];
                                    let down = [sortedCards[0]];
                                    doYaWildCards = getAllDoYaWildCards(sortedCards[0]);
                                    sortedCards = up.concat(down);
                                }
                                else {
                                    let up = [sortedCards[2], sortedCards[3], sortedCards[4]];
                                    let down = [sortedCards[0], sortedCards[1]];
                                    sortedCards = up.concat(down);
                                }
                                let hand = Hand.solve(sortedCards).descr;
                                if(gameJokersInDeck.length > 0 || gameWildCards.length > 0 || followTheQueenCards.length > 0 || doYaWildCards.length > 0) {
                                    let newSettings = {
                                        wildCardsSettings : {
                                            wildCardsSet : new Set(gameWildCards.concat(doYaWildCards)),
                                            jokersInDeckSet : new Set(gameJokersInDeck),
                                            royalFlushBeatsFiveOfKind : Boolean(gameroyalFlushBeatsFiveOfKind),
                                            handsMustHaveOneNaturalCard: Boolean(gamehandsMustHaveOneNaturalCard),
                                            payForFaceUpWildCard: 0
                                        }
                                    }
                                    hand = getHiHandStringDescription(sortedCards, newSettings);
                                }
                                let loHand;
                                if(currentGameName === "Do Ya") {
                                    let newSettings = {
                                        loSettings : {
                                            countStraightAndFlushes : Boolean(gameLoFlush),
                                            considerAceLow: Boolean(gameLoAce)
                                        }
                                    }
                                    if(gameJokersInDeck.length > 0 || gameWildCards.length > 0 || followTheQueenCards.length > 0 || doYaWildCards.length > 0) {
                                        newSettings = {
                                            loSettings : {
                                                countStraightAndFlushes : Boolean(gameLoFlush),
                                                considerAceLow: Boolean(gameLoAce)
                                            },
                                            wildCardsSettings : {
                                                wildCardsSet : new Set(gameWildCards.concat(doYaWildCards)),
                                                jokersInDeckSet : new Set(gameJokersInDeck),
                                                royalFlushBeatsFiveOfKind : Boolean(gameroyalFlushBeatsFiveOfKind),
                                                handsMustHaveOneNaturalCard: Boolean(gamehandsMustHaveOneNaturalCard),
                                                payForFaceUpWildCard: 0
                                            }
                                        }
                                        loHand = getLoHandStringDescription(sortedCards, newSettings);
                                    }
                                    else {
                                        loHand = getLoHandStringDescription(sortedCards, newSettings);
                                    } 
                                }
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            {index<(currentGameName === "Do Ya" ? 4 : 3) && <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {(doYaWildCards.includes(card) || gameWildCards.includes(card) || followTheQueenCards.includes(card))&& <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>}
                                            {index>=(currentGameName === "Do Ya" ? 4 : 3) && <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)} border={true}/>
                                                {(doYaWildCards.includes(card) || gameWildCards.includes(card) || followTheQueenCards.includes(card))&& <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>}
                                        </div>
                                    ))}
                                    {currentGameName !== "Do Ya" && <div className="hand-description">{hand}</div>}
                                    {currentGameName === "Do Ya" && <div className="hand-description">Hi: {hand}, Lo: {loHand}</div>}
                                    </div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {gameEnds727 && <div>
                    {allCards.length > 0 && <div className="cards-display">
                            {allCards.map(player357 => {
                                let sortedCards = player357.cards;
                                let hand = calculateCardsSum(sortedCards);
                                return <div className="">
                                    {player357.clientName === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}
                                    {hand.length > 0 && <div className="hand-description">Sum: {hand.map((sum, index) => <span key={index}>{sum}{index<hand.length - 1 && <span>/</span>}</span>)}</div>}
                                    </div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}

                {(gameEndsHomeRun) && <div>
                    {winners.bonusPayments.length > 0 && <div>
                        {winners.bonusPayments.map(bonusPayment => {

                            return <div>
                                {bonusPayment.bonusType == 'Dragon' && bonusPayment.player == player.clientName && <div>
                                    <img src={Dragon} alt="Dragon" className='dragon-image'/>
                                </div>}
                            </div>
                        })
                        }
                    </div>}
                </div>}


                {(isHomeRunLive || gameEndsHomeRun) && <div>
                    {settingsFront.length > 0 && <div className="frontsettings-display">
                            {settingsFront.map(player357 => {
                                let sortedCards = player357.cards;
                                let hand = Hand.solve(sortedCards);
                                return <div className="">
                                    {player357.player === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}{true && <div className="homerunhand-description-front">Front: {hand.descr}</div>}</div>}
                                </div>
                            })
                        }
                    </div>}
                    {settingsMiddle.length > 0 && <div className="middlesettings-display">
                            {settingsMiddle.map(player357 => {
                                let sortedCards = player357.cards;
                                let hand = Hand.solve(sortedCards);
                                return <div className="">
                                    {player357.player === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}{true && <div className="homerunhand-description-middle">Mid: {hand.descr}</div>}</div>}
                                </div>
                            })
                        }
                    </div>}
                    {settingsBack.length > 0 && <div className="backsettings-display">
                            {settingsBack.map(player357 => {
                                let sortedCards = player357.cards;
                                let hand = Hand.solve(sortedCards);
                                return <div className="">
                                    {player357.player === player.clientName && <div className="d-flex flex-row">
                                    {sortedCards.map((card, index) => (<div>
                                            <div key={index} className="winnerWildCards">
                                                <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                                {gameWildCards.includes(card) && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                                {card[card.length - 1] === "w" && <div className="isWildWinnerCard"><i class="fa fa-winner fa-star" aria-hidden="true"/></div>}
                                            </div>
                                        </div>
                                    ))}{true && <div className="homerunhand-description-back">Back: {hand.descr}</div>}</div>}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {bigBlindPlayer === player.clientName && player.clientName.length > 0 && <div className="cards-display">
                        <img className="sb-display" src={BigBlind} alt="Big Blind"/>
                    </div>}
                {(currentGameName === "5-Card-Stud Round") && roundDealer === player.clientName && player.clientName.length > 0 && <div className="cards-display">
                    <img className="dealer5cs-display" src={DealerRound} alt="Big Blind"/>
                </div>}        
                {(isTexasLive || is5CDLive) && roundDealer === player.clientName && player.clientName.length > 0 && <div className="cards-display">
                        <img className="sb-display" src={DealerRound} alt="Big Blind"/>
                    </div>}
                {diabloOpener.length > 0 && diabloOpener === player.clientName && <div className="cards-display">
                        <img className="opener-display" src={OpenerImage} alt="Opener"/>
                </div>}
                {smallBlindPlayer === player.clientName && player.clientName.length > 0 && <div className="cards-display">
                        <img className="sb-display" src={SmallBlind} alt="Small Blind"/>
                    </div>}
                {foldedPlayers.length > 0 &&
                    <div>
                        {foldedPlayers.includes(player.clientName) && <div>
                                {!bustedPlayers.includes(player.clientName) && <img className={`${is5CDLive && showExchangedCards && allPlayersExchangedCards.includes(player.clientName) ? "fold-display-fivecd" : "fold-display"}`} src={foldImage} alt="Fold"/>}
                                {bustedPlayers.includes(player.clientName) && <img className="fold-display" src={bustedImage} alt="Fold"/>}
                        </div>}
                    </div>}
                {bool && (is5CDLive || isAnacondaLive || isTexasLive || is7CSLive || is5CSLive || isCincinnatiLive || is727Live || isBaseballLive) &&
                    <div className={this.props.coin}>
                        <PlayerCoinsImage
                        myCurrentBet={myCurrentBet}
                        currentPlayerBets={currentPlayerBets}
                        player={player}/>
                    </div>
                }
                {bool && (is5CDLive || isAnacondaLive || isTexasLive || is7CSLive || is5CSLive || isCincinnatiLive || is727Live || isBaseballLive) &&
                    <div className={this.props.coinValue}>
                        <strong>
                            <PlayerCoinsStatus myCurrentBet={myCurrentBet} currentPlayerBets={currentPlayerBets} player={player}/>
                        </strong>
                    </div>
                }
                {(gamefinished || gameEnds727) && !winners.hasEveryoneFolded && player.clientName.length > 0 && !foldedPlayers.includes(player.clientName) && 
                    // <Card className={this.props.winningAnaconda}>
                    <div>
                        {Object.keys(everyonesWinningType).map(playerwinner => {
                                return (
                                    <div>
                                        {playerwinner === player.clientName && <span className={`${this.props.winningAnaconda} type-${everyonesWinningType[playerwinner]}`}>{everyonesWinningType[playerwinner] === 'Mixed' ? 'Both' : everyonesWinningType[playerwinner]}</span>}
                                    </div>
                                )
                            }
                        )}
                        </div>
                    //</Card>
                }
                {roundOver357 && !gameEnds357 && player.clientName.length > 0 && !foldedPlayers.includes(player.clientName) && !kickedOutPlayers.includes(player.clientName) &&
                    <div>
                        {Object.keys(playersSelection).map(player357 => {
                                return (
                                    <div>
                                        {player357 === player.clientName && playersSelection[player357]==='IN' && <div className={`${this.props.winning357} hold`}>Hold</div>}
                                        {player357 === player.clientName && playersSelection[player357]==='OUT' && <div className={`${this.props.winning357} drop`}>Drop</div>}
                                    </div>
                                )
                            }
                        )}
                    </div>
                }
                {showReady357 && <div className="ready-display font-weight-bold">
                    {playerReady.map((data, index) => {
                                return (
                                    <div key={index}>
                                        {data.clientName === player.clientName && <div>{data.status}</div>}
                                    </div>
                                )
                            }
                        )}
                </div>}
                {showExchangedCards && is5CDLive && <div className="card-exchange-display">
                    {cardsExchanged5CD.map((data, index) => {
                                return (
                                    <div key={index}>
                                        {data.clientName === player.clientName && <div>Cards Exchanged: {data.status}</div>}
                                    </div>
                                )
                            }
                        )}
                </div>}
                {showPlayersPassedCin && isCincinnatiLive && <div className={cinArrLeft}>
                    <Arrow angle={270} length={45} lineWidth={2}
                        color={playersPassedCardsCin.includes(player.clientName) ? "green" : "red"}
                        style={{
                            width: '60px'
                        }}
                    />
                </div>}
                {showPlayersPassedCin && isCincinnatiLive && <div className={cinArrRight}>
                    <Arrow angle={270} length={45} lineWidth={2}
                        color={playersPassedCardsCin.includes(player.clientName) ? "green" : "red"}
                        style={{
                            width: '60px'
                        }}
                    />
                </div>}
                {cardPassingRoundActive && !foldedPlayers.includes(player.clientName) && <div className={anacondaCss}>
                    <Arrow angle={270} length={45} lineWidth={2}
                        color={anacondaPassedCards.includes(player.clientName) ? "green" : "red"}
                        style={{
                            width: '60px'
                        }}
                    />
                </div>}
                {showRevealStatus && isCincinnatiLive && <div className="card-exchange-display">
                    {playersRevealStatus.includes(player.clientName) && <div>Ready</div>}
                </div>}
                {homeRunShowStatus && isHomeRunLive && <div className="card-exchange-display">
                    {homeRunSubmitStatus.includes(player.clientName) && <div>Ready</div>}
                </div>}
                {showPencilStatus && <div className="card-exchange-display">
                    {pencilAmountData.includes(player.clientName) && <div>Ready</div>}
                </div>}
                {drawRound727Show && !gameSimultaneousPassDrawRound && <div className="ready-display font-weight-bold">
                    {draw727RoundDetails.map((data, index) => {
                            return (
                                <div key={index}>
                                    {data.clientName === player.clientName && <div>{data.decision === 'pass' ? 'PASS' : 'DRAW'}</div>}
                                </div>
                            )
                        }
                    )}
                </div>}
                {drawRoundBaseballShow && <div className="ready-display font-weight-bold">
                    {drawBaseballDecisions.map((data, index) => {
                            return (
                                <div key={index}>
                                    {data.clientName === player.clientName && <div>{data.decision === 'DRAW' ? 'DRAW' : 'PASS'}</div>}
                                </div>
                            )
                        }
                    )}
                </div>}
                {drawRound727Show && gameSimultaneousPassDrawRound && <div className="ready-display font-weight-bold">
                    {draw727RoundDetails.includes(player.clientName) && <div>READY</div>}
                </div>}
                {is727Live && noOfPassesPlayerDetails.length > 0 && <div className="passes-display">
                    {noOfPassesPlayerDetails.map((data, index) => {
                            return (
                                <div key={index}>
                                    {data.clientName === player.clientName && <div>Passes: {data.passes}</div>}
                                </div>
                            )
                        }
                    )}
                </div>}
                {showRoomReady && <div className="ready-display font-weight-bold">
                    {gameReady.includes(player.clientName) && <div>READY</div>}
                </div>}
            </div>}
            </div>
        );
    }
}

export default withRouter(Player);
