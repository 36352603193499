const {SevenTwoSevenAceCardsValues} = require('../CardsConstants')

const AcesSums = {
    1 : [1, 11],
    2 : [2, 22, 12],
    3 : [3, 13, 23, 33],
    4 : [4, 14, 24, 34, 44]
}

const calculateCardsSum = (playerCards) => {
    const sums = []
    let noOfAces = 0
    let nonAcesSum = 0
    for(const card of playerCards){
        if(card[0] === 'A'){
            noOfAces++
            continue
        }
        nonAcesSum += SevenTwoSevenAceCardsValues[card[0]]
    }

    if(noOfAces === 0){ return [nonAcesSum] }
    for(const acesSum of AcesSums[noOfAces]){ sums.push(nonAcesSum + acesSum) }
    return sums
}

export default calculateCardsSum;
