const HiCardsValues = {
    '2' : 1,
    '3' : 2,
    '4' : 3,
    '5' : 4,
    '6' : 5,
    '7' : 6,
    '8' : 7,
    '9' : 8,
    'T' : 9,
    'J' : 10,
    'Q' : 11,
    'K' : 12,
    'A' : 13,
}

const HiValuesCards = {
    1 : '2',
    2 : '3',
    3 : '4',
    4 : '5',
    5 : '6',
    6 : '7',
    7 : '8',
    8 : '9',
    9 : 'T',
    10 : 'J',
    11 : 'Q',
    12 : 'K',
    13 : 'A'
}

const LoAceCardsValues = {
    'A' : 1,
    '2' : 2,
    '3' : 3,
    '4' : 4,
    '5' : 5,
    '6' : 6,
    '7' : 7,
    '8' : 8,
    '9' : 9,
    'T' : 10,
    'J' : 11,
    'Q' : 12,
    'K' : 13,
}

const LoAceValuesCards = {
    1 : 'A',
    2 : '2',
    3 : '3',
    4 : '4',
    5 : '5',
    6 : '6',
    7 : '7',
    8 : '8',
    9 : '9',
    10 : 'T',
    11 : 'J',
    12 : 'Q',
    13 : 'K'
}

const HiCardsPreNext = {
    '2' : [undefined, '3'],
    '3' : ['2', '4'],
    '4' : ['3', '5'],
    '5' : ['4', '6'],
    '6' : ['5', '7'],
    '7' : ['6', '8'],
    '8' : ['7', '9'],
    '9' : ['8', 'T'],
    'T' : ['9', 'J'],
    'J' : ['T', 'Q'],
    'Q' : ['J', 'K'],
    'K' : ['Q', 'A'],
    'A' : ['K', undefined],
}

const LoAceCardsPreNext = {
    'A' : [undefined, '2'],
    '2' : ['A', '3'],
    '3' : ['2', '4'],
    '4' : ['3', '5'],
    '5' : ['4', '6'],
    '6' : ['5', '7'],
    '7' : ['6', '8'],
    '8' : ['7', '9'],
    '9' : ['8', 'T'],
    'T' : ['9', 'J'],
    'J' : ['T', 'Q'],
    'Q' : ['J', 'K'],
    'K' : ['Q', undefined]
}

const valuesPrimes = {
    1 : 2,
    2 : 3, 
    3 : 5, 
    4 : 7, 
    5 : 11, 
    6 : 13, 
    7 : 17, 
    8 : 19, 
    9 : 23, 
    10 : 29, 
    11 : 31, 
    12 : 37,
    13 : 41
}

const SevenTwoSevenAceCardsValues = {
    '2' : 2,
    '3' : 3,
    '4' : 4,
    '5' : 5,
    '6' : 6,
    '7' : 7,
    '8' : 8,
    '9' : 9,
    'T' : 10,
    'J' : 0.5,
    'Q' : 0.5,
    'K' : 0.5,
}

const SevenTwoSevenHiAceCardsValues = {
    'A' : 11,
    '2' : 2,
    '3' : 3,
    '4' : 4,
    '5' : 5,
    '6' : 6,
    '7' : 7,
    '8' : 8,
    '9' : 9,
    'T' : 10,
    'J' : 0.5,
    'Q' : 0.5,
    'K' : 0.5,
}

const RANKS = {
    FIVE_OF_A_KIND  : 9,
    STRAIGHT_FLUSH  : 8,
    FOUR_OF_A_KIND  : 7,
    FULL_HOUSE      : 6,
    FLUSH           : 5,
    STRAIGHT        : 4,
    THREE_OF_A_KIND : 3,
    TWO_PAIR        : 2,
    ONE_PAIR        : 1,
    HIGH_CARD       : 0  
}

const RANKS_PS = {
    FIVE_OF_A_KIND  : 11,
    ROYAL_FLUSH     : 10,
    STRAIGHT_FLUSH  : 9,
    FOUR_OF_A_KIND  : 8,
    FULL_HOUSE      : 7,
    FLUSH           : 6,
    STRAIGHT        : 5,
    THREE_OF_A_KIND : 4,
    TWO_PAIR        : 3,
    ONE_PAIR        : 2,
    HIGH_CARD       : 1  
}

const RANKS_STRINGS = {
    9 : "FIVE_OF_A_KIND" ,
    8 : "STRAIGHT_FLUSH" ,
    7 : "FOUR_OF_A_KIND" ,
    6 : "FULL_HOUSE"     ,
    5 : "FLUSH"          ,
    4 : "STRAIGHT"       ,
    3 : "THREE_OF_A_KIND",
    2 : "TWO_PAIR"       ,
    1 : "ONE_PAIR"       ,
    0 : "HIGH_CARD"        
}

const N_OF_A_KIND_RANKS = {
    1 : RANKS.HIGH_CARD,
    2 : RANKS.ONE_PAIR,
    3 : RANKS.THREE_OF_A_KIND,
    4 : RANKS.FOUR_OF_A_KIND,
    5 : RANKS.FIVE_OF_A_KIND
}

const JOKER_SUIT = 'w'
const JOKER_CARDS = []
const letterToExcludeSet = new Set([74, 75, 81, 84, 79]) // T,J,Q,K,O
for (let i = 90; i >= 65; i--) {
    if(letterToExcludeSet.has(i)){continue}
    JOKER_CARDS.push(String.fromCharCode(i) + JOKER_SUIT)
}

const suitsSet = new Set(['s', 'd', 'h', 'c'])


//////////////////// CONSTANSTS for converting in deck cards to wild-card and vice-versa //////////
const suitSymbolsMappingForWild = {
    s : 'z', d : 'x', h : 'y', c : 'u' 
}

const reverseSuitSymbolsMappingForWild = {
    z : 's', x : 'd', y : 'h', u : 'c' 
}

const cardValuesMappingForWild = {
    'A' : "R",
    '2' : "B",
    '3' : "C",
    '4' : "D",
    '5' : "E",
    '6' : "F",
    '7' : "G",
    '8' : "H",
    '9' : "I",
    'T' : "L",
    'J' : "M",
    'Q' : "N",
    'K' : "P"
}

const reverseCardValuesMappingForWild = {
    "R" : 'A', 
    "B" : '2', 
    "C" : '3', 
    "D" : '4', 
    "E" : '5', 
    "F" : '6', 
    "G" : '7', 
    "H" : '8', 
    "I" : '9', 
    "L" : 'T', 
    "M" : 'J', 
    "N" : 'Q', 
    "P" : 'K', 
}
class RankDescription{
    constructor(rank, rankBestCards=[]){
        this.rank = rank
        this.rankBestCards = rankBestCards
        this.rankString = RANKS_STRINGS[rank]
    }
}

const HandTypes = {
    HIGH : 'HIGH',
    LOW : "LOW"
}

// exports.HiCardsValues = HiCardsValues
// exports.HiValuesCards = HiValuesCards
// exports.LoAceCardsValues = LoAceCardsValues
// exports.LoAceValuesCards = LoAceValuesCards
// exports.HiCardsPreNext = HiCardsPreNext
// exports.LoAceCardsPreNext = LoAceCardsPreNext
// exports.valuesPrimes = valuesPrimes
// exports.RANKS = RANKS
// exports.RANKS_PS = RANKS_PS
// exports.RANKS_STRINGS = RANKS_STRINGS
// exports.N_OF_A_KIND_RANKS = N_OF_A_KIND_RANKS
// exports.JOKER_SUIT = JOKER_SUIT
// exports.JOKER_CARDS = JOKER_CARDS
// exports.suitsSet = suitsSet
// exports.suitSymbolsMappingForWild = suitSymbolsMappingForWild
// exports.reverseSuitSymbolsMappingForWild = reverseSuitSymbolsMappingForWild
// exports.cardValuesMappingForWild = cardValuesMappingForWild
// exports.reverseCardValuesMappingForWild = reverseCardValuesMappingForWild
// exports.RankDescription = RankDescription
// exports.HandTypes = HandTypes

export {
    HiCardsValues,
    HiValuesCards,
    LoAceCardsValues,
    LoAceValuesCards,
    HiCardsPreNext,
    LoAceCardsPreNext,
    valuesPrimes,
    RANKS,
    RANKS_PS,
    RANKS_STRINGS,
    N_OF_A_KIND_RANKS,
    JOKER_SUIT,
    JOKER_CARDS,
    suitsSet,
    suitSymbolsMappingForWild,
    reverseSuitSymbolsMappingForWild,
    cardValuesMappingForWild,
    reverseCardValuesMappingForWild,
    RankDescription,
    HandTypes,
    SevenTwoSevenAceCardsValues
}