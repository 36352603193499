import React, { Component } from 'react';

import twos from "../../assets/Cards/2s.svg";
import twod from "../../assets/Cards/2d.svg";
import twoc from "../../assets/Cards/2c.svg";
import twoh from "../../assets/Cards/2h.svg";

import threes from "../../assets/Cards/3s.svg";
import threed from "../../assets/Cards/3d.svg";
import threec from "../../assets/Cards/3c.svg";
import threeh from "../../assets/Cards/3h.svg";

import fours from "../../assets/Cards/4s.svg";
import fourd from "../../assets/Cards/4d.svg";
import fourc from "../../assets/Cards/4c.svg";
import fourh from "../../assets/Cards/4h.svg";

import fives from "../../assets/Cards/5s.svg";
import fived from "../../assets/Cards/5d.svg";
import fivec from "../../assets/Cards/5c.svg";
import fiveh from "../../assets/Cards/5h.svg";

import sixs from "../../assets/Cards/6s.svg";
import sixd from "../../assets/Cards/6d.svg";
import sixc from "../../assets/Cards/6c.svg";
import sixh from "../../assets/Cards/6h.svg";

import sevens from "../../assets/Cards/7s.svg";
import sevend from "../../assets/Cards/7d.svg";
import sevenc from "../../assets/Cards/7c.svg";
import sevenh from "../../assets/Cards/7h.svg";

import eights from "../../assets/Cards/8s.svg";
import eightd from "../../assets/Cards/8d.svg";
import eightc from "../../assets/Cards/8c.svg";
import eighth from "../../assets/Cards/8h.svg";

import nines from "../../assets/Cards/9s.svg";
import nined from "../../assets/Cards/9d.svg";
import ninec from "../../assets/Cards/9c.svg";
import nineh from "../../assets/Cards/9h.svg";

import Ts from "../../assets/Cards/Ts.svg";
import Td from "../../assets/Cards/Td.svg";
import Tc from "../../assets/Cards/Tc.svg";
import Th from "../../assets/Cards/Th.svg";

import Js from "../../assets/Cards/Js.svg";
import Jd from "../../assets/Cards/Jd.svg";
import Jc from "../../assets/Cards/Jc.svg";
import Jh from "../../assets/Cards/Jh.svg";

import Qs from "../../assets/Cards/Qs.svg";
import Qd from "../../assets/Cards/Qd.svg";
import Qc from "../../assets/Cards/Qc.svg";
import Qh from "../../assets/Cards/Qh.svg";

import Ks from "../../assets/Cards/Ks.svg";
import Kd from "../../assets/Cards/Kd.svg";
import Kc from "../../assets/Cards/Kc.svg";
import Kh from "../../assets/Cards/Kh.svg";

import As from "../../assets/Cards/As.svg";
import Ad from "../../assets/Cards/Ad.svg";
import Ac from "../../assets/Cards/Ac.svg";
import Ah from "../../assets/Cards/Ah.svg";

import Joker from "../../assets/Cards/Joker.svg";
import DownCard from "../../assets/Cards/DownCard.svg";

import './WinnerCard.css';

class WinnerCards extends Component {
    render() {

        if(this.props.suit === 's') {
            if(this.props.value === '2') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={twos} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '3') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={threes} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '4') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={fours} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '5') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={fives} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '6') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={sixs} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '7') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={sevens} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '8') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={eights} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '9') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={nines} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'T') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Ts} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'J') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Js} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'Q') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Qs} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'K') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Ks} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'A') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={As} alt="Cards" width='38px' height='55px'/></div>
            }
        }

        if(this.props.suit === 'd') {
            if(this.props.value === '2') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={twod} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '3') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={threed} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '4') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={fourd} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '5') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={fived} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '6') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={sixd} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '7') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={sevend} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '8') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={eightd} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '9') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={nined} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'T') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Td} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'J') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Jd} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'Q') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Qd} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'K') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Kd} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'A') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Ad} alt="Cards" width='38px' height='55px'/></div>
            }
        }

        if(this.props.suit === 'c') {
            if(this.props.value === '2') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={twoc} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '3') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={threec} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '4') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={fourc} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '5') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={fivec} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '6') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={sixc} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '7') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={sevenc} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '8') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={eightc} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '9') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={ninec} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'T') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Tc} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'J') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Jc} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'Q') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Qc} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'K') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Kc} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'A') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Ac} alt="Cards" width='38px' height='55px'/></div>
            }
        }

        if(this.props.suit === 'h') {
            if(this.props.value === '2') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={twoh} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '3') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={threeh} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '4') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={fourh} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '5') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={fiveh} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '6') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={sixh} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '7') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={sevenh} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '8') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={eighth} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === '9') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={nineh} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'T') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Th} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'J') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Jh} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'Q') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Qh} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'K') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Kh} alt="Cards" width='38px' height='55px'/></div>
            }
            if(this.props.value === 'A') {
                return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Ah} alt="Cards" width='38px' height='55px'/></div>
            }
        }

        if(this.props.suit === 'w') {
            return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={Joker} alt="Cards" width='38px' height='55px'/></div>
        }

        if(this.props.suit === 'B') {
            return <div className={`newwinning ${this.props.border ? "border-active" : ""}`}><img src={DownCard} alt="Cards" width='38px' height='55px'/></div>
        }

        return<span></span>

        // if (this.props.suit.toLowerCase() === 's'){
        //     return (
        //     <div className="winner-card winner-card-black">
        //         <div className="winner-card-tl">
        //             <div className="winner-card-value">
        //                 {this.props.value === 'T' ? '10' : this.props.value}
        //             </div>
        //             <div className="winner-card-suit">
        //                 ♠︎
        //             </div>
        //         </div>
        //     </div>);
        // }
        // else if (this.props.suit.toLowerCase() === 'c'){
        //     return (
        //         <div className="winner-card winner-card-black">
        //             <div className="winner-card-tl">
        //                 <div className="winner-card-value">
        //                     {this.props.value === 'T' ? '10' : this.props.value}
        //                 </div>
        //                 <div className="winner-card-suit">
        //                     ♣︎
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }
        //   else if (this.props.suit.toLowerCase() === 'd'){
        //     return (
        //         <div className="winner-card winner-card-red">
        //             <div className="winner-card-tl">
        //                 <div className="winner-card-value">
        //                     {this.props.value === 'T' ? '10' : this.props.value}
        //                 </div>
        //                 <div className="winner-card-suit">
        //                     ♦︎
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }
        // else {
        //     return (
        //         <div className="winner-card winner-card-red">
        //             <div className="winner-card-tl">
        //                 <div className="winner-card-value">
        //                     {this.props.value === 'T' ? '10' : this.props.value}
        //                 </div>
        //                 <div className="winner-card-suit">
        //                     ♥︎
        //                 </div>
        //             </div>
        //         </div>
        //     );
        //   }
    }
}

export default WinnerCards;
