import {Hand} from "pokersolver"
const {orderCardsHomeRunType1, sortHandsCompareFunction, HiCardsValues, sortBySuit, sortCardsBasedOnValue} = require("../../PokerHandUtils")


export const SETTINGS = {
    FRONT : "Front",
    BACK : 'Back',
    MIDDLE : 'Middle'
}

const SETTINGS_CARDS = {
    [SETTINGS.FRONT] : 3,
    [SETTINGS.BACK] : 5,
    [SETTINGS.MIDDLE] : 5
}

export const CARDS_ORDER_TYPE = {
    T1_BMF: "T1_BMF", 
    T1_MBF: "T1_MBF", 
    T1_BFM: "T1_BFM", 
    T1_MFB: "T1_MFB", 
    T1_FMB: "T1_FMB",
    T1_FBM: "T1_FBM",
    T2_SORT_BY_SUIT : "T2_SORT_BY_SUIT",
    T3_SORT_BY_CARDS_NO : "T3_SORT_BY_CARDS_NO"
}

export const getBestSettings = function(cards, orderType){
    const convertCardsToSettings = (cards) => {
        return {
            [SETTINGS.FRONT] : cards.slice(0,3),
            [SETTINGS.MIDDLE] : cards.slice(3,8),
            [SETTINGS.BACK] : cards.slice(8,13)
        }
    }
    if(orderType === CARDS_ORDER_TYPE.T1_BFM){
        return orderCardsHomeRunType1(cards, [SETTINGS.BACK, SETTINGS.FRONT, SETTINGS.MIDDLE], SETTINGS_CARDS, validateSettingsRank)
    }
    else if(orderType === CARDS_ORDER_TYPE.T1_BMF){
        return orderCardsHomeRunType1(cards, [SETTINGS.BACK, SETTINGS.MIDDLE, SETTINGS.FRONT], SETTINGS_CARDS, validateSettingsRank)
    }
    else if(orderType === CARDS_ORDER_TYPE.T1_MBF){
        return orderCardsHomeRunType1(cards, [SETTINGS.MIDDLE, SETTINGS.BACK, SETTINGS.FRONT], SETTINGS_CARDS, validateSettingsRank)
    }
    else if(orderType === CARDS_ORDER_TYPE.T1_MFB){
        return orderCardsHomeRunType1(cards, [SETTINGS.MIDDLE, SETTINGS.FRONT, SETTINGS.BACK], SETTINGS_CARDS, validateSettingsRank)
    }
    else if(orderType === CARDS_ORDER_TYPE.T1_FMB){
        return orderCardsHomeRunType1(cards, [SETTINGS.FRONT, SETTINGS.MIDDLE, SETTINGS.BACK], SETTINGS_CARDS, validateSettingsRank)
    }
    else if(orderType === CARDS_ORDER_TYPE.T1_FBM){
        return orderCardsHomeRunType1(cards, [SETTINGS.FRONT, SETTINGS.BACK, SETTINGS.MIDDLE], SETTINGS_CARDS, validateSettingsRank)
    }
    else if(orderType === CARDS_ORDER_TYPE.T2_SORT_BY_SUIT){
        sortBySuit(cards, HiCardsValues)
        return convertCardsToSettings(cards)
    }   
    else if(orderType === CARDS_ORDER_TYPE.T3_SORT_BY_CARDS_NO){
        sortCardsBasedOnValue(cards, HiCardsValues)
        return convertCardsToSettings(cards)
    }
}

export const getAllArrangements = function (cards){
    const arrangements = {}
    for(const type in CARDS_ORDER_TYPE){
        arrangements[type] = getBestSettings(cards, CARDS_ORDER_TYPE[type])
    }
    return arrangements
}

export const validateSettingsRank = (settings) => {
    const array = []
    Object.values(SETTINGS).forEach(setting => {
        const hand = Hand.solve([...settings[setting]])
        hand.setting = setting
        array.push(hand)
    })

    array.sort(sortHandsCompareFunction.bind(undefined, "setting"))

    if(array[2].setting !== SETTINGS.BACK || array[1].setting !== SETTINGS.MIDDLE || array[0].setting !== SETTINGS.FRONT){
        return false
    }
    
    return true
}