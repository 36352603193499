import {Hand} from '../PokerSolver/pokersolver';
import {getPokerSolverHandSetting, transformRank} from './utils'
import {isAllowCardsToEvaluate, getNoOfNonWildsInSuperSetCards, getCombinations} from '../CardsUtils'

const getHiHandWinners = (players, playersCards, settings) => {
    const playerHands = players.map(player => {
        const hand = Hand.solve(playersCards[player], getPokerSolverHandSetting(settings), settings)
        hand.playerMarker = player
        return hand
    })

    const winners = Hand.winners(playerHands)

    return {winners : winners.map(hand=>hand.playerMarker)}
}

const compareHiHands = (cardsA, cardsB, settings) => {
    /** Works for no of cards : 2,3,4,5,6,7 
     *  Returns 0,1,-1
     */
    const handA = Hand.solve(cardsA, getPokerSolverHandSetting(settings), settings);
    handA.marker = 'A'
    const handB = Hand.solve(cardsB, getPokerSolverHandSetting(settings), settings);
    handB.marker = 'B'
    console.log(handA.descr, handB.descr)
    return handB.compare(handA)
}


const getHiHandStringDescription = (cards, settings) => {
    if(cards.length === 2){ return }
    const hand = Hand.solve(cards, getPokerSolverHandSetting(settings), settings)
    return hand.descr
}

const sortHiHand = (cardsInHand, settings) => {
    const hand = Hand.solve(cardsInHand, getPokerSolverHandSetting(settings), settings);
    const {cardPool, cards, cardsProvided} = hand
    const sortedHand = []
    const sortedHandSet = new Set()
    for(const cardObj of cards){
        const card = cardObj.value + cardObj.suit
        sortedHand.push(card)
        sortedHandSet.add(card)
    }
    for(const cardObj of cardPool){
        const card = cardObj.value + cardObj.suit
        if(! sortedHandSet.has(card)){
            sortedHand.push(card)
            sortedHandSet.add(card)
        }
    }

    // add remaining cards
    for(const card of cardsProvided){
        if(! sortedHandSet.has(card)){ sortedHand.push(card) }
    }

    return sortedHand
}

const sortOmahaCards = (playerCards, communityCards, settings) => {
    const cards = [...playerCards, ...communityCards]
    const bestHand = getBestCardsOmaha(playerCards, communityCards, settings)
    const sortedHand = sortHiHand(bestHand, settings)

    const sortedHandSet = new Set(sortedHand)
    const cardsRemaining = cards.filter(card => sortedHandSet.has(card))

    return [...sortedHand, ...cardsRemaining]
}

const getHandRank = (cards, settings) => {
    const hand = Hand.solve(cards, getPokerSolverHandSetting(settings), settings)
    return transformRank(hand.rank, (settings && settings.wildCardsSettings) ? settings.wildCardsSettings.royalFlushBeatsFiveOfKind : true) 
}


const getBestCardsOmaha = (playerCards, communityCards, settings) => {
    /*  Player Cards : Always 4 cards
        Board cards :  3,4,5 Board cards
        Returns best 5 cards
    */
    const doesSuperSetCardsHasAtleastOneNaturalCard = getNoOfNonWildsInSuperSetCards([...playerCards, ...communityCards], settings) > 0

    const playerCardsCombinations = []
    getCombinations(playerCards, playerCards.length, 2, playerCardsCombinations)
    const communityCardsCombinations = []
    getCombinations(communityCards, communityCards.length, 3, communityCardsCombinations)
    if(communityCardsCombinations.length === 0){ communityCardsCombinations.push([]) }

    const hands = []
    for(const communityCardsCombination of communityCardsCombinations){
        for(const playerCardsCombination of playerCardsCombinations){
            const cards = [...playerCardsCombination, ...communityCardsCombination]
            let cardsToEvaluate = cards
            const allowCardsToEvaluate = isAllowCardsToEvaluate(cardsToEvaluate, settings.wildCardsSettings, doesSuperSetCardsHasAtleastOneNaturalCard)
            if(! allowCardsToEvaluate) { continue }            
            const hand = Hand.solve(cardsToEvaluate, getPokerSolverHandSetting(settings), settings)
            hand.cardsCombination = cards
            hands.push(hand)
        }
    }

    const bestHands = Hand.winners(hands)

    return bestHands[0].cardsCombination
}

export {
    getHiHandWinners,
    compareHiHands,
    sortHiHand,
    getHiHandStringDescription,
    getHandRank,   
    getBestCardsOmaha,
    sortOmahaCards
}