import React, {Component} from "react";
import tableImage from "../../assets/table.png"
import Player from "./Player";
import {Card, Col, Toast, ToastHeader, Row, Container, Button} from "react-bootstrap";
import Cards from "../Cards/Cards";
import {dealerKickOut} from "../../services/room";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {getHiHandStringDescription, getBestCardsOmaha, sortHiHand} from '../../utils/modules/HandEvaluation/HiHandEvaluation';
import {getBestCardsOmaha8Lo, getLoHandStringDescription} from '../../utils/modules/HandEvaluation/LoHandEvaluation';
import WinnerCards from "../Cards/WinnerCards";
var Hand = require('pokersolver').Hand;

class GameTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showToast: true,
            toggleDetails: true,
            showPlayerRemoveSheet: false,
            playerToRemove: "",
            currentHomeRunChoice: "Back"
        };
    }

    toggleHomeRun = () => {
        this.setState({
            toggleDetails: !this.state.toggleDetails
        })
    }

    changeHomeRunChoice = (type) => {
        this.setState({currentHomeRunChoice: type})
    }

    findMyPosition = (clientName) => {
        let idx = this.props.players.findIndex(pl => pl.clientName === clientName);
        return idx === -1 ? 0 : idx;
    };

    getPlayerPosition = (idx) => {
        return idx % 7;
    };

    updateTableDetails = (players) => {
        let gamePlayers = players;
        while(gamePlayers.length < 7) {
            gamePlayers.push({clientName: "", stack: 0});
        }
        return gamePlayers;
    };

    playerPassedCards = (from, to) => {
        let cardsPassed = false;
        if (from.clientName === "" && (to.clientName === "")) {
            return true
        }
        this.props.cardsPassingStatus.map(status => {
            if ((from.clientName === "" || status.from === from.clientName) && (status.to === to.clientName || to.clientName === "")) {
                cardsPassed = status.cardsPassed
            }
        });
        return cardsPassed;
    };

    getColor = (gamePlayers, fromIdx, toIdx) => {
        let status = this.playerPassedCards(gamePlayers[this.getPlayerPosition(fromIdx)], gamePlayers[this.getPlayerPosition(toIdx)])
        return status ? "green" : "#F44336";
    };

    dealerRemove = async (player) => {
        console.log('DEALER KICK OUT', player);
        let body = {
            playerToKickOut : player
        }
        let response = await dealerKickOut(body, this.props.roomName);
        console.log('DEALER KICK OUT', response);
        this.setState({showPlayerRemoveSheet: false})
    }

    doYaFinalAction = (decision, card) => {
        if(this.props.showDoYaActions) {
            this.props.doYaCallback(decision, card);
        }
    }

    baseballFinalAction = (decision) => {
        if(this.props.drawActionsBaseballShow) {
            this.props.baseballCallback(decision);
        }
    }

    render() {
        let {players, currentPlayer, potValue, everyonesWinningType, winner, winnerCards, dealer, spectate,
            playerMsgDict, actionMsg, cardsPassingStatus, cardPassingRoundActive, currentTurnPlayer,
            foldedPlayers, potDetails, sidePots, betRoundOver, currentPlayerBets, myCurrentBet,
            allPlayerInfo, gameName357, winners, gamefinished, gameEnds357, details357, doYaCards, showDoYaActions,
            roundOver357, inPlayersCards, selectedIn, sideBetLowPress, afterRoundModalShow, current357Cards,
            isAnacondaLive, is5CDLive, gameEnds5CD, showReady357, readyData357, readyPressed357, is357Live,
            isTexasLive, allCommunityCards, gameEndsTexas, showExchangedCards, exchangedCards,
            allPlayerCards7CS, is7CSLive, gameEnds7CS, is5CSLive, gameEnds5CS, isCincinnatiLive, gameEndsCincinnati, gameEnds727,
            smallBlindPlayer, bigBlindPlayer, roundDealer, showPlayersPassedCin, playersPassedCardsCin,
            showRevealStatus, playersRevealStatus, isHomeRunLive, settingsFront, settingsMiddle, settingsBack,
            gameEndsHomeRun, cardSelectionEnabled, homeRunSubmitStatus, homeRunShowStatus,
            showRoomReady, roomReadyData, firstGameStarted, positions, loHand3B7, gameAnte, mainPlayer, resultHomeRun,
            spadeCardAllPlayers, currentGameName, boolHomeRun, bustedPlayers, anacondaPassedCards, roomName, texasGameTypeOmaha8, playersDisconnected,
            showPencilStatus, pencilAmountData, allowKickOut, gameStarted, allPlayersReady, kickedOutPlayers,
            currentPlayersToKick ,currentDisconnectedPlayers, rollOnPlayerCards, rollOnCardsShow, bots, diabloOpener, downCards7CS, gameWildCards, gameJokersInDeck,
            gameroyalFlushBeatsFiveOfKind, gamehandsMustHaveOneNaturalCard, gameLoAce, gameLoFlush, gameOverAllData, followTheQueenCards,
            drawRound727Show, draw727RoundDetails, is727Live, downCards727, gameSplitHiLo, noOfPassesPlayerDetails, lastBettingRound727, gameSimultaneousPassDrawRound, 
            drawBaseballDecisions, drawRoundBaseballShow, drawActionsBaseballShow, gameEndsBaseball, isBaseballLive, allPlayerCardsBaseball, animatedWinners} = this.props;

        let topLeftCss = "top-left";
        let topRightCss = "top-right";
        let midLeftCss = "middle-left";
        let midRightCss = "middle-right";
        let botLeftCss = "bottom-left";
        let botRightCss = "bottom-right";
        let botMidCss = "bottom-center";

        let gamePlayers = this.updateTableDetails(players);

        let pIndex = this.findMyPosition(currentPlayer);
        let play1 = gamePlayers[this.getPlayerPosition(pIndex + 1)];
        let play2 = gamePlayers[this.getPlayerPosition(pIndex + 2)];
        let play3 = gamePlayers[this.getPlayerPosition(pIndex + 3)];
        let play4 = gamePlayers[this.getPlayerPosition(pIndex + 4)];
        let play5 = gamePlayers[this.getPlayerPosition(pIndex + 5)];
        let play6 = gamePlayers[this.getPlayerPosition(pIndex + 6)];
        let play7 = gamePlayers[this.getPlayerPosition(pIndex)];
        let pl = [play1, play2, play3, play4, play5, play6, play7];
        // console.log("NEW PLAYERS", pIndex, pl);


        if(positions.length === 2) {
            botMidCss = "middle-left";
            if(play1.clientName) {
                botLeftCss = "middle-right";
            }   
            if(play6.clientName) {
                botRightCss = "middle-right"
            }
        }

        if(positions.length === 3) {
            botMidCss = "bottom-center";
            if(play6.clientName && play5.clientName) {
                botRightCss = "middle-right";
                midRightCss = "middle-left";
                // console.log('CSS POSITIONS', play6, botRightCss, play5, midRightCss);
            }
            if(play6.clientName && play1.clientName) {
                botRightCss = "middle-right";
                botLeftCss = "middle-left";
                // console.log('CSS POSITIONS', play6, botRightCss, play1, botLeftCss);
            }
            if(play1.clientName && play2.clientName) {
                botLeftCss = "middle-left";
                midLeftCss = "middle-right";
                // console.log('CSS POSITIONS', play1, botLeftCss, play2, midLeftCss);
            }
            // console.log('ALL CSS', topLeftCss, topRightCss, midLeftCss, midRightCss, botLeftCss, botRightCss, botMidCss);
        }

        if(positions.length === 4) {
            botMidCss = "bottom-left";
            if(play6.clientName && play5.clientName && play4.clientName) {
                botRightCss = "bottom-right";
                midRightCss = "top-right";
                topRightCss = "top-left";
            }
            if(play6.clientName && play5.clientName && play1.clientName) {
                botRightCss = "bottom-right";
                midRightCss = "top-right";
                botLeftCss = "top-left";
            }
            if(play6.clientName && play2.clientName && play1.clientName) {
                botRightCss = "bottom-right";
                midLeftCss = "top-right";
                botLeftCss = "top-left";
            }
            if(play3.clientName && play2.clientName && play1.clientName) {
                topLeftCss = "bottom-right";
                midLeftCss = "top-right";
                botLeftCss = "top-left";
            }
        }

        if(positions.length === 5) {
            botMidCss = "bottom-center";
            if(play6.clientName && play5.clientName && play4.clientName && play3.clientName) {
                botRightCss = "bottom-right";
                midRightCss = "top-right";
                topRightCss = "top-left";
                topLeftCss = "bottom-left";
            }
            if(play6.clientName && play5.clientName && play4.clientName && play1.clientName) {
                botRightCss = "bottom-right";
                midRightCss = "top-right";
                topRightCss = "top-left";
                botLeftCss = "bottom-left";
            }
            if(play6.clientName && play5.clientName && play2.clientName && play1.clientName) {
                botRightCss = "bottom-right";
                midRightCss = "top-right";
                midLeftCss = "top-left";
                botLeftCss = "bottom-left";
            }
            if(play6.clientName && play3.clientName && play2.clientName && play1.clientName) {
                botRightCss = "bottom-right";
                topLeftCss = "top-right";
                midLeftCss = "top-left";
                botLeftCss = "bottom-left";
            }
            if(play4.clientName && play3.clientName && play2.clientName && play1.clientName) {
                topRightCss = "bottom-right";
                topLeftCss = "top-right";
                midLeftCss = "top-left";
                botLeftCss = "bottom-left";
            }
        }

        let cincinnatiArrowDict = {
            "top-left" : {
                arrowleft: "cin-topleft-left",
                arrowright: "cin-topleft-right"
            },
            "top-right" : {
                arrowleft: "cin-topright-left",
                arrowright: "cin-topright-right"
            },
            "middle-left" : {
                arrowleft: "cin-midleft-left",
                arrowright: "cin-midleft-right"
            },
            "middle-right" : {
                arrowleft: "cin-midright-left",
                arrowright: "cin-midright-right"
            },
            "bottom-left" : {
                arrowleft: "cin-botleft-left",
                arrowright: "cin-botleft-right"
            },
            "bottom-center" : {
                arrowleft: "cin-botcenter-left",
                arrowright: "cin-botcenter-right"
            },
            "bottom-right" : {
                arrowleft: "cin-botright-left",
                arrowright: "cin-botright-right"
            }
        }

        let anacondaArrowDict = {
            "top-left" : {
                arrowpos: "arrow-top-right"
            },
            "top-right" : {
                arrowpos: "arrow-right-down"
            },
            "middle-left" : {
                arrowpos: "arrow-left-up"
            },
            "middle-right" : {
                arrowpos: "arrow-right-down"
            },
            "bottom-left" : {
                arrowpos: "arrow-left-up"
            },
            "bottom-center" : {
                arrowpos: "arrow-bottom-left"
            },
            "bottom-right" : {
                arrowpos : "arrow-bottom-left"
            }
        }

        let CSSdict = {
            "top-left" : {
                coin: "coin-topleft",
                coinValue: "coinvalue-topleft",
                winningAnaconda: "winningtype-topleft",
                winning357: "winningtype-topleft"
            },
            "top-right" : {
                coin: "coin-topright",
                coinValue: "coinvalue-topright",
                winningAnaconda: "winningtype-topright",
                winning357: "winningtype-topright"
            },
            "middle-left" : {
                coin: "coin-midleft",
                coinValue: "coinvalue-midleft",
                winningAnaconda: "winningtype-midleft",
                winning357: "winningtype-midleft"
            },
            "middle-right" : {
                coin: "coin-midright",
                coinValue: "coinvalue-midright",
                winningAnaconda: "winningtype-midright",
                winning357: "winningtype-midright"
            },
            "bottom-left" : {
                coin: "coin-botleft",
                coinValue: "coinvalue-botleft",
                winningAnaconda: "winningtype-botleft",
                winning357: "winningtype-botleft"
            },
            "bottom-center" : {
                coin: "coin-botcenter",
                coinValue: "coinvalue-botcenter",
                winningAnaconda: "winningtype-botcenter",
                winning357: "selectiontype-botcenter"
            },
            "bottom-right" : {
                coin: "coin-botright",
                coinValue: "coinvalue-botright",
                winningAnaconda: "winningtype-botright",
                winning357: "selectiontype-botright"
            },
        };

        let {toggleDetails, currentHomeRunChoice} = this.state;

        let pot;
        let isLeg;
        let winnerOfRound;
        let whoPaysWho;
        let finalNames = [];
        let finalSideBetNames = [];
        let eligiblePlayers;
        let playersSelection;
        let cardsDetails;
        const allCards = [];
        let netGainDict = {};
        let netSideBetDict = {};
        let sideBet = false;

        if(roundOver357) {

            if(details357.sideBetLowDetails) {
                sideBet = true;
                whoPaysWho = details357.sideBetLowDetails.whoPaysWho;
                netSideBetDict = details357.sideBetLowDetails.eligiblePlayersSideBetLow.reduce((a,player)=>{
                    a[player]=0
                    return a }, {})
                if (typeof whoPaysWho !== "undefined") {

                    const paymentSender = [].concat(...Object.values(whoPaysWho))

                    netSideBetDict = details357.sideBetLowDetails.eligiblePlayersSideBetLow.reduce((dict,player)=>{
                    dict[player] = dict[player]-paymentSender.reduce((a, v) => (v === player ? a + 1 : a), 0);
                    return dict},netSideBetDict)

                    netSideBetDict = Object.keys(whoPaysWho).reduce((dict,paymentReceiver)=>{
                        dict[paymentReceiver] = dict[paymentReceiver] + whoPaysWho[paymentReceiver].length;
                        return dict},netSideBetDict)

                        netSideBetDict = Object.keys(netSideBetDict).reduce((dict,player)=>{
                        dict[player] = dict[player]*details357.sideBetLowDetails.amountSideBetLow;
                        return dict},netSideBetDict)
                }
                let sortedNames = Object.keys(netSideBetDict).map(function(key) {
                    return [key, netSideBetDict[key]];
                });
                sortedNames.sort(function(first, second) {
                    return second[1] - first[1];
                });
                for(const player of sortedNames) {
                    finalSideBetNames.push(player[0]);
                }
            }

            eligiblePlayers = details357.eligiblePlayers;
            playersSelection = details357.playersSelection;
            if(details357.pot) {
                pot = details357.pot;
            }
            if(details357.isLeg) {
                isLeg = details357.isLeg;
            }
            if(details357.winnerOfRound) {
                winnerOfRound = details357.winnerOfRound;
            }
            if(details357.whoPaysWho) {
                whoPaysWho = details357.whoPaysWho;
                netGainDict = eligiblePlayers.reduce((a,player)=>{
                    a[player]=0
                    return a }, {})
                if (typeof whoPaysWho !== "undefined") {

                    const paymentSender = [].concat(...Object.values(whoPaysWho))

                    netGainDict = eligiblePlayers.reduce((dict,player)=>{
                    dict[player] = dict[player]-paymentSender.reduce((a, v) => (v === player ? a + 1 : a), 0);
                    return dict},netGainDict)

                    netGainDict = Object.keys(whoPaysWho).reduce((dict,paymentReceiver)=>{
                        dict[paymentReceiver] = dict[paymentReceiver] + whoPaysWho[paymentReceiver].length;
                        return dict},netGainDict)

                    netGainDict = Object.keys(netGainDict).reduce((dict,player)=>{
                        dict[player] = dict[player]*pot;
                        return dict},netGainDict)
                }
                let sortedNames = Object.keys(netGainDict).map(function(key) {
                    return [key, netGainDict[key]];
                });
                sortedNames.sort(function(first, second) {
                    return second[1] - first[1];
                });
                for(const player of sortedNames) {
                    finalNames.push(player[0]);
                }
            }

            if(selectedIn || sideBetLowPress || current357Cards===7 || gameEnds357 || spectate) {
                cardsDetails = inPlayersCards;
                for (const player in cardsDetails) {
                    let body = {
                        clientName: player,
                        cards: cardsDetails[player]
                    };
                    allCards.push(body);
                }
            }
        }

        if(gameEnds727) {
            if(!winners.hasEveryoneFolded) {
                let cards5CD = winners.cards;
                for (const player in cards5CD) {
                    let body = {
                        clientName: player,
                        cards: cards5CD[player].faceDownCards.concat(cards5CD[player].faceUpCards),
                    };
                    allCards.push(body);
                }
            }
        }

        if(gameEnds5CD || (gameEndsTexas && !texasGameTypeOmaha8) || gameEnds7CS || gameEnds5CS ||gameEndsCincinnati || gameEndsBaseball) {

            if(!winners.hasEveryoneFolded || gameEndsBaseball) {
                let cards5CD = winners.cards;
                for (const player in cards5CD) {
                    let body = {
                        clientName: player,
                        cards: cards5CD[player],
                    };
                    allCards.push(body);
                }
            }
        }

        if(gameEndsTexas && texasGameTypeOmaha8) {
            if(!winners.hasEveryoneFolded) {
                let settings = {};
                if(gameWildCards.length > 0 || gameJokersInDeck.length > 0) {
                    settings = {
                        loSettings : {
                            countStraightAndFlushes : Boolean(gameLoFlush),
                            considerAceLow: Boolean(gameLoAce)
                        },
                        wildCardsSettings : {
                            wildCardsSet : new Set(gameWildCards),
                            jokersInDeckSet : new Set(gameJokersInDeck),
                            royalFlushBeatsFiveOfKind : Boolean(gameroyalFlushBeatsFiveOfKind),
                            handsMustHaveOneNaturalCard: Boolean(gamehandsMustHaveOneNaturalCard),
                            payForFaceUpWildCard: 0
                        }
                    }
                }
                else {
                    settings = {
                        loSettings : {
                            countStraightAndFlushes : Boolean(gameLoFlush),
                            considerAceLow: Boolean(gameLoAce)
                        }
                    }
                }
                let cardsOmaha8 = winners.cards
                for (const player in cardsOmaha8) {
                    let body = {};
                    let bestLoHand = getBestCardsOmaha8Lo(cardsOmaha8[player], winners.communityCards, settings);
                    let bestHiHand = getBestCardsOmaha(cardsOmaha8[player], winners.communityCards, settings);
                    let hiHand = getHiHandStringDescription(bestHiHand, settings);
                    if(bestLoHand) {
                        let loHand = getLoHandStringDescription(bestLoHand, settings);
                        body = {
                            lo: true,
                            clientName: player,
                            cards: cardsOmaha8[player],
                            descriptionHi: hiHand,
                            descriptionLo: loHand
                        };
                    }
                    else {
                        body = {
                            lo: false,
                            clientName: player,
                            cards: cardsOmaha8[player],
                            descriptionHi: hiHand,
                        };
                    }
                    allCards.push(body);
                }
            }
        }

        let monsterhandDesc = "";
        let monsterHandWon = false;

        if(gameEnds5CD && ("monsterHand" in winners)) {
            if(winners.monsterHand.length > 0) {
                let settings = {};
                settings = {
                    wildCardsSettings : {
                        wildCardsSet : new Set(gameWildCards),
                        jokersInDeckSet : new Set(gameJokersInDeck),
                        royalFlushBeatsFiveOfKind : Boolean(gameroyalFlushBeatsFiveOfKind),
                        handsMustHaveOneNaturalCard: Boolean(gamehandsMustHaveOneNaturalCard),
                        payForFaceUpWildCard: 0
                    }
                }
                if(gameJokersInDeck.length > 0 || gameWildCards.length > 0) {
                    let sortedCards = sortHiHand(winners.monsterHand, settings);
                    monsterhandDesc = getHiHandStringDescription(sortedCards, settings);
                }
                else {
                    monsterhandDesc = Hand.solve(winners.monsterHand).descr;
                }
            }
            if(!winners.hasEveryoneFolded) {
                monsterHandWon = winners.potsWinners[0].doesMonsterHandWon
            }
        }

        let winnersHomeRun = [];
        if(gameEndsHomeRun) {
            for (const player in winners.playerPointsAndHits) {
                let body = {
                    clientName: player,
                    hits: winners.playerPointsAndHits[player].hits,
                    points: winners.playerPointsAndHits[player].pointsGained
                };
                winnersHomeRun.push(body);
            }
            let homeRunSorted = winnersHomeRun.sort((first, second) => second.points - first.points);
            // console.log('SORTED ORDER HOMERUN', homeRunSorted)
        }

        let myIndex = this.findMyPosition(currentPlayer);
        let bool = foldedPlayers.length > 0 ? true : false;
        let bool1 = true;
        let bool2 = true;
        let bool3 = true;
        let bool4 = true;
        let bool5 = true;
        let bool6 = true;
        let bool7 = true;

        let player1 = gamePlayers[this.getPlayerPosition(myIndex + 1)];
        let player2 = gamePlayers[this.getPlayerPosition(myIndex + 2)];
        let player3 = gamePlayers[this.getPlayerPosition(myIndex + 3)];
        let player4 = gamePlayers[this.getPlayerPosition(myIndex + 4)];
        let player5 = gamePlayers[this.getPlayerPosition(myIndex + 5)];
        let player6 = gamePlayers[this.getPlayerPosition(myIndex + 6)];
        let player7 = gamePlayers[this.getPlayerPosition(myIndex)];

        if(bool) {
            if(foldedPlayers.includes(gamePlayers[this.getPlayerPosition(myIndex + 1)].clientName)) {
                bool1 = false;
            }
            if(foldedPlayers.includes(gamePlayers[this.getPlayerPosition(myIndex + 2)].clientName)) {
                bool2 = false;
            }
            if(foldedPlayers.includes(gamePlayers[this.getPlayerPosition(myIndex + 3)].clientName)) {
                bool3 = false;
            }
            if(foldedPlayers.includes(gamePlayers[this.getPlayerPosition(myIndex + 4)].clientName)) {
                bool4 = false;
            }
            if(foldedPlayers.includes(gamePlayers[this.getPlayerPosition(myIndex + 5)].clientName)) {
                bool5 = false;
            }
            if(foldedPlayers.includes(gamePlayers[this.getPlayerPosition(myIndex + 6)].clientName)) {
                bool6 = false;
            }
            if(foldedPlayers.includes(gamePlayers[this.getPlayerPosition(myIndex)].clientName)) {
                bool7 = false;
            }
        }

        return (
            <div className="tableContainer d-flex m-auto">
                <img className="gameTableImage" src={tableImage} alt="Table"/>
                {gamePlayers[this.getPlayerPosition(myIndex + 3)].clientName && <div className={topLeftCss}>
                    <Player player={gamePlayers[this.getPlayerPosition(myIndex + 3)]} roomName={roomName}
                            dealer={dealer} toastPosition="right" allPlayerInfo={allPlayerInfo} is357Live={is357Live}
                            playerMsgDict={playerMsgDict} currentTurnPlayer={currentTurnPlayer} gameWildCards={gameWildCards} gameJokersInDeck={gameJokersInDeck} 
                            everyonesWinningType={everyonesWinningType} currentPlayerBets={currentPlayerBets}
                            winners={winners} gameName357={gameName357} roundOver357={roundOver357} 
                            allCards={allCards} foldedPlayers={foldedPlayers} anacondaCss={anacondaArrowDict[topLeftCss].arrowpos}
                            bool={bool3} myCurrentBet={myCurrentBet} coin={CSSdict[topLeftCss].coin} coinValue={CSSdict[topLeftCss].coinValue}
                            gamefinished={gamefinished} winningAnaconda={CSSdict[topLeftCss].winningAnaconda} 
                            gameEnds357={gameEnds357} playersSelection={playersSelection} winning357={CSSdict[topLeftCss].winning357}
                            is5CDLive={is5CDLive} isAnacondaLive={isAnacondaLive} gameEnds5CD={gameEnds5CD}
                            showReady357={showReady357} readyData357={readyData357} readyPressed357={readyPressed357}
                            isTexasLive={isTexasLive} gameEndsTexas={gameEndsTexas} showExchangedCards={showExchangedCards}
                            exchangedCards={exchangedCards} allPlayerCards7CS={allPlayerCards7CS} is7CSLive={is7CSLive}
                            gameEnds7CS={gameEnds7CS} allCommunityCards={allCommunityCards} isCincinnatiLive={isCincinnatiLive}
                            gameEndsCincinnati={gameEndsCincinnati} smallBlindPlayer={smallBlindPlayer} bigBlindPlayer={bigBlindPlayer}
                            roundDealer={roundDealer} showPlayersPassedCin={showPlayersPassedCin}
                            playersPassedCardsCin={playersPassedCardsCin} showRevealStatus={showRevealStatus}
                            playersRevealStatus={playersRevealStatus} isHomeRunLive={isHomeRunLive}
                            settingsFront={settingsFront} settingsMiddle={settingsMiddle} settingsBack={settingsBack}
                            gameEndsHomeRun={gameEndsHomeRun} cardSelectionEnabled={cardSelectionEnabled}
                            homeRunShowStatus={homeRunShowStatus} homeRunSubmitStatus={homeRunSubmitStatus}
                            roomReadyData={roomReadyData} showRoomReady={showRoomReady} firstGameStarted={firstGameStarted}
                            loHand3B7={loHand3B7} mainPlayer={mainPlayer} resultHomeRun={resultHomeRun} winnersHomeRun={winnersHomeRun}
                            bustedPlayers={bustedPlayers} anacondaPassedCards={anacondaPassedCards} cardPassingRoundActive={cardPassingRoundActive}
                            cinArrLeft={cincinnatiArrowDict[topLeftCss].arrowleft} cinArrRight={cincinnatiArrowDict[topLeftCss].arrowright}
                            texasGameTypeOmaha8={texasGameTypeOmaha8} playersDisconnected={playersDisconnected} gameStarted={gameStarted} 
                            showPencilStatus={showPencilStatus} pencilAmountData={pencilAmountData} allowKickOut={allowKickOut} allPlayersReady={allPlayersReady} 
                            kickedOutPlayers={kickedOutPlayers} currentDisconnectedPlayers={currentDisconnectedPlayers} currentPlayersToKick={currentPlayersToKick}
                            rollOnPlayerCards={rollOnPlayerCards} rollOnCardsShow={rollOnCardsShow} bots={bots} diabloOpener={diabloOpener} downCards7CS={downCards7CS}
                            gameroyalFlushBeatsFiveOfKind={gameroyalFlushBeatsFiveOfKind} gamehandsMustHaveOneNaturalCard={gamehandsMustHaveOneNaturalCard} 
                            gameLoAce={gameLoAce} gameLoFlush={gameLoFlush} gameOverAllData={gameOverAllData} followTheQueenCards={followTheQueenCards} 
                            drawRound727Show={drawRound727Show} draw727RoundDetails={draw727RoundDetails} is727Live={is727Live} gameEnds727={gameEnds727} 
                            downCards727={downCards727} gameSplitHiLo={gameSplitHiLo} currentGameName={currentGameName} noOfPassesPlayerDetails={noOfPassesPlayerDetails}
                            gameSimultaneousPassDrawRound={gameSimultaneousPassDrawRound} is5CSLive={is5CSLive} gameEnds5CS={gameEnds5CS}
                            drawBaseballDecisions={drawBaseballDecisions} drawRoundBaseballShow={drawRoundBaseballShow}
                            isBaseballLive={isBaseballLive} gameEndsBaseball={gameEndsBaseball} allPlayerCardsBaseball={allPlayerCardsBaseball}
                            animatedWinners={animatedWinners}/>
                </div>}
                {gamePlayers[this.getPlayerPosition(myIndex + 4)].clientName && <div className={topRightCss}>
                    <Player player={gamePlayers[this.getPlayerPosition(myIndex + 4)]} roomName={roomName}
                            dealer={dealer} toastPosition="left" allPlayerInfo={allPlayerInfo} is357Live={is357Live}
                            playerMsgDict={playerMsgDict} currentTurnPlayer={currentTurnPlayer} gameWildCards={gameWildCards} gameJokersInDeck={gameJokersInDeck} 
                            everyonesWinningType={everyonesWinningType} currentPlayerBets={currentPlayerBets}
                            winners={winners} gameName357={gameName357} roundOver357={roundOver357} 
                            allCards={allCards} foldedPlayers={foldedPlayers} anacondaCss={anacondaArrowDict[topRightCss].arrowpos}
                            bool={bool4} myCurrentBet={myCurrentBet} coin={CSSdict[topRightCss].coin} coinValue={CSSdict[topRightCss].coinValue}
                            gamefinished={gamefinished} winningAnaconda={CSSdict[topRightCss].winningAnaconda} 
                            gameEnds357={gameEnds357} playersSelection={playersSelection} winning357={CSSdict[topRightCss].winning357}
                            is5CDLive={is5CDLive} isAnacondaLive={isAnacondaLive} gameEnds5CD={gameEnds5CD}
                            showReady357={showReady357} readyData357={readyData357} readyPressed357={readyPressed357}
                            isTexasLive={isTexasLive} gameEndsTexas={gameEndsTexas} showExchangedCards={showExchangedCards}
                            exchangedCards={exchangedCards} allPlayerCards7CS={allPlayerCards7CS} is7CSLive={is7CSLive}
                            gameEnds7CS={gameEnds7CS} allCommunityCards={allCommunityCards} isCincinnatiLive={isCincinnatiLive}
                            gameEndsCincinnati={gameEndsCincinnati} smallBlindPlayer={smallBlindPlayer} bigBlindPlayer={bigBlindPlayer}
                            roundDealer={roundDealer} showPlayersPassedCin={showPlayersPassedCin}
                            playersPassedCardsCin={playersPassedCardsCin} showRevealStatus={showRevealStatus}
                            playersRevealStatus={playersRevealStatus} isHomeRunLive={isHomeRunLive}
                            settingsFront={settingsFront} settingsMiddle={settingsMiddle} settingsBack={settingsBack}
                            gameEndsHomeRun={gameEndsHomeRun} cardSelectionEnabled={cardSelectionEnabled}
                            homeRunShowStatus={homeRunShowStatus} homeRunSubmitStatus={homeRunSubmitStatus}
                            roomReadyData={roomReadyData} showRoomReady={showRoomReady} firstGameStarted={firstGameStarted}
                            loHand3B7={loHand3B7} mainPlayer={mainPlayer} resultHomeRun={resultHomeRun} winnersHomeRun={winnersHomeRun}
                            bustedPlayers={bustedPlayers} anacondaPassedCards={anacondaPassedCards} cardPassingRoundActive={cardPassingRoundActive}
                            cinArrLeft={cincinnatiArrowDict[topRightCss].arrowleft} cinArrRight={cincinnatiArrowDict[topRightCss].arrowright}
                            texasGameTypeOmaha8={texasGameTypeOmaha8} playersDisconnected={playersDisconnected} gameStarted={gameStarted} 
                            showPencilStatus={showPencilStatus} pencilAmountData={pencilAmountData} allowKickOut={allowKickOut} allPlayersReady={allPlayersReady} 
                            kickedOutPlayers={kickedOutPlayers} currentDisconnectedPlayers={currentDisconnectedPlayers} currentPlayersToKick={currentPlayersToKick}
                            rollOnPlayerCards={rollOnPlayerCards} rollOnCardsShow={rollOnCardsShow} bots={bots} diabloOpener={diabloOpener} downCards7CS={downCards7CS}
                            gameroyalFlushBeatsFiveOfKind={gameroyalFlushBeatsFiveOfKind} gamehandsMustHaveOneNaturalCard={gamehandsMustHaveOneNaturalCard} 
                            gameLoAce={gameLoAce} gameLoFlush={gameLoFlush} gameOverAllData={gameOverAllData} followTheQueenCards={followTheQueenCards} 
                            drawRound727Show={drawRound727Show} draw727RoundDetails={draw727RoundDetails} is727Live={is727Live} gameEnds727={gameEnds727} 
                            downCards727={downCards727} gameSplitHiLo={gameSplitHiLo} currentGameName={currentGameName} noOfPassesPlayerDetails={noOfPassesPlayerDetails}
                            gameSimultaneousPassDrawRound={gameSimultaneousPassDrawRound} is5CSLive={is5CSLive} gameEnds5CS={gameEnds5CS}
                            drawBaseballDecisions={drawBaseballDecisions} drawRoundBaseballShow={drawRoundBaseballShow}
                            isBaseballLive={isBaseballLive} gameEndsBaseball={gameEndsBaseball} allPlayerCardsBaseball={allPlayerCardsBaseball}
                            animatedWinners={animatedWinners}/>
                </div>}
                {gamePlayers[this.getPlayerPosition(myIndex + 2)].clientName && <div className={midLeftCss}>
                    <Player player={gamePlayers[this.getPlayerPosition(myIndex + 2)]} roomName={roomName}
                            dealer={dealer} toastPosition="right" allPlayerInfo={allPlayerInfo} is357Live={is357Live}
                            playerMsgDict={playerMsgDict} currentTurnPlayer={currentTurnPlayer} gameWildCards={gameWildCards} gameJokersInDeck={gameJokersInDeck} 
                            everyonesWinningType={everyonesWinningType} currentPlayerBets={currentPlayerBets}
                            winners={winners} gameName357={gameName357} roundOver357={roundOver357}
                            allCards={allCards} foldedPlayers={foldedPlayers} anacondaCss={anacondaArrowDict[midLeftCss].arrowpos}
                            bool={bool2} myCurrentBet={myCurrentBet} coin={CSSdict[midLeftCss].coin} coinValue={CSSdict[midLeftCss].coinValue}
                            gamefinished={gamefinished} winningAnaconda={CSSdict[midLeftCss].winningAnaconda} 
                            gameEnds357={gameEnds357} playersSelection={playersSelection} winning357={CSSdict[midLeftCss].winning357}
                            is5CDLive={is5CDLive} isAnacondaLive={isAnacondaLive} gameEnds5CD={gameEnds5CD}
                            showReady357={showReady357} readyData357={readyData357} readyPressed357={readyPressed357}
                            isTexasLive={isTexasLive} gameEndsTexas={gameEndsTexas} showExchangedCards={showExchangedCards}
                            exchangedCards={exchangedCards} allPlayerCards7CS={allPlayerCards7CS} is7CSLive={is7CSLive}
                            gameEnds7CS={gameEnds7CS} allCommunityCards={allCommunityCards} isCincinnatiLive={isCincinnatiLive}
                            gameEndsCincinnati={gameEndsCincinnati} smallBlindPlayer={smallBlindPlayer} bigBlindPlayer={bigBlindPlayer}
                            roundDealer={roundDealer} showPlayersPassedCin={showPlayersPassedCin}
                            playersPassedCardsCin={playersPassedCardsCin} showRevealStatus={showRevealStatus}
                            playersRevealStatus={playersRevealStatus} isHomeRunLive={isHomeRunLive}
                            settingsFront={settingsFront} settingsMiddle={settingsMiddle} settingsBack={settingsBack}
                            gameEndsHomeRun={gameEndsHomeRun} cardSelectionEnabled={cardSelectionEnabled}
                            homeRunShowStatus={homeRunShowStatus} homeRunSubmitStatus={homeRunSubmitStatus}
                            roomReadyData={roomReadyData} showRoomReady={showRoomReady} firstGameStarted={firstGameStarted}
                            loHand3B7={loHand3B7} mainPlayer={mainPlayer} resultHomeRun={resultHomeRun} winnersHomeRun={winnersHomeRun}
                            bustedPlayers={bustedPlayers} anacondaPassedCards={anacondaPassedCards} cardPassingRoundActive={cardPassingRoundActive}
                            cinArrLeft={cincinnatiArrowDict[midLeftCss].arrowleft} cinArrRight={cincinnatiArrowDict[midLeftCss].arrowright}
                            texasGameTypeOmaha8={texasGameTypeOmaha8} playersDisconnected={playersDisconnected} gameStarted={gameStarted} 
                            showPencilStatus={showPencilStatus} pencilAmountData={pencilAmountData} allowKickOut={allowKickOut} allPlayersReady={allPlayersReady} 
                            kickedOutPlayers={kickedOutPlayers} currentDisconnectedPlayers={currentDisconnectedPlayers} currentPlayersToKick={currentPlayersToKick}
                            rollOnPlayerCards={rollOnPlayerCards} rollOnCardsShow={rollOnCardsShow} bots={bots} diabloOpener={diabloOpener} downCards7CS={downCards7CS}
                            gameroyalFlushBeatsFiveOfKind={gameroyalFlushBeatsFiveOfKind} gamehandsMustHaveOneNaturalCard={gamehandsMustHaveOneNaturalCard} 
                            gameLoAce={gameLoAce} gameLoFlush={gameLoFlush} gameOverAllData={gameOverAllData} followTheQueenCards={followTheQueenCards} 
                            drawRound727Show={drawRound727Show} draw727RoundDetails={draw727RoundDetails} is727Live={is727Live} gameEnds727={gameEnds727} 
                            downCards727={downCards727} gameSplitHiLo={gameSplitHiLo} currentGameName={currentGameName} noOfPassesPlayerDetails={noOfPassesPlayerDetails}
                            gameSimultaneousPassDrawRound={gameSimultaneousPassDrawRound} is5CSLive={is5CSLive} gameEnds5CS={gameEnds5CS}
                            drawBaseballDecisions={drawBaseballDecisions} drawRoundBaseballShow={drawRoundBaseballShow}
                            isBaseballLive={isBaseballLive} gameEndsBaseball={gameEndsBaseball} allPlayerCardsBaseball={allPlayerCardsBaseball}
                            animatedWinners={animatedWinners}/>
                </div>}
                <div className="center-top">
                    {actionMsg && <div className="PlayerToast w-100 mt-2" onClose={() => this.setState({showToast: false})} show={this.state.showToast}>
                        <div closeButton={false}>
                            <strong className={`mx-auto ${actionMsg === "Click cards to lay" ? 'actionMsgblink' : ''}`}>{actionMsg}</strong>
                        </div>
                    </div>}
                </div>
                {roundOver357 && !gameEnds357 && <div>
                    <div className="details-357 d-flex flex-column">
                        <div>
                        </div>
                        <br></br>
                        <div className="details-pot d-flex flex-row">
                            {pot && <div className="font-weight-bold">POT: {pot}</div>}
                            <div>{winnerOfRound && !isLeg && <div className="font-weight-bold">WINNER: {winnerOfRound}- Gets {gameAnte} from everyone</div>}</div>
                            <div>&nbsp;</div>
                            <div>{winnerOfRound && isLeg && <div className="font-weight-bold">LEG WINNER: {winnerOfRound}</div>}</div>
                        </div>
                        <br></br>
                        {whoPaysWho && <div className="details-wpw">
                            {whoPaysWho && <div className="font-weight-bold">Total Gain</div>}
                            {finalNames.map(player => {
                                    return (
                                        <div className="d-flex flex-row">
                                            {/* <span>{`${whoPaysWho[player].join()} pays ${pot} to ${player}`}: {whoPaysWho[player].length*pot}</span> */}
                                            <span>{player}: {netGainDict[player]}</span>
                                        </div>
                                    )
                                }
                            )}
                        </div>}
                        {sideBet && <div className="details-sidebet">
                            {details357.sideBetLowDetails.eligiblePlayersSideBetLow.length > 1 && <div>
                            <span className="font-weight-bold">Side Bet Amount: {details357.sideBetLowDetails.amountSideBetLow}</span>
                            <br/>
                            {finalSideBetNames.map(player => {
                                    return (
                                        <div className="d-flex flex-row">
                                            {/* <span>{`${whoPaysWho[player].join()} pays ${pot} to ${player}`}: {whoPaysWho[player].length*pot}</span> */}
                                            <span>{player}: {netSideBetDict[player]}</span>
                                        </div>
                                    )
                                }
                            )}
                            {/* Players: {details357.sideBetLowDetails.eligiblePlayersSideBetLow.join()}
                            <br/>
                            Winner: {details357.sideBetLowDetails.winners.join()} */}
                            </div>}
                        </div>}
                        {eligiblePlayers.length === 0 && <div className="details-wpw">WIMP RULE</div>}
                    </div>
                </div>}
                {/* {gameEndsHomeRun && <img className="dragon" src={DragonGIF} alt="Dragon.."/>} */}
                {gameEndsHomeRun && toggleDetails && <div className="winningdetails-homerun d-flex flex-column">
                    <span><span onClick={this.toggleHomeRun} className="onhover">RANK</span>&nbsp; &nbsp;<span className="textchange">POINTS</span></span>
                    <br/>
                    <table id="homeruntable" className="font-weight-bold">
                        <tr>
                            <th>Player</th>
                            <td>Hits</td>
                            {winners.bonusPayments.length > 0 && <th>Bonus</th>}
                            <td>Points</td>
                        </tr>
                    </table>
                    {winnersHomeRun.map((playerName, index) => (
                        <table id="homeruntable" key={index}>
                        <tr>
                            <th>{playerName.clientName}</th>
                            <td>{playerName.hits}</td>
                            {winners.bonusPayments.length > 0 && <th>{winners.bonusPayments.map((player, index) => (
                                <div key={index}>
                                    {player.player === playerName.clientName && <div>{player.points}: {player.bonusType}</div>}
                                </div>
                            ))}</th>}
                            <td>{playerName.points}</td>
                        </tr>
                        </table>
                        ))}
                </div>}
                {gameEndsHomeRun && !toggleDetails && settingsBack.length > 0 && <div className="rank-center">
                <span><span className="textchange">RANK</span>&nbsp; &nbsp;<span onClick={this.toggleHomeRun} className="onhover">POINTS</span></span>
                <br/><br/>
                {currentHomeRunChoice === "Back" && <span>
                    <span onClick={() => this.setState({currentHomeRunChoice: "Front"})} className="onhover">FRONT</span>&nbsp;
                    <span onClick={() => this.setState({currentHomeRunChoice: "Middle"})} className="onhover">MID</span>&nbsp;
                    <span className="textchange">BACK</span>&nbsp;
                </span>}
                {currentHomeRunChoice === "Front" && <span>
                    <span className="textchange">FRONT</span>&nbsp;
                    <span onClick={() => this.setState({currentHomeRunChoice: "Middle"})} className="onhover">MID</span>&nbsp;
                    <span onClick={() => this.setState({currentHomeRunChoice: "Back"})}  className="onhover">BACK</span>&nbsp;
                </span>}
                {currentHomeRunChoice === "Middle" && <span>
                    <span onClick={() => this.setState({currentHomeRunChoice: "Front"})} className="onhover">FRONT</span>&nbsp;
                    <span className="textchange">MID</span>&nbsp;
                    <span onClick={() => this.setState({currentHomeRunChoice: "Back"})}  className="onhover">BACK</span>&nbsp;
                </span>}
                <br/><br/>
                    {settingsFront.length > 0 && settingsMiddle.length > 0 && currentHomeRunChoice === "Back" && <div>Back-Hand Rank<br/>
                            {settingsBack.map((player357, index) => {
                                    let sortedCards = player357.cards;
                                    let hand = Hand.solve(sortedCards);
                                    return <div key={index} className="homerun-center-name">
                                        {index + 1}. {player357.player}: {hand.descr}
                                    </div>
                                })
                            }
                        </div>}
                    {settingsFront.length > 0 && settingsMiddle.length > 0 && currentHomeRunChoice === "Front" && <div>Front-Hand Rank<br/>
                        {settingsFront.map((player357, index) => {
                                let sortedCards = player357.cards;
                                let hand = Hand.solve(sortedCards);
                                return <div key={index} className="homerun-center-name">
                                    {index + 1}. {player357.player}: {hand.descr}
                                </div>
                            })
                        }
                    </div>}
                    {settingsFront.length > 0 && settingsMiddle.length > 0 && currentHomeRunChoice === "Middle" && <div>Middle-Hand Rank<br/>
                        {settingsMiddle.map((player357, index) => {
                                let sortedCards = player357.cards;
                                let hand = Hand.solve(sortedCards);
                                return <div key={index} className="homerun-center-name">
                                    {index + 1}. {player357.player}: {hand.descr}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {gamefinished && !winners.hasEveryoneFolded && <div>
                    <div className="winning-details d-flex flex-row">
                    <Row className="mb-2 d-flex">
                    {winners.potsWinners.map((pot, index) => (
                            <div>
                            <li key={index} className="d-flex flex-column mr-2">
                                <div className="font-weight-bold mb-2">{pot.type}Pot: {pot.amount}</div>
                                <div>Eligible Players
                                    {pot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                                </div>
                                <div>
                                    {pot.winners.High.length > 0 && <div>Winner High
                                            {pot.winners.High.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                    {pot.winners.Low.length > 0 && <div>Winner Low
                                            {pot.winners.Low.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                    {pot.winners.Mixed.length > 0 && <div>Winner Both
                                            {pot.winners.Mixed.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                </div>
                            </li>
                            {/*<div class="vl"></div>*/}
                            </div>
                        ))}
                    </Row>
                    </div>
                </div>}
                {gameEnds5CD && !winners.hasEveryoneFolded && currentGameName === "Frankenstein" && <div className="frankensteinHand">{monsterHandWon && <span>Frankenstein Hand Wins</span>}{!monsterHandWon && <span>Frankenstein Hand Loses</span>}</div>}
                {gameEnds5CD && !winners.hasEveryoneFolded && <div>
                    <div className="winning-details d-flex flex-row">
                    <Row className="mb-2 d-flex">
                        {winners.potsWinners.map((pot, index) => (
                            <div>
                            <li key={index} className="d-flex flex-column mr-2">
                                <div className="font-weight-bold mb-2">{pot.type}Pot: {pot.amount}</div>
                                <div>Eligible Players
                                    {pot.eligiblePlayers.map((playerName, index) => (<Col>
                                        <li key={index} className={`${pot.winners.length > 0 && pot.winners.includes(playerName) ? "highlightedplayer" : ""}`}>
                                            {playerName} {diabloOpener.length > 0 && diabloOpener===playerName && <span> (O)</span>}{pot.winners.length > 0 && pot.winners.includes(playerName) && <span> (W)</span>}
                                        </li>
                                    </Col>))}
                                </div>
                                {/* <div>
                                    {pot.winners.length > 0 && <div>Winner
                                            {pot.winners.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                </div> */}
                            </li>
                            {/*<div class="vl"></div>*/}
                            </div>
                        ))}
                    </Row>
                    </div>
                </div>}
                {gameEndsTexas && !winners.hasEveryoneFolded && <div>
                    <div className="winning-details d-flex flex-row">
                    <Row className="mb-2 d-flex">
                        {winners.potsWinners.map((pot, index) => (
                            <div>
                            <li key={index} className="d-flex flex-column mr-2">
                                <div className="font-weight-bold mb-2">{pot.type}Pot: {pot.amount}</div>
                                <div>Eligible Players
                                    {pot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                                </div>
                                {!texasGameTypeOmaha8 && <div>
                                    {pot.winners.length > 0 && <div>Winner
                                            {pot.winners.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                    {currentGameName === "Ironcross" && pot.winners.High.length > 0 && <div>Winner High
                                        {pot.winners.High.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                    </div>}
                                    {currentGameName === "Ironcross" && pot.winners.Low.length > 0 && <div>Winner Low
                                        {pot.winners.Low.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                    </div>}
                                </div>}
                                {texasGameTypeOmaha8 && <div>
                                    {pot.winners.High.length > 0 && <div>Winner High
                                            {pot.winners.High.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                    {pot.winners.Low.length > 0 && <div>Winner Low
                                        {pot.winners.Low.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                    </div>}
                                </div>}
                            </li>
                            {/*<div class="vl"></div>*/}
                            </div>
                        ))}
                    </Row>
                    </div>
                </div>}
                {gameEndsBaseball && !winners.hasEveryoneFolded && <div>
                    <div className="winning-details d-flex flex-row">
                    <Row className="mb-2 d-flex">
                        {winners.potsWinners.map((pot, index) => (
                            <div>
                            <li key={index} className="d-flex flex-column mr-2">
                                <div className="font-weight-bold mb-2">{pot.type}Pot: {pot.amount}</div>
                                <div>Eligible Players
                                    {pot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                                </div>
                                {("winners" in pot.winners) && <div>
                                    {pot.winners.winners.length > 0 && <div>Winner
                                            {pot.winners.winners.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                </div>}
                            </li>
                            {/*<div class="vl"></div>*/}
                            </div>
                        ))}
                    </Row>
                    </div>
                </div>}
                {gameEnds5CS && !winners.hasEveryoneFolded && <div>
                    <div className="winning-details d-flex flex-row">
                    <Row className="mb-2 d-flex">
                        {winners.potsWinners.map((pot, index) => (
                            <div>
                            <li key={index} className="d-flex flex-column mr-2">
                                <div className="font-weight-bold mb-2">{pot.type}Pot: {pot.amount}</div>
                                <div>Eligible Players
                                    {pot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                                </div>
                                {("High" in pot.winners) && <div>
                                    {pot.winners.High.length > 0 && <div>Winner High
                                            {pot.winners.High.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                </div>}
                                {("Low" in pot.winners) && <div>
                                    {pot.winners.Low.length > 0 && <div>Winner Low
                                            {pot.winners.Low.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                </div>}
                            </li>
                            {/*<div class="vl"></div>*/}
                            </div>
                        ))}
                    </Row>
                    </div>
                </div>}
                {gameEnds7CS && !winners.hasEveryoneFolded && <div>
                    <div className="winning-details d-flex flex-row">
                    <Row className="mb-2 d-flex">
                        {winners.potsWinners.map((pot, index) => (
                            <div>
                            <li key={index} className="d-flex flex-column mr-2">
                                <div className="font-weight-bold mb-2">{pot.type}Pot: {pot.amount}</div>
                                <div>Eligible Players
                                    {pot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                                </div>
                                <div>
                                    {pot.winners.BEST_HAND.length > 0 && <div>HIGH-HAND
                                            {pot.winners.BEST_HAND.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                    {pot.winners.BEST_SPADE_HAND && <div>
                                        {pot.winners.BEST_SPADE_HAND.length > 0 && <div>
                                            {currentGameName==="Lo Chicago" && <span>LO-SPADE</span>}
                                            {currentGameName==="High Chicago" && <span>HIGH-SPADE</span>}
                                            {pot.winners.BEST_SPADE_HAND.map((playerName, index) => (<div><li key={index}>
                                                {playerName}: {spadeCardAllPlayers.map((player, index) => <span key={index}>
                                                    {player.clientName === playerName && <span>{player.card}</span>}
                                                    </span>)}
                                            </li></div>))}
                                        </div>}
                                    </div>}
                                </div>
                            </li>
                            {/*<div class="vl"></div>*/}
                            </div>
                        ))}
                    </Row>
                    </div>
                </div>}
                {gameEndsCincinnati && !winners.hasEveryoneFolded && <div>
                    <div className="winning-details d-flex flex-row">
                    <Row className="mb-2 d-flex">
                    {winners.potsWinners.map((pot, index) => (
                            <div>
                            <li key={index} className="d-flex flex-column mr-2">
                                <div className="font-weight-bold mb-2">{pot.type}Pot: {pot.amount}</div>
                                <div>Eligible Players
                                    {pot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                                </div>
                                <div>
                                    {pot.winners.High.length > 0 && <div>Winner High
                                            {pot.winners.High.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                    {pot.winners.Low.length > 0 && <div>Winner Low
                                            {pot.winners.Low.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                    {/* {pot.winners.Mixed.length > 0 && <div>Winner Both
                                            {pot.winners.Mixed.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>} */}
                                </div>
                            </li>
                            {/*<div class="vl"></div>*/}
                            </div>
                        ))}
                    </Row>
                    </div>
                </div>}
                {gameEnds727 && !winners.hasEveryoneFolded && <div>
                    <div className="winning-details d-flex flex-row">
                    <Row className="mb-2 d-flex">
                    {winners.potsWinners.map((pot, index) => (
                            <div>
                            <li key={index} className="d-flex flex-column mr-2">
                                <div className="font-weight-bold mb-2">{pot.type}Pot: {pot.amount}</div>
                                <div>Eligible Players
                                    {pot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                                </div>
                                <div>
                                    {pot.winners.Low.length > 0 && <div>7 Winner
                                            {pot.winners.Low.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                    {pot.winners.High.length > 0 && <div>27 Winner
                                            {pot.winners.High.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                    {pot.winners.Mixed.length > 0 && <div>Winner Both
                                            {pot.winners.Mixed.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                        </div>}
                                </div>
                            </li>
                            </div>
                        ))}
                    </Row>
                    </div>
                </div>}
                {gamefinished && winners.hasEveryoneFolded && <div>
                    <div className="centered d-flex flex-row">
                        Winner: {winners.winner}
                    </div>
                </div>}
                {gameEnds357 && <div>
                    <div className="centered d-flex flex-row">
                        WINNER: {winners.winner} &nbsp; &nbsp; AMOUNT: {winners.winAmount} 
                        <br/>
                        {sideBet && <div className="details-sidebet-center">
                            {details357.sideBetLowDetails.eligiblePlayersSideBetLow.length > 1 && <div>
                            <span className="font-weight-bold">Side Bet Amount: {details357.sideBetLowDetails.amountSideBetLow}</span>
                            <br/>
                            <div className="font-weight-bold">Total Gain</div>
                            {finalSideBetNames.map(player => {
                                    return (
                                        <div className="d-flex flex-row">
                                            {/* <span>{`${whoPaysWho[player].join()} pays ${pot} to ${player}`}: {whoPaysWho[player].length*pot}</span> */}
                                            <span>{player}: {netSideBetDict[player]}</span>
                                        </div>
                                    )
                                }
                            )}
                            </div>}
                        </div>}
                    </div>
                </div>}
                {(gameEnds5CD || gameEndsTexas || gameEnds7CS || gameEnds5CS || gameEndsCincinnati || gameEnds727 || gameEndsBaseball) && winners.hasEveryoneFolded && <div>
                    <div className="centered d-flex flex-row">
                        Winner: {winners.winner} &nbsp; <br/>
                        {gameEndsBaseball && ("potsWinners" in winners) && <span>
                            {winners.potsWinners.map((pot, index) => (<span key={index}>{pot.type}: {pot.amount}<br/></span>))}    
                        </span>}
                    </div>
                </div>}
                {!firstGameStarted && <div className="playernames">
                    <h4>PLAYERS IN ROOM</h4>
                    {players.map((player, index) => (<Col>{player.clientName && <li key={index}>
                        {player.clientName}: {player.stack} &nbsp; &nbsp; {(dealer === currentPlayer && dealer !== player.clientName) && <span className="removetext" onClick={() => this.setState({playerToRemove: player.clientName, showPlayerRemoveSheet: true})}>REMOVE</span>}
                    </li>}</Col>))}
                </div>}
                {this.state.showPlayerRemoveSheet && <div className="removePlayerSheet">
                    <Col>
                        <span>Do you want to remove {this.state.playerToRemove}?</span>
                        <div><Button className="removeBtn" onClick={() => this.dealerRemove(this.state.playerToRemove)}>YES</Button></div>
                        <div><Button className="removeBtn" onClick={() => {this.setState({showPlayerRemoveSheet: false, playerToRemove: ""})}}>NO</Button></div>
                    </Col>
                </div>}
                {lastBettingRound727 && <div className="last-betting-round-details">
                    <span className="last-betting-text">LAST BETTING ROUND</span>
                </div>}
                {!gamefinished && !gameEnds357 && !gameEnds5CD && !gameEndsTexas && !gameEnds7CS && !gameEnds5CS && !gameEndsCincinnati && !gameEnds727 && !gameEndsBaseball && firstGameStarted &&<div>{!winner &&
                <div className={`d-flex flex-row ${(currentGameName === "Ironcross" || currentGameName === "Do Ya" || currentGameName === "Baseball 7CS" || currentGameName === "Baseball-9CD" || currentGameName === "Blind Baseball") ? "centeredironcross" : "centered"}`}>
                    {(!isHomeRunLive && !gameEndsHomeRun) && <OverlayTrigger placement="bottom"
                        overlay={
                            <Tooltip id={`tooltip`}>
                                {betRoundOver && potDetails.mainPot.eligiblePlayers.map((player, index) => (
                                    <span key={index}>{player}<br/></span>
                                ))}
                            </Tooltip>
                        }>
                        <Card className="centerGame gameCard">
                            <Col className="p-2">
                                <h4 className="d-flex justify-content-center">{betRoundOver && <div>{potDetails.mainPot.amount}</div>}
                                    {!betRoundOver && <div>{potValue}</div>}
                                </h4>
                                {/* <h4 className="d-flex justify-content-center">{potValue - minusValue}</h4> */}
                                <div>
                                <h6 className="d-flex justify-content-center">Main</h6></div>
                            </Col>
                        </Card>
                    </OverlayTrigger>}
                        <div>&nbsp; &nbsp;</div>
                    {betRoundOver && sidePots.map((sidepot, index) => (
                            <div key={index} className="ml-2">
                                <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip`}>
                                            {sidepot.eligiblePlayers.map((player, index) => (
                                                <span key={index}>{player}<br/></span>
                                            ))}
                                        </Tooltip>
                                    }>
                                    <Card className="centerGame gameCard">
                                        <Col className="p-2">
                                            <h4 className="d-flex justify-content-center">{sidepot.amount}</h4>
                                            <h6 className="d-flex justify-content-center">Side</h6>
                                        </Col>
                                    </Card>
                                </OverlayTrigger>
                                <div>&nbsp; &nbsp;</div>
                            </div>
                        ))}
                </div>}</div>}
                {(isHomeRunLive && !gameEndsHomeRun) && <div className="rank-center">
                    {settingsFront.length > 0 && settingsMiddle.length === 0 && <div>Front-Hand Rank<br/>
                        {settingsFront.map((player357, index) => {
                                let sortedCards = player357.cards;
                                let hand = Hand.solve(sortedCards);
                                return <div key={index} className="homerun-center-name">
                                    {index + 1}. {player357.player}: {hand.descr}
                                </div>
                            })
                        }
                    </div>}
                    {settingsMiddle.length > 0 && settingsBack.length === 0 && <div>Middle-Hand Rank<br/>
                        {settingsMiddle.map((player357, index) => {
                                let sortedCards = player357.cards;
                                let hand = Hand.solve(sortedCards);
                                return <div key={index} className="homerun-center-name">
                                    {index + 1}. {player357.player}: {hand.descr}
                                </div>
                            })
                        }
                    </div>}
                </div>}
                {/* {winner && <div className="centered">
                    <div className="d-flex justify-content-center">Winner is {winner}</div>
                    <div className="d-flex flex-row">
                        {winnerCards.map((card, index) => (<div className="d-flex">
                                            <span key={index}><Cards suit={card[card.length - 1]}
                                                                     value={card.slice(0, card.length - 1)}/></span></div>
                        ))}
                    </div>
                </div>} */}
                {gameEnds5CD && ("discardedCards" in winners) && <div className="discarded-monster-cards d-flex my-auto ml-4">
                    {winners.discardedCards.filter(val => !winners.monsterHand.includes(val)).map((card, index) => (<div className="cardanimation">
                        <span key={index} className="normalcommunitycard">
                            <WinnerCards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                            {gameWildCards.includes(card) && <div className="isWildCardCommunity"><i class="fa fa-star" aria-hidden="true"/></div>}
                            {card[card.length - 1] === "w" && <div className="isWildCardCommunity"><i class="fa fa-star" aria-hidden="true"/></div>}
                        </span>
                    </div>
                    ))}   
                </div>}
                {((is7CSLive && currentGameName === "Baseball 7CS") || isBaseballLive) && drawActionsBaseballShow && <div className="baseballinfo">
                    Do you want extra card for {5*gameAnte}?
                </div>}
                {((is7CSLive && currentGameName === "Baseball 7CS") || isBaseballLive) && <div className="baseball-actions d-flex my-auto ml-4">
                    {drawActionsBaseballShow && <div className="d-flex my-auto ml-4">
                        <Button className="doYaBtn" onClick={() => this.baseballFinalAction('DRAW')}>YES</Button>
                    </div>}
                    {drawActionsBaseballShow && <div className="d-flex my-auto ml-4">
                        <Button className="doYaBtn" onClick={() => this.baseballFinalAction('NO_DRAW')}>NO</Button>
                    </div>}    
                </div>}
                {is5CSLive && doYaCards.length > 0 && showDoYaActions && <div className="doyainfo">
                    Do-Ya
                    <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip`}>
                                            Click on the card to select.
                                        </Tooltip>
                                    }>
                        <span className="startgametext"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                    </OverlayTrigger>
                    </div>}
                {is5CSLive && doYaCards.length > 0 && <div className="doya-cards d-flex my-auto ml-4">
                    {doYaCards.map((card, index) => (<div className="">
                        <span key={index} className="normalcommunitycard doCards" onClick={() => this.doYaFinalAction('YES', card)}>
                            <Cards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                            {gameWildCards.includes(card) && <div className="isWildCardCommunity"><i class="fa fa-star" aria-hidden="true"/></div>}
                            {card[card.length - 1] === "w" && <div className="isWildCardCommunity"><i class="fa fa-star" aria-hidden="true"/></div>}
                        </span>
                    </div>
                    ))}
                    {showDoYaActions && <div className="d-flex my-auto ml-4">
                        <Button className="doYaBtn" onClick={() => this.doYaFinalAction('NO', '')}>NO</Button>
                    </div>}    
                </div>}
                {gameEnds5CD && ("monsterHand" in winners) && <div className="monsterhand-cards d-flex my-auto ml-4">
                    {winners.monsterHand.map((card, index) => (<div className="cardanimation">
                        <span key={index} className="normalcommunitycard">
                            <Cards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                            {gameWildCards.includes(card) && <div className="isWildCardCommunity"><i class="fa fa-star" aria-hidden="true"/></div>}
                            {card[card.length - 1] === "w" && <div className="isWildCardCommunity"><i class="fa fa-star" aria-hidden="true"/></div>}
                        </span>
                    </div>
                    ))}   
                </div>}
                {gameEnds5CD && ("monsterHand" in winners) && <div className="monster-hand-description">Frankenstein hand: {monsterhandDesc}</div>}
                {(isTexasLive || gameEndsTexas) && allCommunityCards.length > 0 && currentGameName !== "Ironcross" &&
                    <div className="community-cards d-flex my-auto ml-4">
                        {allCommunityCards.map((card, index) => (<div className="cardanimation">
                            <span key={index} className="normalcommunitycard">
                                <Cards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)}/>
                                {gameWildCards.includes(card) && <div className="isWildCardCommunity"><i class="fa fa-star" aria-hidden="true"/></div>}
                                {card[card.length - 1] === "w" && <div className="isWildCardCommunity"><i class="fa fa-star" aria-hidden="true"/></div>}
                            </span>
                        </div>
                        ))}
                    </div>
                }
                {(isTexasLive || gameEndsTexas) && allCommunityCards.length > 0 && currentGameName == "Ironcross" &&
                    <div className="community-cards d-flex my-auto ml-4">
                        {allCommunityCards.map((card, index) => (<div className="cardanimation">
                            <span key={index} className={`normalcommunitycard ${index === 0 ? "ironcardzero" : ""} ${index === 1 ? "ironcardone" : ""} ${index === 2 ? "ironcardtwo" : ""} ${index === 3 ? "ironcardthree" : ""} ${index === 4 ? "ironcardfour" : ""}`}>
                                <Cards suit={card[card.length - 1]} value={card.slice(0, card.length - 1)} ironcard={true}/>
                                {gameWildCards.includes(card) && <div className="isWildCardCommunityIron"><i class="fa fa-star" aria-hidden="true"/></div>}
                                {card[card.length - 1] === "w" && <div className="isWildCardCommunityIron"><i class="fa fa-star" aria-hidden="true"/></div>}
                            </span>
                        </div>
                        ))}
                    </div>
                }
                {gamePlayers[this.getPlayerPosition(myIndex + 5)].clientName && <div className={midRightCss}>
                    <Player player={gamePlayers[this.getPlayerPosition(myIndex + 5)]} roomName={roomName}
                            dealer={dealer} toastPosition="left" allPlayerInfo={allPlayerInfo} is357Live={is357Live}
                            playerMsgDict={playerMsgDict} currentTurnPlayer={currentTurnPlayer} gameWildCards={gameWildCards} gameJokersInDeck={gameJokersInDeck} 
                            everyonesWinningType={everyonesWinningType} currentPlayerBets={currentPlayerBets}
                            winners={winners} gameName357={gameName357} roundOver357={roundOver357} 
                            allCards={allCards}  foldedPlayers={foldedPlayers} anacondaCss={anacondaArrowDict[midRightCss].arrowpos}
                            bool={bool5} myCurrentBet={myCurrentBet} coin={CSSdict[midRightCss].coin} coinValue={CSSdict[midRightCss].coinValue}
                            gamefinished={gamefinished} winningAnaconda={CSSdict[midRightCss].winningAnaconda} 
                            gameEnds357={gameEnds357} playersSelection={playersSelection} winning357={CSSdict[midRightCss].winning357}
                            is5CDLive={is5CDLive} isAnacondaLive={isAnacondaLive} gameEnds5CD={gameEnds5CD}
                            showReady357={showReady357} readyData357={readyData357} readyPressed357={readyPressed357}
                            isTexasLive={isTexasLive} gameEndsTexas={gameEndsTexas} showExchangedCards={showExchangedCards}
                            exchangedCards={exchangedCards} allPlayerCards7CS={allPlayerCards7CS} is7CSLive={is7CSLive}
                            gameEnds7CS={gameEnds7CS} allCommunityCards={allCommunityCards} isCincinnatiLive={isCincinnatiLive}
                            gameEndsCincinnati={gameEndsCincinnati} smallBlindPlayer={smallBlindPlayer} bigBlindPlayer={bigBlindPlayer}
                            roundDealer={roundDealer} showPlayersPassedCin={showPlayersPassedCin}
                            playersPassedCardsCin={playersPassedCardsCin} showRevealStatus={showRevealStatus}
                            playersRevealStatus={playersRevealStatus} isHomeRunLive={isHomeRunLive}
                            settingsFront={settingsFront} settingsMiddle={settingsMiddle} settingsBack={settingsBack}
                            gameEndsHomeRun={gameEndsHomeRun} cardSelectionEnabled={cardSelectionEnabled}
                            homeRunShowStatus={homeRunShowStatus} homeRunSubmitStatus={homeRunSubmitStatus}
                            roomReadyData={roomReadyData} showRoomReady={showRoomReady} firstGameStarted={firstGameStarted}
                            loHand3B7={loHand3B7} mainPlayer={mainPlayer} resultHomeRun={resultHomeRun} winnersHomeRun={winnersHomeRun}
                            bustedPlayers={bustedPlayers} anacondaPassedCards={anacondaPassedCards} cardPassingRoundActive={cardPassingRoundActive}
                            cinArrLeft={cincinnatiArrowDict[midRightCss].arrowleft} cinArrRight={cincinnatiArrowDict[midRightCss].arrowright}
                            texasGameTypeOmaha8={texasGameTypeOmaha8} playersDisconnected={playersDisconnected} gameStarted={gameStarted} 
                            showPencilStatus={showPencilStatus} pencilAmountData={pencilAmountData} allowKickOut={allowKickOut} allPlayersReady={allPlayersReady} 
                            kickedOutPlayers={kickedOutPlayers} currentDisconnectedPlayers={currentDisconnectedPlayers} currentPlayersToKick={currentPlayersToKick}
                            rollOnPlayerCards={rollOnPlayerCards} rollOnCardsShow={rollOnCardsShow} bots={bots} diabloOpener={diabloOpener} downCards7CS={downCards7CS}
                            gameroyalFlushBeatsFiveOfKind={gameroyalFlushBeatsFiveOfKind} gamehandsMustHaveOneNaturalCard={gamehandsMustHaveOneNaturalCard} 
                            gameLoAce={gameLoAce} gameLoFlush={gameLoFlush} gameOverAllData={gameOverAllData} followTheQueenCards={followTheQueenCards} 
                            drawRound727Show={drawRound727Show} draw727RoundDetails={draw727RoundDetails} is727Live={is727Live} gameEnds727={gameEnds727} 
                            downCards727={downCards727} gameSplitHiLo={gameSplitHiLo} currentGameName={currentGameName} noOfPassesPlayerDetails={noOfPassesPlayerDetails}
                            gameSimultaneousPassDrawRound={gameSimultaneousPassDrawRound} is5CSLive={is5CSLive} gameEnds5CS={gameEnds5CS}
                            drawBaseballDecisions={drawBaseballDecisions} drawRoundBaseballShow={drawRoundBaseballShow}
                            isBaseballLive={isBaseballLive} gameEndsBaseball={gameEndsBaseball} allPlayerCardsBaseball={allPlayerCardsBaseball}
                            animatedWinners={animatedWinners}/>
                </div>}

                {gamePlayers[this.getPlayerPosition(myIndex + 1)].clientName && <div className={botLeftCss}>
                    <Player player={gamePlayers[this.getPlayerPosition(myIndex + 1)]} roomName={roomName}
                            dealer={dealer} toastPosition="top" allPlayerInfo={allPlayerInfo} is357Live={is357Live}
                            playerMsgDict={playerMsgDict} currentTurnPlayer={currentTurnPlayer} gameWildCards={gameWildCards} gameJokersInDeck={gameJokersInDeck} 
                            everyonesWinningType={everyonesWinningType} currentPlayerBets={currentPlayerBets}
                            winners={winners} gameName357={gameName357} roundOver357={roundOver357} 
                            allCards={allCards} foldedPlayers={foldedPlayers} anacondaCss={anacondaArrowDict[botLeftCss].arrowpos}
                            bool={bool1} myCurrentBet={myCurrentBet} coin={CSSdict[botLeftCss].coin} coinValue={CSSdict[botLeftCss].coinValue}
                            gamefinished={gamefinished} winningAnaconda={CSSdict[botLeftCss].winningAnaconda}
                            gameEnds357={gameEnds357} playersSelection={playersSelection} winning357={CSSdict[botLeftCss].winning357}
                            is5CDLive={is5CDLive} isAnacondaLive={isAnacondaLive} gameEnds5CD={gameEnds5CD}
                            showReady357={showReady357} readyData357={readyData357} readyPressed357={readyPressed357}
                            isTexasLive={isTexasLive} gameEndsTexas={gameEndsTexas} showExchangedCards={showExchangedCards}
                            exchangedCards={exchangedCards} allPlayerCards7CS={allPlayerCards7CS} is7CSLive={is7CSLive}
                            gameEnds7CS={gameEnds7CS} allCommunityCards={allCommunityCards} isCincinnatiLive={isCincinnatiLive}
                            gameEndsCincinnati={gameEndsCincinnati} smallBlindPlayer={smallBlindPlayer} bigBlindPlayer={bigBlindPlayer}
                            roundDealer={roundDealer} showPlayersPassedCin={showPlayersPassedCin}
                            playersPassedCardsCin={playersPassedCardsCin} showRevealStatus={showRevealStatus}
                            playersRevealStatus={playersRevealStatus} isHomeRunLive={isHomeRunLive}
                            settingsFront={settingsFront} settingsMiddle={settingsMiddle} settingsBack={settingsBack}
                            gameEndsHomeRun={gameEndsHomeRun} cardSelectionEnabled={cardSelectionEnabled}
                            homeRunShowStatus={homeRunShowStatus} homeRunSubmitStatus={homeRunSubmitStatus}
                            roomReadyData={roomReadyData} showRoomReady={showRoomReady} firstGameStarted={firstGameStarted}
                            loHand3B7={loHand3B7} mainPlayer={mainPlayer} resultHomeRun={resultHomeRun} winnersHomeRun={winnersHomeRun}
                            bustedPlayers={bustedPlayers} anacondaPassedCards={anacondaPassedCards} cardPassingRoundActive={cardPassingRoundActive}
                            cinArrLeft={cincinnatiArrowDict[botLeftCss].arrowleft} cinArrRight={cincinnatiArrowDict[botLeftCss].arrowright}
                            texasGameTypeOmaha8={texasGameTypeOmaha8} playersDisconnected={playersDisconnected} gameStarted={gameStarted} 
                            showPencilStatus={showPencilStatus} pencilAmountData={pencilAmountData} allowKickOut={allowKickOut} allPlayersReady={allPlayersReady} 
                            kickedOutPlayers={kickedOutPlayers} currentDisconnectedPlayers={currentDisconnectedPlayers} currentPlayersToKick={currentPlayersToKick}
                            rollOnPlayerCards={rollOnPlayerCards} rollOnCardsShow={rollOnCardsShow} bots={bots} diabloOpener={diabloOpener} downCards7CS={downCards7CS}
                            gameroyalFlushBeatsFiveOfKind={gameroyalFlushBeatsFiveOfKind} gamehandsMustHaveOneNaturalCard={gamehandsMustHaveOneNaturalCard} 
                            gameLoAce={gameLoAce} gameLoFlush={gameLoFlush} gameOverAllData={gameOverAllData} followTheQueenCards={followTheQueenCards} 
                            drawRound727Show={drawRound727Show} draw727RoundDetails={draw727RoundDetails} is727Live={is727Live} gameEnds727={gameEnds727} 
                            downCards727={downCards727} gameSplitHiLo={gameSplitHiLo} currentGameName={currentGameName} noOfPassesPlayerDetails={noOfPassesPlayerDetails}
                            gameSimultaneousPassDrawRound={gameSimultaneousPassDrawRound} is5CSLive={is5CSLive} gameEnds5CS={gameEnds5CS}
                            drawBaseballDecisions={drawBaseballDecisions} drawRoundBaseballShow={drawRoundBaseballShow}
                            isBaseballLive={isBaseballLive} gameEndsBaseball={gameEndsBaseball} allPlayerCardsBaseball={allPlayerCardsBaseball}
                            animatedWinners={animatedWinners}/>
                </div>}
                
                {gamePlayers[this.getPlayerPosition(myIndex)].clientName && <div className={botMidCss}>
                    <Player player={gamePlayers[this.getPlayerPosition(myIndex)]} roomName={roomName}
                            dealer={dealer} toastPosition="top" allPlayerInfo={allPlayerInfo} is357Live={is357Live}
                            playerMsgDict={playerMsgDict} currentTurnPlayer={currentTurnPlayer} gameWildCards={gameWildCards} gameJokersInDeck={gameJokersInDeck} 
                            everyonesWinningType={everyonesWinningType} currentPlayerBets={currentPlayerBets}
                            winners={winners} gameName357={gameName357} roundOver357={roundOver357} 
                            allCards={allCards} foldedPlayers={foldedPlayers} anacondaCss={anacondaArrowDict[botMidCss].arrowpos}
                            bool={bool7} myCurrentBet={myCurrentBet} coin={CSSdict[botMidCss].coin} coinValue={CSSdict[botMidCss].coinValue}
                            gamefinished={gamefinished} winningAnaconda={CSSdict[botMidCss].winningAnaconda} 
                            gameEnds357={gameEnds357} playersSelection={playersSelection} winning357={CSSdict[botMidCss].winning357}
                            is5CDLive={is5CDLive} isAnacondaLive={isAnacondaLive} gameEnds5CD={gameEnds5CD}
                            showReady357={showReady357} readyData357={readyData357} readyPressed357={readyPressed357}
                            isTexasLive={isTexasLive} gameEndsTexas={gameEndsTexas} showExchangedCards={showExchangedCards}
                            exchangedCards={exchangedCards} allPlayerCards7CS={allPlayerCards7CS} is7CSLive={is7CSLive}
                            gameEnds7CS={gameEnds7CS} allCommunityCards={allCommunityCards} isCincinnatiLive={isCincinnatiLive}
                            gameEndsCincinnati={gameEndsCincinnati} smallBlindPlayer={smallBlindPlayer} bigBlindPlayer={bigBlindPlayer}
                            roundDealer={roundDealer} showPlayersPassedCin={showPlayersPassedCin}
                            playersPassedCardsCin={playersPassedCardsCin} showRevealStatus={showRevealStatus}
                            playersRevealStatus={playersRevealStatus} isHomeRunLive={isHomeRunLive}
                            settingsFront={settingsFront} settingsMiddle={settingsMiddle} settingsBack={settingsBack}
                            gameEndsHomeRun={gameEndsHomeRun} cardSelectionEnabled={cardSelectionEnabled}
                            homeRunShowStatus={homeRunShowStatus} homeRunSubmitStatus={homeRunSubmitStatus}
                            roomReadyData={roomReadyData} showRoomReady={showRoomReady} firstGameStarted={firstGameStarted}
                            loHand3B7={loHand3B7} mainPlayer={mainPlayer} resultHomeRun={resultHomeRun} winnersHomeRun={winnersHomeRun}
                            bustedPlayers={bustedPlayers} anacondaPassedCards={anacondaPassedCards} cardPassingRoundActive={cardPassingRoundActive}
                            cinArrLeft={cincinnatiArrowDict[botMidCss].arrowleft} cinArrRight={cincinnatiArrowDict[botMidCss].arrowright}
                            texasGameTypeOmaha8={texasGameTypeOmaha8} playersDisconnected={playersDisconnected} gameStarted={gameStarted} 
                            showPencilStatus={showPencilStatus} pencilAmountData={pencilAmountData} allowKickOut={allowKickOut} allPlayersReady={allPlayersReady} 
                            kickedOutPlayers={kickedOutPlayers} currentDisconnectedPlayers={currentDisconnectedPlayers} currentPlayersToKick={currentPlayersToKick}
                            rollOnPlayerCards={rollOnPlayerCards} rollOnCardsShow={rollOnCardsShow} bots={bots} diabloOpener={diabloOpener} downCards7CS={downCards7CS}
                            gameroyalFlushBeatsFiveOfKind={gameroyalFlushBeatsFiveOfKind} gamehandsMustHaveOneNaturalCard={gamehandsMustHaveOneNaturalCard} 
                            gameLoAce={gameLoAce} gameLoFlush={gameLoFlush} gameOverAllData={gameOverAllData} followTheQueenCards={followTheQueenCards} 
                            drawRound727Show={drawRound727Show} draw727RoundDetails={draw727RoundDetails} is727Live={is727Live} gameEnds727={gameEnds727} 
                            downCards727={downCards727} gameSplitHiLo={gameSplitHiLo} currentGameName={currentGameName} noOfPassesPlayerDetails={noOfPassesPlayerDetails}
                            gameSimultaneousPassDrawRound={gameSimultaneousPassDrawRound} is5CSLive={is5CSLive} gameEnds5CS={gameEnds5CS}
                            drawBaseballDecisions={drawBaseballDecisions} drawRoundBaseballShow={drawRoundBaseballShow}
                            isBaseballLive={isBaseballLive} gameEndsBaseball={gameEndsBaseball} allPlayerCardsBaseball={allPlayerCardsBaseball}
                            animatedWinners={animatedWinners}/>
                </div>}
                
                {gamePlayers[this.getPlayerPosition(myIndex + 6)].clientName && <div className={botRightCss}>
                    <Player player={gamePlayers[this.getPlayerPosition(myIndex + 6)]} roomName={roomName}
                            dealer={dealer} toastPosition="top" allPlayerInfo={allPlayerInfo} is357Live={is357Live}
                            playerMsgDict={playerMsgDict} currentTurnPlayer={currentTurnPlayer} gameWildCards={gameWildCards} gameJokersInDeck={gameJokersInDeck} 
                            everyonesWinningType={everyonesWinningType} currentPlayerBets={currentPlayerBets}
                            winners={winners} gameName357={gameName357} roundOver357={roundOver357} 
                            allCards={allCards} foldedPlayers={foldedPlayers} anacondaCss={anacondaArrowDict[botRightCss].arrowpos}
                            bool={bool6} myCurrentBet={myCurrentBet} coin={CSSdict[botRightCss].coin} coinValue={CSSdict[botRightCss].coinValue}
                            gamefinished={gamefinished} winningAnaconda={CSSdict[botRightCss].winningAnaconda} 
                            gameEnds357={gameEnds357} playersSelection={playersSelection} winning357={CSSdict[botRightCss].winning357}
                            is5CDLive={is5CDLive} isAnacondaLive={isAnacondaLive} gameEnds5CD={gameEnds5CD}
                            showReady357={showReady357} readyData357={readyData357} readyPressed357={readyPressed357}
                            isTexasLive={isTexasLive} gameEndsTexas={gameEndsTexas} showExchangedCards={showExchangedCards}
                            exchangedCards={exchangedCards} allPlayerCards7CS={allPlayerCards7CS} is7CSLive={is7CSLive}
                            gameEnds7CS={gameEnds7CS} allCommunityCards={allCommunityCards} isCincinnatiLive={isCincinnatiLive} 
                            gameEndsCincinnati={gameEndsCincinnati} smallBlindPlayer={smallBlindPlayer} bigBlindPlayer={bigBlindPlayer}
                            roundDealer={roundDealer} showPlayersPassedCin={showPlayersPassedCin}
                            playersPassedCardsCin={playersPassedCardsCin} showRevealStatus={showRevealStatus}
                            playersRevealStatus={playersRevealStatus} isHomeRunLive={isHomeRunLive}
                            settingsFront={settingsFront} settingsMiddle={settingsMiddle} settingsBack={settingsBack}
                            gameEndsHomeRun={gameEndsHomeRun} cardSelectionEnabled={cardSelectionEnabled}
                            homeRunShowStatus={homeRunShowStatus} homeRunSubmitStatus={homeRunSubmitStatus}
                            roomReadyData={roomReadyData} showRoomReady={showRoomReady} firstGameStarted={firstGameStarted}
                            loHand3B7={loHand3B7} mainPlayer={mainPlayer} resultHomeRun={resultHomeRun} winnersHomeRun={winnersHomeRun}
                            bustedPlayers={bustedPlayers} anacondaPassedCards={anacondaPassedCards} cardPassingRoundActive={cardPassingRoundActive}
                            cinArrLeft={cincinnatiArrowDict[botRightCss].arrowleft} cinArrRight={cincinnatiArrowDict[botRightCss].arrowright}
                            texasGameTypeOmaha8={texasGameTypeOmaha8} playersDisconnected={playersDisconnected} gameStarted={gameStarted} 
                            showPencilStatus={showPencilStatus} pencilAmountData={pencilAmountData} allowKickOut={allowKickOut} allPlayersReady={allPlayersReady} 
                            kickedOutPlayers={kickedOutPlayers} currentDisconnectedPlayers={currentDisconnectedPlayers} currentPlayersToKick={currentPlayersToKick}
                            rollOnPlayerCards={rollOnPlayerCards} rollOnCardsShow={rollOnCardsShow} bots={bots} diabloOpener={diabloOpener} downCards7CS={downCards7CS}
                            gameroyalFlushBeatsFiveOfKind={gameroyalFlushBeatsFiveOfKind} gamehandsMustHaveOneNaturalCard={gamehandsMustHaveOneNaturalCard} 
                            gameLoAce={gameLoAce} gameLoFlush={gameLoFlush} gameOverAllData={gameOverAllData} followTheQueenCards={followTheQueenCards} 
                            drawRound727Show={drawRound727Show} draw727RoundDetails={draw727RoundDetails} is727Live={is727Live} gameEnds727={gameEnds727} 
                            downCards727={downCards727} gameSplitHiLo={gameSplitHiLo} currentGameName={currentGameName} noOfPassesPlayerDetails={noOfPassesPlayerDetails}
                            gameSimultaneousPassDrawRound={gameSimultaneousPassDrawRound} is5CSLive={is5CSLive} gameEnds5CS={gameEnds5CS}
                            drawBaseballDecisions={drawBaseballDecisions} drawRoundBaseballShow={drawRoundBaseballShow}
                            isBaseballLive={isBaseballLive} gameEndsBaseball={gameEndsBaseball} allPlayerCardsBaseball={allPlayerCardsBaseball}
                            animatedWinners={animatedWinners}/>
                </div>}
            </div>
        );
    }
}

export default GameTable;
