export const getAllSpectators = (data) => {
    let temp = [];
    for(const i in data) {
        let body = {
            name: i,
            email: data[i]
        }
        temp.push(body);
    }
    return temp;
}

export const updatePlayersPositions = (positions_old, players) => {

    let positions = positions_old;
    let play = players;
    let new_players = [];

    for (const i in positions) {
        for(const y in play) {
            if(positions[i] === play[y].clientName) {
                new_players.push(play[y]);
            }
        }
    }

    while(new_players.length < 7) {
        new_players.push({clientName: "", stack: 0});
    }
    return new_players;
}

export const anacondaPassingCardStatus = (data) => {
    let temp = [];
    for(const i in data) {
        temp.push(data[i].from)
    }
    return temp;
}

export const rollOnPlayerCardsFunction = (data) => {
    let allCards = [];
    for (const player in data) {
        let body = {
            clientName: player,
            cards: data[player]
        };
        allCards.push(body);
    }
    return allCards;
}

export const getItems = (cards) => {
    return Array.from({ length: cards.length }, (cards, k) => k).map(k => ({
        id: cards[k],
        order: k,
    }));
};

export const sortCards = (list) => {
    return list.slice().sort((first, second) => first.order - second.order);
};

export const descriptionWildCards = (cards) => {

    let x = cards;
    if(['As', 'Ah', 'Ac', 'Ad'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['As', 'Ah', 'Ac', 'Ad'].includes( el ) );
        x.push("All Ace");
    }
    if(['2s', '2h', '2c', '2d'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['2s', '2h', '2c', '2d'].includes( el ) );
        x.push("All 2");
    }
    if(['3s', '3h', '3c', '3d'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['3s', '3h', '3c', '3d'].includes( el ) );
        x.push("All 3");
    }
    if(['4s', '4h', '4c', '4d'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['4s', '4h', '4c', '4d'].includes( el ) );
        x.push("All 4");
    }
    if(['5s', '5h', '5c', '5d'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['5s', '5h', '5c', '5d'].includes( el ) );
        x.push("All 5");
    }
    if(['6s', '6h', '6c', '6d'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['6s', '6h', '6c', '6d'].includes( el ) );
        x.push("All 6");
    }
    if(['7s', '7h', '7c', '7d'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['7s', '7h', '7c', '7d'].includes( el ) );
        x.push("All 7");
    }
    if(['8s', '8h', '8c', '8d'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['8s', '8h', '8c', '8d'].includes( el ) );
        x.push("All 8");
    }
    if(['9s', '9h', '9c', '9d'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['9s', '9h', '9c', '9d'].includes( el ) );
        x.push("All 9");
    }
    if(['Ts', 'Th', 'Tc', 'Td'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['Ts', 'Th', 'Tc', 'Td'].includes( el ) );
        x.push("All 10");
    }
    if(['Js', 'Jh', 'Jc', 'Jd'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['Js', 'Jh', 'Jc', 'Jd'].includes( el ) );
        x.push("All Jack");
    }
    if(['Qs', 'Qh', 'Qc', 'Qd'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['Qs', 'Qh', 'Qc', 'Qd'].includes( el ) );
        x.push("All Queen");
    }
    if(['Ks', 'Kh', 'Kc', 'Kd'].every(elem => x.includes(elem))) {
        x = x.filter( ( el ) => !['Ks', 'Kh', 'Kc', 'Kd'].includes( el ) );
        x.push("All King");
    }
    return x;
}

export const getAllDoYaWildCards = (card) => {

    if(['As', 'Ah', 'Ac', 'Ad'].includes(card)) {
        return ['As', 'Ah', 'Ac', 'Ad']
    }
    if(['2s', '2h', '2c', '2d'].includes(card)) {
        return ['2s', '2h', '2c', '2d']
    }
    if(['3s', '3h', '3c', '3d'].includes(card)) {
        return ['3s', '3h', '3c', '3d']
    }
    if(['4s', '4h', '4c', '4d'].includes(card)) {
        return ['4s', '4h', '4c', '4d']
    }
    if(['5s', '5h', '5c', '5d'].includes(card)) {
        return ['5s', '5h', '5c', '5d']
    }
    if(['6s', '6h', '6c', '6d'].includes(card)) {
        return ['6s', '6h', '6c', '6d']
    }
    if(['7s', '7h', '7c', '7d'].includes(card)) {
        return ['7s', '7h', '7c', '7d']
    }
    if(['8s', '8h', '8c', '8d'].includes(card)) {
        return ['8s', '8h', '8c', '8d']
    }
    if(['9s', '9h', '9c', '9d'].includes(card)) {
        return ['9s', '9h', '9c', '9d']
    }
    if(['Ts', 'Th', 'Tc', 'Td'].includes(card)) {
        return ['Ts', 'Th', 'Tc', 'Td']
    }
    if(['Js', 'Jh', 'Jc', 'Jd'].includes(card)) {
        return ['Js', 'Jh', 'Jc', 'Jd']
    }
    if(['Qs', 'Qh', 'Qc', 'Qd'].includes(card)) {
        return ['Qs', 'Qh', 'Qc', 'Qd']
    }
    if(['Ks', 'Kh', 'Kc', 'Kd'].includes(card)) {
        return ['Ks', 'Kh', 'Kc', 'Kd']
    }
}