import {BASE_URL} from "../utils/constants";

const io = require('socket.io-client');

export default function (client, room, token, roomCode, amount, spectate) {
  console.log(client, room);

  // const token = auth().currentUser.getIdToken();
  console.log('TOKEN', token);
  console.log('SPECTATOR', spectate);
  const socket = io.connect(`${BASE_URL}/rooms`, {query : {roomName :  room, clientName: client, token : token, initialBuyIn : amount, roomCode: roomCode, joinAsSpectator: spectate}});
  console.log('SOCKET', socket);

  function closeTab() {
    socket.close();
  }

  function connect(cb) {
    socket.on('connect', cb)
  }

  function someoneJoined(cb) {
    socket.on('someoneJoined', cb)
  }

  function someoneLeavedTable(cb) {
    socket.on('someoneLeavedTable', cb)
  }

  function broadcastMessage(cb) {
    socket.on('broadcastMessage', cb)
  }

  function tableDetails(cb) {
    socket.on('tableDetails', cb)
  }

  function gameStarting(cb) {
    socket.on('gameStarting', cb)
  }

  function roundStarting(cb) {
    socket.on('roundStarting', cb)
  }

  function doAction(cb) {
    socket.on('doAction', cb)
  }

  function someoneBetted(cb) {
    socket.on('someoneBetted', cb)
  }

  function selectCards(cb) {
    socket.on('selectCards', cb)
  }

  function getCardsFromNeighbour(cb) {
    socket.on('getCardsFromNeighbour', cb)
  }

  function selectWinningType(cb) {
    socket.on('selectWinningType', cb)
  }

  function everyonesWinningType(cb) {
    socket.on('everyonesWinningType', cb)
  }

  function gameOver(cb) {
    socket.on('gameOver', cb)
  }

  function someonePassedCards(cb) {
    socket.on('someonePassedCards', cb)
  }

  function cardsPassingRoundOver(cb) {
    socket.on('cardsPassingRoundOver', cb)
  }

  function currentBettingTurn(cb) {
    socket.on('currentBettingTurn', cb)
  }

  function bettingRoundOver(cb) {
    socket.on('bettingRoundOver', cb)
  }

  function someoneDisconnected(cb) {
    socket.on('someoneDisconnected', cb)
  }

  function someoneReconnected(cb) {
    socket.on('someoneReconnected', cb)
  }

  function someoneTimeout(cb) {
    socket.on('someoneTimeout', cb)
  }

  function voteoutSomeone(cb) {
    socket.on('voteoutSomeone', cb)
  }

  function votingResult(cb) {
    socket.on('votingResult', cb)
  }

  function message(client, room, message, cb) {
    socket.emit('message', { clientName: client, roomName: room, message }, cb)
  }

  function leaveRoom(client, room, cb) {
    socket.emit('leaveRoom', {clientName: client, roomName: room}, cb)
  }

  //357

  function fetchCards(cb) {
    socket.on('fetchCards', cb);
  }

  function selectInOut(cb) {
    socket.on('selectInOut', cb);
  }

  function stopSelectInOutRound(cb) {
    socket.on('stopSelectInOutRound', cb);
  }

  function playersSelectionInOut(cb) {
    socket.on('playersSelectionInOut', cb);
  }

  function roundOver(cb) {
    socket.on('roundOver', cb);
  }

  function gameOver357(cb) {
    socket.on('gameOver357', cb);
  }

  function startSelectInOutRound(cb) {
    socket.on('startSelectInOutRound', cb);
  }

  //5CD

  function fetchCards5CD(cb) {
    socket.on('fetchCards5CD', cb);
  }

  function bettingRoundStarting(cb) {
    socket.on('bettingRoundStarting', cb);
  }

  function bet(cb) {
    socket.on('bet', cb);
  }

  function discardCards(cb) {
    socket.on('discardCards', cb);
  }

  function gameOver5CD(cb) {
    socket.on('gameOver5CD', cb);
  }

  function selectReady(cb) {
    socket.on('selectReady', cb);
  }

  function someoneSelectedReady(cb) {
    socket.on('someoneSelectedReady', cb);
  }

  function roundOver5CD(cb) {
    socket.on('roundOver5CD', cb);
  }

  function playersToKickOut(cb) {
    socket.on('playersToKickOut', cb);
  }

  function fetchCardsTexasHoldem(cb) {
    socket.on('fetchCardsTexasHoldem', cb);
  }

  function communityCards(cb) {
    socket.on('communityCards', cb);
  }

  function gameOverTexasHoldem(cb) {
    socket.on('gameOverTexasHoldem', cb);
  }

  function blindDeducted(cb) {
    socket.on('blindDeducted', cb);
  }

  function someoneDiscardedCards(cb) {
    socket.on('someoneDiscardedCards', cb);
  }

  function roundOverTexasHoldem(cb) {
    socket.on('roundOverTexasHoldem', cb);
  }

  function fetchCards7CS(cb) {
    socket.on('fetchCards7CS', cb);
  }

  function someoneFetchedCards(cb) {
    socket.on('someoneFetchedCards', cb);
  }

  function gameOver7CS(cb) {
    socket.on('gameOver7CS', cb);
  }

  function fetchCardsCincinnati(cb) {
    socket.on('fetchCardsCincinnati', cb);
  }

  function passCards(cb) {
    socket.on('passCards', cb);
  }

  function fetchPassedCards(cb) {
    socket.on('fetchPassedCards', cb);
  }

  function selectCardsToReveal(cb) {
    socket.on('selectCardsToReveal', cb);
  }

  function revealedCards(cb) {
    socket.on('revealedCards', cb);
  }

  function gameOverCincinnati(cb) {
    socket.on('gameOverCincinnati', cb);
  }

  function currentRoundDealer(cb) {
    socket.on('currentRoundDealer', cb);
  }

  function gameSettings(cb) {
    socket.on('gameSettings', cb);
  }
  
  function somoneSelectedCardsToReveal(cb) {
    socket.on('somoneSelectedCardsToReveal', cb);
  }

  function cardsPassingRoundStarting(cb) {
    socket.on('cardsPassingRoundStarting', cb);
  }

  function fetchCardsHomeRun(cb) {
    socket.on('fetchCardsHomeRun', cb);
  }

  function submitSettings(cb) {
    socket.on('submitSettings', cb);
  }

  function someoneSubmittedSetting(cb) {
    socket.on('someoneSubmittedSetting', cb);
  }

  function frontSettingRevealed(cb) {
    socket.on('frontSettingRevealed', cb);
  }

  function backSettingRevealed(cb) {
    socket.on('backSettingRevealed', cb);
  }

  function middleSettingRevealed(cb) {
    socket.on('middleSettingRevealed', cb);
  }

  function gameOverHomeRun(cb) {
    socket.on('gameOverHomeRun', cb);
  }

  function someoneBuyIn(cb) {
    socket.on('someoneBuyIn', cb);
  }

  function selectReadyRoom(cb) {
    socket.on('selectReadyRoom', cb);
  }

  function readyRoundOver(cb) {
    socket.on('readyRoundOver', cb);
  }

  function someonePaid(cb) {
    socket.on('someonePaid', cb);
  }

  function roundOverHomeRun(cb) {
    socket.on('roundOverHomeRun', cb);
  }

  function choosePencilAmount(cb) {
    socket.on('choosePencilAmount', cb);
  }

  function roomDetails(cb) {
    socket.on('roomDetails', cb);
  }

  function someoneLeavedRoom(cb) {
    socket.on('someoneLeavedRoom', cb);
  }

  function spectatorJoined(cb) {
    socket.on('spectatorJoined', cb);
  }

  function spectatorRejoined(cb) {
    socket.on('spectatorRejoined', cb);
  }

  function allowSpectatorToJoin(cb) {
    socket.on('allowSpectatorToJoin', cb);
  }

  function aboutToLeaveRoom(cb) {
    socket.on('aboutToLeaveRoom', cb);
  }

  function votingStarted(cb) {
    socket.on('votingStarted', cb);
  }

  function someoneChoosePencilAmount(cb) {
    socket.on('someoneChoosePencilAmount', cb);
  }

  function someoneWantsToReSubmitSetting(cb) {
    socket.on('someoneWantsToReSubmitSetting', cb);
  }

  function submitSettingRoundOver(cb) {
    socket.on('submitSettingRoundOver', cb);
  }

  function roomCrashed(cb) {
    socket.on('roomCrashed', cb);
  }

  function someoneOpened(cb) {
    socket.on('someoneOpened', cb);
  }

  function decideForFaceUpWildCardRoundStart(cb) {
    socket.on('decideForFaceUpWildCardRoundStart', cb);
  }

  function decideForFaceUpWildCard(cb) {
    socket.on('decideForFaceUpWildCard', cb);
  }

  function someoneDecideForFaceUpWildCard(cb) {
    socket.on('someoneDecideForFaceUpWildCard', cb);
  }

  function followTheQueenWildCards(cb) {
    socket.on('followTheQueenWildCards', cb);
  }

  function fetchCardsSevenTwoSeven(cb) {
    socket.on('fetchCardsSevenTwoSeven', cb);
  }

  function decideToDrawCards(cb) {
    socket.on('decideToDrawCards', cb);
  }

  function someoneDecidedForDrawCards(cb) {
    socket.on('someoneDecidedForDrawCards', cb);
  }

  function gameOverSevenTwoSeven(cb) {
    socket.on('gameOverSevenTwoSeven', cb);
  }

  function decideToDrawCardsRoundOver(cb) {
    socket.on('decideToDrawCardsRoundOver', cb);
  }

  function decideToDrawCardsRoundStart(cb) {
    socket.on('decideToDrawCardsRoundStart', cb);
  }

  function lastBettingRound(cb) {
    socket.on('lastBettingRound', cb);
  }

  function fetchCards5CS(cb){
    socket.on('fetchCards5CS', cb);
  }

  function someoneFetchedCards5CS(cb) {
    socket.on('someoneFetchedCards5CS', cb);
  }

  function someoneDecidedForDoYa(cb) {
    socket.on('someoneDecidedForDoYa', cb);
  }

  function doYa(cb) {
    socket.on('doYa', cb);
  }

  function gameOver5CS(cb) {
    socket.on('gameOver5CS', cb);
  }

  function roundOver5CS(cb) {
    socket.on('roundOver5CS', cb);
  }

  function decideFor4xCardRoundStart(cb) {
    socket.on('decideFor4xCardRoundStart', cb);
  }

  function decideFor4xCard(cb) {
    socket.on('decideFor4xCard', cb);
  }

  function someoneDecideFor4xCard(cb) {
    socket.on('someoneDecideFor4xCard', cb);
  }

  function fetchCardsBaseball(cb) {
    socket.on('fetchCardsBaseball', cb);
  }

  function layCards(cb) {
    socket.on('layCards', cb);
  }

  function someoneLayedCards(cb) {
    socket.on('someoneLayedCards', cb);
  }

  function gameOverBaseball(cb) {
    socket.on('gameOverBaseball', cb);
  }
 
  function turnSkipped(cb) {
    socket.on('turnSkipped', cb);
  }

  function newWildCardsAdded(cb) {
    socket.on('newWildCardsAdded', cb);
  }

  return {
    connect,
    someoneJoined,
    someoneLeavedTable,
    broadcastMessage,
    tableDetails,
    gameStarting,
    roundStarting,
    doAction,
    someoneBetted,
    selectCards,
    getCardsFromNeighbour,
    message,
    leaveRoom,
    selectWinningType,
    everyonesWinningType,
    gameOver,
    someonePassedCards,
    cardsPassingRoundOver,
    currentBettingTurn,
    bettingRoundOver,
    someoneDisconnected,
    someoneReconnected,
    someoneTimeout,
    voteoutSomeone,
    votingResult,
    fetchCards,
    selectInOut,
    playersSelectionInOut,
    roundOver,
    gameOver357,
    startSelectInOutRound,
    fetchCards5CD,
    bettingRoundStarting,
    bet,
    discardCards,
    gameOver5CD,
    selectReady,
    someoneSelectedReady,
    roundOver5CD,
    playersToKickOut,
    fetchCardsTexasHoldem,
    communityCards,
    gameOverTexasHoldem,
    blindDeducted,
    someoneDiscardedCards,
    roundOverTexasHoldem,
    fetchCards7CS,
    someoneFetchedCards,
    gameOver7CS,
    fetchCardsCincinnati,
    passCards,
    fetchPassedCards,
    selectCardsToReveal,
    revealedCards,
    gameOverCincinnati,
    currentRoundDealer,
    gameSettings,
    closeTab,
    somoneSelectedCardsToReveal,
    cardsPassingRoundStarting,
    fetchCardsHomeRun,
    submitSettings,
    someoneSubmittedSetting,
    frontSettingRevealed,
    backSettingRevealed,
    middleSettingRevealed,
    gameOverHomeRun,
    someoneBuyIn,
    selectReadyRoom,
    readyRoundOver,
    someonePaid,
    stopSelectInOutRound,
    roundOverHomeRun,
    choosePencilAmount,
    roomDetails,
    someoneLeavedRoom,
    spectatorJoined,
    spectatorRejoined,
    allowSpectatorToJoin,
    aboutToLeaveRoom,
    votingStarted,
    someoneChoosePencilAmount,
    someoneWantsToReSubmitSetting,
    submitSettingRoundOver,
    roomCrashed,
    someoneOpened,
    decideForFaceUpWildCardRoundStart,
    decideForFaceUpWildCard,
    someoneDecideForFaceUpWildCard,
    followTheQueenWildCards,
    fetchCardsSevenTwoSeven,
    decideToDrawCards,
    someoneDecidedForDrawCards,
    gameOverSevenTwoSeven,
    decideToDrawCardsRoundOver,
    decideToDrawCardsRoundStart,
    lastBettingRound,
    fetchCards5CS,
    someoneFetchedCards5CS,
    someoneDecidedForDoYa,
    doYa,
    gameOver5CS,
    roundOver5CS,
    decideFor4xCardRoundStart,
    decideFor4xCard,
    someoneDecideFor4xCard,
    fetchCardsBaseball,
    layCards,
    someoneLayedCards,
    gameOverBaseball,
    turnSkipped,
    newWildCardsAdded
  }
}
