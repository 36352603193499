// Add separate WildCards
const separateWildcards = (cards, wildCardsSet, jokersInDeckSet) => {
    const wildCards = []
    const nonWildCards = []
    for(const card of cards){
        if(wildCardsSet.has(card) || jokersInDeckSet.has(card)){
            wildCards.push(card)
        }
        else{ nonWildCards.push(card) }
    }
    return {playerWildCards : wildCards, playerNonWildCards : nonWildCards}
}

const getNoOfNonWildsInSuperSetCards =  (playerHand, settings) => {
    let noOfNonWildsInSuperSetCards = playerHand.length;
    const {wildCardsSettings} = settings
    if(wildCardsSettings){
        const {wildCardsSet, jokersInDeckSet} = wildCardsSettings
        const {playerNonWildCards} = separateWildcards(playerHand, wildCardsSet, jokersInDeckSet)
        noOfNonWildsInSuperSetCards = playerNonWildCards.length
    }
    return noOfNonWildsInSuperSetCards
}
  
const isAllowCardsToEvaluate = (cards, wildCardsSettings, doesSuperSetCardsHasAtleastOneNaturalCard) => {
    // Added to handle the case when we have rule : handsMustHaveOneNaturalCard true and noOfCards are > 5
    if(! wildCardsSettings){ return true }
    const {handsMustHaveOneNaturalCard, wildCardsSet, jokersInDeckSet} = wildCardsSettings
    
    if(! handsMustHaveOneNaturalCard){ return true }
    
    const {playerNonWildCards} = separateWildcards(cards, wildCardsSet, jokersInDeckSet)
    if(playerNonWildCards.length > 0){ return true }

    if(doesSuperSetCardsHasAtleastOneNaturalCard > 0){ return false }
    else { return true }
}

const getCombinations = (arr, n, r, combinations) => {
    const data = Array(r);
    combinationUtil(arr, data, 0, n - 1, 0, r, combinations); 
  }
  
const combinationUtil = (arr, data, start, end, index, r, combinations) => {
    if (index === r){
        combinations.push([...data])
        return
    }
        
    let i = start;  
    while(i <= end && end - i + 1 >= r - index){
        data[index] = arr[i]; 
        combinationUtil(arr, data, i + 1, end, index + 1, r, combinations); 
        i += 1; 
    }      
}

const getNchooseK = (n, k) => {
    const nArray = [] // [0,1,2,3 ... n-1]
    for (let index = 0; index < n; index++) { nArray.push(index) }
    const combinations = []
    getCombinations(nArray, n, k, combinations)
    return combinations
}

const getCardsOfSameRank = (cards, desiredRanks) => {
    const desiredRanksSet = new Set(desiredRanks)
    const cardsOfDesiredRank = []
    for(const card of cards){
        if(desiredRanksSet.has(card[0])){ cardsOfDesiredRank.push(card) }
    }
    return cardsOfDesiredRank
}

const sevenChooseFiveIndexCombinations = getNchooseK(7, 5)
const sixChooseFiveIndexCombinations = getNchooseK(6, 5)
const nineChooseFiveIndexCombinations = getNchooseK(9, 5)
const eightChooseFiveIndexCombination = getNchooseK(8,5)

// exports.separateWildcards = separateWildcards
// exports.isAllowCardsToEvaluate = isAllowCardsToEvaluate
// exports.getNoOfNonWildsInSuperSetCards = getNoOfNonWildsInSuperSetCards
// exports.getCombinations = getCombinations
// exports.sevenChooseFiveIndexCombinations = sevenChooseFiveIndexCombinations
// exports.sixChooseFiveIndexCombinations = sixChooseFiveIndexCombinations
// exports.nineChooseFiveIndexCombinations = nineChooseFiveIndexCombinations
// exports.eightChooseFiveIndexCombination = eightChooseFiveIndexCombination
// exports.getCardsOfSameRank = getCardsOfSameRank

export {
    separateWildcards,
    isAllowCardsToEvaluate,
    getNoOfNonWildsInSuperSetCards,
    getCombinations,
    sevenChooseFiveIndexCombinations,
    sixChooseFiveIndexCombinations,
    nineChooseFiveIndexCombinations,
    eightChooseFiveIndexCombination,
    getCardsOfSameRank
}