import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom"; 
import firebase from "firebase";
import {getCustomClaimRole} from "../services/firebase-service"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {sendToCustomerPortal, getPremiumPlan, prices} from '../services/stripe'
import {Button, Col, Modal, Row} from "react-bootstrap";
import './NewHome.css';
import HomeImg from '../assets/HomeBG.png';
import BotHome from '../assets/HomeBot.png';
import FriendHome from '../assets/HomeFriends.png';
import HomeLogo from '../assets/Poker4usText@2x.png';
import Cards4Us from '../assets/Logo.svg';
import {auth} from "firebaseui"
import ThreeBrentSevenIcon from '../assets/GameIcons/3B7.png';
import FiveCardDrawIcon from '../assets/GameIcons/5CardDraw.png';
import SevenCardStudIcon from '../assets/GameIcons/7CS.png';
import ThreeFiveSevenIcon from '../assets/GameIcons/357.png';
import AnacondaIcon from '../assets/GameIcons/Anaconda.png';
import CincinnatiIcon from '../assets/GameIcons/Cincinnati.png';
import HomeRunIcon from '../assets/GameIcons/HomeRun.png';
import TexasHoldemIcon from '../assets/GameIcons/TexasHoldem.png';
import OmahaIcon from '../assets/GameIcons/Omaha.png';
import LoChicagoIcon from '../assets/GameIcons/LoChicago.png';
import HighChicagoIcon from '../assets/GameIcons/HighChicago.png';
import DoyaIcon from '../assets/GameIcons/DO-YA.png';
import s727Icon from '../assets/GameIcons/727.png';
import BaseballIcon from '../assets/GameIcons/Baseball.png';
import IronCrossLogo from '../assets/GameIcons/Ironcross.png';
import CardDice from '../assets/CardDice.png';
import CloseIcon from '../assets/Iconclosecircle.svg';
import Aos from 'aos';
import "aos/dist/aos.css";
import queryString from "query-string/index";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CookieConsent from "react-cookie-consent";
import GoogleAds from './GoogleAds';
import ReactGA from 'react-ga';

class NewHome extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isSignedIn: false,
            playerAccount: {},
            showAccount: false,
            isPremiumMember : false,
            showGameButtons: false,
            showLoginOptions: false,
            showGuestLogin: false,
            showAllOptions: false,
            cookiesAccepted: false,
            showLinks: false,
            gameList: [
                {
                    game: "Anaconda",
                    logo: AnacondaIcon,
                    link: '/rules?game=Anaconda',
                    alt: "Anaconda"
                },
                {
                    game: "727",
                    logo: s727Icon,
                    link: '/rules?game=727',
                    alt: "727"
                },
                {
                    game: "Cincinnati",
                    logo: CincinnatiIcon,
                    link: '/rules?game=Cincinnati',
                    alt: "Cincinnati"
                },
                {
                    game: "357",
                    logo: ThreeFiveSevenIcon,
                    link: '/rules?game=357',
                    alt: "357"
                },
                {
                    game: "3-Brent-7",
                    logo: ThreeBrentSevenIcon,
                    link: '/rules?game=3-Brent-7',
                    alt: "3-Brent-7"
                },
                // {
                //     game: "5-Card-Stud",
                //     logo: null,
                //     link: '/rules?game=5CardStud',
                //     alt: "5-Card-Stud"
                // },
                {
                    game: "Do-Ya",
                    logo: DoyaIcon,
                    link: '/rules?game=DoYa',
                    alt: "Do-Ya"
                },
                {
                    game: "Baseball",
                    logo: BaseballIcon,
                    link: '/rules?game=Baseball',
                    alt: "Baseball"
                },
                // {
                //     game: "Blind-Baseball",
                //     logo: null,
                //     link: '/rules?game=BlindBaseball',
                //     alt: "Blind-Baseball"
                // },
                {
                    game: "7-Card-Stud",
                    logo: SevenCardStudIcon,
                    link: '/rules?game=7-Card-Stud',
                    alt: "Cincinnati"
                },
                // {
                //     game: "Follow-the-Queen",
                //     logo: null,
                //     link: '/rules?game=FollowtheQueen',
                //     alt: "Follow-the-Queen"
                // },
                {
                    game: "Lo-Chicago",
                    logo: LoChicagoIcon,
                    link: '/rules?game=Lo-Chicago',
                    alt: "Lo-Chicago"
                },
                {
                    game: "High-Chicago",
                    logo: HighChicagoIcon,
                    link: '/rules?game=High-Chicago',
                    alt: "High-Chicago"
                },
                {
                    game: "5-Card-Draw",
                    logo: FiveCardDrawIcon,
                    link: '/rules?game=5-Card-Draw',
                    alt: "5-Card-Draw"
                },
                // {
                //     game: "Diablo",
                //     logo: null,
                //     link: '/rules?game=Diablo',
                //     alt: "Diablo"
                // },
                // {
                //     game: "Frankenstein",
                //     logo: null,
                //     link: '/rules?game=Frankenstein',
                //     alt: "Frankenstein"
                // },
                {
                    game: "Homerun",
                    logo: HomeRunIcon,
                    link: '/rules?game=HomeRun',
                    alt: "Homerun"
                },
                {
                    game: "Texas-Holdem",
                    logo: TexasHoldemIcon,
                    link: '/rules?game=Texas-Holdem',
                    alt: "Texas-Holdem"
                },
                {
                    game: "Ironcross",
                    logo: IronCrossLogo,
                    link: '/rules?game=Ironcross',
                    alt: "Ironcross"
                },
                {
                    game: "Omaha",
                    logo: OmahaIcon,
                    link: '/rules?game=Omaha',
                    alt: "Omaha"
                },
                // {
                //     game: "Omaha8",
                //     logo: null,
                //     link: '/rules?game=Omaha8',
                //     alt: "Omaha8"
                // },
            ]
        }
    }

    NewTab = (message) => {
        if(this.validURL(message)) {
          window.open(message, "_blank"); 
        }
    }
    
    validURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: async function(authResult, redirectUrl) {
                const user = authResult.user;
                const credential = authResult.credential;
                const isNewUser = authResult.additionalUserInfo.isNewUser;
                const providerId = authResult.additionalUserInfo.providerId;
                const operationType = authResult.operationType;
                console.log('AUTH RES', authResult);
                // If a user is new we want to insert it in firestore.
                const db = firebase.firestore()
                const insertNewUser = async function(user){
                    await db.collection('customers').doc(user.uid).set({
                        email : user.email
                    })
                }
                if(isNewUser){
                    await insertNewUser(user)
                }
                else{
                    const doc = await db.collection('customers').doc(user.uid).get()
                    console.log('USER', doc);
                    if(! doc.exists){ await insertNewUser(user) }
                }
                return true;
              },
        }
    }

    uiAllConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            auth.AnonymousAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult: async function(authResult, redirectUrl) {
                const user = authResult.user;
                const credential = authResult.credential;
                const isNewUser = authResult.additionalUserInfo.isNewUser;
                const providerId = authResult.additionalUserInfo.providerId;
                const operationType = authResult.operationType;

                // If a user is new we want to insert it in firestore.
                const db = firebase.firestore()
                const insertNewUser = async function(user){
                    await db.collection('customers').doc(user.uid).set({
                        email : user.email
                    })
                }
                if(isNewUser){
                    await insertNewUser(user)
                }
                else{
                    const doc = await db.collection('customers').doc(user.uid).get()
                    if(! doc.exists){ await insertNewUser(user) }
                }
                return true;
              },
        }
    }

    uiGuestConfig = {
        signInFlow: "popup",
        signInOptions: [
            auth.AnonymousAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult: async function(authResult, redirectUrl) {
                const user = authResult.user;
                const credential = authResult.credential;
                const isNewUser = authResult.additionalUserInfo.isNewUser;
                const providerId = authResult.additionalUserInfo.providerId;
                const operationType = authResult.operationType;
                console.log('AUTH RES', authResult);

                // If a user is new we want to insert it in firestore.
                const db = firebase.firestore()
                const insertNewUser = async function(user){
                    await db.collection('customers').doc(user.uid).set({
                        email : user.email
                    })
                }
                if(isNewUser){
                    await insertNewUser(user)
                }
                else{
                    const doc = await db.collection('customers').doc(user.uid).get()
                    if(! doc.exists){ await insertNewUser(user) }
                }
                return true;
              },
        }
    }

    componentDidMount = () => {

        // ReactGA.initialize('G-WEZEQHVP58');
        ReactGA.pageview(window.location.pathname + window.location.search);


        let { id } = queryString.parse(this.props.location.search);
        if(id === 'games') {
            this.scrollToOurgames();
        }
        if(id === 'aboutUs') {
            this.scrollToAboutUs();
        }
        if(id === 'faq') {
            this.scrollToFAQ();
        }
        Aos.init({duration: 2000});

        // firebase.auth().onAuthStateChanged(user =>{
        //     this.setState({isSignedIn: !!user})
        //     console.log('USER DETAILS', user);
        // })

        firebase.auth().onAuthStateChanged( async function(user) {
            let areCookiesAccepted = localStorage.getItem('cookieSettings') === 'true';
            this.setState({
                cookiesAccepted: areCookiesAccepted
            })
            if (user) {
                const role = await getCustomClaimRole().catch(err => console.log("getCustomClaimRole", err))
                this.setState({
                    isSignedIn: true,
                    isPremiumMember : (role==="premium") ? true : false,
                    showGameButtons: false,
                    showLoginOptions: false,
                    showGuestLogin: false,
                    showAllOptions: false,
                    showGameButtons: true
                })
            } else {
                this.setState({
                    isSignedIn: false,
                    showLoginOptions: false,
                    showGameButtons: false,
                    showGuestLogin: false
                })
                console.log('Firebase Signed Out user');
            }
        }.bind(this));
    }

    getPremiumPlanHandler = async (event) => {
        await getPremiumPlan(event.target.id).catch(err => console.log("Get Premium Plan error", err))
    }

    sendToCustomerPortal = async () => {
        await sendToCustomerPortal().catch(err => console.log("Get Premium Plan error", err))
    }

    mainButtonPress = () => {
        if(this.state.isSignedIn) {
            this.setState({
                showGameButtons: true
            })
        }
        else {
            // alert(`Please Sign In`);
            this.setState({
                showAllOptions: !this.state.showAllOptions
            })
        }
    }

    signOutButton = () => {
        firebase.auth().signOut();
        this.setState({
            showGameButtons: false
        })
    }

    scrollToOurgames = () => {
        this.ourGames.scrollIntoView({ behavior: "smooth" });
    }

    scrollToAboutUs = () => {
        this.aboutUs.scrollIntoView({ behavior: "smooth" });
    }

    scrollToHome = () => {
        this.ourHome.scrollIntoView({ behavior: "smooth" });
    }
    
    scrollToFAQ = () => {
        this.faq.scrollIntoView({ behavior: "smooth" });
    }

    onHomeClick = () => {
        this.scrollToHome();
        this.setState({showLoginOptions: false, showGuestLogin: false, showLinks: false})
    }

    onOurGamesClick = () => {
        this.scrollToOurgames();
        this.setState({showLoginOptions: false, showGuestLogin: false, showLinks: false})
    }

    onAboutUsClick = () => {
        this.scrollToAboutUs();
        this.setState({showLoginOptions: false, showGuestLogin: false, showLinks: false})
    }

    onFaqClick = () => {
        this.scrollToFAQ();
        this.setState({showLoginOptions: false, showGuestLogin: false, showLinks: false})
    }

    loginButtonClicked = () => {
        this.setState({
            showLoginOptions: !this.state.showLoginOptions,
            showGuestLogin: false,
            showLinks: false
        })
    }

    guestButtonClicked = () => {
        this.setState({
            showLoginOptions: false,
            showGuestLogin: !this.state.showGuestLogin,
            showLinks: false
        })
    }

    gaClick = () => {
        window.ga('send', 'event', {eventAction: 'test', eventCategory: 'test', eventValue: 1, title: 'test'});
    }

    render() {
        let {playerAccount, showAccount, isSignedIn, showGameButtons, showLoginOptions, showGuestLogin, showAllOptions} = this.state;

        var myBigGreenDialog = {
            backgroundColor: '#00001B',
            color: '#ffffff',
            width: '100%',
            height: '100%',
            marginTop: '-50vh',
            marginLeft: '-50%',
            closeButtonStyle: {
                cursor: 'pointer',
                position: 'absolute',
                fontSize: '1.8em',
                right: '100px',
                top: '0'
            },
          };

        return (
            <div className="">
                {!this.state.cookiesAccepted && <CookieConsent
                    location="bottom"
                    buttonText="I understand"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    debug={true}
                    onAccept={() => {
                        this.setState({cookiesAccepted: true})
                        localStorage.setItem('cookieSettings', true);
                    }}
                    >
                    We use cookies to improve your experience on our website.{" "}
                    <Link to={`/privacypolicy`}><span>Privacy Policy</span></Link>
                </CookieConsent>}
                {showLoginOptions && <div className="loginoptions" id="loginoptionsui">
                    <StyledFirebaseAuth
                        uiConfig={this.uiConfig}
                        firebaseAuth={firebase.auth()}
                    />
                </div>}
                {showGuestLogin && <div className="guestloginoption" id="guestoptionui">
                    You won't be able to save your session if you sign-out or close an icognito browser window. We strongly recommend to use sign in using email or social media sites. 
                    <StyledFirebaseAuth
                        uiConfig={this.uiGuestConfig}
                        firebaseAuth={firebase.auth()}
                        />
                    <Button className="headerBtn" onClick={() => this.setState({showGuestLogin: false})}>Close</Button>
                </div>}
                {showAllOptions && <div className="guestloginoption">
                    You need to sign-in first to play games on this site. You won't be able to save your session if you sign-out or close an icognito browser window if you Login as a Guest. We strongly recommend to use sign in using email or social media sites. 
                    <StyledFirebaseAuth
                        uiConfig={this.uiAllConfig}
                        firebaseAuth={firebase.auth()}
                    />
                    <Button className="headerBtn" onClick={() => this.setState({showAllOptions: false})}>Close</Button>
                </div>}
                <div className="Navbar">
                    <div className="leftSide">
                        POKER4US    
                    </div>
                    <div className="rightSide">
                    {!this.state.showLinks && <div className="linkButton" onClick={() => {this.setState({showLinks: !this.state.showLinks})}}><i class="fa fa-bars" aria-hidden="true"></i></div>}
                    {this.state.showLinks && <div className="linkButton" onClick={() => {this.setState({showLinks: !this.state.showLinks})}}><i class="fa fa-close" aria-hidden="true"></i></div>}
                        <div className="links" id={this.state.showLinks ? "hidden" : ""}>
                            <a onClick={this.onHomeClick}>Home</a>
                            <a onClick={this.onOurGamesClick}>Our Games</a>
                            <a onClick={this.onAboutUsClick}>About Us</a>
                            <a onClick={this.onFaqClick}>Faq's</a>
                            {!isSignedIn && <a className="headerBtn" onClick={this.guestButtonClicked}>Play As Guest</a>}
                            {!isSignedIn && <a className="headerBtn" onClick={this.loginButtonClicked}>Login</a>}
                            {isSignedIn && !firebase.auth().currentUser.isAnonymous && <a href="/">{firebase.auth().currentUser.displayName}</a>}
                            {isSignedIn && !firebase.auth().currentUser.isAnonymous && !firebase.auth().currentUser.displayName && <a href="/">{firebase.auth().currentUser.email}</a>}
                            {isSignedIn && firebase.auth().currentUser.isAnonymous && <a href="/">Anonymous</a>}
                            {isSignedIn && <a onClick={() => {firebase.auth().signOut();this.setState({isSignedIn: false})}} className="signoutnew">Sign Out!</a>}
                        </div>
                    </div>
                </div>
                <div className="containerone" ref={(el) => { this.ourHome = el; }} onClick={() => this.setState({showLoginOptions: false, showGuestLogin: false, showLinks: false})}>
                    <Carousel showThumbs={false} autoPlay={false} interval={10000} infiniteLoop={true} showStatus={false} stopOnHover={true}>
                        {/* <div className="homecarou1">     */}
                        <div className="testImg">
                            {/* <img className="newHomeImg" src={HomeImg} alt="Home"/> */}
                            {showGameButtons && <div className="homeLogoLarger">
                                <img className="carouImg1" src={HomeLogo} alt="HomeLogo"/>
                            </div>}
                            {!showGameButtons && <div className="homeLogoLargerNew">
                                <img className="carouImg2" src={HomeLogo} alt="HomeLogo"/>
                            </div>}
                            {isSignedIn && <div className="userscreen mt-4">
                                {showGameButtons && <div className="homebuttons">
                                    <NavLink to={`/create?createRoom`}>
                                        <button className="myaccountBtn" type="submit">CREATE</button>
                                    </NavLink>
                                    <NavLink to={`/join?joinRoom`}>
                                        <button className="myaccountBtn" type="submit">JOIN</button>
                                    </NavLink>
                                    <Link to={`/preferences`}>
                                        <button className="myaccountBtn" type="submit">ROOM PREFERENCES</button>
                                    </Link>
                                    <Link to={`/account?playerAccount`}>
                                        <button className="myaccountBtn" type="submit">MY ACCOUNT</button>
                                    </Link>
                                    <div className="ad-classhomelogin">
                                        <GoogleAds 
                                            slot="3235242311" 
                                            style={{
                                                display: 'inline-block',
                                                width: '728px',
                                                height: '90px',
                                        }}/>
                                    </div>
                                    {/* {!this.state.isPremiumMember && !firebase.auth().currentUser.isAnonymous && 
                                        <Row> 
                                            <button id={prices.perMonth} onClick={this.getPremiumPlanHandler} className="myaccountBtn">Subscribe : $1/month</button>
                                            <button id={prices.perYear} onClick={this.getPremiumPlanHandler} className="myaccountBtn">Subscribe : $5/year</button>
                                        </Row>  
                                    }
                                    {this.state.isPremiumMember && !firebase.auth().currentUser.isAnonymous &&
                                        <button onClick={this.sendToCustomerPortal} className="myaccountBtn">Customer Portal</button>
                                    } */}
                                </div>}
                            </div>}
                            {!showGameButtons && <div className="trueBtn">
                                {isSignedIn && <button className="mainBtn" type="submit" onClick={this.mainButtonPress}>PLAY</button>}
                                {!isSignedIn && <button className="mainBtn" type="submit" onClick={this.mainButtonPress}>LOGIN TO PLAY</button>}
                                <div className="ad-classhomelogout">
                                        <GoogleAds 
                                            slot="3235242311" 
                                            style={{
                                                display: 'inline-block',
                                                width: '728px',
                                                height: '90px',
                                        }}/>
                                    </div>
                            </div>}
                        </div>
                        <div className="friendsdiv">
                        <div className='testimagefriends'>
                            {/* <img className="newHomeImg" src={FriendHome} alt="Home"/> */}
                            <div className="pageinfo">
                            <span className="pageheader">INVITE FRIENDS AND PLAY WITH THEM</span>
                                <br/><br/>
                                <span className="pagetext">Invite your friends and enjoy playing even more. You can join a group phone call or video conference at the same time, so jokes and banter can more easily take place. Google Meet and Zoom are great options.</span>
                                <br/>
                                {!showGameButtons && <div>
                                    <button className="myaccountBtn" type="submit" onClick={this.mainButtonPress}>LOGIN TO PLAY</button>
                                </div>}
                                {showGameButtons && <div>
                                    <Link to={`/create?createRoom`}>
                                        <button className="myaccountBtn" type="submit">CREATE</button>
                                    </Link>
                                    <Link to={`/join?joinRoom`}>
                                        <button className="myaccountBtn" type="submit">JOIN</button>
                                    </Link>    
                                    <div className="ad-classhometwo">
                                        {/* <ins className='adsbygoogle'
                                            data-ad-slot="1234014517147570"
                                            data-ad-format= 'auto'
                                            style={{
                                                display: 'inline-block',
                                                alignContent: 'center',
                                                width: '1000px',
                                                height: '150px',
                                            }}
                                            data-ad-client="ca-pub-1234014517147570"
                                            data-ad-format="auto"
                                        >
                                        </ins> */}
                                    </div>
                                </div>}
                            </div>
                        </div>
                        </div>
                        <div className="testimagebots">
                            {/* <img className="newHomeImg" src={BotHome} alt="Home"/> */}
                            <div className="pageinfo">
                            <span className="pageheader">TRY PLAYING WITH BOTS</span>
                                <br/><br/>
                                <span className="pagetext">A BOT will take random actions for each game you want to play. You can add or remove a bot before starting a game.</span>
                                <br/><br/>
                                {!showGameButtons && <div>
                                    <button className="myaccountBtn" type="submit" onClick={this.mainButtonPress}>LOGIN TO PLAY</button>
                                    <div className="ad-classhomethree">
                                        {/* <ins className='adsbygoogle'
                                            data-ad-slot="1234014517147570"
                                            data-ad-format= 'auto'
                                            style={{
                                                display: 'inline-block',
                                                alignContent: 'center',
                                                width: '500px',
                                                height: '150px',
                                            }}
                                            data-ad-client="ca-pub-1234014517147570"
                                            data-ad-format="auto"
                                        >
                                        </ins> */}
                                    </div>
                                </div>}
                                {showGameButtons && <div>
                                    <Link to={`/create?createRoom`}>
                                        <button className="myaccountBtn" type="submit">CREATE</button>
                                    </Link>
                                    <Link to={`/join?joinRoom`}>
                                        <button className="myaccountBtn" type="submit">JOIN</button>
                                    </Link>    
                                    <div className="ad-classhometwo">
                                        {/* <ins className='adsbygoogle'
                                            data-ad-slot="1234014517147570"
                                            data-ad-format= 'auto'
                                            style={{
                                                display: 'inline-block',
                                                alignContent: 'center',
                                                width: '1000px',
                                                height: '150px',
                                            }}
                                            data-ad-client="ca-pub-1234014517147570"
                                            data-ad-format="auto"
                                        >
                                        </ins> */}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div ref={(el) => { this.ourGames = el; }} className="containertwo"  onClick={() => this.setState({showLoginOptions: false, showGuestLogin: false, showAllOptions: false, showLinks: false})}>
                    <div className="titleinfo">
                        <span>OUR GAMES</span>
                        <br/>
                        <span className="titledescription">Learn the rules by clicking any of the games &nbsp; &nbsp;</span><Link to={`/rules?game=Poker-Rules`} className="titledescriptionrules">POKER-RULES</Link>
                        <div className="ad-classhomefour">
                            {/* <ins className='adsbygoogle'
                                data-ad-slot="1234014517147570"
                                data-ad-format= 'auto'
                                style={{
                                    display: 'inline-block',
                                    alignContent: 'center',
                                    width: '500px',
                                    height: '150px',
                                }}
                                data-ad-client="ca-pub-1234014517147570"
                                data-ad-format="auto"
                            >
                            </ins> */}
                        </div>
                        <div className="ad-classhomefive">
                            {/* <ins className='adsbygoogle'
                                data-ad-slot="1234014517147570"
                                data-ad-format= 'auto'
                                style={{
                                    display: 'inline-block',
                                    alignContent: 'center',
                                    width: '500px',
                                    height: '150px',
                                }}
                                data-ad-client="ca-pub-1234014517147570"
                                data-ad-format="auto"
                            >
                            </ins> */}
                        </div>
                    </div>
                    <Row className="gamelist">
                        {this.state.gameList.map((game, index) => <div key={index} className="gameDetails d-flex flex-row">
                            <Link to={game.link} data-aos="fade-up">
                                <img className="iconImg" src={game.logo} alt={game.alt}/>
                                <div className="iconText">{game.game}</div>
                            </Link>
                        </div>)}
                    </Row>
                </div>
                <div className="containerthreenew" ref={(el) => { this.aboutUs = el; }}  onClick={() => this.setState({showLoginOptions: false, showGuestLogin: false, showAllOptions: false, showLinks: false})}>
                    {/* <div data-aos="fade-up" className="innercontainerthree">
                        <span className="innertitle">ABOUT US</span>
                        <br/>
                        <br/>
                        <span className="innertext">
                        Poker4Us is a website devoted to letting people play poker when it’s hard to get together in person and attempts to provide a home-poker-party feel, where the dealer can pick the game. It’s best used with a group phone call or video conference at the same time, so jokes and banter can more easily take place. Google Meet and Zoom are great options. <br/>
                        We also created <a className="pplink_2" onClick={() => this.NewTab('https://cards4.us/')}>Cards4us</a>, which is for card games other than poker. We hope you have fun! <br/>
                        The Cards4Us collective is a small group of friends who like games and couldn’t find a site that did what we wanted. Please contact us with any comments or questions! 
                        </span>
                        <br/>
                    </div>
                    <img data-aos="fade-up" className="pokerImg" src={CardDice} alt="POKER"/> */}
                    <div className="" data-aos="fade-up">
                        <span className="innertitle">ABOUT US</span>
                        <img src={CardDice} className="pokerImg" alt="Poker"></img>
                        <br/><br/>
                        <span className="faqanswer">
                        Poker4Us is a website devoted to letting people play poker when it’s hard to get together in person and attempts to provide a home-poker-party feel, where the dealer can pick the game. It’s best used with a group phone call or video conference at the same time, so jokes and banter can more easily take place. Google Meet and Zoom are great options. <br/>
                        We also created <a className="pplink_2" onClick={() => this.NewTab('https://cards4.us/')}>Cards4us</a>, which is for card games other than poker. We hope you have fun! <br/>
                        The Cards4Us collective is a small group of friends who like games and couldn’t find a site that did what we wanted. Please contact us with any comments or questions! 
                        </span>
                    </div>
                </div>
                <div className="containerfive" ref={(el) => { this.faq = el; }}  onClick={() => this.setState({showLoginOptions: false, showGuestLogin: false, showAllOptions: false, showLinks: false})}>
                    <div data-aos="fade-up" className="faqtitle">
                        Frequently Asked Questions
                    </div>
                    <br/>
                    <div data-aos="fade-up">
                    <div className="faqquestion">
                    Is this a gambling site?        
                    </div>
                    <div className="faqanswer">
                    No. Games are only for fun; this is a simulation of home poker for friends to play when it is hard to get together in person.
                    </div></div>
                    <br/>
                    <div data-aos="fade-up">
                        <div className="faqquestion">
                        What are the memberships for?
                        </div>
                        <div className="faqanswer">
                        To allow for saving of a set of your default house and game rules, as well as to disable ads.
                        </div>
                    </div>
                    <br/>
                    <div data-aos="fade-up">
                        <div className="faqquestion">
                        Can I play for free?
                        </div>
                        <div className="faqanswer">
                        Yes -- you can play for free without signing up for an account, or even if you sign in with an email or a third-party account (like Google or Facebook), you can still play for free. 
                        </div>
                    </div>
                    <br/>
                    <div data-aos="fade-up">
                        <div className="faqquestion">
                        Can people I don’t invite enter my room?
                        </div>
                        <div className="faqanswer">
                        It depends on how you set up the room.  You can create a private room that only  those you invite can enter, or you can create a public room that everyone can see. 
                        </div>
                    </div>
                    <br/>
                    <div data-aos="fade-up">
                        <div className="faqquestion">
                        Can you do a Texas Hold Em tournament on this site?
                        </div>
                        <div className="faqanswer">
                        Not yet, but it’s coming soon! You could jankily keep playing Hold-Em rounds and modifying the blinds yourself, make it no-limit and no-rebuys during the game, and kind of make it work, but support for blind structures, limited rebuy periods, etc, is coming soon. 
                        </div>
                    </div>
                    <br/>
                    <div data-aos="fade-up">
                        <div className="faqquestion">
                        Can people I don’t invite enter my room?
                        </div>
                        <div className="faqanswer">
                        It depends on how you set up the room.  You can create a private room that only  those you invite can enter, or you can create a public room that everyone can see. 
                        </div>
                    </div>
                    <br/>
                    <div data-aos="fade-up">
                        <div className="faqquestion">
                        Can I add BOTS to play a game?
                        </div>
                        <div className="faqanswer">
                        Yes, you can add any number of BOTS in your room. You can add or remove a BOT before starting a game. BOTS will not be given the option to start the game. 
                        </div>
                    </div>
                    <br/>
                    <div data-aos="fade-up">
                        <div className="faqquestion">
                        How intelligently can a BOT play?
                        </div>
                        <div className="faqanswer">
                        BOTS will take random actions on betting and making choices for each games you want to play. For 357 and 3-Brent-7, BOTS will make more sensible choices. We are currently making the BOTS more intelligent for other games.
                        </div>
                    </div>
                </div>
                <div className="containerfour">
                    <img className="cardsusImg" src={Cards4Us} alt="CARDS4US"/><br/>
                    <span className>
                        <span>© 2021 CARD4US All Rights Reserved.<br/><br/><NavLink to={`/privacypolicy`}><span className>PRIVACY-POLICY</span></NavLink></span>
                    </span><br/><br/>
                    <div>DISCLAIMER All the information on this website is published for general information purpose only. Any use or action you take upon the information you find on this site is strictly at your own risk. Please check the Privacy Policies of the site before uploading any information. This website uses cookies to store your preferences and for advertising purposes. By using our website, you hereby consent to our privacy policy.</div>
                </div>
            </div>
        )
    }
}

export default NewHome;
