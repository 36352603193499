import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {
    fetchPlayerAccount,
    fetchPlayerRoomAccount,
    getPlayerPaymentInfo
} from "../services/room";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import firebase from "firebase";
import {Link} from "react-router-dom";
import {getPaymentInfoUser, updatePaymentInfoUser} from "../services/firebase-service"

import '../styles/PlayerAccount.css';

function RoomDetailsModal(props) {

    let roomData = props.roomData.games;
    let summaryData = [];
    for (const i in props.roomData.summary) {
        let body = {
            player: i,
            amount: props.roomData.summary[i]
        }
        summaryData.push(body);
    }

    if(props.apiRoomFetched) {

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold d-flex" id="contained-modal-title-vcenter">
                        Room-History
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                    <div className="font-weight-bold">Room Summary</div>   
                    {summaryData.map((summary, index) => {
                        return <div key={index} className="d-flex flex-column">
                            <table id="customers" className="font-weight-bold detailstable">
                                <tr>
                                    <td>{summary.player}</td>
                                    <td>{summary.amount}</td>
                                </tr>
                            </table>
                        </div>
                    })}
                    <br/>
                    <div className="font-weight-bold">WHO-PAYS-WHO</div>
                    {props.whoPaysWhoData.map((wpw, index) => {
                        return <div key={index} className="d-flex flex-column">
                                    {wpw.player}
                                    {wpw.data.map((data, index) => {
                                        return <div key={index} className="d-flex flex-column">
                                            <table id="customers" className="font-weight-bold detailstable">
                                            <tr>
                                                <td>{data.player}</td>
                                                <td>{data.amount}</td>
                                                {wpw.player === props.myEmail && data.payment && <td>PayPal: {data.payPal}<br/>Cash App: {data.cashApp}<br/>Venmo: {data.venmo}</td>}
                                                {wpw.player === props.myEmail && !data.payment && <td>No Payment ID</td>}
                                            </tr>
                                        </table>
                                        </div>
                                    })}
                                    <br/>
                                </div>
                    })}
                    <br></br>
                    {roomData.map((room, index) => {
                        let stackHistory = Object.entries(room.playersAccount);
                        return  <div key={index} className="d-flex flex-column">
                                    {room.gameNoInRoom}. {room.name}
                                    <table id="customers" className="font-weight-bold detailstable">
                                        <tr>
                                            <td>Player</td>
                                            <td>Buy In</td>
                                            <td>Start Stack, End Stack</td>
                                            {/* <td>End Stack</td> */}
                                            <td>Net Gain</td>
                                        </tr>
                                    </table>
                                    {stackHistory.map((player, index) =>
                                        <table id="customers" key={index}>
                                            <tr>
                                                <td>{player[1].player}</td>
                                                <td>{player[1].buyIn}</td>
                                                <td>{player[1].startStack}, {player[1].endStack}</td>
                                                {/* <td>{player[1].endStack}</td> */}
                                                <td>{player[1].netAmountGained}</td>
                                            </tr>
                                        </table>
                                    )}
                                    <br></br>
                                </div>
                    })}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        Room Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    <div className="d-flex flex-column">
                        Play a game
                    </div>
                    <div classname="d-flex flex-column">

                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

class PlayerAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            playerAccount: {},
            apiFetched: false,
            apiRoomFetched: false,
            showModal: false,
            roomData: {},
            myPaypalAccount: "",
            myCashAppAccount: "",
            myVenmoAccount: "",
            myEmail: "",
            whoPaysWhoData: [],
            isAnonymousUser: true
        }
    }

    componentDidMount = async () => {

        firebase.auth().onAuthStateChanged( async function(user) {
            if (user) {
                this.setState({
                    myEmail: firebase.auth().currentUser.email || '',
                    isAnonymousUser: firebase.auth().currentUser.isAnonymous
                })
                if(!firebase.auth().currentUser.isAnonymous) {
                    const data = await getPaymentInfoUser().catch(err => console.log("getPaymentInfoUser", err));
                    console.log('MY Payment data', data);
                    if(data) {
                        this.setState({
                            myPaypalAccount: data.paypal.paypalMeLink,
                            myVenmoAccount: data.venmo.username,
                            myCashAppAccount: data.cashApp.cashTagName
                        })
                    }
                }
                let response = await fetchPlayerAccount();
                console.log('Firebase Signed In user PlayerAccount', response);
                this.setState({
                    playerAccount: response.data,
                    apiFetched: true
                })
            } else {
                console.log('Firebase Signed Out user');
            }
        }.bind(this));

        
    }

    onDetailsPress = async (roomID) => {

        let response = await fetchPlayerRoomAccount(roomID);
        console.log('Fetchin Player Account Room Details', response);
        let whoPaysWhoTempData = [];
        for(const i in response.data.whoPaysWho) {
            let temp = response.data.whoPaysWho[i];
            let x = [];
            for(const j in temp) {
                if(i === this.state.myEmail && !this.state.isAnonymousUser) {
                    let paymentInfo = await getPlayerPaymentInfo(j);
                    console.log('PAYMENT INFO', paymentInfo);
                    if(j in paymentInfo.data) {
                        let body = {
                            player: j,
                            amount: temp[j],
                            payment: true,
                            payPal: paymentInfo.data[j].paypal.paypalMeLink,
                            cashApp: paymentInfo.data[j].cashApp.cashTagName,
                            venmo: paymentInfo.data[j].venmo.username
                        }
                        x.push(body)
                    }
                    else {
                        let body = {
                            player: j,
                            amount: temp[j],
                            payment: false,
                        }
                        x.push(body)
                    }
                }
                else {
                    let body = {
                        player: j,
                        amount: temp[j],
                        payment: false
                    }
                    x.push(body)
                }
            }
            let body = {
                player: i,
                data: x
            }
            whoPaysWhoTempData.push(body);
        }
        this.setState({
            roomData: response.data,
            showModal: true,
            apiRoomFetched: true,
            whoPaysWhoData: whoPaysWhoTempData
        })
    };

    handleChange = (event) => {
        const {name, value, type, checked} = event.target;
        type === "checkbox" ? this.setState({[name]: checked}) : this.setState({[name]: value})
    }

    savePaymentOptions = async () => {
        let paymentInfo =
        {
            paypal: { paypalMeLink : this.state.myPaypalAccount },
            cashApp: { cashTagName : this.state.myCashAppAccount },
            venmo: { username : this.state.myVenmoAccount }
        }
        let response = await updatePaymentInfoUser(paymentInfo).catch(err => console.log("updatePaymentInfoUser", err));
        console.log('Update', response);
    }

    render() {

        let data = this.state.playerAccount;
        let roomWiseDetails = this.state.playerAccount.roomWiseDetails;

        return (
            <div>
                <div className="player-account-details">
                <div className="homepageaccountlink">
                    <Link to={'/'} className="homepageaccountBtn">
                        <i class="fa fa-home" aria-hidden="true"></i> <span>HOME</span>
                    </Link>
                </div>
                {!this.state.isAnonymousUser && <div>
                    <h3 className="center-align-text">PLAYER ACCOUNT DETAILS</h3>
                    <div className="align-payment-options">
                    <Form>
                    <Form.Group className="mb-2" controlId="formBasicEmail">
                        <Form.Label>PayPal Me Account</Form.Label>
                        <Form.Control placeholder="paypal.me/name" name="myPaypalAccount" className="paymentInput mt-n2" type="text" value={this.state.myPaypalAccount} onChange={this.handleChange} />
                        <Form.Text className="text-muted mt-n2">
                        Enter your PayPal Link. Eg: paypal.me/yourName
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formBasicEmail">
                        <Form.Label>Cash App Account</Form.Label>
                        <Form.Control placeholder="cash tag name" name="myCashAppAccount" className="paymentInput mt-n2" type="text" value={this.state.myCashAppAccount} onChange={this.handleChange} />
                        <Form.Text className="text-muted mt-n2">
                        Enter your Cash Tag Name.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formBasicEmail">
                        <Form.Label>Venmo Account</Form.Label>
                        <Form.Control placeholder="venmo username" name="myVenmoAccount" className="paymentInput mt-n2" type="text" value={this.state.myVenmoAccount} onChange={this.handleChange} />
                        <Form.Text className="text-muted mt-n2">
                        Enter your Venmo unique Username.
                        </Form.Text>
                    </Form.Group>
                    <Button variant="primary" onClick={this.savePaymentOptions} className="paymentsavebtn center-align-text">
                        SAVE
                    </Button>
                    </Form>
                    </div>
                </div>}
                {this.state.apiFetched && <div>
                        <div className="d-flex flex-column">
                            <div className="font-weight-bold center-align-text">Player Account Status</div>
                                <table id="customers">
                                    <tr>
                                        <td className="font-weight-bold detailstable">E-Mail</td>
                                        <td>{data.email}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold detailstable">Total Games</td>
                                        <td>{data.playerOverallDetails.noOfGames}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold detailstable">Rooms Joined</td>
                                        <td>{data.playerOverallDetails.noOfRooms}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold detailstable">Money Gain</td>
                                        <td>{data.playerOverallDetails.totalMoneyGained}</td>
                                    </tr>
                                </table>
                            </div>
                            <br></br>
                            {roomWiseDetails.length > 0 && <div classname="d-flex flex-column">
                                <div className="font-weight-bold center-align-text">Room-History</div>
                                    {roomWiseDetails.map((room, index) => {
                                        return <div key={index}>
                                            <div className="player-account-details">
                                            {/* <Button className="DetailsBtn" onClick={() => this.onDetailsPress(room._id)}>Details</Button> */}
                                            </div>
                                            <table id="customers"  onClick={() => this.onDetailsPress(room._id)}>
                                                <tr>
                                                    <td className="font-weight-bold detailstable">Room</td>
                                                    <td>{room.roomName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold detailstable">Time</td>
                                                    <td>{room.createdAt}</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold detailstable">No Of Games Played</td>
                                                    <td>{room.gamesPlayed}</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold detailstable">Amount Gained</td>
                                                    <td>{room.totalAmountGained}</td>
                                                </tr>
                                            </table>
                                            <br></br>
                                        </div>
                                    })}
                            </div>}
                            {roomWiseDetails.length === 0 && <div className="font-weight-bold">JOIN A ROOM</div>}
                    </div>
                }
                </div>
                <RoomDetailsModal
                    roomData={this.state.roomData}
                    show={this.state.showModal}
                    apiRoomFetched={this.state.apiRoomFetched}
                    onHide={() => this.setState({showModal: false, roomData: {}, apiRoomFetched: false})}
                    myEmail={this.state.myEmail}
                    whoPaysWhoData={this.state.whoPaysWhoData}
                />
            </div>
        );
    }
}

export default withRouter(PlayerAccount);

