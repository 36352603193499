import queryString from "query-string/index";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import ThreeFiveSevenIcon from '../../assets/GameIcons/357.png';
import ThreeBrentSevenIcon from '../../assets/GameIcons/3B7.png';
import FiveCardDrawIcon from '../../assets/GameIcons/5CardDraw.png';
import s727Icon from '../../assets/GameIcons/727.png';
import SevenCardStudIcon from '../../assets/GameIcons/7CS.png';
import AnacondaIcon from '../../assets/GameIcons/Anaconda.png';
import BaseballIcon from '../../assets/GameIcons/Baseball.png';
import CincinnatiIcon from '../../assets/GameIcons/Cincinnati.png';
import DoyaIcon from '../../assets/GameIcons/DO-YA.png';
import HomeRunIcon from '../../assets/GameIcons/HomeRun.png';
import IroncrossIcon from '../../assets/GameIcons/Ironcross.png';
import LoChicagoIcon from '../../assets/GameIcons/LoChicago.png';
import TexasHoldemIcon from '../../assets/GameIcons/TexasHoldem.png';
import CloseIcon from '../../assets/Iconclosecircle.svg';
import Cards from '../Cards/Cards';
import './Rules.css';

class AnacondaRules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentSelectedGame: '',
            allGameRules: [
                {value: "Poker-Rules", label: "Poker-Rules"},
                {value: "Anaconda", label: "Anaconda"},
                {value: "357", label: "357"},
                {value: "3-Brent-7", label: "3-Brent-7"},
                {value: "Texas-Holdem", label: "Texas-Holdem"},
                {value: "7-Card-Stud", label: "7-Card-Stud"},
                {value: "5-Card-Draw", label: "5-Card-Draw"},
                {value: "Cincinnati", label: "Cincinnati"},
                {value: "Omaha", label: "Omaha"},
                {value: "Diablo", label: "Diablo"},
                {value: "Lo-Chicago", label: "Lo-Chicago"},
                {value: "High-Chicago", label: "High-Chicago"},
                {value: "DoYa", label: "DoYa"},
                {value: "727", label: "727"},
                {value: "Baseball", label: "Baseball"},
            ],
            selectedGameRule: {}
        }
    }

    componentDidMount = () => {
        let { game } = queryString.parse(this.props.location.search);
        this.setState({
            currentSelectedGame: game,
        })
    }

    changeGameRules = (game) => {   
        this.setState({
            currentSelectedGame: game
        })
    }

    handleGameRuleChange = (selectedGameRule) => {
        this.setState({
            currentSelectedGame: selectedGameRule.value
        })
    }

    render() {

        const { currentSelectedGame } = this.state;

        return (
        <div className="rulespage">
            <div className="">
                <div className="">
                    <nav>
                        <Select placeholder={currentSelectedGame}
                            value={this.state.selectedGameRule.name}
                            onChange={this.handleGameRuleChange}
                            options={this.state.allGameRules}
                        />
                    </nav>
                </div>
            </div>
            <div className="">
                <Link to={`/`}>
                    <div className="rulescloseBtn">
                        <img className="backBtn" title="Home" src={CloseIcon} alt="Close"/>
                    </div>
                </Link>
                {currentSelectedGame === 'Poker-Rules' && <div><header id="showcase">
                    <h1>POKER RULES</h1>
                </header>
                <div id="content" className="rulescontainer">
                    <br/>
                    Poker is a card game played with at least two players with no real bound on the number (although mostly  when playing with one deck, 7 or 8 players is the max for many games, except for Texas Hold Em, when you can have up to 20, although 10 or 12 is often used as a table maximum for speed.   Each variant (described below) leaves the player with some number of cards, usually 5 or more, and nearly always, the player makes their best five card hand. Most games have betting rounds, often with limits.  
                    <br/><br/>
                    Betting Round Description: Most poker games have an ante, which is an amount that every player must put in and this starts off the pot. A later better round usually starts to the left of the dealer, but in some games the first bet will go to the last player who raised, or to the player with the best cards showing. If no one has bet, in many games, one can check (or knock). This is essentially a pass. Another option is to fold (which is to quit the hand and put your cards in the discards, face down). Another is to make a bet (typically at least the ante or a multiple of it). Once a player has bet, the next player must match it (called a call or calling the bet), fold, or can raise the bet. Many games have a maximum number of raises in a betting round (often three). The betting continues until everyone has matched or folded. Rules for dealing with cases when a player runs out of money during a hand are described here. 
                    <br/><br/>
                    At the final betting round for a given game, the pot is awarded to the player with the best hand. In the case of a tie, split the pot. If there is an odd # in the pot, the extra chip stays in for the next game. In the case of a high-low split game, the extra chip goes to the player winning high.  
                    <br/><br/>
                    <h3>1. Five Of A Kind</h3>
                    This is the highest possible hand and can occur only in games where at least one card is wild (e.g. the joker or the two one-eyed jacks).
                    <br/><br/>
                    <h3>2. Straight Flush</h3>
                    This is the highest possible hand when only the standard pack is used, and there are no wild cards. A straight flush consists of five cards of the same suit in sequence, such as 9, 8, 7, 6, 5 of hearts. The highest-ranking straight flush is the A, K, Q, J, and 10 of one suit, and this combination has a special name: a royal flush or a royal straight flush. The odds on being dealt this hand are 1 in almost 650,000.
                    <div className="cardsrow">
                        <Cards suit={'s'} value={'A'}/>
                        <Cards suit={'s'} value={'K'}/>
                        <Cards suit={'s'} value={'Q'}/>
                        <Cards suit={'s'} value={'J'}/>
                        <Cards suit={'s'} value={'T'}/>
                    </div>
                    <br/>
                    <h3>3. Four of a Kind</h3>
                    This is the next highest hand, and it ranks just below a straight flush. An example is four aces or four 3s. It does not matter what the fifth, unmatched card is.
                    <div className="cardsrow">
                        <Cards suit={'d'} value={'A'}/>
                        <Cards suit={'s'} value={'A'}/>
                        <Cards suit={'h'} value={'A'}/>
                        <Cards suit={'c'} value={'A'}/>
                        <Cards suit={'d'} value={'5'}/>
                    </div>
                    <br/>
                    <h3>4. Full House</h3>
                    This colorful hand is made up of three cards of one rank and two cards of another rank, such as three 8s and two 4s (described as 8s full of 4s), or three aces and two 6s (aces full of 6s).
                    <div className="cardsrow">
                        <Cards suit={'d'} value={'A'}/>
                        <Cards suit={'s'} value={'A'}/>
                        <Cards suit={'c'} value={'A'}/>
                        <Cards suit={'s'} value={'6'}/>
                        <Cards suit={'h'} value={'6'}/>
                    </div>
                    <br/>
                    <h3>5. Flush</h3>
                    Five cards, all of the same suit, but not all in sequence, is a flush. An example is Q, 10, 7, 6, and 2 of clubs, which would be called a queen high flush.
                    <div className="cardsrow">
                        <Cards suit={'c'} value={'Q'}/>
                        <Cards suit={'c'} value={'T'}/>
                        <Cards suit={'c'} value={'7'}/>
                        <Cards suit={'c'} value={'6'}/>
                        <Cards suit={'c'} value={'2'}/>
                    </div>
                    <br/>
                    <h3>6. Straight</h3>
                    Five cards in sequence, but not all from the same suit is called a straight. An example is 9♥, 8♣, 7♠, 6♦, 5♥.
                    <div className="cardsrow">
                        <Cards suit={'h'} value={'9'}/>
                        <Cards suit={'c'} value={'8'}/>
                        <Cards suit={'s'} value={'7'}/>
                        <Cards suit={'d'} value={'6'}/>
                        <Cards suit={'h'} value={'5'}/>
                    </div>
                    <br/>
                    <h3>7. Three of a Kind</h3>
                    This combination contains three cards of the same rank, and the other two cards each of a different rank, such as three queens, a seven, and a two.
                    <div className="cardsrow">
                        <Cards suit={'s'} value={'Q'}/>
                        <Cards suit={'d'} value={'Q'}/>
                        <Cards suit={'h'} value={'Q'}/>
                        <Cards suit={'c'} value={'7'}/>
                        <Cards suit={'h'} value={'2'}/>
                    </div>
                    <br/> 
                    <h3>8. Two Pairs</h3>
                    This hand contains a pair of one rank and another pair of a different rank, plus any fifth card of a different rank, such as K, K, 7, 7, 4.
                    <div className="cardsrow">
                        <Cards suit={'d'} value={'K'}/>
                        <Cards suit={'h'} value={'K'}/>
                        <Cards suit={'s'} value={'7'}/>
                        <Cards suit={'h'} value={'7'}/>
                        <Cards suit={'c'} value={'4'}/>
                    </div>
                    <br/>
                    <h3>9. One Pair</h3>
                    This frequent combination contains just one pair with the other three cards being of different rank. An example is J, J, K, 5, 3.
                    <div className="cardsrow">
                        <Cards suit={'c'} value={'J'}/>
                        <Cards suit={'s'} value={'J'}/>
                        <Cards suit={'d'} value={'K'}/>
                        <Cards suit={'s'} value={'5'}/>
                        <Cards suit={'d'} value={'3'}/>
                    </div>
                    <br/>
                    <h3>10. High Card</h3>
                    This very common hand contains "nothing." None of the five cards pair up, nor are all five cards of the same suit or consecutive in rank. When more than one player has no pair, the hands are rated by the highest card each hand contains, so that an ace-high hand beats a king-high hand, and so on.
                    <div className="cardsrow">
                        <Cards suit={'h'} value={'A'}/>
                        <Cards suit={'c'} value={'7'}/>
                        <Cards suit={'d'} value={'K'}/>
                        <Cards suit={'h'} value={'3'}/>
                        <Cards suit={'s'} value={'8'}/>
                    </div>
                    <br/><br/>
                </div></div>}
                {currentSelectedGame === 'Anaconda' && <div><header id="showcase">
                    <h1>ANACONDA</h1>
                </header>
                <img id="rulesImg" src={AnacondaIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    Each player antes and get 7 down cards -&gt; Players bet -&gt; Each player passes 3 to the left -&gt; Players bet -&gt; Each player passes 2 to the left -&gt; Players bet -&gt; Each player passes 1 to the left -&gt; Final round of betting -&gt; High-low-both declaration.
                    <br/><br/>
                    Min Players: 2, Max Players: 7
                    <br/><br/>
                    <h3>Game Options</h3>
                    <br/>
                    <ul>
                        <li><h4>Mutually exclusive options:</h4>
                        <ul>
                            <li>No-roll (this is our default) and implies betting before passing. With a roll, this is a pre-amble to a Cincinnati like game. Sometimes called stutter-step Anaconda.</li>
                            <li>Pass-first anaconda: pass 3, pass 2, pass 1 before any betting, then discard 2, roll 1, then start betting (this is like a different pass structure for Cincinnati). Cards talk (no high-low-both declaration).</li>    
                        </ul></li>
                        <li><h4>Other Options:</h4>
                        <ul>
                            <li>Pass in different patterns (to the right, across, chosen by dice, etc)</li>
                            <li>Only pass once (3 cards)</li>
                            <li>Avocado -- pass 3 to the left, 3 to the right. Then 2 to the left, then 2 to the right. Then 1 left, 1 right. Then roll like pass-first first anaconda.</li>
                        </ul>
                        </li>
                    </ul>
                </div></div>}
                {currentSelectedGame === '357' && <div><header id="showcase">
                    <h1>357</h1>
                </header>
                <img id="rulesImg" src={ThreeFiveSevenIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                <br/>
                Long game similar to guts that goes on until a player achieves [3] legs. Players ante. In each round, each player is dealt 3 cards, then required to participate in hold/drop betting round. All players who hold compare, and each player pays the pot to everyone to beats them. Each player is then dealt 2 more cards. Hold/drop betting round. Each player is then dealt 2 more cards, and a final hold/drop betting round is held. On 3 cards, 357 is the top hand, and flushes and straights do not count. Typical rules: if you would otherwise get a leg but a rule prevents it, you get the ante from each other player. No legs on the first round, and no more than one leg per round. If everyone drops, it’s a wimp rule, and everyone re-antes. Everyone re-antes at the end of a round.  
                <br/><br/>
                Min Players: 2, Max players: 7<br/><br/>
                <h3>Game Options</h3>
                <br/>
                <ul>
                    <li>Number of legs required [3]</li>
                    <li>Ante [5]</li>
                    <li>Wimp rule?</li>
                    <li>First round no legs</li>
                    <li>Round-leg-limitation</li>
                    <li>Middle-hand-low — named variante, 3-Brent-7</li>
                </ul>
                </div></div>}
                {currentSelectedGame === '3-Brent-7' && <div><header id="showcase">
                    <h1>3-BRENT-7</h1>
                </header>
                <img id="rulesImg" src={ThreeBrentSevenIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                <br/>
                Long game similar to guts that goes on until a player achieves [3] legs. Players ante. In each round, each player is dealt 3 cards, then required to participate in hold/drop betting round. All players who hold compare, and each player pays the pot to everyone to beats them. Each player is then dealt 2 more cards. Hold/drop betting round. Each player is then dealt 2 more cards, and a final hold/drop betting round is held. On 3 cards, 357 is the top hand, and flushes and straights do not count. Typical rules: if you would otherwise get a leg but a rule prevents it, you get the ante from each other player. No legs on the first round, and no more than one leg per round. If everyone drops, it’s a wimp rule, and everyone re-antes. Everyone re-antes at the end of a round.  
                <br/><br/>
                Same as 357, but in the middle hand, the low hand wins.
                <br/><br/>
                Min Players: 2, Max players: 7<br/><br/>
                </div></div>}
                {currentSelectedGame === 'Texas-Holdem' && <div><header id="showcase">
                    <h1>TEXAS-HOLDEM</h1>
                </header>
                <img id="rulesImg" src={TexasHoldemIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    In hold'em, players receive two down cards as their personal hand (holecards), after which there is a round of betting. Three board cards are turned simultaneously (called the flop) and another round of betting occurs. The next two board cards are turned one at a time, with a round of betting after each card. The board cards are community cards, and a player can use any five-card combination from among the board and personal cards. A player can even use all of the board cards and no personal cards to form a hand ("play the board"). A dealer button is used. The usual structure is to use two blinds (small and big, with small half the big in value). 
                    <br/><br/>
                    Min Players: 2, Max Players: 7
                </div></div>}
                {currentSelectedGame === 'Omaha' && <div><header id="showcase">
                    <h1>Omaha</h1>
                </header>
                <img id="rulesImg" src={TexasHoldemIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    This is like Texas Hold Em, but you get 4 cards. However, you can only use two cards from your hand. Not 4, not 3. 2, and you must use 2. Omaha-8 is omaha high-low, with 8 or better as the high card for low. By default, straights and flushes do not count in Omaha-8, but this is an option.  
                    <br/><br/>
                    Min Players: 2, Max Players: 7
                </div></div>}
                {currentSelectedGame === '7-Card-Stud' && <div><header id="showcase">
                    <h1>7-CARD-STUD</h1>
                </header>
                <img id="rulesImg" src={SevenCardStudIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    Each player antes, and then gets 2 cards down (only viewable by them) and one up. Betting round. Another up card. Betting round. Another up card. Betting round. Another up card. Betting round. Last card is “down” (only viewable by the player). Final betting round. High hand wins. <br/>                        
                    <br/>
                    Min players: 2, Max Players: 7<br/><br/>
                    <h3>Game Options</h3>
                    <br/>
                    <ul>
                        <li><h4>Standard Variants:</h4>
                        <ul>
                            <li>Wild card options, none by default. </li>
                            <li>Lowball (only the low hand wins) or High-low</li>    
                        </ul></li>
                        <li><h4>Named Variants:</h4>
                        <ul>
                            <li>Low and High Chicago (described below as standalone games)</li>
                            <li>Follow the Queen</li>
                            <li>Countdown (the wild card is equal to the number of remaining players)</li>
                        </ul>
                        </li>
                    </ul>
                </div></div>}
                {currentSelectedGame === '5-Card-Draw' && <div><header id="showcase">
                    <h1>5-CARD-DRAW</h1>
                </header>
                <img id="rulesImg" src={FiveCardDrawIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    Each player antes, and then gets 5 cards. There is a betting round, where each player can only initiate a bet if they have a minimum of [option, default JJ or better]. Then each player can trade in  3 cards (or 4 if and only if the remaining card is an ace). There is another betting round, then the called players must show. High hand wins. Note, if ‘round’ is selected, each player deals this once. 
                    <br/><br/>
                    Min Players: 2, Max Players: 7
                </div></div>}
                {currentSelectedGame === 'Diablo' && <div><header id="showcase">
                    <h1>Diablo</h1>
                </header>
                <img id="rulesImg" src={FiveCardDrawIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    The opener (first person to bet) has to win the pot or match it. If no one opens, everyone still in folds, reshuffle, deal moves to left, re-ante. If the opener doesn’t win the pot, the opener has to replace the pot after the winner takes it. Option: max pot to replace (eg. $5). Note: any game that has rules where folding matters means that one can only fold if they owe the pot money.
                    <br/><br/>
                    Min Players: 2, Max Players: 7
                </div></div>}
                {currentSelectedGame === 'Cincinnati' && <div><header id="showcase">
                    <h1>CINCINNATI</h1>
                </header>
                <img id="rulesImg" src={CincinnatiIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    Each player antes and gets 7 down cards. Immediately pass 2 to the left, 1 to the right. Betting round. Choose 5 cards and reveal order, discarding the other 2. Show first card. Betting round. Repeat until all cards shown, then high/low determined with cards talking.<br/><br/>
                    Min Players: 3, Max Player s: 7
                </div></div>}
                {currentSelectedGame === '5CardDrawRound' && <div><header id="showcase">
                    <h1>5-CARD-DRAW-ROUND</h1>
                </header>
                <img id="rulesImg" src={FiveCardDrawIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                <br/>
                    2 Betting Rounds, Minimum Players: 2, Maximum Players: 7
                    <br/><br/>
                    Rules(Ante amount, max raise amount, max no. of raises per round) of the game are set either by the person who creates the room or by the dealer when starting the game.
                    <br/><br/>
                    Each player has to match the max bet in a betting round to stay in the game.
                    <br/><br/>
                    Each player antes, and then gets 5 cards -&gt; There is a betting round, where each player can only initiate a bet if they have a minimum of [option, default JJ or better]. 
                    <br/><br/>
                    Then each player can trade in  3 cards (or 4 if and only if the remaining card is an ace) -&gt; There is another betting round, then the called players must show.
                    <br/><br/>
                    Winner will be decided on the basis of best HIGH hand. Another game of Texas-Holdem will start with a new dealer until each players has dealt once. 
                </div></div>}
                {currentSelectedGame === 'TexasHoldemRound' && <div><header id="showcase">
                    <h1>TEXAS-HOLDEM-ROUND</h1>
                </header>
                <img id="rulesImg" src={TexasHoldemIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                <br/>
                    6 Betting Rounds, Minimum Players: 2, Maximum Players: 7
                    <br/><br/>
                    Rules(Blind amount, max raise amount, max no. of raises per round) of the game are set either by the person who creates the room or by the dealer when starting the game.
                    <br/><br/>
                    Each player has to match the max bet in a betting round to stay in the game.
                    <br/><br/>
                    A dealer button is used. The usual structure is to use two blinds (small:left-of-dealer and big:2nd-left-of-dealer, with small half the big in value).
                    <br/><br/>
                    In hold'em, players receive two down cards as their personal hand (holecards), after which there is a round of betting.
                    <br/><br/> 
                    Three board cards are turned simultaneously (called the flop) and another round of betting occurs -&gt; The next two board cards are turned one at a time, with a round of betting after each card -&gt;
                    <br/><br/> 
                    The board cards are community cards, and a player can use any five-card combination from among the board and personal cards. A player can even use all of the board cards and no personal cards to form a hand ("play the board").
                    <br/><br/>
                    Winner will be decided on the basis of best HIGH hand. Another game of Texas-Holdem will start with a new dealer until each players has dealt once.
                </div></div>}
                {currentSelectedGame === 'HomeRun' && <div><header id="showcase">
                    <h1>HOMERUN</h1>
                </header>
                <img id="rulesImg" src={HomeRunIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    Each of four players receives a 13-card hand from a standard 52-card deck. Each player then has to divide their cards into three poker hands (known as "setting"): two containing five cards each (known as "the middle" and "the back"), and one containing three cards ("the front"); the back must be the highest-ranking hand, and the front, the lowest-ranking hand (note that straights and flushes do not count in the three-card hand). The back hand is placed face down on the table in front of the player, then the middle hand is placed face down in front of the back hand, and the front hand is placed face down in front of the middle hand.
                    <br/><br/>
                    The game has a unit amount (often the standard ante size) and this controls all payouts.  Each player reveals the 3-card hand (front), then the middle, then the “back”.   Each pair of players can be compared; most typically a player will beat the other on 2 out of three hands; this results in the losing player paying the winner 1 unit.   If a player beats you on all three hands, the winning player gets a hit; they get paid 6 units.  If a player hits all other players, they get paid 12 units by each other player.
                    <br/><br/>
                    Bonus payments:<br/>
                    3 of a kind in the front hand = 3 unit bonus<br/>
                    4 of a kind in the middle or back hand = 4 unit bonus<br/>
                    Straight flush in the middle or back hand = 5 unit bonus
                    <br/>
                    No pairs = the Dragon = 24 units from all players
                </div></div>}
                {currentSelectedGame === 'Lo-Chicago' && <div><header id="showcase">
                    <h1>LO-CHICAGO</h1>
                </header>
                <img id="rulesImg" src={LoChicagoIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    Each player antes, and then gets 2 cards down (only viewable by them) and one up. Betting round. Another up card. Betting round. Another up card. Betting round. Another up card. Betting round. Last card is “down” (only viewable by the player). Final betting round. Low spade down (in the hole) shares the pot with the high-hand. Ace counts as the lowest. <br/>
                    <br/>
                    Min players: 2, Max Players: 7<br/><br/>
                </div></div>}
                {currentSelectedGame === 'High-Chicago' && <div><header id="showcase">
                    <h1>HIGH-CHICAGO</h1>
                </header>
                <img id="rulesImg" src={LoChicagoIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    Each player antes, and then gets 2 cards down (only viewable by them) and one up. Betting round. Another up card. Betting round. Another up card. Betting round. Another up card. Betting round. Last card is “down” (only viewable by the player). Final betting round. High spade down (in the hole) shares the pot with the high-hand. <br/>
                    <br/>
                    Min players: 2, Max Players: 7<br/><br/>
                </div></div>}
                {currentSelectedGame === 'DoYa' && <div><header id="showcase">
                    <h1>Do-Ya</h1>
                </header>
                <img id="rulesImg" src={DoyaIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    Each player starts with a down card, as above, and it’s wild for that player, as well as any others of its denomination.   Starting to the left of the dealer, each player is dealt an upcard, and that player may take his first card or decline it, in which case another upcard is dealt.   Again he may take or decline it.  If he declines, he gets the next card that he must take.   The next player then can choose between the two cards or take an unknown third.   If only one is showing (or none, they can refuse and see a new card until they’ve seen two and must choose one or take the next card).   This continues until everyone has an upcard, then a betting round.   This process continues until everyone has 5 cards. High Hand wins<br/>
                    <br/>
                    Min players: 2, Max Players: 7<br/><br/>
                </div></div>}
                {currentSelectedGame === '727' && <div><header id="showcase">
                    <h1>727</h1>
                </header>
                <img id="rulesImg" src={s727Icon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    Each player antes, and is dealt a card face down and one face up. There’s a round of betting. Then, each player is offered the chance to take another card. Then another round of betting, until no one takes a card. The goal is to get cards totalling as close to 7 or as close to 27 as possible. Cards count equal to their value. Face cards are worth 1/2 point. Aces are worth 1 or 11.  <br/>
                    <br/>
                    Min players: 2, Max Players: 7<br/><br/>
                </div></div>}
                {currentSelectedGame === 'Baseball' && <div><header id="showcase">
                    <h1>Baseball</h1>
                </header>
                <img id="rulesImg" src={BaseballIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    Once each player has received their cards they can look at them. The first player to the left of the dealer will open the betting round. Once the betting has completed, the same player will lay down their first card (they can choose).  The object of the game is to lay down your cards until you’re able to beat the last hand played or you’re out.
                    <br/><br/>
                    The second player would need to select cards from his hole cards to turn over that would beat out the first player. This would be followed up with another round of betting.
                    <br/><br/>
                    This pattern will continue until each of the players has had the opportunity to flip over their cards with a betting round in between.
                    The player at the end of the game with the best five card poker hand wins.
                    <br/><br/>
                    In regular Baseball, players have the advantage of looking at their cards. You can use this as a strategy to select the cards you need to continue in the game without giving away your hand should you hold something fairly strongl. Typically, if a 4 is flipped, the player can pay [5x the ante] to get an extra card. 
                    <br/><br/>
                    Blind Baseball is played exactly the same, except one the cards have been dealt to the players they are not allowed to look at them. Players will randomly choose one of the face down cards to turn over. 
                    <br/><br/>
                    Min Players: 2, Max Players: 5<br/><br/>
                </div></div>}
                {currentSelectedGame === 'Ironcross' && <div><header id="showcase">
                    <h1>Iron-Cross</h1>
                </header>
                <img id="rulesImg" src={IroncrossIcon} alt="Anaconda"/>
                <div id="content" className="rulescontainer">
                    <br/>
                    In Ironcross, players receive four down cards as their personal hand, after which there is a round of betting. Five cards are turned as a cross one at a time, with a round of betting after each card. The board cards are community cards, and a player For a player’s final hand, they may use 2 or more of their dealt cards. They may also use cards from the cross layout. However, if using cards from the layout you can only use cards from the horizontal line (4th,5th and 2nd card dealt) or the vertical line (1st,5th, and 3rd card dealt), but not both. A dealer button is used. Pot is split between High-hand Winner and Low-hand winner(if any).
                    <br/><br/>
                    Min Players: 2, Max Players: 7<br/><br/>
                </div></div>}
            </div>
        </div>
        );
    }
}

export default withRouter(AnacondaRules);

