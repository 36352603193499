import React, { Component } from 'react';
import { Link } from "react-router-dom"; 
import firebase from "firebase";
import {getCustomClaimRole} from "../services/firebase-service"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {sendToCustomerPortal, getPremiumPlan, prices} from '../services/stripe'
import {Button, Col, Modal, Row} from "react-bootstrap";
import '../styles/Home.css';
import HomeImg from '../assets/BG@2x.png';
import HomeLogo from '../assets/Logo.svg';
import {auth} from "firebaseui"


// firebase.initializeApp({
//     apiKey: "AIzaSyBQpbFc5Jk5ekcWey9Z87LmuE3DQk1g000",
//     authDomain:"cards4u-53be2.firebaseapp.com"
// })

class Home extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isSignedIn: false,
            playerAccount: {},
            showAccount: false,
            isPremiumMember : false
        }
    }

    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            auth.AnonymousAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult: async function(authResult, redirectUrl) {
                const user = authResult.user;
                const credential = authResult.credential;
                const isNewUser = authResult.additionalUserInfo.isNewUser;
                const providerId = authResult.additionalUserInfo.providerId;
                const operationType = authResult.operationType;

                // If a user is new we want to insert it in firestore.
                const db = firebase.firestore()
                const insertNewUser = async function(user){
                    await db.collection('customers').doc(user.uid).set({
                        email : user.email
                    })
                }
                if(isNewUser){
                    await insertNewUser(user)
                }
                else{
                    const doc = await db.collection('customers').doc(user.uid).get()
                    if(! doc.exists){ await insertNewUser(user) }
                }
                return true;
              },
            
            
            // signInSuccess: () => false
        }
    }

    componentDidMount = () => {

        let response;

        // firebase.auth().onAuthStateChanged(user =>{
        //     this.setState({isSignedIn: !!user})
        //     console.log('USER DETAILS', user);
        // })

        firebase.auth().onAuthStateChanged( async function(user) {
            if (user) {
                const role = await getCustomClaimRole().catch(err => console.log("getCustomClaimRole", err))
                this.setState({
                    isSignedIn: true,
                    isPremiumMember : (role==="premium") ? true : false
                })
            } else {
                this.setState({isSignedIn: false})
                console.log('Firebase Signed Out user');
            }
        }.bind(this));
    }

    getPremiumPlanHandler = async (event) => {
        await getPremiumPlan(event.target.id).catch(err => console.log("Get Premium Plan error", err))
    }

    sendToCustomerPortal = async () => {
        await sendToCustomerPortal().catch(err => console.log("Get Premium Plan error", err))
    }

    render() {
        let {playerAccount, showAccount} = this.state;

        return (
            <div className="App">
                {this.state.isSignedIn ?
                    // (<div>
                    //     <div className="topbar">
                    //         <h1>Welcome to Cards4Us</h1>
                    //         <h2>{firebase.auth().currentUser.displayName}</h2>
                    //         {!firebase.auth().currentUser.displayName && <h2>
                    //             {firebase.auth().currentUser.email}
                    //             </h2>}
                    //         {/*<img
                    //             alt="profile picture"
                    //             src={firebase.auth().currentUser.photoURL}
                    //         />*/}
                    //     </div>
                    //     <div className="joinOuterContainer">
                    //     {/* <img className="homeImg" src={HomeImg} alt="Home"/> */}
                    //         <div className="joinInnerContainer">
                    //             <div className="heading">
                    //                 <h1>Join or Create a Room</h1>
                    //             </div>
                    //             <Link to={`/create?createRoom`}>
                    //                 <button className={'button mt-20'} type="submit">Create</button>
                    //             </Link>
                    //             <Link to={`/join?joinRoom`}>
                    //                 <button className={'button mt-20'} type="submit">Join</button>
                    //             </Link>
                    //             <Link to={`/account?playerAccount`}>
                    //                 <button className={'button mt-20'} type="submit">MY ACCOUNT</button>
                    //             </Link>
                    //             <button onClick={() => firebase.auth().signOut()} className={'button sign-out mt-20'}>Sign Out!</button>
                    //         </div>
                    //     </div>
                    // </div>)
                    (<div>
                        <div>
                        <img className="homeImg" src={HomeImg} alt="Home"/>
                        <img className="homeLogo" src={HomeLogo} alt="HomeLogo"/>
                        <div className="createjoin">
                           <div className="heading">
                                <div className="topinfo">
                                    <h2>{firebase.auth().currentUser.displayName}</h2>
                                    {!firebase.auth().currentUser.displayName && <h2>
                                        {firebase.auth().currentUser.email}
                                    </h2>}
                                    <h2>Join or Create a Room</h2>
                                </div>
                                <br/>
                                </div>
                                <Link to={`/create?createRoom`}>
                                    <button className={'infobutton'} type="submit">CREATE</button>
                                </Link>
                                <Link to={`/join?joinRoom`}>
                                    <button className={'infobutton'} type="submit">JOIN</button>
                                </Link>
                                <Link to={`/account?playerAccount`}>
                                    <button className={'infobutton'} type="submit">MY ACCOUNT</button>
                                </Link>
                                {!this.state.isPremiumMember &&
                                    <Row> 
                                        <button id={prices.perMonth} onClick={this.getPremiumPlanHandler} className={'button sign-out'}>Subscribe : $1/month</button>
                                        <button id={prices.perYear} onClick={this.getPremiumPlanHandler} className={'button sign-out'}>Subscribe : $5/year</button>
                                    </Row>  
                                }
                                {this.state.isPremiumMember &&
                                    <button onClick={this.sendToCustomerPortal} className={'button sign-out'}>Customer Portal</button>
                                }
                                <button onClick={() => firebase.auth().signOut()} className={'button sign-out'}>Sign Out!</button>
                                <Link to={`/privacypolicy`}>
                                    <button className={'infobutton'} type="submit">PRIVACY POLICY</button>
                                </Link>
                                <ins
                                className="adsbygoogle"
                                style={{ display: 'block', height: 100 }}
                                data-ad-client="ca-pub-2473519662920083"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                                ></ins>
                            </div>
                        </div>
                    </div>)
                    :
                    <div>
                        <div>
                        <img className="homeImg" src={HomeImg} alt="Home"/>
                        <img className="homeLogo" src={HomeLogo} alt="HomeLogo"/>
                        <div className="signIn">
                        <StyledFirebaseAuth
                            uiConfig={this.uiConfig}
                            firebaseAuth={firebase.auth()}
                        />
                        <Link to={`/privacypolicy`}>
                                    <button className={'infobutton'} type="submit">PRIVACY POLICY</button>
                        </Link>
                        </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Home;
