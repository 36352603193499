import {trackPromise} from "react-promise-tracker";
import {auth} from './firebase-service';

export const callAPI = async (url, method, body={}) => {
    const token = await auth().currentUser.getIdToken();
    const headers = new Headers({'Content-Type': 'application/json', 'token': token});
    try{
        const options = {headers, method};
        if(method !== 'GET'){
            options.body = JSON.stringify(body);
        }
        let promise = await trackPromise(fetch(url, options));
        let response = await promise.json();
        console.log("response:", response);

        if (response.status === "success") {
            return response;
        } else if (response.status === "error") {
            if(response.errorCode !== 3001) alert(response.message);
        }
    } catch (e) {
        alert(e);
    }
};
