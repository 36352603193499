import React, {useState, useEffect} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {BASE_URL} from "../../utils/constants";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './Game.css';
import './GameTable.css';
import Cards from "../Cards/Cards";

export function HouseRulesModal(props) {

    if(props.showHouseRules) {

        console.log('debug', props.roomHouseRules);
        let roomRules = props.roomHouseRules
        let allGames = [];
        for (const game in roomRules) {
            let body = {
                gameName: game,
                ante: roomRules[game].ante || 0,
                maxRaise: roomRules[game].maxRaiseAllowed || 0,
                minRaise: roomRules[game].minRaiseAllowed || 0,
                maximumNoOfRaisesAllowed: roomRules[game].maximumNoOfRaisesAllowed || 0,
            };
            allGames.push(body);
        }

        console.log('debug array', allGames);

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        Room House-Rules
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                    <div>
                        {BASE_URL}/join/joinroom?name={props.roomName}&code={props.roomCode} &nbsp; &nbsp; 
                        <CopyToClipboard text={`${BASE_URL}/join/joinroom?name=${props.roomName}&code=${props.roomCode}`}>
                            <div className="copytext"><i className="cloneImg fa fa-clone" aria-hidden="true"></i></div>
                        </CopyToClipboard>
                        </div>
                    <br/>
                    <table id="customers" className="font-weight-bold">
                        <tr>
                            <td>Game</td>
                            <td>Max Bet Allowed</td>
                            <td>Min Bet Allowed</td>
                            <td>No of Raises</td>
                            <td>Ante</td>
                        </tr>
                    </table>
                        {allGames.map((game, index) => <table id="customers" className="font-weight-bold">
                            <tr>
                                <td>{game.gameName}</td>
                                {(game.gameName === "357" || game.gameName === "3-Brent-7" || game.gameName === "HomeRun" || game.gameName === "HomeRun Round") && <td>
                                    {game.maxRaise === 0 ? '-' : game.maxRaise}
                                </td>}
                                {!(game.gameName === "357" || game.gameName === "3-Brent-7" || game.gameName === "HomeRun" || game.gameName === "HomeRun Round") && <td>
                                    {game.maxRaise === 0 ? 'No-Limit' : game.maxRaise}
                                </td>}
                                {(game.gameName === "357" || game.gameName === "3-Brent-7" || game.gameName === "HomeRun" || game.gameName === "HomeRun Round") && <td>
                                    {game.minRaise === 0 ? '-' : game.minRaise}
                                </td>}
                                {!(game.gameName === "357" || game.gameName === "3-Brent-7" || game.gameName === "HomeRun" || game.gameName === "HomeRun Round") && <td>
                                    {game.minRaise}
                                </td>}
                                {(game.gameName === "357" || game.gameName === "3-Brent-7" || game.gameName === "HomeRun" || game.gameName === "HomeRun Round") && <td>
                                    {game.maximumNoOfRaisesAllowed === 0 ? '-' : game.maximumNoOfRaisesAllowed}
                                </td>}
                                {!(game.gameName === "357" || game.gameName === "3-Brent-7" || game.gameName === "HomeRun" || game.gameName === "HomeRun Round") && <td>
                                    {game.maximumNoOfRaisesAllowed === 0 ? 'No-Limit' : game.maximumNoOfRaisesAllowed}
                                </td>}
                                <td>{game.ante}</td>
                            </tr>
                        </table>)}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        Room House-Rules
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    <div className="d-flex flex-column">
                        House-Rules
                    </div>
                    <div className="d-flex flex-column">

                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export function CounterApp(props) {

    const [counter, setCounter] = useState(props.time);

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    return (
        <div>
            <div>{counter}</div>
        </div>
    );
}

export function WinnerModal(props) {

    if(props.winners.hasEveryoneFolded === false) {

        let allCards = props.winners.cards || [];
        let everyonesWinningType = props.everyonesWinningType;
        let allPotDetails = props.winners.potsWinners;

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        Game Finished
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <div className="font-weight-bold mb-2">Selected winning types</div>
                        {Object.keys(everyonesWinningType).map(player => {
                            return (
                                <div>
                                    <span className="font-weight-bold">{player}: </span>
                                    <span>{everyonesWinningType[player] === 'Mixed' ? 'Both' : everyonesWinningType[player]}</span>
                                </div>
                            )}
                        )}
                        {allCards.length > 0 && <div className="d-flex flex-column mb-3">
                            <div className="font-weight-bold mb-2">All player cards</div>
                            {allCards.map(player => {
                                let sortedCards = player.cards;
                                return <Row className="mb-2">
                                            <span className="d-flex align-items-center mr-2">{player.clientName}</span>
                                            {sortedCards.map((card, index) => (<div>
                                            <span key={index}><Cards suit={card[card.length - 1]}
                                                                    value={card.slice(0, card.length - 1)}/></span></div>
                                            ))}
                                        </Row>
                                })
                            }
                        </div>}
                        <Row className="mb-2 d-flex">
                            {allPotDetails.map((pot, index) => (
                                <li key={index} className="d-flex flex-column">
                                    <div className="font-weight-bold mb-2">{pot.type}Pot: {pot.amount}</div>
                                    <div>Eligible Players
                                        {pot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                                    </div>
                                    <div>
                                        {pot.winners.High.length > 0 && <div>Winner High
                                                {pot.winners.High.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                            </div>}
                                        {pot.winners.Low.length > 0 && <div>Winner Low
                                                {pot.winners.Low.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                            </div>}
                                        {pot.winners.Mixed.length > 0 && <div>Winner Both
                                                {pot.winners.Mixed.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                            </div>}
                                    </div>
                                </li>
                            ))}
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return(
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        Game Finished
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <div className="font-weight-bold mb-2">Winner</div>
                            {props.winners.winner}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

}

export function PotDetailsModal(props) {

    if(props.betRoundOver===true) {

        let modalTitle = 'Pot Details';
        let mainpot = props.potDetails.mainPot || [];
        let sidepots = props.potDetails.sidePots || [];

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        {modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    <div className="d-flex flex-column">
                        MainPot amount: {mainpot.amount}
                        <div>Contribution: {mainpot.individualContribution}</div>
                        <div>Eligible Players
                                        {mainpot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                        </div>
                    </div>
                    <br/>
                    <div className="d-flex flex-column">
                        {sidepots.map((sidepot, index) => (
                            <div key={index}>
                                SidePot amount: {sidepot.amount}
                                <div>Contribution: {sidepot.individualContribution}</div>
                                <div>Eligible Players
                                        {sidepot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                                </div>
                                <br/>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {
        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        Pot Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    No Pot Details
                </Modal.Body>
            </Modal>
        );
    }
}

export function AccountDetailsModal(props) {

    if(props.accountFlag) {

        let data = props.accountDetails;
        let playersAccountArray = Object.entries(data.data.playersAccount);
        let playersHistory = data.data.history;

        let reverseArray = [];
        for (var i = playersHistory.length - 1; i >= 0; i--) {
            reverseArray.push(playersHistory[i]);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        Player Account History Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <div className="font-weight-bold">Player Account status</div>
                        {playersAccountArray.length > 0 && <div>
                            <table id="customers" className="font-weight-bold detailstable">
                                <tr>
                                    <td>Player</td>
                                    {/* <td>Money Spend</td> */}
                                    <td>Money Gain</td>
                                </tr>
                            </table>
                            {playersAccountArray.map((player, index) =>
                            <table id="customers" key={index}>
                                <tr>
                                    <td>{player[0]}</td>
                                    {/* <td>{player[1].totalMoneySpend}</td> */}
                                    <td>{player[1].totalMoneyGained}</td>
                                </tr>
                                </table>
                            )}
                        </div>}
                    </div>
                    <br/>
                    <div className="d-flex flex-column">
                        <div className="font-weight-bold">Games</div>
                        {reverseArray.map((history, index) => {
                            let stackHistory = Object.entries(history.playersStackHistory);
                            return <div key={index} className="d-flex flex-column">
                                <div>Game {reverseArray.length - index}: {history.gameName}</div>
                                    <table id="customers" className="font-weight-bold detailstable">
                                        <tr>
                                            <td>Player</td>
                                            <td>Buy In</td>
                                            <td>Start Stack</td>
                                            <td>End Stack</td>
                                        </tr>
                                    </table>
                                    {stackHistory.map((player, index) =>
                                    <table id="customers" key={index}>
                                        <tr>
                                            <td>{player[0]}</td>
                                            <td>{player[1].buyIn}</td>
                                            <td>{player[1].startStack}</td>
                                            <td>{player[1].endStack}</td>
                                        </tr>
                                    </table>
                                    )}
                                    <br/>
                                </div>
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        Player Account Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    <div className="d-flex flex-column">
                        Play a game
                    </div>
                    <div className="d-flex flex-column">

                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export function GameDetails357(props) {

    if(props.showModal357) {

        let pot;
        let isLeg;
        let winnerOfRound;
        let whoPaysWho;
        let eligiblePlayers = props.details357.eligiblePlayers;
        let playersSelection = props.details357.playersSelection;
        let finalNames = [];
        if(props.details357.pot) {
            pot = props.details357.pot;
        }
        if(props.details357.isLeg) {
            isLeg = props.details357.isLeg;
        }
        if(props.details357.winnerOfRound) {
            winnerOfRound = props.details357.winnerOfRound;
        }
        if(props.details357.whoPaysWho) {
            whoPaysWho = props.details357.whoPaysWho;
            let sortedNames = Object.keys(whoPaysWho).map(function(key) {
                return [key, whoPaysWho[key].length];
            });
            sortedNames.sort(function(first, second) {
                return second[1] - first[1];
            });
            for(const player of sortedNames) {
                finalNames.push(player[0]);
            }
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        357 GAME DETAILS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        {}
                        <div className="font-weight-bold">{`ELIGIBLE PLAYERS : ${eligiblePlayers.join()}` }</div>
                        <br/>
                        <div>{pot && <div className="font-weight-bold">POT: {pot}</div>}</div>
                        <div>{winnerOfRound && <div className="font-weight-bold">WINNER OF ROUND: {winnerOfRound}</div>}</div>
                        <div>{isLeg && <div className="font-weight-bold">LEG: YES</div>}</div>
                        <div className="font-weight-bold">PLAYERS SELECTIONS</div>
                        {Object.keys(playersSelection).map(player => {
                                return (
                                    <div>
                                        <span className="font-weight-bold">{player}: </span>
                                        <span>{playersSelection[player]}</span>
                                    </div>
                                )
                            }
                        )}
                        <br/>
                        {whoPaysWho && <div className="font-weight-bold">Who Pays Whom</div>}
                        {whoPaysWho && <div>
                        {finalNames.map(player => {
                                if(whoPaysWho[player].length > 0){
                                    return (
                                        <div className="d-flex flex-row">
                                            <span>{`${whoPaysWho[player].join()} pays ${player}`}</span>
                                        </div>
                                    )
                                }
                            }
                        )}
                        </div>}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        357 GAME DETAILS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    <div className="d-flex flex-column">
                        START A GAME
                    </div>
                    <div className="d-flex flex-column">

                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export function AfterRoundDetails357(props) {
    if(props.showModal357) {
        let pot;
        let isLeg;
        let winnerOfRound;
        let whoPaysWho;
        // let eligiblePlayers = props.details357.eligiblePlayers;
        let playersSelection = props.details357.playersSelection;
        if(props.details357.pot) {
            pot = props.details357.pot;
        }
        if(props.details357.isLeg) {
            isLeg = props.details357.isLeg;
        }
        if(props.details357.winnerOfRound) {
            winnerOfRound = props.details357.winnerOfRound;
        }
        if(props.details357.whoPaysWho) {
            whoPaysWho = props.details357.whoPaysWho;
        }
        let cardsDetails;
        const allCards = [];
        if(props.inPlayersCards) {
            cardsDetails = props.inPlayersCards;
            for (const player in cardsDetails) {
                let body = {
                    clientName: player,
                    cards: cardsDetails[player]
                };
                allCards.push(body);
            }
        }
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold d-flex" id="contained-modal-title-vcenter">
                        357 ROUND DETAILS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                    {allCards.length > 0 && <div className="d-flex flex-column mb-3">
                        <div className="font-weight-bold mb-2">All player cards</div>
                        {allCards.map(player => {
                            let sortedCards = player.cards;
                            return <Row className="mb-2">
                                <span className="d-flex align-items-center mr-2">{player.clientName}</span>
                                {sortedCards.map((card, index) => (<div>
                                        <span key={index}><Cards suit={card[card.length - 1]}
                                                                value={card.slice(0, card.length - 1)}/></span></div>
                                ))}
                                {winnerOfRound && winnerOfRound === player.clientName && <span className="d-flex align-items-center mr-2">WINNER</span>}
                            </Row>
                        })
                        }
                        </div>}
                        <div>{isLeg && <div className="font-weight-bold">LEG: YES</div>}</div>
                        <br/>
                        <div className="font-weight-bold">PLAYERS SELECTIONS</div>
                        {Object.keys(playersSelection).map(player => {
                                return (
                                    <div>
                                        <span className="font-weight-bold">{player}: </span>
                                        <span>{playersSelection[player]}</span>
                                    </div>
                                )
                            }
                        )}
                        <br/>
                        <div>{pot && <div className="font-weight-bold">POT: {pot}</div>}</div>
                        <br/>
                        {whoPaysWho && <div className="font-weight-bold">Who Pays Whom</div>}
                        {whoPaysWho && <div>
                            {Object.keys(whoPaysWho).map(player => {
                                    if(whoPaysWho[player].length > 0){
                                        return (
                                            <div className="d-flex flex-row">
                                                <span>{`${whoPaysWho[player].join()} pays ${pot} to ${player}`}: {whoPaysWho[player].length*pot}</span>
                                            </div>
                                        )
                                    }
                                }
                            )}
                        </div>}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {
        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        357 GAME DETAILS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    <div className="d-flex flex-column">
                        START A GAME
                    </div>
                    <div className="d-flex flex-column">
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export function HomeRunModal(props) {

    if(props.showModalHomeRun) {

        let result = props.winners.whoBeatsWho;

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        WINNERS HOME-RUN
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                    <table id="customers" className="font-weight-bold">
                        <tr>
                            <td>Players</td>
                            <td>Front</td>
                            <td>Middle</td>
                            <td>Back</td>
                        </tr>
                    </table>
                        {result.map((game, index) => <table id="customers" className="font-weight-bold">
                            <tr>
                                <td>{game.players[0]}, {game.players[1]}</td>
                                <td>{game.settingsWinner["Front"]}</td>
                                <td>{game.settingsWinner["Middle"]}</td>
                                <td>{game.settingsWinner["Back"]}</td>
                            </tr>
                        </table>)}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        Room House-Rules
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    <div className="d-flex flex-column">
                        House-Rules
                    </div>
                    <div className="d-flex flex-column">

                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export function TexasHoldemModal(props) {

    if(props.showTexasModal && ("hasEveryoneFolded" in props.winningDataTexas)) {

        console.log('Texas Modal data', props.winningDataTexas);

        if(!props.winningDataTexas.hasEveryoneFolded) {

            const allCards = props.playerCardsTexas;

            return (
                <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                            {props.currentGameName} Last Hand
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column">
                            {allCards.length > 0 && <div className="d-flex flex-column mb-3">
                                <div className="font-weight-bold mb-2">All player cards</div>
                                {allCards.map(player => {
                                    let sortedCards = player.cards;
                                    return <Row className="mb-2">
                                        <span className="d-flex align-items-center mr-2">{player.clientName}</span>
                                        {sortedCards.map((card, index) => (<div>
                                                {!props.winningDataTexas.communityCards.includes(card) && <div className="cardborder"><span key={index}><Cards suit={card[card.length - 1]}
                                                                        value={card.slice(0, card.length - 1)}/></span></div>}
                                                {props.winningDataTexas.communityCards.includes(card) && <div><span key={index}><Cards suit={card[card.length - 1]}
                                                                        value={card.slice(0, card.length - 1)}/></span></div>}
                                                </div>
                                        ))}
                                    </Row>
                                })
                                }
                            </div>}           
                            <Row className="mb-2 d-flex">
                                {props.winningDataTexas.potsWinners.map((pot, index) => (
                                    <div>
                                    <li key={index} className="d-flex flex-column mr-2">
                                        <div className="font-weight-bold mb-2">{pot.type}Pot: {pot.amount}</div>
                                        <div>Eligible Players
                                            {pot.eligiblePlayers.map((playerName, index) => (<Col><li key={index}>{playerName}</li></Col>))}
                                        </div>
                                        {props.currentGameName !== "Omaha8 Round" && <div>
                                            {pot.winners.length > 0 && <div>Winner
                                                    {pot.winners.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                                </div>}
                                        </div>}
                                        {props.currentGameName === "Omaha8 Round" && <div>
                                            {pot.winners.High.length > 0 && <div>Winner High
                                                    {pot.winners.High.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                                </div>}
                                            {pot.winners.Low.length > 0 && <div>Winner Low
                                                {pot.winners.Low.map((playerName, index) => (<div><li key={index}>{playerName}</li></div>))}
                                            </div>}
                                        </div>}
                                    </li>
                                    </div>
                                ))}
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        }
        else {
            return (
                <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                            Texas-Holdem Last Hand
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column">
                            Winner: {props.winningDataTexas.winner}
                        </div>
                    </Modal.Body>
                </Modal>
            );
        }

    }
    else {

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader" id="contained-modal-title-vcenter">
                        {props.currentGameName} Last Hand
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="HistoryModalBody">
                    <div className="d-flex flex-column">
                        PLAY A GAME
                    </div>
                    <div className="d-flex flex-column">

                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export function RulesModalAnaconda(props) {

    if(props.rulesAnaconda) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        ANACONDA RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <br/>
                        Each player antes and get 7 down cards -&gt; Players bet -&gt; Each player passes 3 to the left -&gt; Players bet -&gt; Each player passes 2 to the left -&gt; Players bet -&gt; Each player passes 1 to the left -&gt; Final round of betting -&gt; High-low-both declaration.
                        <br/><br/>
                        Min Players: 2, Max Players: 7
                        <br/><br/>

                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}

export function RulesModal357(props) {

    if(props.rules357) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        357 RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="d-flex flex-column">
                    <br/>
                    Long game similar to guts that goes on until a player achieves [3] legs. Players ante. In each round, each player is dealt 3 cards, then required to participate in hold/drop betting round. All players who hold compare, and each player pays the pot to everyone to beats them. Each player is then dealt 2 more cards. Hold/drop betting round. Each player is then dealt 2 more cards, and a final hold/drop betting round is held. On 3 cards, 357 is the top hand, and flushes and straights do not count. Typical rules: if you would otherwise get a leg but a rule prevents it, you get the ante from each other player. No legs on the first round, and no more than one leg per round. If everyone drops, it’s a wimp rule, and everyone re-antes. Everyone re-antes at the end of a round.
                    <br/><br/>
                    Min Players: 2, Max players: 7<br/><br/>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}

export function RulesModal3B7(props) {

    if(props.rules3B7) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        3-BRENT-7 RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="d-flex flex-column">
                    <br/>
                    Long game similar to guts that goes on until a player achieves [3] legs. Players ante. In each round, each player is dealt 3 cards, then required to participate in hold/drop betting round. All players who hold compare, and each player pays the pot to everyone to beats them. Each player is then dealt 2 more cards. Hold/drop betting round. Each player is then dealt 2 more cards, and a final hold/drop betting round is held. On 3 cards, 357 is the top hand, and flushes and straights do not count. Typical rules: if you would otherwise get a leg but a rule prevents it, you get the ante from each other player. No legs on the first round, and no more than one leg per round. If everyone drops, it’s a wimp rule, and everyone re-antes. Everyone re-antes at the end of a round.
                    <br/><br/>
                    Same as 357, but in the middle hand, the low hand wins.
                    <br/><br/>
                    Min Players: 2, Max players: 7<br/><br/>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}

export function RulesModalTexas(props) {

    if(props.rulesTexas) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        {props.currentGameName} RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.currentGameName === "Texas-Holdem" || props.currentGameName === "Texas-Holdem Round") && <div className="d-flex flex-column">
                        <br/>
                        In hold'em, players receive two down cards as their personal hand (holecards), after which there is a round of betting. Three board cards are turned simultaneously (called the flop) and another round of betting occurs. The next two board cards are turned one at a time, with a round of betting after each card. The board cards are community cards, and a player can use any five-card combination from among the board and personal cards. A player can even use all of the board cards and no personal cards to form a hand ("play the board"). A dealer button is used. The usual structure is to use two blinds (small and big, with small half the big in value).
                        <br/><br/>
                        Min Players: 2, Max Players: 7
                    </div>}
                    {(props.currentGameName === "Omaha" || props.currentGameName === "Omaha Round") && <div className="d-flex flex-column">
                        <br/>
                        In Omaha, players receive four down cards as their personal hand (holecards), after which there is a round of betting. Three board cards are turned simultaneously (called the flop) and another round of betting occurs. The next two board cards are turned one at a time, with a round of betting after each card. The board cards are community cards, and a player can use any five-card combination with two from the personal and three from the board cards. A dealer button is used. The usual structure is to use two blinds (small and big, with small half the big in value).
                        <br/><br/>
                        Min Players: 2, Max Players: 7
                    </div>}
                    {(props.currentGameName === "Omaha8" || props.currentGameName === "Omaha8 Round") && <div className="d-flex flex-column">
                        <br/>
                        In Omaha8, players receive four down cards as their personal hand (holecards), after which there is a round of betting. Three board cards are turned simultaneously (called the flop) and another round of betting occurs. The next two board cards are turned one at a time, with a round of betting after each card. The board cards are community cards, and a player can use any five-card combination with two from the personal and three from the board cards. A dealer button is used. The usual structure is to use two blinds (small and big, with small half the big in value). Pot is split between High-hand Winner and Low-hand winner(if any).
                        <br/><br/>
                        Min Players: 2, Max Players: 7
                    </div>}
                    {(props.currentGameName === "Ironcross") && <div className="d-flex flex-column">
                        <br/>
                        In Ironcross, players receive four down cards as their personal hand, after which there is a round of betting. Five cards are turned as a cross one at a time, with a round of betting after each card. The board cards are community cards, and a player For a player’s final hand, they may use 2 or more of their dealt cards. They may also use cards from the cross layout. However, if using cards from the layout you can only use cards from the horizontal line (4th,5th and 2nd card dealt) or the vertical line (1st,5th, and 3rd card dealt), but not both. A dealer button is used. Pot is split between High-hand Winner and Low-hand winner(if any).
                        <br/><br/>
                        Min Players: 2, Max Players: 7
                    </div>}
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}

export function RulesModal7CS(props) {

    if(props.rules7CS) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        {props.currentGameName} RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.currentGameName === "7-Card-Stud" && <div className="d-flex flex-column">
                        <br/>
                        Each player antes, and then gets 2 cards down (only viewable by them) and one up. Betting round. Another up card. Betting round. Another up card. Betting round. Another up card. Betting round. Last card is “down” (only viewable by the player). Final betting round. High hand wins. <br/>
                        <br/>
                        Min players: 2, Max Players: 7<br/><br/>
                    </div>}
                    {props.currentGameName === "Lo Chicago" && <div className="d-flex flex-column">
                        <br/>
                        Each player antes, and then gets 2 cards down (only viewable by them) and one up. Betting round. Another up card. Betting round. Another up card. Betting round. Another up card. Betting round. Last card is “down” (only viewable by the player). Final betting round. Low spade down (in the hole) shares the pot with the high-hand. Ace counts as the lowest. <br/>
                        <br/>
                        Min players: 2, Max Players: 7<br/><br/>
                    </div>}
                    {props.currentGameName === "High Chicago" && <div className="d-flex flex-column">
                        <br/>
                        Each player antes, and then gets 2 cards down (only viewable by them) and one up. Betting round. Another up card. Betting round. Another up card. Betting round. Another up card. Betting round. Last card is “down” (only viewable by the player). Final betting round. High spade down (in the hole) shares the pot with the high-hand. <br/>
                        <br/>
                        Min players: 2, Max Players: 7<br/><br/>
                    </div>}
                    {props.currentGameName === "Baseball 7CS" && <div className="d-flex flex-column">
                        <br/>
                        Each player antes, and then gets 2 cards down (only viewable by them) and one up. Betting round. Another up card. Betting round. Another up card. Betting round. Another up card. Betting round. Last card is “down” (only viewable by the player). 3s and 9s are wild.  If 4s are dealt, you can pay [5x the ante] to get an extra card.<br/>
                        <br/>
                        Min players: 2, Max Players: 7<br/><br/>
                    </div>}
                    {props.currentGameName === "Countdown" && <div className="d-flex flex-column">
                        <br/>
                        Each player antes, and then gets 2 cards down (only viewable by them) and one up. Betting round. Another up card. Betting round. Another up card. Betting round. Another up card. Betting round. Last card is “down” (only viewable by the player). The wild card is equal to the number of remaining players in the game.<br/>
                        <br/>
                        Min players: 2, Max Players: 7<br/><br/>
                    </div>}
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}

export function RulesModal5CS(props) {

    if(props.rules5CS) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        {props.currentGameName} RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.currentGameName === "5-Card-Stud" && <div className="d-flex flex-column">
                        <br/>
                        5CS
                        Each player antes, and then gets 2 cards down (only viewable by them) and one up. Betting round. Another up card. Betting round. Another up card. Betting round. Another up card. Betting round. Last card is “down” (only viewable by the player). Final betting round. High hand wins. <br/>
                        <br/>
                        Min players: 2, Max Players: 7<br/><br/>
                    </div>}
                    {props.currentGameName === "Do Ya" && <div className="d-flex flex-column">
                        <br/>
                        Do Ya
                        Each player starts with a down card, as above, and it’s wild for that player, as well as any others of its denomination.   Starting to the left of the dealer, each player is dealt an upcard, and that player may take his first card or decline it, in which case another upcard is dealt.   Again he may take or decline it.  If he declines, he gets the next card that he must take.   The next player then can choose between the two cards or take an unknown third.   If only one is showing (or none, they can refuse and see a new card until they’ve seen two and must choose one or take the next card).   This continues until everyone has an upcard, then a betting round.   This process continues until everyone has 5 cards<br/>
                        <br/>
                        Min players: 2, Max Players: 7<br/><br/>
                    </div>}
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}

export function RulesModal5CD(props) {

    if(props.rules5CD) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        {props.currentGameName} RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.currentGameName === "5-Card-Draw" || props.currentGameName === "5-Card-Draw Round") && <div className="d-flex flex-column">
                        <br/>
                        Each player antes, and then gets 5 cards. There is a betting round, where each player can only initiate a bet if they have a minimum of [option, default JJ or better]. Then each player can trade in  3 cards (or 4 if and only if the remaining card is an ace). There is another betting round, then the called players must show. High hand wins. Note, if ‘round’ is selected, each player deals this once.
                        <br/><br/>
                        Min Players: 2, Max Players: 7
                    </div>}
                    {(props.currentGameName === "Diablo") && <div className="d-flex flex-column">
                        <br/>
                        Each player antes, and then gets 5 cards. There is a betting round, where each player can only initiate a bet if they have a minimum of [option, default JJ or better]. Then each player can trade in 3 cards. There is another betting round, then the called players must show. The opener (first person to bet) has to win the pot or match it. If no one opens, everyone still in folds, reshuffle, deal moves to left, re-ante. If the opener doesn’t win the pot, the opener has to replace the pot after the winner takes it and another round starts until the opener wins the Pot.
                        <br/><br/>
                        Min Players: 2, Max Players: 7
                    </div>}
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}

export function RulesModalCincinnati(props) {

    if(props.rulesCincinnati) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        CINCINNATI RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <br/>
                        Each player antes and gets 7 down cards. Immediately pass 2 to the left, 1 to the right. Betting round. Choose 5 cards and reveal order, discarding the other 2. Show first card. Betting round. Repeat until all cards shown, then high/low determined with cards talking.<br/><br/>
                        Min Players: 3, Max Player s: 7
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}

export function RulesModalHomerun(props) {

    if(props.rulesHomerun) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        HOME-RUN RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <br/>
                        Each of four players receives a 13-card hand from a standard 52-card deck. Each player then has to divide their cards into three poker hands (known as "setting"): two containing five cards each (known as "the middle" and "the back"), and one containing three cards ("the front"); the back must be the highest-ranking hand, and the front, the lowest-ranking hand (note that straights and flushes do not count in the three-card hand). The back hand is placed face down on the table in front of the player, then the middle hand is placed face down in front of the back hand, and the front hand is placed face down in front of the middle hand.
                        <br/><br/>
                        The game has a unit amount (often the standard ante size) and this controls all payouts.  Each player reveals the 3-card hand (front), then the middle, then the “back”.   Each pair of players can be compared; most typically a player will beat the other on 2 out of three hands; this results in the losing player paying the winner 1 unit.   If a player beats you on all three hands, the winning player gets a hit; they get paid 6 units.  If a player hits all other players, they get paid 12 units by each other player.
                        <br/><br/>
                        Bonus payments:<br/>
                        3 of a kind in the front hand = 3 unit bonus<br/>
                        4 of a kind in the middle or back hand = 4 unit bonus<br/>
                        Straight flush in the middle or back hand = 5 unit bonus
                        <br/>
                        No pairs = the Dragon = 24 units from all players
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}

export function RulesModal727(props) {

    if(props.rules727) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        7-27 RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    <div className="d-flex flex-column">
                        <br/>
                        Each player antes, and is dealt a card face down and one face up. There’s a round of betting. Then, each player is offered the chance to take another card. Then another round of betting, until no one takes a card. The goal is to get cards totalling as close to 7 or as close to 27 as possible. Cards count equal to their value. Face cards are worth 1/2 point. Aces are worth 1 or 11.  
                        <br/><br/>
                        Min Players: 2, Max Players: 7
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}

export function RulesModalBaseball(props) {

    if(props.rulesBaseball) {

        const NewTab = (message) => {
            if(validURL(message)) {
                window.open(message, "_blank"); 
            }
        }
            
        const validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="HistoryModalHeader font-weight-bold" id="contained-modal-title-vcenter">
                        {props.currentGameName} RULES --- <span className="generalrules" onClick={() => NewTab('https://poker4.us/rules?game=PokerRules')}>POKER-RULES</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.currentGameName === "Baseball-9CD"  ) && <div className="d-flex flex-column">
                        <br/>
                        Once each player has received their cards they can look at them. The first player to the left of the dealer will open the betting round. Once the betting has completed, the same player will lay down their first card (they can choose).  The object of the game is to lay down your cards until you’re able to beat the last hand played or you’re out.
                        <br/><br/>
                        The second player would need to select cards from his hole cards to turn over that would beat out the first player. This would be followed up with another round of betting.
                        <br/><br/>
                        This pattern will continue until each of the players has had the opportunity to flip over their cards with a betting round in between.
                        The player at the end of the game with the best five card poker hand wins.
                        <br/><br/>
                        In regular Baseball, players have the advantage of looking at their cards. You can use this as a strategy to select the cards you need to continue in the game without giving away your hand should you hold something fairly strongl. Typically, if a 4 is flipped, the player can pay [5x the ante] to get an extra card. 
                        <br/><br/>
                        Min Players: 2, Max Players: 5
                    </div>}
                    {(props.currentGameName === "Blind Baseball"  ) && <div className="d-flex flex-column">
                        <br/>
                        Once each player has received their cards they can look at them. The first player to the left of the dealer will open the betting round. Once the betting has completed, the same player will lay down their first card (they can choose).  The object of the game is to lay down your cards until you’re able to beat the last hand played or you’re out.
                        <br/><br/>
                        The second player would need to select cards from his hole cards to turn over that would beat out the first player. This would be followed up with another round of betting.
                        <br/><br/>
                        This pattern will continue until each of the players has had the opportunity to flip over their cards with a betting round in between.
                        The player at the end of the game with the best five card poker hand wins.
                        <br/><br/>
                        Blind Baseball is played exactly the same, except one the cards have been dealt to the players they are not allowed to look at them. Players will randomly choose one of the face down cards to turn over. 
                        <br/><br/>
                        Min Players: 2, Max Players: 5
                    </div>}
                </Modal.Body>
            </Modal>
        );
    }
    else {

        return (
            <div></div>
        );
    }
}