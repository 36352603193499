import {getPokerSolverHandSetting} from './utils'
import {Hand, HandLo} from '../PokerSolver/pokersolver'
import {HandTypes} from '../CardsConstants'
import {getHiHandStringDescription} from './HiHandEvaluation'
import {getLoHandStringDescription} from './LoHandEvaluation'

const COMMUNITY_CARDS_DIRECTIONS = {
    UP_DOWN : "upDown",
    ACROSS : "across"
}

const addCommunityCardsToPlayerCards = (playerCards, communityCards, direction) => {
    const cards = [...playerCards]
    const communityCardsIndexesMapping = {
        0 : { "upDown" : [], "across" : [] },
        1 : { "upDown" : [0], "across" : [] },
        2 : { "upDown" : [0], "across" : [1] },
        3 : { "upDown" : [0, 2], "across" : [1] },
        4 : { "upDown" : [0, 2], "across" : [1, 3] },
        5 : { "upDown" : [0, 2, 4], "across" : [1, 3, 4] },
    }

    const communityCardsToAdd = communityCardsIndexesMapping[communityCards.length][direction].map(index=> communityCards[index])
    if(communityCardsToAdd.length === 0){ return playerCards }

    cards.push(...communityCardsToAdd)
    return cards
}

const getBestCardsForPlayerIroncross = (playerCards, communityCards, settings, handType) => {
    /**This function returns the best hand for a player. 
     * It checks [playersCards + up-down communityCards], [playersCards + across communityCards]
     */
    const playerCardsCommunityAcross = addCommunityCardsToPlayerCards(playerCards, communityCards, COMMUNITY_CARDS_DIRECTIONS.ACROSS)
    const playerCardsCommunityUpDown = addCommunityCardsToPlayerCards(playerCards, communityCards, COMMUNITY_CARDS_DIRECTIONS.UP_DOWN)

    const HandClass = (handType === HandTypes.HIGH ) ? Hand : HandLo

    const handPlayerCardsCommunityAcross = HandClass.solve(playerCardsCommunityAcross, getPokerSolverHandSetting(settings), settings)
    const handPlayerCardsCommunityUpDown = HandClass.solve(playerCardsCommunityUpDown, getPokerSolverHandSetting(settings), settings)

    const bestHands = HandClass.winners([handPlayerCardsCommunityAcross, handPlayerCardsCommunityUpDown])

    return bestHands[0].cardsProvided
}

const ironCrossHiHandDescription = (playerCards, communityCards, settings) => {
    const bestHiCards = getBestCardsForPlayerIroncross(playerCards, communityCards, settings, HandTypes.HIGH)
    return getHiHandStringDescription(bestHiCards, settings)
}

const ironCrossLoHandDescription = (playerCards, communityCards, settings) => {
    const bestLoCards = getBestCardsForPlayerIroncross(playerCards, communityCards, settings, HandTypes.LOW)
    return getLoHandStringDescription(bestLoCards, settings)
}

// exports.getBestCardsForPlayerIroncross = getBestCardsForPlayerIroncross

export {
    getBestCardsForPlayerIroncross,
    ironCrossHiHandDescription, 
    ironCrossLoHandDescription
}