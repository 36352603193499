import * as constants from "../utils/constants";
import {callAPI} from "./wrapper";
import {auth} from './firebase-service';

export async function loginUser(email, password){
  return await auth().signInWithEmailAndPassword(email, password);
}

export async function createRoomHandler(body){
    console.log(`Fetching room details ${body.clientName} `);
    return await callAPI(`${constants.API_URL}/rooms`, 'POST', body);
}

export async function joinRoomHandler() {
    return await callAPI(`${constants.API_URL}/rooms`, 'GET');
}

export async function getMembersInfo(room) {
    return await callAPI(`${constants.API_URL}/rooms/${room}`, 'GET');
}

export async function dealCards(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/Anaconda/actions`, 'POST', body);
}

export async function bettingAction(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/Anaconda/actions`, 'POST', body);
}

export async function postStartGame(body, roomName) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/games`, 'POST', body);
}

export async function playerVoteOutRequest(body, roomName) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/startVoting`, 'POST', body);
}

export async function playerVoteOutAction(body, roomName) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/vote`, 'POST', body);
}

export async function getAvailableGames() {
    return await callAPI(`${constants.API_URL}/games`, 'GET');
}

export async function getAllowedGames(roomName) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/allowedGames`, 'GET');
}

export async function getAllPlayerInfo(roomName) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/getPlayersDetails`, 'GET');
}

export async function getBettingHistory(roomName, isAnaconda, is5CD, isTexas, is7CS, is5CS, isCin, is727, isBaseball) {
    console.log("room: ", roomName);
    if (roomName)
        if(isAnaconda)
            return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/Anaconda/bettingHistory`, 'GET');
        if(is5CD)
            return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/5-Card-Draw/bettingHistory`, 'GET');
        if(isTexas)
            return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/Texas-Holdem/bettingHistory`, 'GET');
        if(is7CS)
            return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/7-Card-Stud/bettingHistory`, 'GET');
        if(is5CS)
            return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/5-Card-Stud/bettingHistory`, 'GET');
        if(isCin)
            return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/Cincinnati/bettingHistory`, 'GET');
        if(is727)
            return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/727/bettingHistory`, 'GET');
        if(isBaseball)
            return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/Baseball-9CD/bettingHistory`, 'GET');
    else
        return "Error"
}

export async function getAccountHistory(roomName) {
    console.log('Account details', roomName);
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/playersAccountingHistory`, 'GET');
}

export async function getPlayerRejoinTableDetails(clientName, roomName) {
    console.log('Fetching details for player rejoin');
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/currentState`, 'GET');
}


//357 API'S

export async function dealThreeFiveSevenCards(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/357/actions`, 'POST', body);
}

export async function pauseSelectionRound(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/357/pause`, 'POST', body);
}

export async function getCurrentState(roomName) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/currentState`, 'GET');
}

export async function fetchInPlayersCards(roomName) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/357/showInPlayersCards`, 'GET');
}

//5CD API's

export async function deal5CDCards(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/5-card-draw/actions`, 'POST', body);
}

// Pots API

export async function fetchPotDetails(roomName, isAnaconda, is5CD) {
    if(isAnaconda)
        return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/Anaconda/pots`, 'GET');
    if(is5CD)
        return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/5-Card-Draw/pots`, 'GET');
}

// Player Account APi

export async function fetchPlayerAccount() {
    return await callAPI(`${constants.API_URL}/db/briefAccountDetails`, 'GET');
}

export async function fetchPlayerRoomAccount(roomID) {
    return await callAPI(`${constants.API_URL}/db/rooms/${roomID}/games`, 'GET');
}

// Player Leave

export async function leaveRoom(roomName, body) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/leaveRoom`, 'POST', body);
}

// Texas Holdem's API

export async function dealTexasCards(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/Texas-Holdem/actions`, 'POST', body);
}

//7-Card-Stud API

export async function deal7CSCards(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/7-Card-Stud/actions`, 'POST', body);
}

//5-Card-Stud API

export async function deal5CSCards(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/5-Card-Stud/actions`, 'POST', body);
}

// Cincinnati API

export async function dealCincinnatiCards(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/Cincinnati/actions`, 'POST', body);
}

// 727 API

export async function deal727Cards(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/727/actions`, 'POST', body);
}

// Baseball-9CD API

export async function dealBaseballCards(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/Baseball-9CD/actions`, 'POST', body);
}

// Home-Run API

export async function dealHomeRunCards(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/HomeRun/actions`, 'POST', body);
}

export async function reSubmitSettings(body, roomName){
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/game/HomeRun/reSubmitSettings`, 'POST', body);
}

// Buy-In API

export async function buyIn(body, roomName) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/buyIn`, 'POST', body);
}

// Room-Ready API

export async function roomReady(body, roomName) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/ready`, 'POST', body);
}

// Pay-To-Player APi

export async function payToPlayer(body, roomName) {
    return await callAPI(`${constants.API_URL}/rooms/${roomName}/payToPlayer`, 'POST', body);
}

// Auto-Ready functionality

export async function selectAutoReady(body, roomName) {
    return  await callAPI(`${constants.API_URL}/rooms/${roomName}/changeSetting`, 'POST', body);
}

// Feedback API

export async function feedbackAPI(body) {
    return  await callAPI(`${constants.API_URL}/feedback`, 'POST', body);
}

// Sort-Cards API

export async function sortCardsAPI(body) {
    return  await callAPI(`${constants.API_URL}/utility/sortCards`, 'POST', body);
}

// Kick-Out disconnected players

export async function removeDisconnectedPlayers(roomName) {
    return  await callAPI(`${constants.API_URL}/rooms/${roomName}/kickoutDisconnectedPlayers`, 'POST');
}

export async function orderHomeRunCards(body) {
    return  await callAPI(`${constants.API_URL}/HomeRun/orderCards`, 'POST', body);
}

//Spectator API's

export async function playerToSpectator(roomName) {
    return  await callAPI(`${constants.API_URL}/rooms/${roomName}/spectate`, 'POST');
}

export async function spectatorToPlayer(body, roomName) {
    return  await callAPI(`${constants.API_URL}/rooms/${roomName}/spectator/joinTable`, 'POST', body);
}

export async function spectatorDecision(body, roomName) {
    return  await callAPI(`${constants.API_URL}/rooms/${roomName}/decisionToAllowSpectator`, 'POST', body);
}

export async function kickOutSpectator(body, roomName) {
    return  await callAPI(`${constants.API_URL}/rooms/${roomName}/kickSpectator`, 'POST', body);
}

export async function dealerKickOut(body, roomName) {
    return  await callAPI(`${constants.API_URL}/rooms/${roomName}/dealerKickPlayerOut`, 'POST', body);
}

export async function destroyRoom(roomName) {
    return  await callAPI(`${constants.API_URL}/rooms/${roomName}/destroyRoom`, 'POST');
}

export async function getFriends() {
    return  await callAPI(`${constants.API_URL}/friends`, 'GET');
}

export async function inviteAllFriends(body, roomName) {
    return  await callAPI(`${constants.API_URL}/rooms/${roomName}/inviteFriends`, 'POST', body);
}

export async function setPrefernces(body, method) {
    return  await callAPI(`${constants.API_URL}/db/preference`, method, body);
}

export async function getPrefernces() {
    return  await callAPI(`${constants.API_URL}/db/preference`, 'GET');
}

export async function getSpecificPrefernce(name) {
    return  await callAPI(`${constants.API_URL}/db/preference?names=${name}`, 'GET');
}

export async function createBots(body, roomName) {
    return  await callAPI(`${constants.API_URL}/rooms/${roomName}/addBots`, 'POST', body);
}

export async function getPlayerPaymentInfo(player) {
    return  await callAPI(`${constants.API_URL}/playersPaymentInfo?users[]=${player}`, 'GET');
}
