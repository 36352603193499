import React, { Component } from 'react';

class GoogleAds extends Component {

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        return (
                <ins className='adsbygoogle'
                    style={this.props.style}
                    data-ad-client="ca-pub-1234014517147570"
                    data-ad-slot={this.props.slot}
                >
                </ins>
        );
    }
}

export default GoogleAds;