import React, {Component} from 'react';
import {Link} from "react-router-dom";

class ErrorPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="createroomoutercontainer">
                <div className="errornotfound">
                404 PAGE NOT FOUND
                        <br/><br/>
                        <Link to={'/'} className="homepageBtn">
                            <i class="fa fa-home" aria-hidden="true"></i> <span>HOME</span>
                        </Link>
                </div>
            </div>
        );
    }
}

export default ErrorPage;
