import React, {Component} from "react";
import {withRouter} from "react-router-dom";

import Message from './Message';

// const Messages = ({ messages }) => (
//   <div className="messages">
//     {messages.map((message, i) => <div key={i}><Message message={message}/></div>)}
//   </div>
// );

// export default Messages;


class Messages extends Component {

  constructor(props) {
      super(props);
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }
  
  componentDidMount() {
    this.scrollToBottom();
  }
  
  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {

    let { messages } = this.props;

      return (
        <div>
            <div>
            {messages.map((message, i) => <div key={i}><Message message={message}/></div>)}
            </div>
            <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }}>
            </div>
        </div>
      );
    }
  }

export default withRouter(Messages);
