import React from 'react';

import './Message.css';
import Avatar from "react-avatar";

const Message = ({ message: {messagetext, currentUser, messageUser} }) => {
  let isSentByCurrentUser = false;

  const trimmedName = currentUser.trim().toLowerCase();

  const NewTab = (message) => {
    if(validURL(message)) {
      window.open(message, "_blank"); 
    }
  }

  const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  if(messageUser === trimmedName) {
    isSentByCurrentUser = true;
  }

  return (
    isSentByCurrentUser
      ? (
        <div className="messageContainer justifyEnd">
          <div className="messageBox backgroundBlue">
              <span className="font-weight-bold">{trimmedName}</span>
            <p className="messageText colorWhite">{messagetext}</p>
          </div>
            <div className="d-flex align-items-center ml-2">
                <Avatar name={trimmedName} maxInitials={1} size={30} round={true}
                    textSizeRatio={2}/>
            </div>
        </div>
        )
        : (
          <div className="messageContainer justifyStart">
              <div className="d-flex align-items-center mr-2">
                  <Avatar name={messageUser} maxInitials={1} size={30} round={true}
                          textSizeRatio={2}/>
              </div>
                <div className="messageBox backgroundLight">
                    <span className="font-weight-bold">{messageUser}</span>
                    <p className="messageText colorDark" onClick={() => NewTab(messagetext)} >{messagetext}</p>
                </div>
          </div>
        )
  );
}

export default Message;
