import {Hand} from "pokersolver"
import {evaluateCards, rankCards} from "phe"
import {RANKS_PS} from './modules/CardsConstants'
import {sortHiHand} from './modules/HandEvaluation/HiHandEvaluation'

export const convertArrayOfDictToDict = (array, keyField, valueField) => {
    const dict = {}
    for(const e of array){ dict[e[keyField]] = e[valueField] }
    return dict
}

export const WINNING_TYPES =  {
    HIGH : "High",
    LOW : "Low",
    MIXED : "Mixed",
}

const combinations5choose7 = [
    //Generated from : https://www.dcode.fr/combinations
    [1,2,3,4,5],
    [1,2,3,4,6],
    [1,2,3,4,7],
    [1,2,3,5,6],
    [1,2,3,5,7],
    [1,2,3,6,7],
    [1,2,4,5,6],
    [1,2,4,5,7],
    [1,2,4,6,7],
    [1,2,5,6,7],
    [1,3,4,5,6],
    [1,3,4,5,7],
    [1,3,4,6,7],
    [1,3,5,6,7],
    [1,4,5,6,7],
    [2,3,4,5,6],
    [2,3,4,5,7],
    [2,3,4,6,7],
    [2,3,5,6,7],
    [2,4,5,6,7],
    [3,4,5,6,7],
]

const flipCards = { // To be used for lo hand evaluation
    'A' : '2',
    '2' : '3',
    '3' : '4',
    '4' : '5',
    '5' : '6',
    '6' : '7',
    '7' : '8',
    '8' : '9',
    '9' : 'T',
    'T' : 'J',
    'J' : 'Q',
    'Q' : 'K',
    'K' : 'A'
}

export const HiCardsValues = {
    'A' : 13,
    '2' : 1,
    '3' : 2,
    '4' : 3,
    '5' : 4,
    '6' : 5,
    '7' : 6,
    '8' : 7,
    '9' : 8,
    'T' : 9,
    'J' : 10,
    'Q' : 11,
    'K' : 12,
}

const LoCardsValues = {
    'A' : 1,
    '2' : 2,
    '3' : 3,
    '4' : 4,
    '5' : 5,
    '6' : 6,
    '7' : 7,
    '8' : 8,
    '9' : 9,
    'T' : 10,
    'J' : 11,
    'Q' : 12,
    'K' : 13,
}

/*
Ranks values used by phe package
STRAIGHT_FLUSH  =	0
FOUR_OF_A_KIND  = 	1
FULL_HOUSE      = 	2
FLUSH           =   3
STRAIGHT        = 	4
THREE_OF_A_KIND = 	5
TWO_PAIR        = 	6
ONE_PAIR        = 	7
HIGH_CARD       =	8


Ranks values used by pokersolver package
STRAIGHT_FLUSH  =	9
FOUR_OF_A_KIND  = 	8
FULL_HOUSE      = 	7
FLUSH           = 	6
STRAIGHT        = 	5
THREE_OF_A_KIND = 	4
TWO_PAIR        = 	3
ONE_PAIR        = 	2
HIGH_CARD       =	1
*/

// Ranks are compatible with phe library
const RANKS = {
    STRAIGHT_FLUSH  :   0,
    FOUR_OF_A_KIND  :   1,
    FULL_HOUSE      :   2,
    FLUSH           :   3,
    STRAIGHT        :   4,
    THREE_OF_A_KIND :   5,
    TWO_PAIR        :   6,
    ONE_PAIR        :   7,
    HIGH_CARD       :   8
}

/**
 * This function is used specially to implement setting countStraightAndFlushes.
 * The rank of the cards should be High card, straight, flush ,straight flush.
 * We will treat straight, flush ,straight flush same as High card.
 * 
 * We will assign a number to the hand, higher the number better the lo hand.
 * 
 * Ex : ['As', '3c', '6d', '9d', 'Ts'], Values = LoCardsValues // sorted card
 * 
 * strength = (13-0)*13^4 + (13-3)*13^3 + (13-6)*13^2 + (13-9)*13^1  + (13-10)*13^0 
 * 
 * @param {Array} cards 
 * @param {Object} values : Mapping of cards values : {'A' : 1, 'B' :2, ...}
 */
const assignStrengthToHighCardRankHand = (cards, values) => {
    sortCardsBasedOnValue(cards, values)
    const noOfCards = cards.length
    let strength = 0;
    for (let index = 0; index < noOfCards; index++) {
        const card = cards[index];
        const cardValue = 13 - values[card[0]]
        strength += Math.pow(13, noOfCards - 1 - index)*cardValue
    }
    return strength
}
/**
 * Algorithm : 
 * 
 * Case-1 : 7 Cards.
 * Generate 21 combinations(each combination will have 5 cards). 
 * Find the best combination associated to the minimum rank (using rankCards()) among all these combinations.
 * 
 * Case-2 : 5 Cards.
 * Return card as it and its rank.
 * 
 * Case-3 : 3 Cards.
 * Find rank using Hand.solve(cards).rank. But return the rank value converted to the value phe library uses.
 * 
 * The rank of the hand (Full House, High, Flush, etc) whether for lo or hi remains 
 * the same so we have no problem using the function evalutateRank(cards) which 
 * is used for finding rank of hi cards.
 * 
 * @param {Array} cards : Player's cards. Length of cards allowed : 3,5,7
 * @returns {Array} [minimum rank, best combination of cards]
 */
const getMinimumRank = (cards, loSettings = defaultLoSettings)=> { 
    const {countStraightAndFlushes} = loSettings

    let minimumRank;
    let minimumRankCombinations = []
    if(cards.length === 7){
        // Round One : Find the minimum rank of all 21 combinations
        minimumRank = -1 //because high number === low rank
        minimumRankCombinations = []

        for (let i = 0; i < combinations5choose7.length; i++) {
            const combination = combinations5choose7[i];
            let cardsCombination = combination.map((cardIndex) => {return cards[cardIndex-1]})
            let rank = rankCards(cardsCombination)
            
            /**
             * If the rank is straight, flush, straight flushes and we don't have to consider straight and flushes
             * Then we will change it the rank of HIGH CARD.
            */
            if([RANKS.STRAIGHT,RANKS.STRAIGHT_FLUSH, RANKS.FLUSH].includes(rank) && (! countStraightAndFlushes) ){ rank = RANKS.HIGH_CARD }   

            if(rank > minimumRank){ minimumRank = rank; minimumRankCombinations = [] }
            if(rank === minimumRank){ minimumRankCombinations.push(cardsCombination) }
        }
        return [minimumRank, minimumRankCombinations]

    }else if(cards.length === 5){
        minimumRank = rankCards(cards)
        if([RANKS.STRAIGHT,RANKS.STRAIGHT_FLUSH, RANKS.FLUSH].includes(minimumRank) && (! countStraightAndFlushes) ){ minimumRank = RANKS.HIGH_CARD }   
        return [minimumRank, [cards]]
    }else if(cards.length === 3){
        const rank = 9 - Hand.solve(cards).rank // Converting to the rank numbers used by phe library. 
        return [rank, [cards]]
    }
    else{
        throw new Error(`NotImplementedError : lo hand evaluation for ${cards.length} is not implemented`)
    }
}

/**
 * Goal is to find the best low combination among provided minimumRankCombinations of the provided rank.
 * 
 * Algorithm :
 * For every combination, assign it a number.
 * 
 * evaluateCards(cards) --> strength : Better the hand lower the strength.
 * 
 * How to find strength of any combination ? Higher the strength better the low hand.
 * If rank is not straight or straight flush we first need to transform the cards. (A-->2, 2-->3, 3-->4, ...) (As ace is consider low) and then use function evaluateCards(cards) to obtain strength.
 * Else : Just use the function evaluateCards(cards) to give strength.
 * 
 * (Because for low or Hi (STRAIGHT OR STRAIGHT FLUSHES) ( [A,2,3,4,5] > [2,3,4,5,6] > [3,4,5,6,7] > ... > [T,J,Q,K,A] )
 *  
 * The above approach will work for every rank.
 * 
 * This strength will be used to find the best lo combination. 
 * Best lo combination will have maximum strenggth (As evaluateCards(cards) will assign lower number for better hi rank)
 * 
 * @param {Array} minimumRankCombinations combinations of minimum rank. Array of card hands [[], [], ...]
 * @param {Number} rank rank of the all combinations
 * @returns {Array} [strength of best low hand, best low combination]
 */
const findLoCardsFromMininumRank = (minimumRankCombinations, rank, settings = defaultLoSettings) => {
    const {countStraightAndFlushes, considerAceLow} = settings

    let maxHandStrength = Number.MIN_SAFE_INTEGER
    let  loHandCombination;
    for (let i = 0; i < minimumRankCombinations.length; i++) {
        const combination = minimumRankCombinations[i];
        let cardsCombination = combination;

        /**
         * If Ace is low and rank is not straight or straight flush. 
         * then we will map cards. A-->2, 2-->3, 3-->4, 4-->5, .....
         */
        if( (considerAceLow) &&
            (! (rank === RANKS.STRAIGHT || rank === RANKS.STRAIGHT_FLUSH))
        ){ 
            cardsCombination = combination.map(card => `${flipCards[card[0]]}${card[1]}`) 
        }

        const strength = ((! countStraightAndFlushes) && rank === RANKS.HIGH_CARD) ? assignStrengthToHighCardRankHand(cardsCombination, (considerAceLow) ? LoCardsValues : HiCardsValues) : evaluateCards(cardsCombination) 

        if(strength > maxHandStrength){
            maxHandStrength = strength
            loHandCombination = combination
        }
    }
    return [maxHandStrength, loHandCombination]
}

/**
 * Returns bestLohand and its strength.
 * @param {Array} cards 
 * @returns{Array} [bestHandStrength, bestLoHand, minimumRank]
 */
const evaluateLoCards = (cards, loSettings=defaultLoSettings) => {
    const [minimumRank, minimumRankCombinations] = getMinimumRank(cards, loSettings)
    const [bestHandStrength, bestLoHand] = findLoCardsFromMininumRank(minimumRankCombinations, minimumRank, loSettings)
    return [bestHandStrength, bestLoHand, minimumRank]
}


const sortPlayersAccordingToLoHand = (players, loSettings=defaultLoSettings) => {
    const loWinners = []
    for(const player in players){
        const cards = players[player]
        const [bestHandStrength, bestLoHand, bestLoRank] = evaluateLoCards(cards, loSettings)
        loWinners.push({clientName : player, bestHandStrength : bestHandStrength, bestLoHand : bestLoHand, bestLoRank : bestLoRank})
    }

    loWinners.sort(loPlayersSortFunction)
    return loWinners
}

// Return < 0 if A has better lo hand than B, > 0 otherwise. 0 if both have equal hands.
const loPlayersSortFunction = (playerA, playerB) => {
    const compRank = playerB.bestLoRank - playerA.bestLoRank
    if(compRank !== 0){return compRank}

    return playerB.bestHandStrength - playerA.bestHandStrength
}

/**
 * Algorithm : 
 * For every player find his minimumRank
 * Sort player based on their minimumRank.
 * Remove player who dont have minimumRank.
 * Return info about the player with minimumRank.
 * @param {Object} players Object with keys as players and values as player's cards. Ex : {"Jay" : ['As', '2c', ..], "Yash" : ['As', '2c', ..]}
 * @returns {Array} [minimumRankPlayerStrengths, minimumRankPlayersBestCombination, playersMinimumRanksAndBestCombinations]
 * 
 * minimumRankPlayerStrengths : Array of only those player who has best low rank. [{clientName : "Jay", strength : 982}, {clientName : "Yash", strength : 982}, ...] // Strength assigned to best combination of the Jay's cards is 982
 * minimumRankPlayersBestCombination : Dict containing only those player who has best low rank. {"Jay" : [// Best lo hand combination], "Yash": [// Best lo hand combination]}
 * playersMinimumRanksAndBestCombinations : Array to dicts [{clientName : "Jay", minimumRank : 2, cardsCombinationsOfMinimumRank : [[], [], [], ...]}, {}, {}, ...] // It will have an entry for every player.
 */
const getMinimumRankPlayersForLo = (players, loSettings = defaultLoSettings) => {
    const lo = [] 
    const lowHandsCombinations = {} // Just for visulization
    const loMinimumRanks = []
    
    for(const player in players){
        const cards = players[player]
        const [minimumRank, cardsCombinationsOfMinimumRank] = getMinimumRank(cards, loSettings)
        loMinimumRanks.push({
            clientName : player, 
            minimumRank : minimumRank, 
            cardsCombinationsOfMinimumRank : cardsCombinationsOfMinimumRank
        })  
    }

    loMinimumRanks.sort((playerA, playerB) => {return playerB.minimumRank - playerA.minimumRank}) // Lower rank first, high card --> Straigh FLush, decending order
    const lowestRank = loMinimumRanks.length > 0 ? loMinimumRanks[0].minimumRank : undefined
    for(const player of loMinimumRanks){
        if(player.minimumRank === lowestRank ){
            const [minHandStrength, loHandCombination] = findLoCardsFromMininumRank(player.cardsCombinationsOfMinimumRank, lowestRank, loSettings)
            lo.push({clientName : player.clientName, strength : minHandStrength})
            lowHandsCombinations[player.clientName] = loHandCombination
        }else{ break }
    }

    return [lo, lowHandsCombinations, loMinimumRanks]
}

/**
 * Returns the best combination of cards (best 5 cards in case of 7 cards input) and the best rank of the best combination
 * 
 * Ex : Input ['As', '2s', '2h', '3s', '3c', '4h', '5d'] --> Output [4 (straight), ['As', '2s', '3s', '4h', '5d']]
 * @param {Array} cards cards
 * @returns {Array} [best rank, best combination of cards]
 */
const getMaximumRank = (cards) => {
    let cardsCombinationsOfMaximumRank;
    let maximumStrength = Number.MAX_SAFE_INTEGER
    if(cards.length === 7){
        const maximumRank = rankCards(cards)
        for (let i = 0; i < combinations5choose7.length; i++) {
            const combination = combinations5choose7[i];
            let cardsCombination = combination.map((cardIndex) => {return cards[cardIndex-1]})
            const strength = evaluateCards(cardsCombination)
            if(strength < maximumStrength){ maximumStrength = strength; cardsCombinationsOfMaximumRank = cardsCombination }    
        }
        return [maximumRank, cardsCombinationsOfMaximumRank]
    }
    else if(cards.length === 5){
        const rank = rankCards(cards)
        return [rank, cards]
    }
    else if([1,2,3,4,6].includes(cards.length)){
        const rank = 9 - Hand.solve(cards).rank 
        return [rank, cards]
    }
    else if(cards.length === 9){
        // TODO : This logic only works for omaha
        const [handStrength, bestHand] = getBestHandandStrengthForOmaha(cards.slice(0,4), cards.slice(4))
        return [rankCards(bestHand), bestHand]
    }
    else{
        throw new Error(`NotImplementedError : lo hand evaluation for ${cards.length} is not implemented`)
    }
}

///////////////////////////////////////// Cards-Sorting-Functions-Start ////////////////////////////////////////////

export const sortCardsBasedOnValue = (cards, values) => {
    cards.sort((cardA, cardB) => {
        return values[cardB[0]] - values[cardA[0]]
    })
}

const evaluateEachCardFrequency = (cards) => {
    const frequency = {}
    for(const card of cards){
        frequency[card[0]] = (frequency[card[0]]) ? frequency[card[0]] + 1 : 1 
    }
    return frequency
}


const sortCardsBasedOnHandRank = (cards, rank, values) => {
    if([RANKS.ONE_PAIR, RANKS.TWO_PAIR, RANKS.THREE_OF_A_KIND, RANKS.FULL_HOUSE, RANKS.FOUR_OF_A_KIND].includes(rank)){
        const frequency = evaluateEachCardFrequency(cards)
        cards.sort((cardA, cardB) => {
            const comp =  frequency[cardB[0]] - frequency[cardA[0]] 
            if(comp === 0){
                return values[cardB[0]] - values[cardA[0]]
            }else {return comp}
        })
    }else{
        sortCardsBasedOnValue(cards, values)
    }
}

/**
 * Sorts cards based on parameter type.
 * 
 * #cards allowed : Low : 3,5,7.  High : 3,5,7
 *  
 * 
 * Ex-1 : Type : High  [As, Ac, Kc, 6d, 3d, 3h, Tc] → [As, Ac, 3d, 3h, Kc, Tc, 6d]
 * 
 * Ex-2 : Type : Low   [As, Ac, Kc, 6d, 3d, 3h, Tc] → [As, 3d, 6d, Tc, Kc, Ac, 3h]
 * 
 * @param {Array} cards : Cards to sort
 * @param {String} type : (High, Low)
 * @returns {Array} sorted cards according to the parameter type 
 */
 export const sortCards = (cards, type, loSettings = defaultLoSettings) => {
    const {countStraightAndFlushes, considerAceLow} = loSettings

    if(type === WINNING_TYPES.MIXED){ type = WINNING_TYPES.HIGH }

    let combination;
    let rank;

    if(type === WINNING_TYPES.HIGH){
        [rank, combination] = getMaximumRank(cards)
    }else if(type === WINNING_TYPES.LOW){
        const [minimumRank, cardsCombinationsOfMinimumRank] = getMinimumRank(cards, loSettings)

        let minHandStrength, Locombination
        if(cards.length === 7 ){
            [minHandStrength, Locombination] = findLoCardsFromMininumRank(cardsCombinationsOfMinimumRank, minimumRank, loSettings)
        }else{ Locombination = cards }

        combination = Locombination
        rank = minimumRank
    }else if(type === WINNING_TYPES.MIXED){
        [rank, combination] = getMaximumRank(cards)
    }
    else{
        throw new Error(`NotImplementedError : cards sorting for type : ${type} is not implemented. `)
    }

    const cardValues = (type === WINNING_TYPES.HIGH || (!considerAceLow)) ? HiCardsValues : LoCardsValues
    sortCardsBasedOnHandRank(combination, rank, cardValues) // It sorts upto 5 cards, because poker hands are based on 5 cards.

    // Now find remaining cards, sort them and append them.
    const remainingCards = []
    if([7,9].includes(cards.length)){
        const cardsSet = new Set(combination)
        for(const card of cards){
            if(!cardsSet.has(card)){ remainingCards.push(card) }
        }
    }
    
    sortCardsBasedOnValue(remainingCards, cardValues)
    
    const sortedCards = [...combination, ...remainingCards]
    return sortedCards

}

const defaultLoSettings = {"countStraightAndFlushes" : true, "considerAceLow": true}

export const sortHandsCompareFunction = (fieldToCompare, handA, handB) => {
    const winners = Hand.winners([handA, handB])
    if(winners.length === 2){ return 0 }
    else{
        if(winners[0][fieldToCompare] === handA[fieldToCompare]) { return 1}
        else{return -1}
    }
}

const getCombinations = (arr, n, r, combinations) => {
    const data = Array(r);
    combinationUtil(arr, data, 0, n - 1, 0, r, combinations); 
}
  
const combinationUtil = (arr, data, start, end, index, r, combinations) => {
    if (index === r){
        combinations.push([...data])
        return
    }
        
    let i = start;  
    while(i <= end && end - i + 1 >= r - index){
        data[index] = arr[i]; 
        combinationUtil(arr, data, i + 1, end, index + 1, r, combinations); 
        i += 1; 
    }      
}

export const orderCardsHomeRunType1 = (cards, settingsOrder, noOfCardsInSetting, validateSettingsRank) => {
    const set = new Set(cards)
    
    let level1Combinations = []
    getCombinations(cards, cards.length, noOfCardsInSetting[settingsOrder[0]], level1Combinations)
    level1Combinations.sort(sortCombinationsCompareFunction)

    for(const level1combination of level1Combinations){
        let level2Combinations = []
        const remainingCards = cards.filter(card => ! level1combination.includes(card))
        getCombinations(remainingCards, remainingCards.length, noOfCardsInSetting[settingsOrder[1]], level2Combinations)
        level2Combinations.sort(sortCombinationsCompareFunction)

        for(const level2Combination of level2Combinations){

            const cardsUsed = new Set([...level1combination,...level2Combination])
            const settings = {
                [settingsOrder[0]] : level1combination,
                [settingsOrder[1]] : level2Combination,
                [settingsOrder[2]] : cards.filter(card=>!cardsUsed.has(card))
            }

            for(const setting in settings){
                settings[setting] = sortCards(settings[setting], WINNING_TYPES.HIGH).reverse()
            }

            if(validateSettingsRank(settings)){
                return swapPairs(settings, validateSettingsRank)
            }
        }
    }

    return {}
}

const SETTINGS = {
    FRONT : "Front",
    BACK : 'Back',
    MIDDLE : 'Middle'
}

const swapPairs = (settings, validateSettingsRank) => {
    const TYPES = {
        "DEST" : "DEST",
        "SRC" : "SRC"
    }

    const copySettings = (settings) => {
        return {
            [SETTINGS.FRONT] : [...settings[SETTINGS.FRONT]],
            [SETTINGS.BACK] : [...settings[SETTINGS.BACK]],
            [SETTINGS.MIDDLE] : [...settings[SETTINGS.MIDDLE]]
        }
    }

    const getSwappablePairofHand = (hand, type) => {
        const handSolve = Hand.solve(hand)
        const sortedCards = sortHiHand(hand, {})

        if(handSolve.rank === RANKS_PS.FULL_HOUSE){ return sortedCards.slice(3,5) }
        if(handSolve.rank === RANKS_PS.TWO_PAIR){ return sortedCards.slice(2,4) }

        if(type === TYPES.DEST){
            if(handSolve.rank === RANKS_PS.ONE_PAIR) { return sortedCards.slice(0,2)}
        }
        
        return []  
    }

    const removeAndAddCardsInHand = (cardsToReplace, srcHand, srcDest) => {
        for(const card of cardsToReplace){
            srcHand.splice(srcHand.indexOf(card), 1)
            srcDest.push(card)
        }
    }

    const priorities = [
        [SETTINGS.BACK, SETTINGS.FRONT], 
        [SETTINGS.MIDDLE, SETTINGS.FRONT],
        [SETTINGS.BACK, SETTINGS.MIDDLE]
    ]

    let settingsToReturn = copySettings(settings)

    for(const priority of priorities){
        const settingsCopy = copySettings(settingsToReturn)

        const PAIRS_THAT_CAN_BE_SWAPED = {
            [priority[0]] : getSwappablePairofHand(settingsCopy[priority[0]], TYPES.SRC),
            [priority[1]] : getSwappablePairofHand(settingsCopy[priority[1]], TYPES.DEST)
        }

        const sourceBestPair = PAIRS_THAT_CAN_BE_SWAPED[priority[0]]
        const destWorstPair =  PAIRS_THAT_CAN_BE_SWAPED[priority[1]]

        if(!(sourceBestPair.length > 0 && destWorstPair.length > 0)){ continue }

        const sourcePairRank = HiCardsValues[sourceBestPair[0][0]]
        const destPairRank = HiCardsValues[destWorstPair[0][0]]

        if(sourcePairRank <= destPairRank){ continue }

        const sourceHand = settingsCopy[priority[0]]
        const destHand =  settingsCopy[priority[1]]

        removeAndAddCardsInHand(sourceBestPair, sourceHand, destHand)
        removeAndAddCardsInHand(destWorstPair, destHand, sourceHand)

        if(validateSettingsRank(settingsCopy)){ settingsToReturn =  settingsCopy }
    }

    return settingsToReturn
}

const sortCombinationsCompareFunction = (combinationA, combinationB) => {
    const bestCombination = selectBestCombination([combinationA, combinationB])  
    if(bestCombination === combinationA && bestCombination === combinationB){
        return 0
    }
    else if(bestCombination === combinationA){
        return -1
    }
    else{
        return 1
    }
}

const selectBestCombination = (combinations) => {
    const noOfCards = combinations[0].length
    let bestCombination;

    if([5,6,7].includes(noOfCards)){
        let minHandStrength = Number.MAX_SAFE_INTEGER
        for(const combination of combinations){
            const strength = evaluateCards(combination)
            if(strength < minHandStrength){
                minHandStrength = strength
                bestCombination = combination
            }
        }
        return bestCombination
    }
    else if([3].includes(noOfCards)){
        const hands = []
        for(const combination of combinations){
            const hand = Hand.solve(combination)
            hand.combination = combination
            hands.push(hand)
        }
        const winners = Hand.winners(hands)
        return winners[0].combination
    }
}


export const sortBySuit = (cards, cardValues) => {
    //sort-by-suit (and # within that suit)
    const suitValues = { 'd' : 0,  's' : 1,  'c' : 2,  'h' : 3 } 

    cards.sort((cardA, cardB) => {
        const cmp = suitValues[cardB[1]] - suitValues[cardA[1]]
        if(cmp === 0){
            return cardValues[cardA[0]] - cardValues[cardB[0]]
        }
        else { return cmp }
    })
}

const getBestHandandStrengthForOmaha = (playerCards, communityCards) => {
    /**
     * We must use 2 cards from player cards. And hence we can only exactly use 3 cards from community
     */
    let minHandStrength = Number.MAX_SAFE_INTEGER
    let minHand;
    for(let i = 0; i <= 2; i++){
        for(let j = i+1; j <= 3; j++){
            for(const communityCombination of threeChooseFive){
                const hand = [playerCards[i], playerCards[j], ...communityCombination.map(i => communityCards[i-1])]
                const cardsStrength = evaluateCards(hand)
                if(cardsStrength < minHandStrength){
                    minHandStrength = cardsStrength
                    minHand = hand 
                }
            }
        }
    }

    return [minHandStrength, minHand]
}

const threeChooseFive = [
    [ 3, 4, 5 ], [ 2, 4, 5 ],
    [ 2, 3, 5 ], [ 2, 3, 4 ],
    [ 1, 4, 5 ], [ 1, 3, 5 ],
    [ 1, 3, 4 ], [ 1, 2, 5 ],
    [ 1, 2, 4 ], [ 1, 2, 3 ]
]

const isFiveOfAKind = (cards) => {
    const frequencies = {}
    for(const card of cards){
        const value = card[0]
        const eleFrequency = frequencies[value]
        frequencies[value] = (eleFrequency === undefined) ? 1 : eleFrequency + 1
        if(frequencies[value] === 5){ return value }
    }
    return undefined
}
export const getHiHandDescription = (cards) => {
    const cardNo = isFiveOfAKind(cards)
    if(cardNo){
        return `Five of a Kind, ${cardNo}'s`
    }
    else{
        const hand = Hand.solve(cards)
        return hand.descr
    }
}