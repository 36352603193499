import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {loginUser} from '../services/room';
import './Rooms/CreateRoom.css';
//import {createRoomHandler} from "../../services/room";
//import Select from 'react-select';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eMail: "",
            password: "",
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const {name, value, type, checked} = event.target;
        type === "checkbox" ? this.setState({[name]: checked}) : this.setState({[name]: value})
    }

    onLogin = async () => {
        let {eMail, password} = this.state;
        let response = await loginUser(eMail, password);
    }

    render() {
        return (
            <div className="joinOuterContainer">
                <div className="joinInnerContainer">
                    <div className="heading mb-2"><h1>Login</h1></div>
                    <div>
                        <input placeholder="E-Mail" name="eMail" className="createRoomInput" type="text"
                               onChange={this.handleChange}/>
                    </div>
                    <div>
                        <input placeholder="Password" name="password" className="createRoomInput mt-20" type="text"
                               onChange={this.handleChange}/>
                    </div>
                    <div onClick={this.onLogin}>
                        <Link
                            onClick={e => (!this.state.eMail || !this.state.password) ? e.preventDefault() : null}
                            to={`/home?home`}>
                            <button className={'button mt-20'} type="submit">
                                Login
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
