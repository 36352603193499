import firebase from 'firebase/app';
const config = {
    apiKey: "AIzaSyBQpbFc5Jk5ekcWey9Z87LmuE3DQk1g000",
    authDomain: "cards4u-53be2.firebaseapp.com",
    databaseURL: "https://cards4u-53be2.firebaseio.com",
    projectId: "cards4u-53be2",
    storageBucket: "cards4u-53be2.appspot.com",
    messagingSenderId: "126732093593",
    appId: "1:126732093593:web:78e1b1fb10cdc7c1a8d008",
    measurementId: "G-JPT4X6501W"
}
firebase.initializeApp(config);
export const db = firebase.firestore()
export const auth = firebase.auth
export default firebase;

auth().onAuthStateChanged(async user => {
  if (user) {
     console.log('Logged IN', user);
    return user;
  }else{
    console.log("Logout")
  }
});

export const getCustomClaimRole = async function(){
  await firebase.auth().currentUser.getIdToken(true);
  const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
  return decodedToken.claims.stripeRole;
}

export const updatePaymentInfoUser = async function(paymentInfo){
  const currentUser = firebase.auth().currentUser
   console.log('VALUES SET');
  return await db.collection("customers").doc(currentUser.uid).update({paymentInfo: paymentInfo});
}

// export const getPaymentInfoUser = async function(){
//   const currentUser = firebase.auth().currentUser
//   // const doc = await db.collection("customers").where(currentUser.uid).get()
//   const doc = await db.collection("customers").where("id", "==", currentUser.uid).get() 
//   if(doc){return doc["paymentInfo"]}
//   else return null
// }

export const getPaymentInfoUser = async function(){
  const currentUser = firebase.auth().currentUser
  const snapshot = await db.collection("customers").doc(currentUser.uid).get()
  if(snapshot){
    const data = snapshot.data()
    return data["paymentInfo"]
  }
  else return null
}